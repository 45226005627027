import React from 'react';

import classNames from 'classnames';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import { useState } from 'react';
import { addressRequest, addressRequestPlace, setFinishAddressStr } from '../../lib/AddressApi';

const AddressInput = ({
	register,
	setValue,
	zipRegister,
	address1Register,
	address2Register,
	zipError,
	address1Error,
	address2Error,
	handleAddressChange,
	target,
	selectAddress = false,
	onButton,
	getValues,
}) => {
	const [addressData, setAddressData] = useState('hello');
	const [addressList, setAddressList] = useState([]);
	const [addressLoading, setAddressLoading] = useState(false);

	var typingTimer = null;
	var doneTypingInterval = 200;

	// 검색 개선버전.
	// 검색시 타이머 적용. 콜수 최소화
	const asyncAddress2 = async (e) => {
		// console.log(e.target.value)
		// setAddressData(e.target.value)
		//await this.setStateAddressListLoading()

		// 변경이 있을때마다 기존의 타임아웃을 지워버리고, 아래에서 다시 타임아웃을 등록하고,
		// 입력이 없을때 timeout이 발생한 다음 timeout종료.
		clearTimeout(typingTimer);
		setAddressLoading(true);
		try {
			if (e.target.value.length >= 1) {
				// addressName이 존재할때 timeout을 건다.
				typingTimer = setTimeout(async () => {
					let adr_result = await addressRequestPlace(e.target.value);

					// 장소 검색 결과 0이라면
					if (adr_result.meta.total_count === 0) {
						// 주소로 검색
						adr_result = await addressRequest(e.target.value);
					}

					setAddressList(adr_result.documents);
					setAddressLoading(false);
					//await this.setStateAddressListLoadingEnd()
					// console.log(adr_result.documents)
				}, doneTypingInterval);
			} else {
				setAddressList([]);
			}
		} catch (e) {
			setAddressList([]);
		}

		setAddressLoading(false);
	};

	return (
		<>
			<div className={classNames('input-wrap input-with-button')}>
				<Input
					type={'text'}
					required
					error={address1Error}
					{...register(address1Register)}
					// onChange가 동작 안하고 있어서 onInput으로 해봤는데 일단 ㅇㅋ
					// 영향범위가 있을런지는 모르겠음.
					onKeyUp={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						} else {
							asyncAddress2(e);
						}
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
					onChange={(e) => {
						// handleAddressChange('zip', e.target.value, target);
						setValue(address1Register, e.target.value);
					}}
					placeholder={'주소'}
				/>

				{selectAddress && (
					<Button
						type={'button'}
						styleType={'tertiary'}
						classes={'button-small'}
						onClick={(e) => {
							onButton(e);
						}}
					>
						불러오기
					</Button>
				)}
			</div>

			{addressList.length >= 1 && (
				<>
					<div style={{ display: 'flex', flexDirection: 'column', background: '#fff', border: '1px solid #eee' }}>
						{/* <div>{addressLoading ? '로딩중' : ''}</div> */}
						{addressList.map((data, idx) => {
							if (!addressList.length) return <div key={idx}>검색 결과가 없습니다.</div>;

							return (
								<div
									style={{ padding: '10px 10px', border: '1px solid #eee' }}
									key={idx}
									onClick={() => {
										setValue(address1Register, setFinishAddressStr(data));
										handleAddressChange('zip', setFinishAddressStr(data), target);
										setAddressList([]);
									}}
								>
									{setFinishAddressStr(data)}
								</div>
							);
						})}
					</div>
				</>
			)}
		</>
	);
};

export default AddressInput;
