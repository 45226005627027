import React from 'react';
import classNames from 'classnames';

const PageButton = ({ pageNo, currPage, onClick, ...props }) => {
  return (
    <button
      className={classNames('page-number', currPage === pageNo && 'selected')}
      onClick={onClick}
      {...props}
    >
      {pageNo}
    </button>
  );
};

export default PageButton;
