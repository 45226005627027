import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {transFormWon} from '../../utils/numbers';
import useCheckPC from '../../hooks/useCheckPC';

const BookingItemRadio = (
  {
    selected,
    subtitle,
    isAlignCenter = false,
  }) => {
  const { isPC } = useCheckPC();

  return(
    <>
      <div className={classNames('custom-radio-wrapper')}>
        <div className={classNames('custom-radio', selected && 'active', isAlignCenter && 'align-center')}>
          <div className={classNames('circle')}></div>
        </div>
        <div className={classNames('custom-label')}>{subtitle}</div>
      </div>
    </>
  )
}

BookingItemRadio.propTypes = {
  hasCheckBox: PropTypes.bool,
  children: PropTypes.node,
};

export default BookingItemRadio;