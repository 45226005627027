import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import {
  DriverDispatchProducts,
  WeekendUsageOptions,
  ConfirmEstimateDescription,
  MonthlyMosilerOption,
  RecruitPurposeOption,
  RouteOptions,
  ConciergeAdviceOptions,
  EtcRequestOptions,
  UsageTimeOptionsDriverDispatch,
  UsageTimeOptionsDriverDispatchPagyun
} from '../../../utils/EstimateFormData';
import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListBox from '../../atoms/ListBox';
import TotalAmount from '../../atoms/TotalAmount';
import useCheckPC from '../../../hooks/useCheckPC';
import ListWithBullet from '../../atoms/ListWithBullet';
import { getCalcSalaryDetailEstimate } from '../../../lib/DriverDispatchApi';
import moment from 'moment';
import { setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { closeLoading, closeLoadingWithEstimate, openLoading, openLoadingWithEstimate } from '../../../reducers/loadingRedux';
import TotalAmountDriverDispatch from '../../atoms/TotalAmountDriverDispatch';





import vutler_dojang from '../../../styles/img/vutler_dojang.png'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getDdEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import ListBox3 from '../../atoms/ListBox3';
import NewListBox from '../../atoms/NewListBox';

/**
 * 견적 확인 페이지
 * name : confirmEstimate
 * */

const EstimateDocsDownload = () => {
  const navigate = useNavigate();

  const [UserName, SetUserName] = useState('')
  const [EmailAddress, SetEmailAddress] = useState('')

  const { isPC } = useCheckPC();
  const { DriverDispatchOption } = useSelector((state) => state);


  const [loading, setLoading] = useState(false)
  const [firstProcess, setFirstProcess] = useState(false)

  const [ConfirmEstimateDescription2, SetConfirmEstimateDescription2] = useState([])

  /** 상품명 **/
  const [subscription, setSubscription] = useState([]);


  /** 기본정보 **/
  const [basicInfomation, setbasicInfomation] = useState([]);

  const [basicOption, setbasicOption] = useState([
    {
      title: `연 4회 맞춤형 기사 교육(분기당 1회)`,
    },
    {
      title: `기사 자동 관리 프로그램 제공`,
    }
  ]);

  /** 선택옵션 **/
  const [optionData, setOptionData] = useState([]);

  /** 상세견적 (임금) **/
  const [estimateStep1, setEstimateStep1] = useState([]);

  /** 상세견적 (제세공과금) **/
  const [estimateStep2, setEstimateStep2] = useState([]);

  /** 상세견적 (관리비용) **/
  const [estimateStep3, setEstimateStep3] = useState([]);

  /** 상세견적 (이익금) **/
  const [estimateStep4, setEstimateStep4] = useState([]);

  /** 견적 총 금액 **/
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState([
    { price: 0 }
  ]);

  /* ------------------- 아래 삭제 예정 */

  /** 이용 시간**/
  const [usageTime, setUsageTime] = useState([]);
  /** 차량 옵션 **/
  const [vehicleOption, setVehicleOption] = useState([]);
  /** 계약 조건 **/
  const [periodOfContract, setPeriodOfContract] = useState([]);
  /** 이용 지역 **/
  const [serviceArea, setServiceArea] = useState([])
  /** 이용 정보 **/
  const [contractTerms2, setContractTerm2] = useState([]);

  /* ------------------- 아래 삭제 예정 end */


  const sleep = (time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, time);
    })
  }


  const downloadPdfData = async () => {
    let canvas = await html2canvas(document.querySelector('#estimate_form'), {
      width: 635,
      // height:891
      height: 2000
    })
    //200

    let imgData = canvas.toDataURL('image/png')

    let pdf = new jsPDF('p', 'mm', 'a3')
    pdf.addImage(imgData, 'PNG', 70, 10, 160, 490, '', 'MEDIUM')
    // pdf.addImage(imgData, 'PNG', 10, 10, 140, 210)
    pdf.save(`견적서_${moment().format('yyyyMMDDHHssmm')}`)
    return true
  }

  useEffect(() => {

    let data = getDdEstimateCookieDataAndDecrypt()
    SetUserName(data.UserName)
    SetEmailAddress(data.EmailAddress)

    const YearSalary = Number(DriverDispatchOption?.basicInfo2?.selectedSalary) * 10000

    /** 상품명 **/
    const updatedSubscription = [
      {
        title: getValueByTarget(
          DriverDispatchProducts,
          DriverDispatchOption.subscription,
          'title'
        )
        // ,
        // price: getValueByTarget(
        //   DriverDispatchProducts,
        //   DriverDispatchOption.subscription,
        //   'price'
        // )
      }]
    setSubscription(updatedSubscription)


    /** 기본정보 **/
    const updateBasicInfomation = [
      {
        title: '상품',
        value: DriverDispatchOption.subscription === 'recruit' ? 
          getValueByTarget(UsageTimeOptionsDriverDispatch, DriverDispatchOption?.usageTime, 'subtitle2') + " (근무 조건은 상담을 통해 맞춤 설정 가능)" : 
          getValueByTarget(UsageTimeOptionsDriverDispatchPagyun, DriverDispatchOption?.usageTime, 'subtitle2'),
      },
      {
        title: `차고지 주소`,
        value: `${DriverDispatchOption?.basicInfo2?.garageAddress}`,
      },
      {
        title: `회사 주소`,
        value: `${DriverDispatchOption?.basicInfo2?.companyAddress}`,
      },
      {
        title: `필요 시점`,
        value: `${moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy년 MM월 DD일')}`,
      },
    ]
    setbasicInfomation(updateBasicInfomation)

    /** 옵션 **/
    const updateOptionInfo = [
      {
        title: `면접자 1일 운행 체험 서비스`,
        value: `${getValueByTarget(MonthlyMosilerOption, DriverDispatchOption?.options?.monthlyMosilerDemo, 'title')}`
      },
      {
        title: '채용 목적',
        value: getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'title'),
      }
    ]
    setOptionData(updateOptionInfo)



    /** 견적 상세 정보 **/
    {
      let obj = getCalcSalaryDetailEstimate(YearSalary)
      const totalToHapgye = (str) => {
        return (str === 'total') ? '합계' : str
      }

      const updateEstimateStep1 = []
      for (let i in Object.keys(obj.step1)) {
        let str = Object.keys(obj.step1)[i]
        updateEstimateStep1.push({ title: totalToHapgye(str), price: obj.step1[str] })
      }

      const updateEstimateStep2 = []
      for (let i in Object.keys(obj.step2)) {
        let str = Object.keys(obj.step2)[i]
        updateEstimateStep2.push({ title: totalToHapgye(str), price: obj.step2[str] })
      }

      const updateEstimateStep3 = []
      for (let i in Object.keys(obj.step3)) {
        let str = Object.keys(obj.step3)[i]
        updateEstimateStep3.push({ title: totalToHapgye(str), price: obj.step3[str] })
      }

      const updateEstimateStep4 = []
      for (let i in Object.keys(obj.step4)) {
        let str = Object.keys(obj.step4)[i]
        updateEstimateStep4.push({ title: totalToHapgye(str), price: obj.step4[str] })
      }

      setEstimateStep1(updateEstimateStep1)
      setEstimateStep2(updateEstimateStep2)
      setEstimateStep3(updateEstimateStep3)
      setEstimateStep4(updateEstimateStep4)

      setEstimatedTotalAmount([{
        title: '총 금액 (임금 + 제세공과금 + 관리비용 + 이익금)',
        price: obj.totalEstimateAmount
      }])


      // const seoulResult = isChkAddressSeoul(BookingOption?.contractTerms1?.serviceArea?.departure?.address1, BookingOption.contractTerms1.serviceArea.destination.address1)
      const addedArray = [];
      let timeData = ''
      if (DriverDispatchOption?.subscription === 'recruit') {
        addedArray.push('본 견적은 채용 수수료에 대한 견적이며, 채용 인원의 급여는 직접 지급해 주시기 바랍니다')
        addedArray.push('실제 계약에 반영되는 내용에 따라 조정될 수 있습니다')
        addedArray.push('채용 수수료는 채용 시 최초 1회에 한하여 발생되는 금액입니다.')
      }
      else {
        addedArray.push('본 견적은 월 단위 금액으로써 산출되었으며, 실제 계약에 반영되는 내용에 따라 조정될 수 있습니다.')
        addedArray.push('휴일근로 및 특근수당은 근무시간에 따라 별도 청구될 수 있습니다.')
        addedArray.push('퇴직/연차급여충당금은 연 단위의 계약 만료 시 발생여부에 따라 별도 청구됩니다.')
        addedArray.push('법정비용은 2023년 1월 1일 기준 노동부 고시된 법정요율을 적용하였습니다. (중도 변동 시 변경될 수 있음)')
      }


      SetConfirmEstimateDescription2([
        ...addedArray
      ])
      setLoading(true)

      const ProcessFunc = async () => {
        if (!firstProcess) {
          await sleep(2000)
          await downloadPdfData()
          await sleep(1000)

          window.close()
        }
        setFirstProcess(true)
      }
      ProcessFunc()

    }
  }, [DriverDispatchOption])




  return (
    <>
      <div style={{ width: '100%', height: '100%', position: 'absolute', backgroundColor: 'white', textAlign: 'center', fontSize: '1.2em', paddingTop: '30px' }}>
        <div>현재 견적서 다운로드 처리를 위해 작업중입니다.</div>
        <div>잠시만 기다려주십시오.</div>
        <div>이 창을 닫지 말아주세요.</div>
        <div className="loader loader-9"></div>
      </div>

      <div id="estimate_form" style={{
        padding: '30px 20px',
        width: '635px',
        height: '891px'
      }}>
        <div className={classNames('booking-wrap2')}>
          <PageTitle title={'MOSILER'} subtitle={DriverDispatchOption?.subscription === 'recruit' ? '모시러 채용 상품 견적서' : '모시러 파견 상품 견적서'} />

          <div className={classNames('confirm-booking-wrap2')}>
            <div className={classNames('confirm-booking-content2')}>
              <div className={classNames('new-list-box')}>
                <div className={classNames('estimate')}>
                  <div className={classNames('estimate-sub')}>
                    <div className={classNames('title')}>PREPARED FOR</div>
                    <div className={classNames('content')}>
                      <div><strong>{UserName}</strong> 고객님</div>
                      <div>{EmailAddress}</div>
                    </div>
                  </div>
                  <div className={classNames('estimate-sub')}>
                    <div className={classNames('title')}>PREPARED BY</div>
                    <div className={classNames('content')}>
                      <div>(주)버틀러</div>
                      <div>463-87-00162</div>
                      <div>서울 강남구 역삼로 160 5층</div>
                    </div>
                  </div>

                  <div className={classNames('estimate-sub-date')}>
                    <div className={classNames('flex')}>
                      <div className={classNames('title')}>PREPARED DATE</div>
                      <div className={classNames('date')}>{moment().format('yyyy년 MM월 DD일')}</div>
                    </div>
                    <div className={classNames('flex')}>
                      <div className={classNames('title')}>EXPIRY DATE</div>
                      <div className={classNames('date')}>{moment().add(1, 'months').add(-1, 'days').format('yyyy년 MM월 DD일')}</div>
                    </div>
                  </div>

                </div>
              </div>


              {/* Options */}
              <div className={classNames('new-list-box')}>
                <div>
                  <div className={classNames('option title')}>OPTIONS</div>
                  <div className={classNames('option wrap')}>
                    {basicInfomation.map((item, idx) => (
                      <div key={idx} className={classNames('option list estimateview')}>
                        <div >{item.title}</div>
                        <div >{item.value}</div>
                      </div>
                    ))}
                    <br />

                    {optionData.map((item, idx) => (
                      <div key={idx} className={classNames('option list estimateview')}>
                        <div >{item.title}</div>
                        <div >{item.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>


              {/** 상품명 **/}
              <NewListBox
                salary={DriverDispatchOption?.usageTime}
                subscript={DriverDispatchOption?.subscription}
                title={'상품명'}
                listItem={subscription}
              />



              {/* <ListBox title={'상품명'} listItem={subscription} />
              <ListBox title={'기본정보'} listItem={basicInfomation} />
              <ListBox title={'기본옵션'} listItem={basicOption} />
              <ListBox3 title={'옵션'} listItem={optionData} />
              <ListBox listItem={estimateStep1} title={'임금(직간접인건비)'} />
              <ListBox listItem={estimateStep2} title={'제세공과금'} />
              <ListBox listItem={estimateStep3} title={'관리비용'} />
              <ListBox listItem={estimateStep4} title={'이익금'} />
              <ListBox listItem={estimatedTotalAmount} title={'견적 총 금액'} />
              <TotalAmountDriverDispatch styleType={'secondary'} amount={estimatedTotalAmount[0].price} title={'견적 총 금액'} />  */}

            </div>

            <div className={classNames('box-light-grey-1 footer-contents2')} style={{ padding: '25px 25px', marginBottom: '20px' }}>
              <div style={{ color: 'var(--blue)', paddingBottom: '15px' }}>NOTICE</div>
              <ul className={classNames('list-style')} style={{ gap: '3px' }}>
                {ConfirmEstimateDescription2.map((item, index) => (
                  <li key={index}>
                    <span className={classNames('grey-2')} dangerouslySetInnerHTML={{ __html: item }}>
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginBottom: '100px' }}>
              <div style={{ fontSize: '18px' }}>
                <span style={{ display: 'block', padding: '5px 0', fontSize: '18px', fontWeight: 'bold' }}>주식회사 버틀러</span>
                <span style={{ display: 'block', padding: '5px 0', fontSize: '18px' }}>서울 강남구 역삼로 160. 5층</span>
              </div>
              <div style={{ fontSize: '18px', textAlign: 'right' }}>
                <span style={{ display: 'block', padding: '5px 0', fontSize: '18px' }}>463-87-00162</span>
                <span style={{ display: 'block', padding: '5px 0', fontSize: '18px' }}>이근우</span>
              </div>
              <div style={{ textAlign: 'left' }}>
                <span style={{ display: 'block', fontSize: '18px' }}><img src={vutler_dojang} style={{ width: '80%' }} alt='' /></span>
              </div>
            </div> */}
          </div>
        </div>
      </div>

    </>

  )
}

export default EstimateDocsDownload;