import { URLS } from './urls';

export const ReservationMenuList = [
  {
    title: '이용예약',
    path: URLS.RESERVATION_CREATE,
    type: 'menu'
  },
  {
    title: '이용내역',
    path: URLS.RESERVATION_HISTORY,
    type: 'menu'
  },
  // {
  //   title: '결제관리',
  //   path: URLS.RESERVATION_MANAGE_PAYMENTS,
  //   type: 'menu'
  // },
  {
    title: '마이페이지',
    path: URLS.RESERVATION_MY_PAGE,
    type: 'menu'
  },
  {
    title: '로그인',
    path: URLS.EMAIL_LOGIN,
    type: 'login'
  },
  // {
  //   title: '회원가입',
  //   path: URLS.REGISTER,
  //   type: 'login'
  // }
]

export const ReservationTableTitleList = [
  {
    title: '구분',
    target: 'status',
  },
  {
    title: '일시',
    target: 'usedDate',
  },
  {
    title: '차감시간',
    target: 'items'
  },
  {
    title: '항목',
    target: 'items',
  },

  {
    title: '후불청구액',
    target: 'items'
  },
  {
    title: '취소',
  },
  {
    title: '상세'
  }
]

export const ReservationTableTitleSummaryList = [
  {
    title: '구분',
    target: 'status',
  },
  {
    title: '일시',
    target: 'usedDate',
  },
  {
    title: '',
  }
]


export const DriverDispatchTableTitleList = [
  {
    title: '구분',
    target: 'status',
  },
  {
    title: '일시',
    target: 'usedDate',
  },
  {
    title: '항목',
    target: 'items',
  },
  {
    title: '총 업무시간',
    target: 'items'
  },
  {
    title: '후불청구액',
    target: 'items'
  },
  {
    title: '이용 키로수',
  },
  {
    title: '이슈사항'
  }
]

export const DriverDispatchTableTitleSummaryList = [
  {
    title: '구분',
    target: 'status',
  },
  {
    title: '일시',
    target: 'usedDate',
  },
  {
    title: '',
  }
]