import classNames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import Icon from './Icon';


const Checkbox = forwardRef(({ label, classes, children, ...props }, ref) => {
  const id = useMemo(() => props.id ?? `checkbox-${uniqueId()}`, [props.id]);
  const [isChecked, setIsChecked] = useState(props.checked);

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  return (
    <span className={classes}>
      <input
        id={id}
        ref={ref}
        type={'checkbox'}
        onChange={e => {
          setIsChecked(e.target.checked);
          props.onChange?.(e);
        }}
        {...props}
      />
      <label htmlFor={id} className={classNames('checkbox-wrap')}>
        {isChecked && <Icon icon={'checkbox_checked'} /> }
        {!isChecked && <Icon icon={'checkbox'} />}
        {children ?? (label && <span>{label}</span>)}
      </label>
    </span>
  );
});

export default Checkbox;

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};
