import React, { useState } from 'react';
import useCheckPC from '../../hooks/useCheckPC';
import classNames from 'classnames';
import Button from './Button';
import Icon from './Icon';

const WhiteBox = ({ classes, title, subTitle, children, showDetailButton = false }) => {
	const [showDetail, setShowDetail] = useState(false);
	const { isPC } = useCheckPC();
	return (
		<div className={classNames('white-box', classes && classes)}>
			{(title || subTitle || showDetailButton) && (
				<div className={classNames('title-set')}>
					<div>
						{title && <span className={classNames('title', !subTitle ? 'mb-20' : 'mb-8', isPC ? 'title-mid-small' : 'title-mid', 'bold')}>{title}</span>}
						{subTitle && <span className={classNames('txt-regular title blue', (showDetail || !showDetailButton) && 'mb-28')}>{subTitle}</span>}
					</div>
					{showDetailButton && !isPC && (
						<>
							{showDetail ? (
								<Button styleType={'icon'} classes={'transparent'} onClick={() => setShowDetail(false)}>
									<Icon icon={'hide_more'} />
								</Button>
							) : (
								<Button styleType={'icon'} classes={'transparent'} onClick={() => setShowDetail(true)}>
									<Icon icon={'show_more'} />
								</Button>
							)}
						</>
					)}
				</div>
			)}
			{(showDetail || !showDetailButton) && children}
		</div>
	);
};

export default WhiteBox;
