import React from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

const RadioContext = createContext({});
export default RadioContext;

export const RadioProvider = ({ children, selected: selectedProp }) => {
  const [selected, setSelected] = useState(selectedProp ?? -1);
  const data = useMemo(
    () => ({
      selected,
      setSelected,
    }),
    [selected],
  );

  return <RadioContext.Provider value={data}>{children}</RadioContext.Provider>;
};

export const useRadioContext = () => {
  const context = useContext(RadioContext);
  if (!context) throw new Error('useRadioContext must be used within a RadioProvider');
  return context;
};

export const withRadioProvider = WrappedComponent => {
  const Component = props => {
    return (
      <RadioProvider selected={props.selected}>
        <WrappedComponent {...props} />
      </RadioProvider>
    );
  };

  Component.displayName = `useRadioContext(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return Component;
};
