const initialUserData = {
    User: {
        CustomerNo: 0,
        CustomerName: '',
        UserId: '',
        UserPw: '',
        AccountType: '',
        Email: '',
        HomeAddress: '',
        PhoneNumber: '',
        CarName: '',
        CarNo: '',
        DeviceId: '',
        DeviceRegId: '',
        OsType: '',
        PushYn: '',
        Change: 0,
        PrePaidTicketAmount: 0,
        LoginEncrypt: '',
        HourPrice: 0,
        IsOldFare: false,
        CustomerCategory: 0,
        ParentCustomerNo: 0,
        UseType: 0,
        Roles: 0,
    },
    UserPool: [
        {
            CustomerNo: 0,
            CustomerName: '',
            UserId: '',
            AccountType: '',
            PhoneNumber: '',
            ParentCustomerNo: 0,
            UseType: 1,
            Roles: 0,
            MemberType: ''
        }
    ],
}

const BOOKERPAGE_LOGIN_USER = 'BOOKERPAGE/LOGIN/USER';
const BOOKERPAGE_LOGIN_USERPOOL = 'BOOKERPAGE/LOGIN/USERPOOL';
const BOOKERPAGE_LOGOUT = 'BOOKERPAGE/LOGOUT';

export const bookerPageLoginUser = (data) => ({
    type: BOOKERPAGE_LOGIN_USER,
    payload: data
})
export const bookerPageLoginUserPool = (data) => ({
    type: BOOKERPAGE_LOGIN_USERPOOL,
    payload: data
})
export const bookerPageLogout = () => ({
    type: BOOKERPAGE_LOGOUT
})

export const Login = (state = initialUserData, action) => {
    if (action.type === BOOKERPAGE_LOGIN_USER) {
        
        return {
            ...state,
            User: action.payload,
        }
    }
    else if (action.type === BOOKERPAGE_LOGIN_USERPOOL) {
        
        return {
            ...state,
            UserPool: action.payload,
        }
    }
    else if (action.type === BOOKERPAGE_LOGOUT) {
        return {
            ...state,
        }
    }

    return {
        ...state,
    };
};