import React from 'react';
import '../../../styles/greyIconSet.css';

const GreyIconSet = ({ iconName, title, subtitle }) => {
	return (
		<>
			<div className='grey-icon-set-wrap'>
				{iconName && <img src={`/assets/${iconName}.png`} alt={`${iconName} icon`} className='icon' />}
				<div className='contents'>
					<span className='title'>{title && title}</span>
					{subtitle && <span className='subtitle' dangerouslySetInnerHTML={{ __html: subtitle }}></span>}
				</div>
			</div>
		</>
	);
};

export default GreyIconSet;
