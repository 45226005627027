import React from 'react';
import classNames from 'classnames';
import Button from '../atoms/Button';


const TableDescriptPopContentsCustom = ({ theadList, tbody, onClickCancel }) => {
  return (
    <div className={classNames('table-pop-description')}>
      <ul>
        {theadList.map((thead, index) => {
          const bodyRowContent = tbody.all[index];

          if (bodyRowContent.indexOf(`cancel_`) >= 0) {
            return (
              <li key={index}>
                <span
                  className={classNames('title-mid bold grey-1')}
                  dangerouslySetInnerHTML={{ __html: thead.title }}></span>
                <span
                  className={classNames('table-description')}>
                  <Button
                    className={'bg-red'}
                    onClick={() => onClickCancel(bodyRowContent)}>
                    취소하기
                  </Button>
                </span>
              </li>
            )
          }

          return (
            <li key={index}>
              <span
                className={classNames('title-mid bold grey-1')}
                dangerouslySetInnerHTML={{ __html: thead.title }}></span>
              <span
                className={classNames('table-description')}
                dangerouslySetInnerHTML={{ __html: bodyRowContent }}></span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default TableDescriptPopContentsCustom