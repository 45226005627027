import React from 'react';
import classNames from 'classnames';

const ReservationItem = ({ title, children }) => {
  return (
    <div className={classNames('reservation-item')}>
      <span className={classNames('txt-large')}>
        {title}
      </span>
      <div>
        {children}
      </div>
    </div>
  )
}

export default ReservationItem