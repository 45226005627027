import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import { useForm } from 'react-hook-form';
import { updateContractTerms1, updatePeriodOfContract } from '../../../reducers/bookingEstimation';
import { URLS } from '../../../utils/urls';
import classNames from 'classnames';
import { ContractPeriodOptions } from '../../../utils/EstimateFormData';

import RadioGroup from '../../atoms/Radio/RadioGroup';
import BookingItem from '../../molecules/BookingItem';
import Radio from '../../atoms/Radio';
import Button from '../../atoms/Button';

import ScheduleDatePicker from '../../atoms/ScheduleDatePicker';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import AddressInput2 from '../../molecules/AddressInput2';
import { formErrorMessage } from '../../../utils/alertMessage';
import { transformDate } from '../../../utils/numbers';
import { getValueByTarget } from '../../../utils/parse';
import ListWithBullet from '../../atoms/ListWithBullet';
import ScheduleDatePicker2 from '../../atoms/ScheduleDatePicker2';
import TotalAmount4 from '../../atoms/TotalAmount4';
import { SetMmEstimateHistory } from '../../../lib/EstimateApi';
import { getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, PAGE } from '../../../utils/constant';
import useCheckPC from '../../../hooks/useCheckPC';
import { useSelector } from 'react-redux';
import TotalAmount3 from '../../atoms/TotalAmount3';
import { closeLoading, openLoading } from '../../../reducers/loadingRedux';
import BookingItemContractTerms1 from '../../molecules/BookingItemContractTerms1';

/*
const [UsageTimeOptions, SetUsageTimeOptions] = useState(
    (BookingOption.subscription !== null) ?
      ((BookingOption.subscription === 'annual') ? UsageTimeOptionsAnnual : UsageTimeOptionsMonthly) :
      {}
  )
*/

/**
 * 계약 조건 페이지
 * name : contractTerms1
 * */

const ContractTerms1 = () => {
  const { ContractMonthArray } = useSelector((state) => state);
  const { isPC } = useCheckPC();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOptions, setSelectedOptions } = useBookingOptionContext();
  const [description, setDescription] = useState([]);


  useEffect(() => {
    const updatedDescription = []

    if (selectedOptions.subscription === 'monthly') {
      updatedDescription.push(`요청해주신 계약기간은 
      <i class='blue'>
      ${getValueByTarget(
        ContractMonthArray.select,
        selectedOptions.contractTerms1.periodOfContract,
        'title'
      )}</i> 입니다.`)
    }

    updatedDescription.push(`차고지는 <i class='blue'>
    ${selectedOptions?.contractTerms1?.serviceArea?.departure?.address1 ?? '미입력'} </i> 입니다.`)

    updatedDescription.push(`이용 예정일은 <i class='blue'>${transformDate(selectedOptions?.contractTerms1?.schedule)}</i> 입니다.`)

    setDescription(updatedDescription)
  }, [
    selectedOptions.contractTerms1.periodOfContract,
    selectedOptions.contractTerms1.serviceArea.departure.address1,
    selectedOptions.contractTerms1.serviceArea.departure.address2,
    selectedOptions.contractTerms1.serviceArea.destination.address1,
    selectedOptions.contractTerms1.serviceArea.destination.address2,
    selectedOptions.contractTerms1.schedule
  ])

  const { handleSubmit, setValue, register, setError, formState: { errors } } = useForm({
    defaultValues: {
      periodOfContract: selectedOptions?.contractTerms1?.periodOfContract,
      serviceAreaDepartZip: selectedOptions?.contractTerms1?.serviceArea?.departure?.zip ?? '',
      serviceAreaDepartAddress1: selectedOptions?.contractTerms1?.serviceArea?.departure?.address1 ?? '',
      serviceAreaDepartAddress2: selectedOptions?.contractTerms1?.serviceArea?.departure?.address2 ?? '',
      serviceAreaDestinationZip: selectedOptions?.contractTerms1?.serviceArea?.destination?.zip ?? '',
      serviceAreaDestinationAddress1: selectedOptions?.contractTerms1?.serviceArea?.destination?.address1 ?? '',
      serviceAreaDestinationAddress2: selectedOptions?.contractTerms1?.serviceArea?.destination?.address2 ?? '',
      schedule: selectedOptions.contractTerms1.schedule
    }
  });

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;


    // if (!data?.serviceAreaDepartZip) {
    //   validCheck = false;
    //   setError('serviceAreaDepartZip', {
    //     type: 'focus',
    //     name: 'serviceAreaDepartZip',
    //     message: formErrorMessage.zipError
    //   }, { shouldFocus: true });
    // }

    if (!data?.serviceAreaDepartAddress1) {
      validCheck = false;
      setError('serviceAreaDepartAddress1', {
        type: 'focus',
        name: 'serviceAreaDepartAddress1',
        message: formErrorMessage.address1Error
      }, { shouldFocus: true });
    }

    // if (!data?.serviceAreaDepartAddress2) {
    //   validCheck = false;
    //   setError('serviceAreaDepartAddress2', {
    //     type: 'focus',
    //     name: 'serviceAreaDepartAddress2',
    //     message: formErrorMessage.address2Error
    //   }, { shouldFocus: true });
    // }

    // if (!data?.serviceAreaDestinationZip) {
    //   validCheck = false;
    //   setError('serviceAreaDestinationZip', {
    //     type: 'focus',
    //     name: 'serviceAreaDestinationZip',
    //     message: formErrorMessage.zipError
    //   }, { shouldFocus: true });
    // }

    // if (!data?.serviceAreaDestinationAddress1) {
    //   validCheck = false;
    //   setError('serviceAreaDestinationAddress1', {
    //     type: 'focus',
    //     name: 'serviceAreaDestinationAddress1',
    //     message: formErrorMessage.address1Error
    //   }, { shouldFocus: true });
    // }

    // if (!data?.serviceAreaDestinationAddress2) {
    //   validCheck = false;
    //   setError('serviceAreaDestinationAddress2', {
    //     type: 'focus',
    //     name: 'serviceAreaDestinationAddress2',
    //     message: formErrorMessage.address2Error
    //   }, { shouldFocus: true });
    // }

    if (validCheck) {
      dispatch(openLoading())
      setSelectedOptions((prevState) => ({
        ...prevState,
        contractTerms1: {
          ...prevState.contractTerms1,
          periodOfContract: data?.periodOfContract,
          serviceArea: {
            ...prevState.contractTerms1.serviceArea,
            departure: {
              ...prevState.contractTerms1.serviceArea.departure,
              zip: data?.serviceAreaDepartZip,
              address1: data?.serviceAreaDepartAddress1,
              address2: data?.serviceAreaDepartAddress2,
            },
            destination: {
              ...prevState.contractTerms1.serviceArea.destination,
              zip: data?.serviceAreaDestinationZip,
              // address1: data?.serviceAreaDestinationAddress1,
              address2: data?.serviceAreaDestinationAddress2,
            }
          },
          schedule: data?.schedule
        },
      }));

      // await하지 않고, 모시러 서버에 저장
      SetMmEstimateHistory({
        ...getEstimateCookieDataAndDecrypt(),
        EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
        EstimatePage: PAGE.PAGE_03,
        JsonData: JSON.stringify({
          ...selectedOptions,
          contractTerms1: {
            ...selectedOptions.contractTerms1,
            periodOfContract: data?.periodOfContract,
            serviceArea: {
              ...selectedOptions.contractTerms1.serviceArea,
              departure: {
                ...selectedOptions.contractTerms1.serviceArea.departure,
                zip: data?.serviceAreaDepartZip,
                address1: data?.serviceAreaDepartAddress1,
                address2: data?.serviceAreaDepartAddress2,
              },
              destination: {
                ...selectedOptions.contractTerms1.serviceArea.destination,
                zip: data?.serviceAreaDestinationZip,
                // address1: data?.serviceAreaDestinationAddress1,
                address2: data?.serviceAreaDestinationAddress2,
              }
            },
            schedule: data?.schedule
          },
        })
      })



      dispatch(updateContractTerms1({
        periodOfContract: data?.periodOfContract,
        serviceArea: {
          departure: {
            zip: data?.serviceAreaDepartZip ?? null,
            address1: data?.serviceAreaDepartAddress1 ?? null,
            address2: data?.serviceAreaDepartAddress2 ?? null,
          },
          destination: {
            zip: data?.serviceAreaDestinationZip ?? null,
            // address1: data?.serviceAreaDestinationAddress1 ?? null,
            address2: data?.serviceAreaDestinationAddress2 ?? null,
          },
        },
        schedule: data?.schedule,
      }))

      // navigate(URLS.BOOKING_ESTIMATION_CONTRACT_TERMS2)
      navigate(URLS.BOOKING_ESTIMATION_VEHICLE_OPTION)
      dispatch(closeLoading())

    }
  });


  const handleAddressChange = (subField, value, target) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      contractTerms1: {
        ...prevState.contractTerms1,
        serviceArea: {
          ...prevState.contractTerms1.serviceArea,
          [target]: {
            ...prevState.contractTerms1.serviceArea[target],
            [subField]: value,
          },
        },
      },
    }));
  };

  const handleAddressChange3 = (subField, value, target) => {

    setSelectedOptions((prevState) => ({
      ...prevState,
      contractTerms1: {
        ...prevState.contractTerms1,
        serviceArea: {
          ...prevState.contractTerms1.serviceArea,
          departure: {
            ...prevState.contractTerms1.serviceArea.departure,
            [target]: value
          }
        },
      },
    }));
  };

  const scriptList = [
    '계약 후 1주일 뒤 부터 이용 가능합니다.',
    '더 빠르게 이용을 원하실 경우는 별도 문의 부탁드립니다.'
  ]


  /*
  ContractMonthArray.select 대신에 전역에서 관리되며
  기본상품 + 추가 이용시간 상태에 따라 할인 금액이 달라지도록 하는 처리가 필요함.
  
  */

  return (
    <>
      <div className={classNames('booking-wrap')}>
        <PageTitle title={'계약 조건'} subtitle={'계약 조건을 선택하세요'} />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          <div className={classNames('booking-form-list')}>
            {(!!ContractMonthArray.select && selectedOptions.subscription === 'monthly') && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>계약 기간</span>
                <RadioGroup
                  classes={''}
                  legend={'periodOfContract'}
                  selected={selectedOptions.contractTerms1.periodOfContract}
                >
                  <ul className={classNames('booking-item-list')}>
                    {ContractMonthArray.select.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('periodOfContract', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              contractTerms1: {
                                ...prevState.contractTerms1,
                                periodOfContract: item.value,
                              },
                            }));
                            dispatch(updatePeriodOfContract({
                              ...selectedOptions,
                              periodOfContract: item.value,
                            }))
                          }}
                        >
                          <BookingItemContractTerms1
                            selected={selectedOptions.contractTerms1.periodOfContract === item.value}
                            subtitle={item?.title}
                            discount={item?.discount}
                            description={item?.description}
                            descriptionTitle={item?.descriptionTitle}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}
            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>차고지</span>
              <AddressInput2
                register={register}
                setValue={setValue}
                // zipError={errors.serviceAreaDepartZip}
                address1Error={errors.serviceAreaDepartAddress1}
                // address2Error={errors.serviceAreaDepartAddress2}
                // zipRegister={'serviceAreaDepartZip'}
                address1Register={'serviceAreaDepartAddress1'}
                // address2Register={'serviceAreaDepartAddress2'}
                // setSelectedOptions={setSelectedOptions}
                handleAddressChange={handleAddressChange3}
                target={'address1'}
              />
            </div>
            {/* <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>종료 지역</span>
              <AddressInput
                register={register}
                setValue={setValue}
                zipError={errors.serviceAreaDestinationZip}
                address1Error={errors.serviceAreaDestinationAddress1}
                address2Error={errors.serviceAreaDestinationAddress2}
                zipRegister={'serviceAreaDestinationZip'}
                address1Register={'serviceAreaDestinationAddress1'}
                address2Register={'serviceAreaDestinationAddress2'}
                handleAddressChange={handleAddressChange}
                target={'destination'}
              />
            </div> */}
            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>이용 예정일</span>
              <ScheduleDatePicker2
                selectedDate={selectedOptions?.contractTerms1?.schedule}
                onChange={(newDate) => {
                  setValue('schedule', newDate)
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    contractTerms1: {
                      ...prevState.contractTerms1,
                      schedule: newDate,
                    },
                  }));
                }}
              />
              <ListWithBullet description={scriptList} gap={0} />
            </div>
          </div>
          <BookingFormSummary
            description={description}
            SubAmountObj={isPC ? TotalAmount3 : null}>


            <div className={classNames('button-set')}>
              <Button
                onClick={
                  // () => navigate(URLS.BOOKING_ESTIMATION_VEHICLE_OPTION)
                  () => navigate(URLS.BOOKING_ESTIMATION_USAGE_TIME)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary>
        </form>
      </div>
    </>
  )
}

export default ContractTerms1;