import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '../atoms/Icon';
import classNames from 'classnames';
import Steps from '../atoms/Steps';
import Button from '../atoms/Button';
import useCheckPC from '../../hooks/useCheckPC';
import Menu from '../atoms/Menu';

const Header = ({ menuList, headerTitle, currentMenuPath, stepList, currentStep, title, hasClose = false, hasMenu = false, hasPrevButton = false, menuOnClick, onClickCloseBtn, children }) => {
	const { isPC } = useCheckPC();
	const navigate = useNavigate();

	return (
		<>
			<nav className={classNames('header')}>
				<div className={classNames('header-menu-list')}>
					{isPC ? (
						<>
							<div className={classNames('logo header-logo')}>
								<Icon icon={'logo'} classes={'logo'} />
							</div>
							{/* <Link to={'/'} className={classNames('logo header-logo')} >
                <Icon icon={'logo'} classes={'logo'} />
              </Link> */}
							{headerTitle && (
								<div className='header-title-wrap'>
									<div className='w-20'></div>
									<div className={classNames('title')}>
										<span className={'title-mid-small bold'}>{headerTitle}</span>
									</div>
									<div className='w-20'>
										{onClickCloseBtn && (
											<Button styleType='icon' classes={'close'} onClick={() => onClickCloseBtn()}>
												<Icon icon='close' />
											</Button>
										)}
									</div>
								</div>
							)}

							{stepList && <Steps stepList={stepList} currentStep={currentStep} />}
							{menuList && <Menu menuList={menuList} currentMenuPath={currentMenuPath} />}
							{/* {hasClose && (
                <Button styleType={'icon'} classes={'header-close transparent'}>
                  <Icon icon={'close'} />
                </Button>
              )} */}
						</>
					) : (
						<>
							{headerTitle ? (
								<div className='header-title-wrap'>
									<div className='w-20'></div>
									<div className={classNames('title')}>
										<span className={'title-mid-small bold'}>{headerTitle}</span>
									</div>
									<div className='w-20'>
										{onClickCloseBtn && (
											<Button styleType='icon' classes={'close'} onClick={() => onClickCloseBtn()}>
												<Icon icon='close' />
											</Button>
										)}
									</div>
								</div>
							) : (
								<>
									{hasPrevButton ? (
										<Button styleType={'icon'} classes={'transparent'} onClick={() => navigate(-1)}>
											<Icon icon={'arrow_left'} />
										</Button>
									) : (
										<div className={'w-22'}></div>
									)}
									<div className={classNames('title')}>
										<span className={'title-mid-small bold'}>{title}</span>
									</div>
									<div className={'w-22'}>
										{hasMenu && (
											<Button styleType={'icon'} classes={'menu'} onClick={() => menuOnClick(true)}>
												<Icon icon={'menu'} />
											</Button>
										)}
									</div>
									{children && children}
								</>
							)}
						</>
					)}
				</div>
			</nav>
		</>
	);
};

export default Header;
