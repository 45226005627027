import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ReservationBox = ({ children }) => {
  return (
    <div id='booking_reservation_container' className={classNames('reservation-box')}>
      {children}
    </div>
  )
}

ReservationBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReservationBox;