import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import WhiteBox from '../../atoms/WhiteBox';
import { customerData } from '../../../archive/temporaryData';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon'
import useCheckPC from '../../../hooks/useCheckPC';
import { useSelector, useDispatch } from 'react-redux';
import MyPageApi from '../../../lib/MyPageApi';
import { mypageAll } from '../../../reducers/MyPage';
import { isChkResultSuccess, isChkEmail, getMasterAccountEmail, getMasterAccountCustomerNo, isChkPw, getLoginAccountInfoFromCookie, isChkAccount, isMasterAccountLoggedIn, isDev, isChkCarNumber, getQueryStringObject } from '../../../lib/Util';
import Modal from '../../molecules/Modal';
import Input from '../../atoms/Input';
import { useForm } from 'react-hook-form'
import { Tag } from '../../atoms/TagInput';
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import Checkbox from '../../atoms/CheckBox';
import { addressRequest, addressRequestPlace, setFinishAddressStr } from '../../../lib/AddressApi';
import ReserveApi from '../../../lib/ReserveApi';
import { useNavigate } from 'react-router-dom';
/**
 * 마이페이지
 * name : mypage
 * TODO: 고객 데이터로 값 대체, button events
 * */
const MyPage = () => {
	const { isPC } = useCheckPC();
	const navigate = useNavigate();

	const [businessInfoSubTitle, setBusinessInfoSubTitle] = useState('사업자 등록증 미등록')
	const [cardCount, setCardCount] = useState(0);
	const [vehicleCount, setVehicleCount] = useState(0);
	const [userCount, setUserCount] = useState(0)
	const [addressCount, setAddressCount] = useState(0)
	const [tempMember, setTempMember] = useState([])
	const [addressList, setAddressList] = useState([])

	var typingTimer = null;
	var doneTypingInterval = 300;

	const {
		handleSubmit, setValue, register, setError, formState: { errors } } = useForm();

	const [settingModalStatus, setSettingModalStatus] = useState({
		BusinessDataFlag: false,
		CarInfoFlag: false,
		MemberFlag: false,
		AddressFlag: false,

		BusinessDataModifyFlag: false,
		CarInfoModifyFlag: false,
		MemberModifyFlag: false,
		AddressModifyFlag: false,

		BusinessDataDeleteFlag: false,
		CarInfoDeleteFlag: false,
		MemberDeleteFlag: false,
		AddressDeleteFlag: false,
	})

	const [addressObj, setAddressObj] = useState({
		Id: 0,
		CustomerNo: 0,
		Alias: '',
		Address: '',
		Lat: '',
		Lng: '',
	})

	const [mailTag, setMailTag] = useState([])
	const [inviteEmailAddress, setInviteEmailAddress] = useState('')

	const [carInfoObj, setCarInfoObj] = useState({
		CustomerNo: 0,
		CarModel: '',
		CarNumber: ''
	})

	const [memberObj, setMemberObj] = useState({
		UserId: '',
		UserPw: '',
		AccountType: 'email',
		CustomerName: '',
		Email: '',
		PhoneNumber: '',
		HomeAddress: '',
		ParentCustomerNo: 0,
		UseType: '0',
		Roles: 0,
		CustomerNo: 0,
	})
	const [passwordSetting, setPasswordSetting] = useState(false)

	const dispatch = useDispatch();

	const loginInfo = useSelector(state => state.Login);
	const mypageInfo = useSelector(state => state.MyPageRedux);

	useEffect(() => {
		async function getMyPageData() {
			dispatch(openLoading())
			let result = await MyPageApi.GetMyPage(getMasterAccountCustomerNo(loginInfo.UserPool))
			let result2 = await MyPageApi.GetTempMemberList(getMasterAccountEmail())
			if (isChkResultSuccess(result)) {
				dispatch(mypageAll(result.Item))
			}
			else {

			}



			if (isChkResultSuccess(result2)) {
				setTempMember(result2.ItemList)
			}
			else {

			}

			dispatch(closeLoading())
		}

		async function getQueryStrCheck() {
			try {
				let data = getQueryStringObject()
				if (data.flag === 'reserve_carinfoset') {
					modalStatusChange('CarInfo', true)
				}
			}
			catch (e) { }
		}

		getMyPageData();
		getQueryStrCheck();
	}, [])

	useEffect(() => {


		if (customerData?.customer?.businessRegistrationNumber) {
			setBusinessInfoSubTitle('사업자 등록증 등록 완료')
		}

		if (customerData?.card?.length > 0) {
			setCardCount(customerData.card.length);
		}

		if (customerData?.vehicle?.length > 0) {
			setVehicleCount(customerData.vehicle.length);
		}

		if (customerData?.userGroup?.length > 0) {
			setUserCount(customerData.userGroup.length);
		}

		if (customerData?.addressGroup?.length > 0) {
			setAddressCount(customerData.addressGroup.length);
		}
	}, [customerData])


	const updateMyPageData = async () => {
		dispatch(openLoading())
		let result = await MyPageApi.GetMyPage(getMasterAccountCustomerNo(loginInfo.UserPool))
		let result2 = await MyPageApi.GetTempMemberList(getMasterAccountEmail())
		if (isChkResultSuccess(result)) {
			dispatch(mypageAll(result.Item))
		}
		else {

		}



		if (isChkResultSuccess(result2)) {
			setTempMember(result2.ItemList)
		}
		else {

		}

		dispatch(closeLoading())
	}

	const asyncAddress2 = async (e) => {
		clearTimeout(typingTimer)
		try {
			if (e.target.value.length >= 1) {
				typingTimer = setTimeout(async () => {
					let adr_result = await addressRequestPlace(e.target.value)
					if (adr_result.meta.total_count === 0) {
						adr_result = await addressRequest(e.target.value)
					}
					setAddressList(adr_result.documents);
				}, doneTypingInterval)
			}
			else {
				setAddressList([])
			}
		}
		catch (e) {
			setAddressList([])
		}
	}
	const InfomationText = (text, topMargin) => (
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', marginTop: topMargin + 'px' }}>
			<div>
				<Icon icon={'bul_exclamation'} style={{ width: '15px', height: '15px' }} />
			</div>
			<div>
				<span style={{ fontSize: '12px' }}>
					{text}
				</span>
			</div>
		</div>
	)


	// 주소지 관리
	const setFormAddress = (e) => {

		setAddressObj({
			...addressObj,
			[e.target.id]: e.target.value
		})
	}
	const resetAddress = () => {
		setAddressObj({ Id: 0, CustomerNo: 0, Alias: '', Address: '', Lat: '', Lng: '', })
	}

	// 차량 관리
	const setFormCarInfo = (e) => {
		setCarInfoObj({
			...carInfoObj,
			[e.target.id]: e.target.value
		})
	}
	const resetCarInfo = () => {
		setCarInfoObj({ CustomerNo: 0, CarModel: '', CarNumber: '' })
	}

	// 멤버 관리
	const setFormMember = (e) => {
		setMemberObj({
			...memberObj,
			[e.target.id]: e.target.value
		})
	}
	const resetMember = () => {
		setMemberObj({
			UserId: '',
			UserPw: '',
			AccountType: 'email',
			CustomerName: '',
			Email: '',
			PhoneNumber: '',
			HomeAddress: '',
			ParentCustomerNo: 0,
			UseType: '0',
			Roles: 0,
			CustomerNo: 0,
		})
		setInviteEmailAddress('')
	}



	const modalStatusChange = (type, data, data2) => {
		switch (type) {
			case 'Business':
				setSettingModalStatus({
					...settingModalStatus,
					BusinessDataFlag: data
				})
				break;
			case 'CarInfo':
				setSettingModalStatus({
					...settingModalStatus,
					CarInfoFlag: data
				})
				break;
			case 'Address':
				setSettingModalStatus({
					...settingModalStatus,
					AddressFlag: data
				})
				break;
			case 'Member':
				setSettingModalStatus({
					...settingModalStatus,
					MemberFlag: data
				})
				break;

			case 'BusinessModify':
				setSettingModalStatus({
					...settingModalStatus,
					BusinessDataFlag: data,
					BusinessDataModifyFlag: data
				})
				break;
			case 'CarInfoModify':
				setSettingModalStatus({
					...settingModalStatus,
					CarInfoFlag: data,
					CarInfoModifyFlag: data
				})
				setCarInfoObj({
					...carInfoObj,
					...data2
				})
				break;
			case 'AddressModify':
				setSettingModalStatus({
					...settingModalStatus,
					AddressFlag: data,
					AddressModifyFlag: data,
				})
				setAddressObj({
					...addressObj,
					...data2
				})
				break;
			case 'MemberModify':
				setSettingModalStatus({
					...settingModalStatus,
					MemberFlag: data,
					MemberModifyFlag: data,
				})
				setMemberObj({
					...memberObj,
					...data2
				})
				break;
			case 'BusinessDelete':
				setSettingModalStatus({
					...settingModalStatus,
					BusinessDataFlag: data,
					BusinessDataDeleteFlag: data
				})
				break;
			case 'CarInfoDelete':
				setSettingModalStatus({
					...settingModalStatus,
					CarInfoFlag: data,
					CarInfoDeleteFlag: data
				})
				setCarInfoObj({
					...carInfoObj,
					...data2
				})
				break;
			case 'AddressDelete':
				setSettingModalStatus({
					...settingModalStatus,
					AddressFlag: data,
					AddressDeleteFlag: data,
				})
				setAddressObj({
					...addressObj,
					...data2
				})
				break;
			case 'MemberDelete':
				setSettingModalStatus({
					...settingModalStatus,
					MemberFlag: data,
					MemberDeleteFlag: data,
				})
				setMemberObj({
					...memberObj,
					...data2
				})
				break;
		}

	}

	const getStrUseType = (useType) => {
		let retData = '';
		if (useType === 1) {
			retData = '탑승자'
		}
		else {
			retData = '부커'
		}
		return retData
	}

	const getStrMainSub = (Type) => {
		let retData = '';
		if (Type === 'MASTER') {
			retData = '대표'
		}
		else {
			retData = '일반'
		}
		return retData
	}

	const ShowModalManage = (type, flag, status) => {
		if (type === 'Business') {
			return (
				<>
					{flag && <Modal
						title={'사업자 정보 변경'}
						subTitle={''}>
						<div className={classNames('main-set')}>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
						</div>
						<div className={classNames('button-set')}>
							<Button
								onClick={() => {
									modalStatusChange('Business', false);
								}}
							>
								닫기
							</Button>
							<Button
								styleType={'secondary'}
								onClick={async () => {
									await MyPageApi.SetMyPageAddress()
									alert('사업자 정보 변경 완료')
									modalStatusChange('Business', false);
								}}
							>
								추가하기
							</Button>
						</div>
					</Modal>}
				</>
			)
		}
		else if (type === 'CarInfo') {
			const getStrCarInfo = () => {
				let retData = {
					titleStr: '',
					btnStr: '',
					subTitleStr: ''
				}
				if (settingModalStatus.CarInfoModifyFlag) {
					retData = {
						...retData,
						titleStr: '차량 수정',
						btnStr: '수정하기'
					}
				}
				else if (settingModalStatus.CarInfoDeleteFlag) {
					retData = {
						...retData,
						titleStr: '차량 삭제',
						btnStr: '삭제하기',
						subTitleStr: '아래 차량을 삭제하시겠습니까?'
					}
				}
				else {
					retData = {
						...retData,
						titleStr: '차량 추가',
						btnStr: '추가하기',

					}
				}
				return retData
			}

			return (
				<>
					{flag && <Modal
						title={getStrCarInfo().titleStr}
						subTitle={getStrCarInfo().subTitleStr}>
						<div className={classNames('main-set')}>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="차량번호" id="CarNumber" onChange={setFormCarInfo} value={carInfoObj.CarNumber} />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="차량모델명" id="CarModel" onChange={setFormCarInfo} value={carInfoObj.CarModel} />
							</div>
						</div>

						<div className={classNames('button-set')}>
							{/* 닫기 */}
							<Button
								onClick={() => {
									if (settingModalStatus.CarInfoModifyFlag) {
										modalStatusChange('CarInfoModify', false);
									}
									else if (settingModalStatus.CarInfoDeleteFlag) {
										modalStatusChange('CarInfoDelete', false);
									}
									else {
										modalStatusChange('CarInfo', false);
									}

									resetCarInfo()
									try {
										let data = getQueryStringObject()
										if (data.flag === 'reserve_carinfoset') {
											navigate(-1)
										}
									}
									catch (e) { }

								}}
							>
								닫기
							</Button>

							{/* 추가하기 or 수정하기 (당분간 기능 죽임) */}
							<Button
								styleType={settingModalStatus.CarInfoDeleteFlag ? 'warning' : 'secondary'}
								onClick={async () => {
									// validation check
									if (!carInfoObj.CarNumber.length) {
										alert('차량번호를 입력해주세요.')
										return;
									}

									if (!isChkCarNumber(carInfoObj.CarNumber)) {
										alert('정확한 차량번호를 입력해주세요(ex. 123가1234)')
										return;
									}

									if (!carInfoObj.CarModel.length) {
										alert('차량모델명을 입력해주세요.')
										return;
									}

									// Setting Data
									let result = {}
									if (settingModalStatus.CarInfoModifyFlag) {
										result = await MyPageApi.UpdateMyPageCarInfo(carInfoObj.Id, carInfoObj.CarModel, carInfoObj.CarNumber)
									}
									else if (settingModalStatus.CarInfoDeleteFlag) {
										result = await MyPageApi.DeleteMyPageCarInfo(carInfoObj.Id)
									}
									else {
										result = await MyPageApi.SetMyPageCarInfo(getMasterAccountCustomerNo(loginInfo.UserPool), carInfoObj.CarModel, carInfoObj.CarNumber)
									}

									if (isChkResultSuccess(result)) {
										if (settingModalStatus.CarInfoModifyFlag) { alert('차량 변경이 완료되었습니다.') }
										else if (settingModalStatus.CarInfoDeleteFlag) { alert('차량 삭제가 완료되었습니다.') }
										else {
											alert('차량 추가가 완료되었습니다.')
											try {
												let data = getQueryStringObject()
												if (data.flag === 'reserve_carinfoset') {
													navigate(-1)
												}
											}
											catch (e) { }

										}

										let result2 = await MyPageApi.GetMyPage(getMasterAccountCustomerNo(loginInfo.UserPool))
										if (isChkResultSuccess(result2)) {
											dispatch(mypageAll(result2.Item))
										}
										else {
											alert(result2.Message)
										}

										if (settingModalStatus.CarInfoModifyFlag) { modalStatusChange('CarInfoModify', false); }
										else if (settingModalStatus.CarInfoDeleteFlag) { modalStatusChange('CarInfoDelete', false); }
										else { modalStatusChange('CarInfo', false); }

										resetCarInfo()
									}
								}}
							>
								{getStrCarInfo().btnStr}
							</Button>
						</div>

					</Modal>}
				</>
			)
		}
		else if (type === 'Address') {
			const getStrAddress = () => {
				let retData = {
					titleStr: '',
					btnStr: '',
					subTitleStr: ''
				}
				if (settingModalStatus.AddressModifyFlag) {
					retData = {
						titleStr: '주소지 수정',
						btnStr: '수정하기'
					}
				}
				else if (settingModalStatus.AddressDeleteFlag) {
					retData = {
						titleStr: '주소지 삭제',
						btnStr: '삭제하기',
						subTitleStr: '아래 주소지를 삭제하시겠습니까?'
					}
				}
				else {
					retData = {
						titleStr: '주소지 추가',
						btnStr: '추가하기',

					}
				}
				return retData
			}

			return (
				<>
					{flag && <Modal
						title={getStrAddress().titleStr}
						subTitle={getStrAddress().subTitleStr}>
						<div className={classNames('main-set')}>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="별명" id="Alias" onChange={setFormAddress} value={addressObj.Alias} disabled={settingModalStatus.AddressDeleteFlag} />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="주소입력" id="Address" onKeyUp={asyncAddress2} onChange={setFormAddress} value={addressObj.Address} disabled={settingModalStatus.AddressDeleteFlag} />
							</div>


							{addressList.length >= 1 && <>
								<div style={{
									display: 'flex',
									flexDirection: 'column',
									background: '#fff',
									border: '1px solid #eee',
									position: 'fixed',
									top: '190px'
								}}>

									{/* <div>{addressLoading ? '로딩중' : ''}</div> */}
									{addressList.map((data, idx) => {
										if (!addressList.length)
											return (<div key={idx}>검색 결과가 없습니다.</div>)

										return (
											<div
												style={{ padding: '10px 10px', border: '1px solid #eee' }}
												key={idx}
												onClick={() => {

													setAddressObj({
														...addressObj,
														Address: setFinishAddressStr(data)
													})

													setAddressList([])
												}}

											>{setFinishAddressStr(data)}</div>
										)
									})}
								</div>
							</>}


						</div>
						<div className={classNames('button-set')}>
							{/* 닫기 */}
							<Button
								onClick={() => {
									if (settingModalStatus.AddressModifyFlag) {
										modalStatusChange('AddressModify', false);
									}
									else if (settingModalStatus.AddressDeleteFlag) {
										modalStatusChange('AddressDelete', false);
									}
									else {
										modalStatusChange('Address', false);
									}

									resetAddress()
									setAddressList([])
								}}
							>
								닫기
							</Button>

							{/* 추가하기 or 수정하기 */}
							<Button
								styleType={settingModalStatus.AddressDeleteFlag ? 'warning' : 'secondary'}
								onClick={async () => {
									// validation check	
									if (!addressObj.Alias.length) {
										alert('별명을 입력해주세요.')
										return;
									}

									if (!addressObj.Address.length) {
										alert('주소를 입력해주세요.')
										return;
									}

									// Setting Data
									let result = {}
									if (settingModalStatus.AddressModifyFlag) {
										result = await MyPageApi.UpdateMyPageAddress(addressObj.Id, addressObj.Alias, addressObj.Address, '', '')
									}
									else if (settingModalStatus.AddressDeleteFlag) {
										result = await MyPageApi.DeleteMyPageAddress(addressObj.Id)
									}
									else {
										result = await MyPageApi.SetMyPageAddress(getMasterAccountCustomerNo(loginInfo.UserPool), addressObj.Alias, addressObj.Address, '', '')
									}

									if (isChkResultSuccess(result)) {
										if (settingModalStatus.AddressModifyFlag) { alert('주소 변경이 완료되었습니다.') }
										else if (settingModalStatus.AddressDeleteFlag) { alert('주소 삭제가 완료되었습니다.') }
										else { alert('주소 추가가 완료되었습니다.') }

										let result2 = await MyPageApi.GetMyPage(getMasterAccountCustomerNo(loginInfo.UserPool))
										if (isChkResultSuccess(result2)) {
											dispatch(mypageAll(result2.Item))
										}
										else {
											alert(result2.Message)
										}

										if (settingModalStatus.AddressModifyFlag) { modalStatusChange('AddressModify', false); }
										else if (settingModalStatus.AddressDeleteFlag) { modalStatusChange('AddressDelete', false); }
										else { modalStatusChange('Address', false); }

										resetAddress()
									}
								}}
							>
								{getStrAddress().btnStr}
							</Button>
						</div>
					</Modal>}
				</>
			)
		}
		else if (type === 'Member') {
			const getStrMember = () => {
				let retData = {
					titleStr: '',
					btnStr: '',
					subTitleStr: ''
				}
				if (settingModalStatus.MemberModifyFlag) {
					retData = {
						titleStr: '서비스 이용 인원 수정',
						btnStr: '수정하기'
					}
				}
				else if (settingModalStatus.MemberDeleteFlag) {
					retData = {
						titleStr: '서비스 이용 인원 삭제',
						btnStr: '삭제하기',
						subTitleStr: '아래 이용자를 삭제하시겠습니까?'
					}
				}
				else {
					retData = {
						titleStr: '서비스 이용 인원 초대',
						subTitleStr: '초대할 이메일 주소 입력해주세요.',
						btnStr: '초대하기',

					}
				}
				return retData
			}


			return (
				<>
					{flag && <Modal
						title={getStrMember().titleStr}
						subTitle={getStrMember().subTitleStr}>
						<div className={classNames('main-set')}>


							{/* {(!settingModalStatus.MemberModifyFlag && !settingModalStatus.MemberDeleteFlag) && (
								<div className='input'>
									<Tag onInsertTag={setMailTag} />
								</div>
							)} */}

							{(!settingModalStatus.MemberModifyFlag && !settingModalStatus.MemberDeleteFlag) && (

								<>
									<div className='input'>
										<Input
											type="text"
											placeholder="이메일 주소"
											id="UserId"
											onChange={(e) => setInviteEmailAddress(e.target.value)}
											value={inviteEmailAddress}
										/>
									</div>
								</>
							)}

							{settingModalStatus.MemberModifyFlag && (
								<>
									<div className='input'>
										<Input type="text" placeholder="이메일 아이디" id="UserId" onChange={setFormMember} value={memberObj.UserId} disabled={true} />
									</div>
									<div className='input'>
										<Input type="password" placeholder="비밀번호" id="UserPw" onChange={setFormMember} value={memberObj.UserPw} disabled={!passwordSetting} />
									</div>
									<Checkbox
										label={'비밀번호를 변경합니다.'}
										checked={passwordSetting}
										onClick={e => {
											setPasswordSetting(e.target.checked)
										}}
									/>
									<div className='input'>
										{/* <Input type="text" placeholder="이름" id="CustomerName" onChange={setFormMember} value={memberObj.CustomerName} disabled={settingModalStatus.MemberDeleteFlag} /> */}
										<Input type="text" placeholder="이름" id="CustomerName" onChange={setFormMember} value={memberObj.CustomerName} disabled={true} />
									</div>
									<div className='input'>
										<Input type="text" placeholder="전화번호" id="PhoneNumber" onChange={setFormMember} value={memberObj.PhoneNumber} disabled={settingModalStatus.MemberDeleteFlag} />
									</div>
									<div className='input'>
										<select id="UseType" onChange={setFormMember} value={memberObj.UseType} disabled={settingModalStatus.MemberDeleteFlag} >
											<option value="0">이용 타입 선택</option>
											<option value="1">탑승자</option>
											<option value="2">부커</option>
										</select>
									</div>
								</>
							)}

						</div>

						{/* 수정 시작 */}
						<div className={classNames('button-set')}>
							{/* 닫기 */}
							<Button
								onClick={() => {
									if (settingModalStatus.MemberModifyFlag) {
										modalStatusChange('MemberModify', false);
									}
									else if (settingModalStatus.MemberDeleteFlag) {
										modalStatusChange('MemberDelete', false);
									}
									else {
										modalStatusChange('Member', false);
									}

									resetMember()

								}}
							>
								닫기
							</Button>

							{/* 추가하기 or 수정하기 */}
							<Button
								styleType={settingModalStatus.MemberDeleteFlag ? 'warning' : 'secondary'}
								onClick={async () => {
									let loginInfoData = getLoginAccountInfoFromCookie()

									if (settingModalStatus.MemberModifyFlag) {
										// validation check	
										if (!isChkEmail(memberObj.UserId)) {
											alert('이메일 아이디를 입력해주세요.')
											return;
										}

										if (passwordSetting) {
											if (!isChkPw(memberObj.UserPw)) {
												alert('비밀번호는 대/소문자,특수문자 포함 8~10자 입니다.')
												return;
											}
										}

										if (!memberObj.CustomerName.length) {
											alert('이름을 입력해주세요.')
											return;
										}

										if (!memberObj.PhoneNumber.length) {
											alert('전화번호를 입력해주세요.')
											return;
										}

										if (Number(memberObj.UseType) === 0) {
											alert('이용 타입을 선택해주세요')
											return;
										}
									}


									let result = {}

									dispatch(openLoading())
									if (settingModalStatus.MemberModifyFlag) {
										result = await MyPageApi.UpdateMember(memberObj.CustomerNo, memberObj.CustomerName, memberObj.UserId, memberObj.UserPw, memberObj.PhoneNumber, memberObj.UseType)
									}
									else if (settingModalStatus.MemberDeleteFlag) {
										result = await MyPageApi.DeleteMember(memberObj.CustomerNo)
									}
									else {
										// if (mailTag.length >= 1) {
										// 	result = await MyPageApi.InviteCustomer(mailTag, 'RequestName', getMasterAccountCustomerNo(loginInfo.UserPool), getMasterAccountEmail(loginInfo.UserPool))
										// 	//result = await MyPageApi.SetMember(memberObj.UserId, memberObj.UserPw, memberObj.CustomerName, memberObj.PhoneNumber, getMasterAccountCustomerNo(loginInfo.UserPool), memberObj.UseType)
										// }
										// else {
										// 	alert('초대할 이메일 주소를 1개 이상 태그해주세요')
										// 	return
										// }

										if (inviteEmailAddress.length >= 1 && isChkEmail(inviteEmailAddress)) {
											result = await MyPageApi.InviteCustomer([inviteEmailAddress], loginInfoData.CustomerName, getMasterAccountCustomerNo(loginInfo.UserPool), getMasterAccountEmail(loginInfo.UserPool))
											//result = await MyPageApi.SetMember(memberObj.UserId, memberObj.UserPw, memberObj.CustomerName, memberObj.PhoneNumber, getMasterAccountCustomerNo(loginInfo.UserPool), memberObj.UseType)
										}
										else {
											alert('초대할 이메일 주소를 입력해주세요.')
											return
										}

									}

									if (isChkResultSuccess(result)) {
										if (settingModalStatus.MemberModifyFlag) { alert('이용자 변경이 완료되었습니다.') }
										else if (settingModalStatus.MemberDeleteFlag) { alert('이용자 삭제가 완료되었습니다.') }
										else { alert('초대 메일을 발송하였습니다.') }

										let result2 = await MyPageApi.GetMyPage(getMasterAccountCustomerNo(loginInfo.UserPool))
										if (isChkResultSuccess(result2)) {
											dispatch(mypageAll(result2.Item))
										}
										else {
											alert(result2.Message)
										}

										if (settingModalStatus.MemberModifyFlag) { modalStatusChange('MemberModify', false); }
										else if (settingModalStatus.MemberDeleteFlag) { modalStatusChange('MemberDelete', false); }
										else { modalStatusChange('Member', false); }

										resetMember()

									}
									else {
										alert(result.Message)
									}
									dispatch(closeLoading())
								}}
							>
								{getStrMember().btnStr}
							</Button>
						</div>
						{/* 수정 완료 */}


						{/* 





						<div className={classNames('button-set')}>
							<Button
								onClick={() => {
									modalStatusChange('Member', false);

								}}
							>
								닫기
							</Button>
							<Button
								styleType={'secondary'}
								onClick={() => {
									alert('서비스 이용 인원 추가')
									modalStatusChange('Member', false);
								}}
							>
								{getStrMember().btnStr}
							</Button>
						</div> */}
					</Modal>}
				</>
			)
		}
	}

	const emailLengthCheck = (text) => {
		let retData = ''
		if(isPC) {
			if (text.length >= 27) {
				retData = text.substring(0,25) + '...'
			}
			else {
				retData = text
			}
		}
		else {
			if (text.length >= 20) {
				retData = text.substring(0,18) + '...'
			}
			else {
				retData = text
			}
		}
		
		return retData

	}



	return (
		<div className={classNames('mypage')}>

			{ShowModalManage('Business', settingModalStatus.BusinessDataFlag)}
			{ShowModalManage('CarInfo', settingModalStatus.CarInfoFlag)}
			{ShowModalManage('Address', settingModalStatus.AddressFlag)}
			{ShowModalManage('Member', settingModalStatus.MemberFlag)}

			<div className={classNames('mypage-section')}>
				{/* {isDev() && (
					<WhiteBox
						title={'사업자 정보'}
						subTitle={businessInfoSubTitle}
						showDetailButton={isPC ? false : true}
					>
						<div className={classNames('user-info-wrap')}>
							<div className={classNames('user-info')}>
								<span>{mypageInfo?.BusinessData?.RepresentativeName}</span>
								<span>{mypageInfo?.BusinessData?.BusinessCode}</span>
								<span>{mypageInfo?.BusinessData?.RepresentativeName}</span>
								<span>{mypageInfo?.BusinessData?.Address1}</span>
								<span>{mypageInfo?.BusinessData?.Address2}</span>
							</div>
							<Button styleType={'text'} classes={'txt-regular grey-3'} onClick={() => modalStatusChange('Business', true)}>수정</Button>
						</div>
					</WhiteBox>
				)} */}

				{/* <WhiteBox
						title={'결제 카드'}
						subTitle={`${cardCount}건`}
						showDetailButton={isPC ? false : true}
						>
						{customerData.card.length > 0 && (
							<ul className={classNames('item-list')}>
							{customerData.card.map((card, index) => (
								<li key={index}>
								<span className={classNames('blue')}>
									{card.type === 'regular' && '정기 결제'}
									{card.type === 'warranty' && '보증 등록'}
								</span>
								<span>
									{card.cardName} {card.cardNum}
								</span>
								<Button styleType={'text'} classes={'txt-regular grey-3'}>삭제</Button>
								</li>
							))}
							</ul>
						)}
						</WhiteBox> */}
				<WhiteBox
					title={'차량'}
					subTitle={`${mypageInfo?.CarInfoList?.length}/2 (대)`}
					showDetailButton={isPC ? false : true}
				>
					{mypageInfo?.CarInfoList?.length > 0 && (
						<ul className={classNames('item-list line mb-28')}>
							{mypageInfo.CarInfoList.map((vehicle, index) => (
								<li key={index}>

									<span className={classNames('mid-small')}>
										{vehicle.CarNumber}
									</span>
									<span>
										{vehicle.CarModel}
									</span>
									<div>
										{/* 당분간 기능 죽임 */}
										<Button
											styleType={'text'}
											classes={'txt-regular grey-3'}
											onClick={() => modalStatusChange('CarInfoModify', true, { ...vehicle })}>
											수정
										</Button>
										<span style={{ color: '#999999' }}>&nbsp; | &nbsp;</span>
										<Button
											styleType={'text'}
											classes={'txt-regular grey-3'}
											onClick={() => modalStatusChange('CarInfoDelete', true, { ...vehicle })}>
											삭제
										</Button>
									</div>
								</li>
							))}
						</ul>
					)}
					{/* 당분간 기능 죽임 */}
					{(mypageInfo?.CarInfoList?.length < 2) &&
						<>
							<Button
								styleType={'secondary'}
								classes={'padding-10 flex'}
								onClick={() => { modalStatusChange('CarInfo', true) }}>
								<Icon icon={'plus'} />
								<span className={classNames('txt-regular white')}>추가하기</span>
							</Button>
						</>
					}

					{InfomationText('차량 등록이 되어있지 않다면 예약할 수 없습니다.', 10)}

				</WhiteBox>
			</div>


			<div className={classNames('mypage-section')}>
				{(tempMember.length >= 1 && isMasterAccountLoggedIn()) && (
					<WhiteBox
						title={'수락 요청'}
						subTitle={`${tempMember.length}명`}
						showDetailButton={isPC ? false : true}
					>
						<ul className={classNames('item-list line member')}>
							{tempMember.map((user, index) => (
								<li key={index}>
									<div className={classNames('item-set', isPC && ' w-180')} style={{ alignItems: 'center' }}>
										<span className={classNames('small')}>
											{user.CustomerName}
										</span>
									</div>
									<div className={classNames('item-set justify-between', isPC ? 'w-240' : 'w-100')} style={{ alignItems: 'center' }}>
										<span>
											{user.PhoneNumber}
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
											<Button
												styleType={'secondary'}
												style={{ padding: '10px' }}
												onClick={async () => {
													if (window.confirm('수락 하시겠습니까?')) {
														let result = await MyPageApi.SetMemberTempApproval({
															CustomerTempId: user.Id,
															MasterEmailId: getMasterAccountEmail(),
															MasterCustomerNo: getMasterAccountCustomerNo()
														})
														if (isChkResultSuccess(result)) {
															alert('수락 처리가 완료되었습니다.')
															updateMyPageData()
														}
														else {
															alert(result.Message)
														}
													}
												}}>
												수락
											</Button>
											<Button
												styleType={'warning'}
												style={{ padding: '10px' }}
												onClick={async () => {
													if (window.confirm('거절 하시겠습니까?')) {
														let result = await MyPageApi.SetMemberTempReject({
															CustomerTempId: user.Id,
															MasterEmailId: getMasterAccountEmail(),
															MasterCustomerNo: getMasterAccountCustomerNo()
														})
														if (isChkResultSuccess(result)) {
															alert('거절 처리가 완료되었습니다.')
															updateMyPageData()
														}
														else {
															alert(result.Message)
														}
													}
												}}>
												거절
											</Button>
										</div>
									</div>
								</li>
							))}
						</ul>

					</WhiteBox>


				)}




				<WhiteBox
					title={'서비스 이용 인원'}
					subTitle={`${mypageInfo?.MemberList?.length}/4 (명)`}
					showDetailButton={isPC ? false : true}
				>
					{mypageInfo?.MemberList?.length > 0 && (
						<ul className={classNames('item-list line mb-28 member member-custom')}>
							{mypageInfo.MemberList.map((user, index) => (
								<li key={index}>
									<div className={classNames('item-set', isPC && ' w-200')}>
										{/* <span className={classNames('small blue')}>
												{user.type === 'owner' && '대표 관리자'}
												{user.type === 'manager' && '일반 관리자'}
												{user.type === 'member' && '일반 이용자'}
											</span> */}
										{/* <span className={classNames('xsmall blue')}>
											{getStrMainSub(user.MemberType)}
										</span> */}
										<span className={classNames(isPC ? 'small' : 'xsmall')}>
											{getStrUseType(user.UseType)}
										</span>
										<span className={classNames(isPC ? 'small' : 'xsmall')}>
											{user.CustomerName}
										</span>
										<span className={classNames(isPC ? 'small' : 'xsmall')}>
											{emailLengthCheck(user.UserId)}
										</span>
									</div>
									<div className={classNames('item-set', isPC ? 'w-100' : 'w-100')}>
										<div>
											<Button
												styleType={'text'}
												classes={'txt-regular grey-3'}
												onClick={() => modalStatusChange('MemberModify', true, { ...user })}>
												수정
											</Button>
											{isPC ? <span style={{ color: '#999999' }}>&nbsp; | &nbsp;</span> : <span style={{ color: '#999999' }}>&nbsp;</span>}

											{(user.MemberType !== 'MASTER') ? <Button
												styleType={'text'}
												classes={'txt-regular grey-3'}
												onClick={() => modalStatusChange('MemberDelete', true, { ...user })}>
												삭제
											</Button> : <Button
												styleType={'text'}
												classes={'txt-regular grey-3'}
											// onClick={() => modalStatusChange('MemberDelete', true, { ...user })}
											>
												<span style={{ visibility: 'hidden' }}>삭제</span>
											</Button>}


										</div>
									</div>
								</li>
							))}
						</ul>
					)}
					{/* <div className={classNames('button-set mb-12')}>
						<Button classes={`padding-10 ${!isPC && 'txt-small'}`}>대표 관리자 인원 변경</Button>
						<Button classes={`padding-10 ${!isPC && 'txt-small'}`}>인원별 계정 관리</Button>
					</div> */}
					<Button styleType={'secondary'} classes={'padding-10 flex'} onClick={() => { modalStatusChange('Member', true) }}>
						<Icon icon={'plus'} />
						<span className={classNames('txt-regular white')}>초대하기</span>
					</Button>
				</WhiteBox>




				<WhiteBox
					title={'주소지 관리'}
					subTitle={`${mypageInfo?.AddressList?.length}개`}
					showDetailButton={isPC ? false : true}
				>
					{mypageInfo?.AddressList?.length > 0 && (
						<ul className={classNames('item-list line mb-28 address')}>
							{mypageInfo?.AddressList.map((address, index) => (
								<li key={index}>
									<div className={classNames('item-set')}>
										<span className={classNames('xsmall blue')}>
											{address.Alias}
										</span>
										<span className={classNames('address')}>
											{address.Address}
										</span>
									</div>
									<div>
										<Button
											styleType={'text'}
											classes={'txt-regular grey-3'}
											onClick={() => modalStatusChange('AddressModify', true, { ...address })}>
											수정
										</Button>
										<span style={{ color: '#999999' }}>&nbsp; | &nbsp;</span>
										<Button
											styleType={'text'}
											classes={'txt-regular grey-3'}
											onClick={() => modalStatusChange('AddressDelete', true, { ...address })}>
											삭제
										</Button>
									</div>


								</li>
							))}
						</ul>
					)}
					<Button styleType={'secondary'} classes={'padding-10 flex'} onClick={() => { modalStatusChange('Address', true) }}>
						<Icon icon={'plus'} />
						<span className={classNames('txt-regular white')}>추가하기</span>
					</Button>
				</WhiteBox>
				<WhiteBox
					title={'고객센터'}
					showDetailButton={false} >
					<ul className={classNames('item-list line mb-28 member')}>
						<li>컨시어지 운영시간 09:00 ~ 17:00</li>
					</ul>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
						<Button styleType={'secondary'} classes={'padding-10 flex'} style={{ marginLeft: '0px', marginRight: '10px', padding: '15px' }} onClick={() => { window.location.href = 'tel:15224556' }}>
							<span className={classNames('txt-regular white')}>1522-4556</span>
						</Button>
						<Button styleType={'secondary'} classes={'padding-10 flex'} style={{ marginLeft: '10px', marginRight: '0px', padding: '15px' }} onClick={() => { window.open('http://pf.kakao.com/_uxmjlM/chat', '_blank', 'width=400,height=600') }}>
							<span className={classNames('txt-regular white')}>1:1 문의하기</span>
						</Button>
					</div>
				</WhiteBox>
			</div>
		</div>
	)
}

export default MyPage