import '../../../styles/mainTable.css';
const PromotionProductTable = () => {
	return (
		<>
			<div className='main-table td-set-2'>
				<table>
					<thead>
						<tr>
							<td className={'w-200'}></td>
							<td>250시간 상품</td>
							<td>500시간 상품</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className={'w-200 left grey'}>기본시간</td>
							<td>250시간</td>
							<td>500시간</td>
						</tr>
						<tr>
							<td className={'w-200 left grey'}>최소 이용 시간</td>
							<td>10시간</td>
							<td>10시간</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};
export default PromotionProductTable;
