import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { calculateContractMonthPrice, calculateTotalPrice } from '../../utils/numbers'
import { updateTotalAmount } from '../../reducers/bookingEstimation';
import { useDispatch, useSelector } from 'react-redux';
import { TestApiTest } from '../../lib/TestApi';
import { RoleOfVehiclesOptions } from '../../utils/EstimateFormData';
import { getValueByTarget } from '../../utils/parse';



const BookingOptionContext = createContext();
export default BookingOptionContext;

export const BookingOptionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { BookingOption, ContractMonthArray } = useSelector((state) => state);

  const [selectedOptions, setSelectedOptions] = useState({
    subscription: BookingOption?.subscription ?? 'monthly',
    usageTime: BookingOption?.usageTime ?? 'day10',
    optionHex: BookingOption?.optionHex ?? 0,
    vehicleOption: {
      numberOfVehicles: BookingOption?.vehicleOption?.numberOfVehicles ?? 'twoVehicles',
      roleOfVehicles: BookingOption?.vehicleOption?.roleOfVehicles ?? 'driver',
      carAmount: BookingOption?.vehicleOption?.carAmount ?? 'twomilliondown',
      insurance: BookingOption?.vehicleOption?.insurance ?? 'insubasic',
      passengerPerson: BookingOption?.vehicleOption?.passengerPerson ?? 'persontwo',
    },
    contractTerms1: {
      periodOfContract: BookingOption?.contractTerms1?.periodOfContract ?? 'month1',
      serviceArea: {
        departure: {
          zip: BookingOption?.contractTerms1?.serviceArea?.departure?.zip ?? null,
          address1: BookingOption?.contractTerms1?.serviceArea?.departure?.address1 ?? null,
          address2: BookingOption?.contractTerms1?.serviceArea?.departure?.address2 ?? null
        },
        destination: {
          zip: BookingOption?.contractTerms1?.serviceArea?.destination?.zip ?? null,
          address1: BookingOption?.contractTerms1?.serviceArea?.destination?.address1 ?? null,
          address2: BookingOption?.contractTerms1?.serviceArea?.destination?.address2 ?? null
        },
      },
      schedule: BookingOption?.contractTerms1?.schedule ?? new Date(),
    },
    contractTerms2: {
      purposeOfContract: BookingOption?.contractTerms2?.purposeOfContract ?? 'business',
      passenger: BookingOption?.contractTerms2?.passenger ?? 'ceo',
      passengerEtcString: BookingOption?.contractTerms2?.passengerEtcString ?? '',
      route: BookingOption?.contractTerms2?.route ?? 'route_instagram',
      routeEtcString: BookingOption?.contractTerms2?.routeEtcString ?? '',
      vehicleName1: BookingOption?.contractTerms2.vehicleName1 ?? null,
      vehicleName2: BookingOption?.contractTerms2.vehicleName2 ?? null,
      vehicleName3: BookingOption?.contractTerms2.vehicleName3 ?? null,
    },
    userInformation: {
      userType: BookingOption.userInformation?.userType ?? 'personal',
      user: {
        name: BookingOption?.userInformation?.user?.name ?? null,
        businessAddress: {
          zip: BookingOption?.userInformation?.user?.businessAddress?.zip ?? null,
          address1: BookingOption?.userInformation?.user?.businessAddress?.address1 ?? null,
          address2: BookingOption?.userInformation?.user?.businessAddress?.address1 ?? null,
        },
        businessRegistrationId: BookingOption?.userInformation?.user?.businessRegistrationId ?? null,
        businessRegistration: BookingOption?.userInformation?.user?.businessRegistration ?? null,
        companyName: BookingOption?.userInformation?.user?.companyName ?? null,
        personalAddress: BookingOption?.userInformation?.user?.personalAddress ?? null,
        personalAddressDetail: BookingOption?.userInformation?.user?.personalAddressDetail ?? null,
      }
    },
    paymentMethod: {
      method: BookingOption?.paymentMethod?.method ?? 'card',
      taxBill: BookingOption?.paymentMethod?.method ?? false,
      cashReceiptPhoneNumber: BookingOption?.paymentMethod.cashReceiptPhoneNumber ?? null,
    },
    savedSign: BookingOption?.savedSign ?? null,
    totalAmount: BookingOption?.totalAmount ?? 0,
  });



  // 견적 금액 총 합 산출
  useEffect(() => {
    const hasPaymentAmountOptions = {
      subscription: selectedOptions.subscription,
      usageTime: selectedOptions.usageTime,
      numberOfVehicles: selectedOptions.vehicleOption.numberOfVehicles,
      // roleOfVehicles: selectedOptions.vehicleOption.roleOfVehicles,
      carAmount: selectedOptions.vehicleOption.carAmount,
      insurance: selectedOptions.vehicleOption.insurance,
      // passengerPerson: selectedOptions.vehicleOption.passengerPerson,

      // periodOfContract: selectedOptions.contractTerms1.periodOfContract,
      // departure: selectedOptions.contractTerms1.serviceArea.departure,
    }

    let totalPrice = calculateTotalPrice(
      hasPaymentAmountOptions
    );

    totalPrice += calculateContractMonthPrice(selectedOptions.contractTerms1.periodOfContract, ContractMonthArray.select)
    totalPrice += getValueByTarget(RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime),selectedOptions.vehicleOption.roleOfVehicles,'price')

    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      totalAmount: totalPrice,
    }));
    dispatch(updateTotalAmount(totalPrice));
  }, [
    selectedOptions.subscription,
    selectedOptions.usageTime,
    selectedOptions.vehicleOption.numberOfVehicles,
    selectedOptions.vehicleOption.roleOfVehicles,
    selectedOptions.vehicleOption.carAmount,
    selectedOptions.vehicleOption.insurance,
    selectedOptions.vehicleOption.passengerPerson,
    selectedOptions.contractTerms1.periodOfContract,
    // selectedOptions.contractTerms1.serviceArea.departure,
  ]);

  useEffect(() => {
    // async function getData() {
    //   console.log('BookingOptionContext UseEffect')
    //   console.log(await TestApiTest())
    // }
    // getData()
  }, [

  ]);


  const state = useMemo(
    () => ({
      selectedOptions,
      setSelectedOptions,
    }),
    [selectedOptions]
  );

  return (
    <BookingOptionContext.Provider value={state}>
      {children}
    </BookingOptionContext.Provider>
  );
};

BookingOptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useBookingOptionContext = () => {
  const context = useContext(BookingOptionContext);
  if (!context) {
    throw new Error('useBookingOptionContext must be used within a BookingOptionProvider');
  }
  return context;
};

export const withBookingProvider = WrappedComponent => {
  const Component = props => {
    return (
      <BookingOptionProvider>
        <WrappedComponent {...props} />
      </BookingOptionProvider>
    );
  };

  Component.displayName = `useBookingOptionContext(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return Component;
}
