import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useCheckPC from '../../../hooks/useCheckPC';
import classNames from 'classnames';
import Header from '../../molecules/Header';
import { ReservationMenuList } from '../../../utils/ReservationFormData';
import DriverDispatchManageBox from './DriverDispatchManageBox';
import '../../../styles/reservationLayout.css';
import { Link } from 'react-router-dom';
import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';
import Dim from '../../atoms/Dim';
import { URLS } from '../../../utils/urls';


const DriverDispatchManage = () => {
	const { isPC } = useCheckPC();
	const location = useLocation();
	const [currentMenu, setCurrentMenu] = useState('');
	const [showMenu, setShowMenu] = useState(false);
	const [cookieStatus, setCookieStatus] = useState(false);
	const [ReservationMenuList, setReservationMenuList] = useState([
		// {
		// 	title: '이용예약',
		// 	path: URLS.RESERVATION_CREATE,
		// 	type: 'menu'
		// },
		{
			title: '이용내역',
			path: URLS.DRIVER_DISPATCH_MANAGE_HISTORY,
			type: 'menu'
		},
		// {
		//   title: '결제관리',
		//   path: URLS.RESERVATION_MANAGE_PAYMENTS,
		//   type: 'menu'
		// },
		{
			title: '마이페이지',
			path: URLS.DRIVER_DISPATCH_MANAGE_MYPAGE,
			type: 'menu'
		},

	])

	const isChkCookieStatus = () => {
		const CookieData = document.cookie
			.split(';')
			.reduce((res, c) => {
				const [key, val] = c.trim().split('=').map(decodeURIComponent)
				try {
					return Object.assign(res, { [key]: JSON.parse(val) })
				} catch (e) {
					return Object.assign(res, { [key]: val })
				}
			}, {});
		

		return (CookieData.li_01_dd !== undefined && CookieData.li_02_dd !== undefined)
	}

	// ReservationMenuList는 동적으로 만들어져야 할 것 같음

	useEffect(() => {
		
		setCookieStatus(isChkCookieStatus())
		let tmpObj = {
			title: '로그인',
			path: URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGIN,
			type: 'login'
		}

		if (isChkCookieStatus()) {
			tmpObj = {
				title: '로그아웃',
				path: URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGOUT,
				type: 'login'
			}
		}

		setReservationMenuList([
			// {
			// 	title: '이용예약',
			// 	path: URLS.RESERVATION_CREATE,
			// 	type: 'menu'
			// },
			{
				title: '이용내역',
				path: URLS.DRIVER_DISPATCH_MANAGE_HISTORY,
				type: 'menu'
			},
			// {
			//   title: '결제관리',
			//   path: URLS.RESERVATION_MANAGE_PAYMENTS,
			//   type: 'menu'
			// },
			{
				title: '마이페이지',
				path: URLS.DRIVER_DISPATCH_MANAGE_MYPAGE,
				type: 'menu'
			},
			tmpObj
		])

		if (location.pathname) {
			const currentPath = location.pathname;
			if (currentPath === URLS.VERIFY_EMAIL || currentPath === URLS.VERIFY_PASSWORD) {
				setCurrentMenu('로그인')
				return;
			}

			const presentMenu = ReservationMenuList.filter((menu) => menu.path === currentPath);
			if (presentMenu) {
				setCurrentMenu(presentMenu[0]?.title);
			}
		}
	}, [location])

	return (
		<div className={classNames('manage-reservation', (currentMenu === '로그인' || currentMenu === '회원가입') && 'bg-white')}>
			<Header
				menuList={ReservationMenuList}
				currentMenuPath={currentMenu}
				title={currentMenu}
				hasMenu={true}
				hasPrevButton={false}
				menuOnClick={setShowMenu}
			>
				{/* 모바일 화면일때의 처리 */}
				{!isPC && showMenu && (
					<>
						<div className={classNames('mobile-nav')}>
							<div className={'nav-header'}>
								<Button
									styleType={'icon'}
									classes={'menu'}
									onClick={() => setShowMenu(false)}
								>
									<Icon icon={'close'} />
								</Button>
							</div>


							<ul className={classNames('mobile-nav-list')}>

								{ReservationMenuList.map((menu, index) => (
									<li key={index}>
										<Link
											to={menu.path}
											onClick={() => setShowMenu(false)}
										>{menu.title}</Link>
									</li>
								))}

							</ul>

						</div>
						<Dim onClick={() => setShowMenu(false)} />
					</>
				)}
			</Header>
			<DriverDispatchManageBox>
				<Outlet />
			</DriverDispatchManageBox>
		</div>
	)
}
export default DriverDispatchManage