import { useState } from 'react';
import styled from 'styled-components';
import { isChkEmail } from '../../lib/Util';

const TagsInput = styled.div`
  margin: 5px auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid var(--line);
  border-radius: 6px;

  > ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;

    > .tag {
      width: auto;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(1, 186, 138);
      padding: 0 8px;
      font-size: 14px;
      list-style: none;
      border-radius: 6px;
      margin: 0 8px 8px 0;
      background: rgb(242,243,244);
      border-radius: 15px;

      > .tag-close-icon {
        display: block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        margin-left: 8px;
        color: rgb(1, 186, 138);
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
      }
    }
  }

  > input {
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
    :focus {
      outline: none !important;
    }
  }

  &:focus-within {
    border: 1px solid var(--line);
  }
`;

const CustomErrorSpan = styled.span`
  font-size:10px;
  text-align:left !important;
  color: red;
`;

export const Tag = ({
  onInsertTag
}) => {
  const initialTags = [];

  const [emailValidate, setEmailValidate] = useState(false)
  const [tags, setTags] = useState(initialTags);
  const removeTags = (indexToRemove) => {
    // 태그를 삭제하는 메소드
    const filter = tags.filter((el, index) => index !== indexToRemove);
    setTags(filter);
    onInsertTag(filter)
  };

  const addTags = (event) => {
    // tags 배열에 새로운 태그를 추가하는 메소드 
    const inputVal = event.target.value;
    // 이미 입력되어 있는 태그인지 검사하여 이미 있는 태그라면 추가하지 말기 
    // 아무것도 입력하지 않은 채 Enter 키 입력시 메소드 실행하지 말기
    // 태그가 추가되면 input 창 비우기 
    if ((event.key === "Enter" || event.key === "," || event.key === ";") && inputVal !== '' && !tags.includes(inputVal)) {
      if (!isChkEmail(inputVal)) {
        setEmailValidate(true)
      }
      else {
        setEmailValidate(false)
        setTags([...tags, inputVal.replace(/[,;]/g, "")]);
        onInsertTag([...tags, inputVal.replace(/[,;]/g, "")])
        event.target.value = '';
      }


    }
  };

  return (
    <>
      <TagsInput>
        <ul id="tags">
          {tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
              <span className="tag-close-icon" onClick={() => removeTags(index)}>
                X
              </span>
            </li>
          ))}
        </ul>
        <input
          className="tag-input"
          type="text"
          //키보드의 Enter 키에 의해 addTags 메소드가 실행
          onKeyUp={(e) => {
            { addTags(e) }
          }}
          placeholder="엔터키(↵), 콤마(,), 세미콜론(;)으로 등록"
        />
      </TagsInput>
      <div style={{ width: '100%', textAlign: 'left' }}>
        {emailValidate && <CustomErrorSpan>이메일 주소를 입력하세요</CustomErrorSpan>}
      </div>



    </>
  );
};