import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SwalTitleComponent from './Common/SwalTitleComponent'


export const showModalPassengerLoadEmpty = async () => {
    const mySwal = withReactContent(Swal)
    return await mySwal.fire({
        title: (
            <SwalTitleComponent 
                title={'탑승자 정보 불러오기'} 
                cancelBtn={()=>{
                    mySwal.clickCancel();
                }} 
            />
        ),
        html: (
            <div className='desc'>
                아직 저장된 탑승자 정보가 없습니다.
            </div>
        ),
        
        showConfirmButton:true,
        confirmButtonText: '추가하기',
        confirmButtonColor: '#1eafd2',

        showCancelButton:false,
        cancelButtonText:'닫기',
        cancelButtonColor: '#f6f6f6',

    })
}


export const showModalPassengerLoadList = async (arr, callBack) => {
    const mySwal = withReactContent(Swal)
    return await mySwal.fire({
        title: (
            <SwalTitleComponent 
                title={'탑승자 정보 불러오기'} 
                cancelBtn={()=>{
                    mySwal.clickCancel();
                }} 
            />
        ),
        html: (
            <>
                {arr.length >= 1 ? (
                    <div className='list'>
                        <ul>
                            {arr.map((data)=>(
                                <li>
                                <div className='node'>
                                    <div className='sub-node'>
                                        <div>{data?.CustomerName}</div>
                                        <div>{data?.PhoneNumber}</div>
                                    </div>
                                    <div>
                                        <button className='select-btn' onClick={()=>{
                                            callBack(data?.CustomerName, data?.PhoneNumber)
                                            mySwal.clickConfirm()
                                        }}>
                                            선택하기
                                        </button>
                                    </div>
                                </div>
                            </li>
                            ))}
                            
                        </ul>
                    </div>
                ) : (
                    <div className='desc'>
                        아직 저장된 탑승자 정보가 없습니다.
                    </div>  
                )}
            </>
            
        ),
        
        showConfirmButton: (arr.length >= 1) ? false : true,
        confirmButtonText: '추가하기',
        confirmButtonColor: '#1eafd2',

        showCancelButton: false,
        cancelButtonText:'닫기',
        cancelButtonColor: '#f6f6f6',

    })
}

export const showModalAddressLoadList = async (AdrArr, RevArr, callBack) => {
    const mySwal = withReactContent(Swal)
    return await mySwal.fire({
        title: (
            <SwalTitleComponent 
                title={'주소 정보 불러오기'} 
                cancelBtn={()=>{
                    mySwal.clickCancel();
                }} 
            />
        ),
        html: (
            <>
                {(AdrArr.length >= 1 || RevArr.length >= 1) ? (
                    <div className='list'>
                        <ul>
                            {AdrArr.map((data)=>(
                                <li>
                                <div className='node'>
                                    <div className='sub-node'>
                                        <div>{data?.Alias}</div>
                                        <div>{data?.Address}</div>
                                    </div>
                                    <div>
                                        <button className='select-btn' onClick={()=>{
                                            callBack(data?.Address)
                                            mySwal.clickConfirm()
                                        }}>
                                            선택하기
                                        </button>
                                    </div>
                                </div>
                            </li>
                            ))}
                            {RevArr.map((data)=>(
                                <li>
                                <div className='node'>
                                    <div className='sub-node'>
                                        <div>{'최근이용주소'}</div>
                                        <div>{data?.Address}</div>
                                    </div>
                                    <div>
                                        <button className='select-btn' onClick={()=>{
                                            callBack(data?.Address)
                                            mySwal.clickConfirm()
                                        }}>
                                            선택하기
                                        </button>
                                    </div>
                                </div>
                            </li>
                            ))}
                            
                        </ul>
                    </div>
                ) : (
                    <div className='desc'>
                        아직 저장된 주소 정보가 없습니다.
                    </div>  
                )}
            </>
            
        ),
        
        showConfirmButton: (AdrArr.length >= 1 && RevArr.length >= 1) ? false : true,
        confirmButtonText: '추가하기',
        confirmButtonColor: '#1eafd2',

        showCancelButton: false,
        cancelButtonText:'닫기',
        cancelButtonColor: '#f6f6f6',

    })
}

export const ReservationConfirm = async (msg, callBack) => {
    
    const mySwal = withReactContent(Swal)
    return await mySwal.fire({
        title: (
            <SwalTitleComponent 
                title={'예약 정보 확인'} 
                cancelBtn={()=>{
                    mySwal.clickCancel();
                }} 
            />
        ),
        html: (
            <>
                <div className='list' style={{textAlign:'left'}}>
                    <div>
                        {msg.split('\n').map((line, idx)=>{
                            return (
                                <div key={idx} style={{padding:'3px 0px'}}>
                                    {line}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
            
        ),
        
        showConfirmButton: true,
        confirmButtonText: '예',
        confirmButtonColor: '#1eafd2',

        showCancelButton: true,
        cancelButtonText:'아니오',
        cancelButtonColor: '#f6f6f6',

    })
}