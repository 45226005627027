import axios from 'axios';


export const KAKAO_LOCAL_API_URL = 'https://dapi.kakao.com';
export const Timeout = 10000;
const NO_ROAD_ADDRESS_STR = '(도로명없음)';
const NO_JIBUN_ADDRESS_STR = '(지번없음)';

export const addressRequestPlace = (adr) => {
    let data = (adr.length <= 0) ? ' ' : adr
    let urlInfo = `/v2/local/search/keyword.json?page=1&size=5&sort=accuracy&query=${encodeURIComponent(data)}`
    return new Promise((resolve, reject) => {
        axios({
            url: `${KAKAO_LOCAL_API_URL}${urlInfo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Authorization': `KakaoAK 9c8644cebd4d4307dd91d7678b7f66ca`,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

export const addressRequest = (adr) => {
    let data = (adr.length <= 0) ? ' ' : adr
    let urlInfo = `/v2/local/search/address.json?page=1&size=5&query=${encodeURIComponent(data)}`
    return new Promise((resolve, reject) => {
        axios({
            url: `${KAKAO_LOCAL_API_URL}${urlInfo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Authorization': `KakaoAK 9c8644cebd4d4307dd91d7678b7f66ca`,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

const getRoadAddressStr = (data) => {
    let retData = NO_ROAD_ADDRESS_STR;
    try {
        // data.address_type값이 존재한다면 address.json response.
        if (data.address_type !== undefined) {
            // 해당되면 값 갱신
            if (data.address_type === 'ROAD_ADDR') {
                retData = data.address_name;
            } else if (data.road_address.address_name !== undefined) {
                retData = data.road_address.address_name;
            }
            // 해당되지 않으면 기본값으로 리턴
        }
        // keyword.json response처리를 하고 있는가?
        else {
            // 해당되면 값 갱신
            if (data.road_address_name !== undefined && data.road_address_name !== '') {
                retData = data.road_address_name;
            }
            // 해당되지 않으면 기본값으로 리턴
        }
    }
    catch (e) {
        
        retData = NO_ROAD_ADDRESS_STR;
    }
    return retData;
}

const getJibunAddressStr = (data) => {
    let retData = NO_JIBUN_ADDRESS_STR;
    try {
        // address.json response 처리
        if (data.address_type !== undefined) {
            // 해당되면 값 갱신
            if (data.address_type === 'REGION_ADDR') {
                retData = data.address_name;
            }
            else if (data.address.address_name !== undefined) {
                retData = data.address.address_name;
            }
            // 해당되지 않으면 기본값으로 리턴
        }
        // keyword.json response처리
        else {
            // 해당되면 값 갱신
            if (data.address_name !== undefined && data.address_name !== '') {
                retData = data.address_name;
            }
            // 해당되지 않으면 기본값으로 리턴
        }


    }
    catch (e) {
        
        retData = NO_JIBUN_ADDRESS_STR;
    }
    return retData;
}

export const setFinishAddressStr = (data) => {
    let retData = '';
    let tmpPlaceName = (data.place_name !== undefined) ? data.place_name : ''

    // 도로명이 있을 경우
    if (getRoadAddressStr(data) !== NO_ROAD_ADDRESS_STR) {
        retData = `${getRoadAddressStr(data)} ${tmpPlaceName}`;
    }
    // 도로명이 없고 지번이 있을 경우
    else if (getJibunAddressStr(data) !== NO_JIBUN_ADDRESS_STR) {
        retData = `${getJibunAddressStr(data)} ${tmpPlaceName}`;
    }
    // 도로명도 없고, 지번도 없을 경우
    else {
        retData = `${tmpPlaceName}`;
    }

    return retData;

}   