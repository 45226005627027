import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from './Icon';
import Calendar from 'react-calendar';
import moment from 'moment';
import { getReserveAvaliableDate, isChk2023Chusok } from '../../lib/Util';
import PropTypes from 'prop-types';

const ScheduleCalendar = (
  {
    selectedDate,
    onChange,
    setDate,
    reservedDateList,
    VisibleStartDate,
    VisibleEndDate,
    DisabledReserveDate,
    isPartnerLogin,
    ReserveFreeFlag,     // 0 or 1  24시간 예약 가능.

  }) => {

  useEffect(() => {
    // 년 월 선택시 선택 못하게 막는다.
    try {
      document.getElementsByClassName('react-calendar__navigation__label')[0].disabled = true
    }
    catch (e) {

    }

  }, [])


  const [dateState, setDateState] = useState([])
  const [dateState2, setDateState2] = useState([])
  const [checkDate, setCheckDate] = useState(new Date());
  const [isNextMonthOrLater, setisNextMonthOrLater] = useState(false);
  const changeDate = (e) => {

    // e 값이 dateState2 배열에 포함되어 있는지 확인
    const exists = dateState2.some(date => date.getTime() === e.getTime());

    let newDateState;

    if (exists) {
      // e 값이 이미 dateState 배열에 있으면, 그 값을 배열에서 삭제
      newDateState = dateState2.filter(date => date.getTime() !== e.getTime());
    } else {
      if (ReserveFreeFlag) {
        newDateState = [e];
      }
      else {
        // e 값이 dateState 배열에 없으면, 그 값을 배열에 추가
        newDateState = [...dateState2, e];
      }
      
    }

    // 새로운 dateState 값을 설정
    setDateState2(newDateState);
    // console.log(newDateState)
    onChange(e);
    setDate(newDateState)

  }
  const handleEvent = (e) => {
    const eventDateData = e.activeStartDate
    const today = new Date();
    setisNextMonthOrLater(eventDateData.getMonth() > today.getMonth() || eventDateData.getFullYear() > today.getFullYear());

  }

  return (
    <div className={classNames('datepicker-wrap')}>
      <Calendar
        locale="ko-KR"
        value={dateState}
        onChange={changeDate}
        onActiveStartDateChange={handleEvent}
        calendarType={'US'}


        onDrillUp={(value, event) => {
          alert('onDrillUp')

        }}

        formatDay={(locale, date) => { return moment(date).format('D'); }}
        // formatWeekday={(locale, date) => moment(date).format('DDD')}
        // selectRange={true}
        nextLabel={<Icon icon={'arrow_right'} />}
        // prevLabel={isNextMonthOrLater ? <Icon icon={'arrow_left'} /> : <Icon icon={'arrow_left'} style={{ visibility: 'hidden' }} />}
        prevLabel={isNextMonthOrLater ? <Icon icon={'arrow_left'} /> : null}
        next2Label={null}
        prev2Label={null}
        showNeighboringMonth={true}

        tileDisabled={(date) => {



          const avaliable = moment(isChk2023Chusok(moment().format('yyyy-MM-DD HH:mm'), 15)).format('yyyy-MM-DD')
          const startDate = moment(VisibleStartDate)
          const endDate = moment(VisibleEndDate).add(3, 'days')
          const currentDate = moment(date.date)

          // console.log(`current - ${currentDate} | startdate - ${startDate} | enddate - ${endDate}`)

          // 계약일 동안의 예약 가능 여부
          if (!(currentDate >= startDate && currentDate <= endDate)) {
            return true;
          }

          // 파트너 로그인시
          if (isPartnerLogin) {
            if ((moment(date.date).format('yyyy-MM-DD') < moment().format('yyyy-MM-DD'))) {
              return (moment(date.date).format('yyyy-MM-DD') < avaliable)
            }
          }
          // 파트너 로그인 이외 (부커페이지)
          else {

            // 24시간 예약 대상자라면
            if (ReserveFreeFlag) {
              let now = moment()
              // 오늘도 체크 가능해야함.
              return moment(date.date).format('yyyy-MM-DD') <= now.add(-1,'days').format('yyyy-MM-DD')
            }
            else {
              if ((moment(date.date).format('yyyy-MM-DD') < avaliable)) {
                return (moment(date.date).format('yyyy-MM-DD') < avaliable)
              }
            }

          }




        }}
        tileClassName={({ date, view }) => {
          let cnData = ''

          if (DisabledReserveDate?.indexOf(moment(date).format('yyyy-MM-DD')) >= 0) {
            cnData += ' reserved '
          }

          // 현재 날짜가 post 작성한 날짜 배열(mark)에 있다면, highlight 클래스 추가
          if (dateState2.find((x) => moment(x).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))) {
            cnData += ' react-calendar__tile--hasActive '


          }

          if (selectedDate.find((x) => moment(x).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))) {
            cnData += ' react-calendar__tile--hasActive '

          }

          if (moment(date).format('ddd') === 'Sat') {
            cnData += ' saturday '

          }

          if (moment(date).format('ddd') === 'Sun') {
            cnData += ' sunday '

          }

          return cnData


          // else {
          //   return 'react-calendar__tile--inactive';
          // }
          /*
          예약 완료 되었다면 선택을 못하도록 막아야함.
           */


        }}
      />
    </div>
  );
};

ScheduleCalendar.propTypes = {
  DisabledReserveDate: PropTypes.array
};

export default ScheduleCalendar;
