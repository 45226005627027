import React from 'react';
import classNames from 'classnames';
import Button from '../atoms/Button';
import Icon from '../atoms/Icon'
import ListWithBullet from '../atoms/ListWithBullet';


const MobileDescriptionPop = (
  {
    title,
    targetDictList,
    setShowDescription,
    setShowDescriptionIndex,
    selectedOptions,
    targetList,
    children
  }) => {

  /** selected option */

  return (
    <div
      className={classNames('mobile-description-pop')}>

      <div className={classNames('mobile-description-header header-menu-list')}>
        {title && (
          <>
            <div className={'w-22'}></div>
            <span className={classNames('title-mid-small bold')}>{title}</span>
          </>
        )}
        <Button
          styleType={'icon'}
          classes={'transparent'}
          onClick={()=>{
            setShowDescription && setShowDescription(false)
            setShowDescriptionIndex && setShowDescriptionIndex(-1)
          }}
        >
          <Icon icon={'close'} />
        </Button>
      </div>

      <div className={classNames('mobile-description-pop-content')}>
        {targetDictList && (
          <ul>
            {targetDictList.map((dict, index) => (
              <li key={index}>
                <div className={classNames('title-regular title flex align-center gap-6')}>
                  {dict?.title}
                  {selectedOptions[dict.category] === dict.value && (
                    <span className={classNames('blue txt-large')}>(선택중)</span>
                  ) }
                </div>
                <div className={classNames('description-wrap')}>
                  <ListWithBullet description={dict.description} />
                </div>
              </li>
            ))}
          </ul>
        )}
        {targetList && (
            <div className={classNames('description-wrap')}>
              <ListWithBullet description={targetList} />
            </div>
        )}
        {children && children}
        <div className={'mobile-description-pop-button'}>
          <Button
            onClick={()=>{
              setShowDescription && setShowDescription(false)
              setShowDescriptionIndex && setShowDescriptionIndex(-1)
            }}
          >
            확인
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MobileDescriptionPop