import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './styles/index.css';
import './styles/header.css';

import Home from './components/pages/Home';
import BookingEstimation from './components/pages/BookingEstimation';
import PageLayout from './components/organisms/PageLayout';

import { URLS } from './utils/urls';
import Subscription from './components/pages/BookingEstimation/Subscription';
import VehicleOption from './components/pages/BookingEstimation/VehicleOption';
import UsageTime from './components/pages/BookingEstimation/UsageTime';
import ContractTerms1 from './components/pages/BookingEstimation/ContractTerms1';
import ContractTerms2 from './components/pages/BookingEstimation/ContractTerms2';
import UserInformation from './components/pages/BookingEstimation/UserInformation';
import PaymentMethod from './components/pages/BookingEstimation/PaymentMethod';
import ConfirmContract from './components/pages/BookingEstimation/ConfirmContract';
import ConfirmEstimate from './components/pages/BookingEstimation/ConfirmEstimate';
import CompleteContract from './components/pages/BookingEstimation/CompleteContract';
import EstimateDownload from './components/pages/BookingEstimation/EstimateDownload';
import EstimateDownloadDataUrl from './components/pages/BookingEstimation/EstimateDownloadDataUrl';
import EstimateCustomize from './components/pages/BookingEstimation/EstimateCustomize';
import ModuRedirectProcess from './components/pages/BookingEstimation/ModuRedirectProcess';
import ModuRedirectProcessDd from './components/pages/DriverDispatchEstimation/ModuRedirectProcessDd';

import DriverDispatchEstimation from './components/pages/DriverDispatchEstimation';
import DdSubscription from './components/pages/DriverDispatchEstimation/Subscription';
import BasicInfo1 from './components/pages/DriverDispatchEstimation/BasicInfo1';
import BasicInfo2 from './components/pages/DriverDispatchEstimation/BasicInfo2';
import BasicInfo3 from './components/pages/DriverDispatchEstimation/BasicInfo3';
import OptionView from './components/pages/DriverDispatchEstimation/OptionView';
import EstimateDocs from './components/pages/DriverDispatchEstimation/EstimateDocs';
import UserInformation2 from './components/pages/DriverDispatchEstimation/UserInformation';
import ConfirmContract2 from './components/pages/DriverDispatchEstimation/ConfirmContract';
import CompleteContract2 from './components/pages/DriverDispatchEstimation/CompleteContract';
import Test from './components/pages/DriverDispatchEstimation/Test';

import DriverDispatchManage from './components/pages/DriverDispatchManage';
import DriverDispatchManageLoginRoot from './components/pages/DriverDispatchManage/Login';
import DriverDispatchManageEmailLogin from './components/pages/DriverDispatchManage/Login/DriverDispatchManageEmailLogin';

import ManageReservation from './components/pages/ManageReservation';
import CreateReservation from './components/pages/ManageReservation/CreateReservation';
import ManagePayments from './components/pages/ManageReservation/ManagePayments';
import MyPage from './components/pages/ManageReservation/MyPage';
import Login from './components/pages/ManageReservation/Login';
import VerifyEmail from './components/pages/ManageReservation/Login/VerifyEmail';
import VerifyPassword from './components/pages/ManageReservation/Login/VerifyPassword';
import Register from './components/pages/ManageReservation/Login/Register';
import ReservationHistory from './components/pages/ManageReservation/ReservationHistory';
import ScrollToTop from './hoc/ScrollToTop';
import EmailLogin from './components/pages/ManageReservation/Login/EmailLogin';
import Logout from './components/pages/ManageReservation/Login/Logout';
import CookieCheck from './hoc/CookieCheck';
import DriverDispatchManageHistory from './components/pages/DriverDispatchManage/DriverDispatchManageHistory';
import DriverDispatchManageMyPage from './components/pages/DriverDispatchManage/DriverDispatchManageMyPage';

import { ProtectedRouteReservation } from './components/pages/ManageReservation/ProtectedRouteReservation';
import { ProtectedRouteMm } from './components/pages/BookingEstimation/ProtectedRouteMm';
import { ProtectedRouteDd } from './components/pages/DriverDispatchEstimation/ProtectedRouteDd';
import { ProtectedRouteDriverDispatch } from './components/pages/DriverDispatchManage/ProtectedRouteDriverDispatch';
import EstimateDocsDownload from './components/pages/DriverDispatchEstimation/EstimateDocsDownload';
import DriverManageLogout from './components/pages/DriverDispatchManage/Login/DriverManageLogout';
import DriverManageRegister from './components/pages/DriverDispatchManage/Login/DriverManageRegister';
import KakaoRedirectProcess from './components/pages/ManageReservation/KakaoRedirectProcess';
import KakaoLoginPhoneCheck from './components/pages/ManageReservation/Login/KakaoLoginPhoneCheck';
import UsageTimeDriverDispatch from './components/pages/DriverDispatchEstimation/UsageTimeDriverDispatch';
import RecuritRequest from './components/pages/DriverDispatchEstimation/RecuritRequest';
import BookingEstimateLogin from './components/pages/BookingEstimation/BookingEstimateLogin';
import EstimateDocsDataUrl from './components/pages/DriverDispatchEstimation/EstimateDocsDataUrl';
import ConfirmDispatchRequest from './components/pages/DriverDispatchEstimation/ConfirmDispatchRequest';
import ReservationHistoryPdf from './components/pages/ManageReservation/ReservationHistoryPdf';
import DriverLogin from './components/pages/ManageReservation/Login/DriverLogin';
import MonthlyMosiler from './components/pages/Introduction/MonthlyMosiler';
import Driver from './components/pages/Introduction/Driver';
import InformationRequestForm from './components/pages/InformationRequestForm';
import ContractApplicationForm from './components/pages/ContractApplicationForm';
import Profile from './components/pages/DriverDispatchManage/Profile';
import MonthlyMosilerCommercial from './components/pages/Introduction/MonthlyMosilerCommercial';

function App() {
	return (
		<div className='App'>
			<ScrollToTop />
			{/* <CookieCheck /> */}
			<Routes>
				<Route element={<PageLayout />}>
					<Route path={URLS.HOME} element={<Home />} />
					<Route path={URLS.BOOKING_ESTIMATION_DOWNLOAD} element={<EstimateDownload />} />
					<Route path={URLS.BOOKING_ESTIMATION_DOWNLOAD_DATAURL} element={<EstimateDownloadDataUrl />} />
					<Route path={URLS.BOOKING_MODUSIGN_REDIRECT} element={<ModuRedirectProcess />} />
					<Route path={URLS.DRIVER_DISPATCH_REDIRECT} element={<ModuRedirectProcessDd />} />
					<Route path={URLS.DRIVER_DISPATCH_ESTIMATE_DOWNLOAD} element={<EstimateDocsDownload />} />
					<Route path={URLS.DRIVER_DISPATCH_ESTIMATE_DOWNLOAD_DATAURL} element={<EstimateDocsDataUrl />} />
					<Route path={URLS.RESERVATION_KAKAO_LOGIN_REDIRECT} element={<KakaoRedirectProcess type={'reservation'} />} />
					<Route path={URLS.BOOKING_ESTIMATION_LOGIN} element={<BookingEstimateLogin />} />
					<Route path={URLS.BOOKING_ESTIMATION_CUSTOMIZE} element={<EstimateCustomize />} />
					<Route path={URLS.RESERVATION_HISTORY_PDF} element={<ReservationHistoryPdf />} />

					{/* 수행기사 채용 */}
					<Route path={URLS.HOME} element={<DriverDispatchEstimation />}>
						<Route path={URLS.DRIVER_DISPATCH_SUBSCRIPTION} element={<DdSubscription />} />
						<Route
							path={URLS.DRIVER_DISPATCH_BASIC_INFO_1}
							element={
								<ProtectedRouteDd>
									<BasicInfo1 />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_USAGE_TIME}
							element={
								<ProtectedRouteDd>
									<UsageTimeDriverDispatch />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_BASIC_INFO_2}
							element={
								<ProtectedRouteDd>
									<BasicInfo2 />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_BASIC_INFO_3}
							element={
								<ProtectedRouteDd>
									<BasicInfo3 />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_OPTION}
							element={
								<ProtectedRouteDd>
									<OptionView />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_ESTIMATE}
							element={
								<ProtectedRouteDd>
									<EstimateDocs />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_USER_INFORMATION}
							element={
								<ProtectedRouteDd>
									<UserInformation2 />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_RECRUIT_REQUEST}
							element={
								<ProtectedRouteDd>
									<RecuritRequest />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_CONFIRM_CONTRACT}
							element={
								<ProtectedRouteDd>
									<ConfirmContract2 />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_CONFIRM_DISPATCH_REQUEST}
							element={
								<ProtectedRouteDd>
									<ConfirmDispatchRequest />
								</ProtectedRouteDd>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_COMPLETE_CONTRACT}
							element={
								<ProtectedRouteDd>
									<CompleteContract2 />
								</ProtectedRouteDd>
							}
						/>
						<Route path={URLS.DRIVER_DISPATCH_TEST} element={<Test />} />
					</Route>

					{/* 수행기사 관리 페이지  */}
					<Route path={URLS.HOME} element={<DriverDispatchManage />}>
						<Route
							path={URLS.DRIVER_DISPATCH_MANAGE_MYPAGE}
							element={
								<ProtectedRouteDriverDispatch>
									<DriverDispatchManageMyPage />
								</ProtectedRouteDriverDispatch>
							}
						/>
						<Route
							path={URLS.DRIVER_DISPATCH_MANAGE_HISTORY}
							element={
								<ProtectedRouteDriverDispatch>
									<DriverDispatchManageHistory />
								</ProtectedRouteDriverDispatch>
							}
						/>

						<Route path={URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGOUT} element={<DriverManageLogout />} />
						<Route path={URLS.DRIVER_DISPATCH_MANAGE_LOGIN} element={<DriverDispatchManageLoginRoot />}>
							<Route path={URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGIN} element={<DriverDispatchManageEmailLogin />} />
							<Route path={URLS.DRIVER_DISPATCH_MANAGE_REGISTER} element={<DriverManageRegister />} />
						</Route>
					</Route>

					<Route path={URLS.HOME} element={<BookingEstimation />}>
						<Route path={URLS.BOOKING_ESTIMATION_SUBSCRIPTION} element={<Subscription />} />
						<Route
							path={URLS.BOOKING_ESTIMATION_USAGE_TIME}
							element={
								<ProtectedRouteMm>
									<UsageTime />
								</ProtectedRouteMm>
							}
						/>
						<Route
							path={URLS.BOOKING_ESTIMATION_VEHICLE_OPTION}
							element={
								<ProtectedRouteMm>
									<VehicleOption />
								</ProtectedRouteMm>
							}
						/>
						<Route
							path={URLS.BOOKING_ESTIMATION_CONTRACT_TERMS1}
							element={
								<ProtectedRouteMm>
									<ContractTerms1 />
								</ProtectedRouteMm>
							}
						/>
						<Route
							path={URLS.BOOKING_ESTIMATION_CONTRACT_TERMS2}
							element={
								<ProtectedRouteMm>
									<ContractTerms2 />
								</ProtectedRouteMm>
							}
						/>
						<Route
							path={URLS.BOOKING_ESTIMATION_USER_INFORMATION}
							element={
								<ProtectedRouteMm>
									<UserInformation />
								</ProtectedRouteMm>
							}
						/>
						<Route
							path={URLS.BOOKING_ESTIMATION_PAYMENT_METHOD}
							element={
								<ProtectedRouteMm>
									<PaymentMethod />
								</ProtectedRouteMm>
							}
						/>
						<Route
							path={URLS.BOOKING_ESTIMATION_CONFIRM_CONTRACT}
							element={
								<ProtectedRouteMm>
									<ConfirmContract />
								</ProtectedRouteMm>
							}
						/>

						<Route
							path={URLS.BOOKING_ESTIMATION_CONFIRM_ESTIMATE}
							element={
								<ProtectedRouteMm>
									<ConfirmEstimate />
								</ProtectedRouteMm>
							}
						/>
						<Route
							path={URLS.BOOKING_ESTIMATION_COMPLETE_CONTRACT}
							element={
								<ProtectedRouteMm>
									<CompleteContract />
								</ProtectedRouteMm>
							}
						/>
					</Route>

					<Route path={URLS.HOME} element={<ManageReservation />}>
						{/* 로그아웃 상태에서 해당 페이지 접근시 접근 못하게 막는 처리 */}
						<Route
							path={URLS.RESERVATION_CREATE}
							element={
								<ProtectedRouteReservation>
									<CreateReservation />
								</ProtectedRouteReservation>
							}
						/>
						<Route
							path={URLS.RESERVATION_MANAGE_PAYMENTS}
							element={
								<ProtectedRouteReservation>
									<ManagePayments />
								</ProtectedRouteReservation>
							}
						/>
						<Route
							path={URLS.RESERVATION_MY_PAGE}
							element={
								<ProtectedRouteReservation>
									<MyPage />
								</ProtectedRouteReservation>
							}
						/>
						<Route
							path={URLS.RESERVATION_HISTORY}
							element={
								<ProtectedRouteReservation>
									<ReservationHistory />
								</ProtectedRouteReservation>
							}
						/>

						<Route path={URLS.EMAIL_LOGOUT} element={<Logout />} />
						<Route path={URLS.LOGIN} element={<Login />}>
							<Route path={URLS.EMAIL_LOGIN} element={<EmailLogin />} />
							<Route path={URLS.EMAIL_LOGIN_DRIVER} element={<DriverLogin />} />
							<Route path={URLS.VERIFY_EMAIL} element={<VerifyEmail />} />
							<Route path={URLS.VERIFY_PASSWORD} element={<VerifyPassword />} />
							<Route path={URLS.REGISTER} element={<Register />} />
							<Route path={URLS.KAKAO_LOGIN_PHONE_CHECK} element={<KakaoLoginPhoneCheck />} />
						</Route>
					</Route>
				</Route>
				{/* 소개서 페이지 */}
				
				<Route path={URLS.COMMERCIAL_MONTHLY_MOSILER} element={<MonthlyMosiler />} />
				<Route path={URLS.INTRODUCTION_MONTHLY_MOSILER} element={<MonthlyMosilerCommercial />} />
				<Route path={URLS.INTRODUCTION_DRIVER} element={<Driver />} />

				{/* 기본 정보 요청서 페이지 */}
				<Route path={URLS.INFORMATION_REQUEST_FORM} element={<InformationRequestForm />} />
				<Route path={URLS.CONTRACT_APPLICATION_FORM} element={<ContractApplicationForm />} />

				<Route path={URLS.DRIVER_DISPATCH_PROFILE} element={<Profile />} />
			</Routes>
		</div>
	);
}

export default App;
