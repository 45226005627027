import React from 'react';
import { Outlet } from 'react-router-dom';
import Loading from '../atoms/Loading';
import { useSelector } from 'react-redux';
import LoadingMessage from '../atoms/LoadingMessage';

const PageLayout = () => {

  const { LoadingRedux } = useSelector((state) => state);

  return (
    <>
      {LoadingRedux.isLoading && <Loading estimateMsg={LoadingRedux.isEstimate} />}
      {LoadingRedux.isReserve && <LoadingMessage Msg={'예약 접수 진행중입니다.<br/>몇초~몇분가량 소요됩니다.<br/>잠시만 기다려주십시오.'} />}
      <main>
        <Outlet />
      </main>
    </>
  )
}

export default PageLayout