import '../../../styles/reviewSet.css';

const ReviewSet = ({ title, reviewer, contents, pattern }) => {
	return (
		<>
			<div className='review-set-wrap'>
				{title && <span className='title' dangerouslySetInnerHTML={{ __html: title }}></span>}
				<div className='review'>
					{reviewer && contents && (
						<>
							<span className='reviewer'>{reviewer}</span>
							<span className='contents'>{contents}</span>
						</>
					)}
				</div>
				{pattern && (
					<div className='pattern-wrap'>
						<ul className={'pattern-list'}>
							{pattern.map((item) => (
								<li>
									<div className='pattern-item'>
										<span className='time'>{item.time}</span>
										<span className='item-title'>{item.title}</span>
									</div>
								</li>
							))}
						</ul>
						<span className='pattern-badge'>이용패턴</span>
					</div>
				)}
			</div>
		</>
	);
};

export default ReviewSet;
