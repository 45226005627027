import React, { useState } from 'react';
import DatePicker from 'react-datepicker'; // react-datepicker 라이브러리 사용
import 'react-datepicker/dist/react-datepicker.css'; // 스타일 import
import Icon from '../atoms/Icon';
import '../../styles/datepicker.css';
import ko from 'date-fns/locale/ko';
import Button from '../atoms/Button';

const DatePickerInput = ({ selectedDate, onDateChange, placeholder }) => {
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

	const handleDateChange = (date) => {
		onDateChange(date);
		setIsDatePickerOpen(false);
	};

	return (
		<div className='date-picker-container'>
			<div className='input-wrapper'>
				<input type='text' readOnly value={selectedDate ? selectedDate.toLocaleDateString() : placeholder} onClick={() => setIsDatePickerOpen(true)} className='date-input' />
				<Button styleType='icon' type='button' className='calendar-icon-button' onClick={() => setIsDatePickerOpen(true)}>
					<Icon icon={'calendar'} />
				</Button>
			</div>
			{isDatePickerOpen && (
				<div className='datepicker-wrap'>
					<DatePicker
						dateFormat='yyyy년 MM월 dd일'
						showPopperArrow={false}
						locale={ko}
						selected={selectedDate}
						onChange={handleDateChange}
						onClickOutside={() => setIsDatePickerOpen(false)}
						inline
					/>
				</div>
			)}
		</div>
	);
};

export default DatePickerInput;
