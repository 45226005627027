import PropTypes from 'prop-types';
import React from 'react';

import { withRadioProvider, useRadioContext } from './RadioContext';

const RadioGroup = ({ children, legend, classes, ...props }) => {
  const { selected, setSelected } = useRadioContext();
  return (
    <div >
      <fieldset {...props} className={classes}>
        <legend className={'hidden'}>{legend}</legend>
        {children}
      </fieldset>
    </div>
  );
};

export default withRadioProvider(RadioGroup);

RadioGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  legend: PropTypes.string,
  classes: PropTypes.string,
};
