import React, { useState, useEffect } from 'react';
import { URLS } from '../../../../utils/urls';
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom';
import AccountApi from '../../../../lib/AccountApi';
import { useSelector } from 'react-redux';
import { isChkResultSuccess } from '../../../../lib/Util';

/**
 * 로그아웃
 * path: reservation/login/email-login
 * **/


const Logout = () => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const loginInfo = useSelector(state => state.Login);
    const navigate = useNavigate();

    useEffect(() => {
        async function process() {
            let result = await AccountApi.SetLogOut(loginInfo.User.CustomerNo)
            if (isChkResultSuccess(result)) {
                removeCookie('li_01', { path: '/' })
                removeCookie('li_02', { path: '/' })
                removeCookie('alchk', { path: '/' })
                removeCookie('lgi', { path: '/' })
                removeCookie('lgupi', { path: '/' })

                navigate(URLS.EMAIL_LOGIN)
            }
        }

        process()

    }, [])


    return (
        <></>
    )
};

export default Logout