import React from 'react';
import classNames from 'classnames';
import Input from '../atoms/Input';
import Button from '../atoms/Button';

const UserInput = (
    {
        register,
        setValue,
        zipRegister,
        address1Register,
        address2Register,
        zipError,
        address1Error,
        address2Error,
        handleAddressChange,
        target,
        selectAddress = false,
        onButton,
        Error,
        nameError,
        phoneError,
    }
) => {
    return (
        <div>
            <div className={'input-wrap input-with-button'} style={{ paddingBottom: '10px' }}>
                <Input
                    type={'text'}
                    required
                    {...register('PassengerUserName')}
                    error={nameError}
                    setValue={setValue}
                    onChange={e => {
                        setValue('PassengerUserName', e.target.value)
                    }}
                    placeholder={'이름'}
                    disabled={true}

                />
                <Button
                    styleType={'tertiary'}
                    classes={'button-small'}
                    onClick={onButton}
                >
                    불러오기
                </Button>
            </div>


            <Input
                type={'text'}
                required
                {...register('PassengerPhoneNo')}
                setValue={setValue}
                error={phoneError}
                onChange={e => {
                    setValue('PassengerPhoneNo', e.target.value)
                    handleAddressChange('PassengerPhoneNo', e.target.value, target);
                }}
                placeholder={'전화번호'}
                disabled={true}
            />

        </div>
    )
}

export default UserInput
