import '../../../styles/mainTable.css';

const MonthlyMosilerTable = () => {
	return (
		<div className='mobile-wrapper'>
			<div className='main-table m-td-set-3'>
				<table>
					<thead>
						<tr>
							<td></td>
							<td>A그룹 직접고용</td>
							<td className='pc'>B사 파견고용</td>
							<td className='pc'>C사 법인대리</td>
							<td className={'highlight'}>월간 모시러</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className={'left'}>주 52시간 연장</td>
							<td>
								<span className='icon-x'></span>
							</td>
							<td className='pc'>
								<span className='icon-x'></span>
							</td>
							<td className='pc'>
								<span className='icon-o'></span>
							</td>
							<td className='highlight'>
								<span className='icon-o'></span>
							</td>
						</tr>
						<tr>
							<td className={'left'}>정기 교육</td>
							<td>
								<span className='icon-x'></span>
							</td>
							<td className='pc'>
								<span className='icon-x'></span>
							</td>
							<td className='pc'>
								<span className='icon-x'></span>
							</td>
							<td className='highlight'>
								<span className='icon-o'></span>
							</td>
						</tr>
						<tr>
							<td className={'left'}>OT/식비/퇴직금</td>
							<td>
								<span className='icon-o'></span>
							</td>
							<td className='pc'>
								<span className='icon-o'></span>
							</td>
							<td className='pc'>
								<span className='icon-x'></span>
							</td>
							<td className='highlight'>
								<span className='icon-x'></span>
							</td>
						</tr>
						<tr>
							<td className={'left'}>드라이버 교체</td>
							<td>번거로움</td>
							<td className='pc'>번거로움</td>
							<td className='pc'>즉시가능</td>
							<td className='highlight'>즉시가능</td>
						</tr>
						<tr>
							<td className={'left'}>서브 드라이버</td>
							<td>
								<span className='icon-x'></span>
							</td>
							<td className='pc'>
								<span className='icon-x'></span>
							</td>
							<td className='pc'>
								<span className='icon-x'></span>
							</td>
							<td className='highlight'>
								<span className='icon-o'></span>
							</td>
						</tr>
						<tr>
							<td className={'left'}>운전 외 업무수행</td>
							<td>
								<span className='icon-x'></span>
							</td>
							<td className='pc'>
								<span className='icon-x'></span>
							</td>
							<td className='pc'>
								<span className='icon-x'></span>
							</td>
							<td className='highlight'>
								<span className='icon-o'></span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default MonthlyMosilerTable;
