import '../../../styles/servicePatternSchedule.css';

const ServicePatternSchedule = () => {
	return (
		<div className='table-wrap'>
			<img src={`/assets/table.png`} alt={`table`} className='image mobile' />
			<div className='service-pattern-schedule pc'>
				<div className='week-list'>
					<span>월</span>
					<span>화</span>
					<span>수</span>
					<span>목</span>
					<span>금</span>
					<span>토</span>
					<span>일</span>
				</div>
				<div className='content'>
					<div className='time-list'>
						<span>오전 5시</span>
						<span>오전 6시</span>
						<span>오전 7시</span>
						<span>오전 8시</span>
						<span>오전 9시</span>
						<span>오전 10시</span>
						<span>오전 11시</span>
						<span>오전 12시</span>
						<span>오후 1시</span>
						<span>오후 2시</span>
						<span>오후 3시</span>
						<span>오후 4시</span>
						<span>오후 5시</span>
						<span>오후 6시</span>
						<span>오후 7시</span>
						<span>오후 8시</span>
						<span>오후 9시</span>
						<span>오후 10시</span>
					</div>
					<div className='schedule-list'>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<div className='time-schedule-list'>
						<div className='schedule'>
							<span className={'h-8 f-2'}>8시간</span>
						</div>
						<div className='schedule'>
							<span className={'h-12 f-5'}>12시간</span>
						</div>
						<div className='schedule'>
							<span className={'h-8 f-3'}>8시간</span>
						</div>
						<div className='schedule'>
							<span className={'h-10 f-3'}>10시간</span>
						</div>
						<div className='schedule'>
							<span className={'h-10 f-1'}>10시간</span>
						</div>
						<div className='schedule'>
							<span className={'h-8 f-7'}>8시간</span>
						</div>
						<div className='schedule'>
							<span className={'h-8 f-6'}>8시간</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServicePatternSchedule;
