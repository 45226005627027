import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { transFormWon } from '../../utils/numbers';
import classNames from 'classnames';
import useCheckPC from '../../hooks/useCheckPC';
import ListBox from './ListBox';
import { getValueByTarget, VehiclesOptionCount } from '../../utils/parse';
import {
  BookingProducts,
  ConfirmEstimateDescription,
  ContractPeriodOptions,
  NumberOfVehiclesOptions,
  PassengerOptions,
  PurposeOfContractOptions,
  UsageTimeOptions,
  CarAmountOptions,
  InsuranceOptions,
  PassengerPersonOptions,
  UsageTimeOptionsAnnual,
  UsageTimeOptionsMonthly
} from '../../utils/EstimateFormData';
import ListBox2 from './ListBox2';




const TotalAmount3 = (
  {
    styleType = 'primary',
    pageType
  }) => {
  const { isPC } = useCheckPC();
  const { BookingOption } = useSelector((state) => state);
  const [pageTypeList, SetPageTypeList] = useState([])
  const { ContractMonthArray } = useSelector((state) => state);


  /** 상품명 **/
  const [subscription, setSubscription] = useState([]);

  /** 이용 시간**/
  const [usageTime, setUsageTime] = useState([]);

  /** 차량 옵션 **/
  // const [vehicleOption, setVehicleOption] = useState([]);

  /** 계약 조건 **/
  const [periodOfContract, setPeriodOfContract] = useState([]);

  /** 이용 지역 **/
  // const [serviceArea, setServiceArea] = useState([])

  /** 이용 정보 **/
  // const [contractTerms2, setContractTerm2] = useState([]);

  useEffect(() => {

    /** 상품명 **/
    const updatedSubscription = [
      {
        // title: getValueByTarget(BookingProducts,BookingOption.subscription,'title'),
        title: '기본금액',
        price: getValueByTarget(
          BookingProducts,
          BookingOption.subscription,
          'price'
        )
      }]
    setSubscription(updatedSubscription)



    if (BookingOption.subscription === 'annual') {
      setUsageTime([
        {
          // title: getValueByTarget(UsageTimeOptionsAnnual,BookingOption.usageTime,'title'),
          title: '추가 이용시간',
          price: getValueByTarget(
            UsageTimeOptionsAnnual,
            BookingOption.usageTime,
            'price'
          )
        }])
    }
    else {
      setUsageTime([
        {
          // title: getValueByTarget(UsageTimeOptions,BookingOption.usageTime,'title'),
          title: '추가 이용시간',
          price: getValueByTarget(
            UsageTimeOptions,
            BookingOption.usageTime,
            'price'
          )
        }])
    }

    const updatedPeriodOfContract = [
      {
        title: `계약 기간: ${getValueByTarget(
          ContractMonthArray.select,
          BookingOption.contractTerms1.periodOfContract,
          'title'
        )}`,
        discount: getValueByTarget(
          ContractMonthArray.select,
          BookingOption.contractTerms1.periodOfContract,
          'discount'
        )
      }
    ]
    setPeriodOfContract(updatedPeriodOfContract)



    // const updatedPeriodOfContract = [
    //   {
    //     title: `계약 기간: ${getValueByTarget(
    //       ContractPeriodOptions,
    //       BookingOption.contractTerms1.periodOfContract,
    //       'title'
    //     )}`,
    //     discount: getValueByTarget(
    //       ContractPeriodOptions,
    //       BookingOption.contractTerms1.periodOfContract,
    //       'discount'
    //     )
    //   }
    // ]
    // setPeriodOfContract(updatedPeriodOfContract)


    // const updatedServiceArea = [
    //   {
    //     title: `출발 지역: 
    //       ${BookingOption?.contractTerms1?.serviceArea?.departure?.address1} 
    //       ${BookingOption?.contractTerms1?.serviceArea?.departure?.address2}`
    //   },
    //   BookingOption?.contractTerms1?.serviceArea?.destination?.address1 && {
    //     title: `도착 지역: 
    //       ${BookingOption.contractTerms1.serviceArea.destination.address1} 
    //       ${BookingOption.contractTerms1.serviceArea.destination.address2}`
    //   }
    // ].filter(Boolean);
    // setServiceArea(updatedServiceArea)


    // const updatedContractTerms2 = [
    //   { title: `이용 목적: ${getValueByTarget(PurposeOfContractOptions, BookingOption?.contractTerms2?.purposeOfContract, 'title')}` },
    //   { title: `탑승자: ${getValueByTarget(PassengerOptions, BookingOption.contractTerms2.passenger, 'title')}` },
    //   { title: `차량 모델명1: ${BookingOption.contractTerms2.vehicleName1}` },
    //   { title: `차량 모델명2: ${BookingOption.contractTerms2.vehicleName2}` },

    // ]

    // if (VehiclesOptionCount(BookingOption?.vehicleOption?.numberOfVehicles) === 3) {
    //   updatedContractTerms2.push({ title: `차량 모델명3: ${BookingOption.contractTerms2.vehicleName3}` })
    // }

    // setContractTerm2(updatedContractTerms2)

  }, [BookingOption])

  return (
    <div style={{ paddingBottom: '10px', paddingTop: '10px', borderTop: '1px solid var(--light-grey-1)' }}>
      <div>
        <ListBox2
          listItem={subscription}
        />
      </div>
      <div>
        <ListBox2
          listItem={usageTime}
        />
      </div>
      {BookingOption?.subscription === 'monthly' && <ListBox2
        listItem={periodOfContract}
      />}



    </div>

  );
};

export default TotalAmount3;
