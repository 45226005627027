import React from 'react';
import classNames from 'classnames';


const TableDescriptPopContents = ({ theadList, tbody}) => {
  return (
    <div className={classNames('table-pop-description')}>
      <ul>
        {theadList.map((thead, index) => {
          const bodyRowContent = tbody.all[index];
          return (
            <li key={index}>
              <span
                className={classNames('title-mid bold grey-1')}
                dangerouslySetInnerHTML={{ __html: thead.title }}></span>
              <span
                className={classNames('table-description')}
                dangerouslySetInnerHTML={{ __html: bodyRowContent }}></span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default TableDescriptPopContents