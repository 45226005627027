export const DRIVER_MOCK_DATA = {
	BusinessData: {
		RepresentativeName: '홍길동',
		BusinessCode: '123-45-67890',
		Address1: '서울특별시 강남구 테헤란로 123',
		Address2: '강남빌딩 10층',
	},
	card: [
		{
			type: 'regular',
			cardName: '신한카드',
			cardNum: '1234-5678-9012-3456',
		},
	],
	MemberList: [
		{
			type: 'owner', // 대표 관리자
			MemberType: '대표 관리자',
			UseType: '활성화',
			CustomerName: '김모시러',
			PhoneNumber: '010-1234-5678',
		},
		{
			type: 'manager', // 일반 관리자
			MemberType: '일반 관리자',
			UseType: '비활성화',
			CustomerName: '이모시러',
			PhoneNumber: '010-9876-5432',
		},
		{
			type: 'member', // 일반 이용자
			MemberType: '일반 이용자',
			UseType: '활성화',
			CustomerName: '박모시러',
			PhoneNumber: '010-5678-1234',
		},
		{
			type: 'member', // 일반 이용자
			MemberType: '일반 이용자',
			UseType: '활성화',
			CustomerName: '정모시러',
			PhoneNumber: '010-4321-8765',
		},
	],
	AddressList: [
		{
			Alias: '집',
			Address: '서울특별시 강남구 테헤란로 123',
		},
		{
			Alias: '회사',
			Address: '서울특별시 서초구 서초대로 456',
		},
		{
			Alias: '별장',
			Address: '경기도 가평군 청평면 호반로 789',
		},
	],
	CarInfoList: [
		{
			CarNumber: '12가 3456',
			CarModel: '벤츠 S 클래스',
		},
		{
			CarNumber: '34나 5678',
			CarModel: 'BMW 7 시리즈',
		},
	],
	partnerPoolList: [
		{
			name: '홍길동',
			number: '010-1234-5678',
		},
		{
			name: '이순신',
			number: '010-9876-5432',
		},
		{
			name: '장보고',
			number: '010-2468-1357',
		},
		{
			name: '강감찬',
			number: '010-1357-2468',
		},
		{
			name: '유관순',
			number: '010-1122-3344',
		},
	],
	drivedPartnerList: [
		{
			name: '홍길동',
			number: '010-1234-5678',
		},
		{
			name: '강감찬',
			number: '010-1357-2468',
		},
		{
			name: '유관순',
			number: '010-1122-3344',
		},
	],
	alertList: [
		{
			time: '2024-01-01',
			message: '지난달은 총 80시간 ',
		},
		{
			time: '2024-01-01',
			message: '· 지난달은 총 80시간 중 <span class="red">112시간</span> 사용하셨습니다<br>· 사용 패턴을 봤을 때 XXX 상품이 맞습니다.',
		},
		{
			time: '2024-01-01',
			message: '지난달은 총 80시간 중 <span class="red">112시간</span> 사용하셨습니다. 더 자세한',
		},
	],
};

export const PROFILE_EDUCATION = [
	{
		title: '대전 XX 대학교 대학원 졸업',
		startTime: '2020.03',
		endTime: '2022.02',
	},
	{
		title: '청주 XX 고등학교 졸업',
		startTime: '2016.03',
		endTime: '2019.02',
	},
];

export const PROFILE_CAREER = [
	{
		title: '트럼프 대통령 의전 운전',
		startTime: '2024.01',
		endTime: '2024.08',
	},
	{
		title: '주식회사 XX 의전 운전',
		startTime: '2022.06',
		endTime: '2023.12',
	},
];

export const PROFILE_CERTIFICATION = [
	{
		title: '정보처리기사 자격증 취득',
		time: '2024.03',
	},
];

export const FEEDBACK = {
	contents: '운전이 안정적이면서 에티튜드 훌륭합니다.<br>고객의 의도를 잘 파악할 수 있습니다.',
	teacher: '양심규',
	date: '2024년 03월 02일',
};
