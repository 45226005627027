
/**
 * url 마지막 path 추축
 * @param url: string ex) '/booking/subscription', 'booking/vehicle-option'
 * @returns subscription, vehicleOption 형태
 * */
export const getLastURLSegment = url => {
  const urlParts = url.split('/');
  const lastSegment = urlParts[urlParts.length - 1];

  return lastSegment
    .split('-')
    .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
}

/**
 * 선택된 item의 value로 target 추출
 * @param (targetDict: object, targetValue: string)
 * @returns string list 형태
 * */
export const getValueByTarget = (targetDict, targetValue, target) => {
  
  const selectedProduct = targetDict.find(product => product.value === targetValue);
  
  if (selectedProduct) {
    return selectedProduct[target];
  }
  return null;
}

export const VehiclesOptionCount = (value) => {
  if (value === 'threeVehicles') {
    return 3;
  }
  return 2;
}

