import React from 'react';
import classNames from 'classnames';
import TotalAmount from './TotalAmount';
import useCheckPC from '../../hooks/useCheckPC';
import ListWithBullet from './ListWithBullet';

const BookingFormSummary = (
  {
    hasAmonut = true,
    description,
    pageType,
    children,
    SubAmountObj

  }) => {
  const { isPC } = useCheckPC();
  return (
    <div className={classNames('booking-form-summary')}>
      {(isPC && description) && (
        <ListWithBullet description={description} />
      )}
      {/* {hasAmonut && <TotalAmount />}
      {SubAmountObj && <SubAmountObj />} */}
      {/* <div className={classNames(!hasAmonut && 'pt-20')}> */}
      <div className={classNames('pt-20')}>
        {children}
      </div>
    </div>
  )
}

export default BookingFormSummary