const initialStateLayout = {
  isModalOpen: false,
  modal: {
    title: '',
    closeBtn: {
      label: '취소',
    },
    confirmBtn: {
      label: '확인',
    },
  },
};

const LAYOUT_OPEN_MODAL = 'LAYOUT/OPEN_MODAL';
const LAYOUT_CLOSE_MODAL = 'LAYOUT/CLOSE_MODAL';

const openModal = modal => ({ type: LAYOUT_OPEN_MODAL, modal });
const closeModal = modal => ({ type: LAYOUT_CLOSE_MODAL, modal });

export const layoutActionCreator = {
  openModal,
  closeModal,
};

export const layout = (state = initialStateLayout, action) => {
  switch (action.type) {
    case LAYOUT_OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        modal: {
          ...state.modal,
          ...action.modal,
        },
      };
    case LAYOUT_CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        modal: {
          ...initialStateLayout.modal,
        },
      };
    default:
      return state;
  };
};
