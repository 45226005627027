import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { transFormWon } from '../../utils/numbers';
import useCheckPC from '../../hooks/useCheckPC';

const BookingItemSizeUp = (
  {
    selected,
    children,
    title,
    subtitle,
    description,
    priceTitle,
    price,
    discount,
    priceUnstable = false,
    isAlignCenter = false,
    priceVisibleFlag = false
  }) => {
  const { isPC } = useCheckPC();
  const pcStyle = { padding: '50px 40px' }
  const mobileStyle = { padding: '35px 20px' }

  return (
    <>
      <div className={classNames('booking-item', selected && 'selected', isAlignCenter && 'align-center')} style={isPC ? pcStyle : mobileStyle}>
        {children}
        {subtitle && (
          <span className={classNames('title-small block subtitle', (!title && !(price >= 0) && !(discount >= 0)) && 'margin-0')}>{subtitle}</span>
        )}
        {title && (
          // <span className={classNames('block title', isPC ? 'title-large' : 'title-mid')}>{title}</span>
          <span className={classNames('block title', isPC ? 'title-large' : 'title-mid')} style={{ marginBottom: '0px' }}>{title}</span>
        )}
        {(price >= 0 || discount >= 0) && (
          <div className={classNames('price-set')}>
            {priceTitle && <span className={classNames('txt-small grey-3')}>{priceTitle}</span>}
            {priceVisibleFlag === true && (<>
              {price >= 0 && <span className={classNames('block', isPC ? 'title-regular' : 'title-mid')}>{price >= 1 && '+'}{transFormWon(price)}{priceUnstable && '~'}</span>}
            </>)}



            {discount >= 0 && <span className={classNames('block', isPC ? 'title-regular' : 'title-mid')}>{discount > 0 && '-'}{transFormWon(discount)}</span>}
          </div>
        )}
        {description && (
          <div className={classNames('booking-description')}>
            <span className={classNames('txt-small grey-3')}>{description}</span>
          </div>
        )}
      </div>
    </>
  )
}

BookingItemSizeUp.propTypes = {
  hasCheckBox: PropTypes.bool,
  children: PropTypes.node,
};

export default BookingItemSizeUp;