import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import useLayout from '../../hooks/useLayout';
import Button from '../atoms/Button';
import Dim from '../atoms/Dim';

const Modal3 = (
  {
    title,
    subTitle,
    onClickConfirm,
    modalClose,
    showClose = false,
    children }) => {
  const { closeModal } = useLayout();
  useEffect(() => {
    const init = () => {
      let containerWidth = document.getElementById('modal3').offsetWidth
      let contentWidth = document.getElementById('iframe').offsetWidth

      let padding = (containerWidth - 380) / 2; // 양쪽에 동일한 패딩을 설정하기 위해 2로 나눕니다.

      document.getElementById('bookingboxId').style.paddingLeft = padding + 'px';
      document.getElementById('bookingboxId').style.paddingRight = padding + 'px';
    }
    init()


  }, [])

  return (
    <>
      <div id="modal3" className={'modal3'} style={{ minWidth: '0px', minHeight: '0px', width: '100%', padding: '24px 3px' }}>
        <div className={classNames('title-set')}>
          {!!title && <span className={classNames('title-mid bold')}>{title}</span>}
          {!!subTitle && <span className={classNames('txt-regular lh12 grey-2')}>{subTitle}</span>}
        </div>
        {!!children && children}
        {(modalClose || showClose) && (
          <div className={classNames('button-set')}>
            <Button
              styleType={'secondary'}
              onClick={() => {
                if (onClickConfirm) {
                  onClickConfirm();
                }
                closeModal();
              }}
            >
              닫기
            </Button>
          </div>
        )}
      </div>
      <Dim onClick={() => closeModal()} />
    </>
  );
};
Modal3.propTypes = {
  title: PropTypes.string,
  onClickConfirm: PropTypes.func,
  modalClose: PropTypes.func,
  showClose: PropTypes.bool,
  children: PropTypes.node,
};
export default Modal3;
