import '../../../styles/mainTable.css';
import '../../../styles/mainCalendar.css';

const MainCalendar = () => {
	return (
		<div className='calendar-wrap'>
			<img src={`/assets/calendar-2.png`} alt={`calendar`} className='image mobile' />
			<div className='main-calendar pc'>
				<div className='main-table'>
					<table>
						<thead>
							<tr>
								<td>월</td>
								<td>화</td>
								<td>수</td>
								<td>목</td>
								<td>금</td>
								<td>토</td>
								<td>일</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td></td>
								<td></td>
								<td>1</td>
								<td>2</td>
								<td>
									3<span className='event sm'>비즈니스</span>
								</td>
								<td>
									4<span className='event sm red'>쇼핑 동행</span>
								</td>
								<td>5</td>
							</tr>
							<tr>
								<td>6</td>
								<td>
									7<span className='event sm orange'>병원 동행</span>
								</td>
								<td>8</td>
								<td>9</td>
								<td>
									10
									<span className='event start'>외부 미팅 관련 출장</span>
								</td>
								<td>
									11
									<span className='event mid'></span>
								</td>
								<td>
									12
									<span className='event end'></span>
								</td>
							</tr>
							<tr>
								<td>13</td>
								<td>14</td>
								<td>
									15
									<span className='event sm purple'>출/퇴근 자녀 픽업</span>
								</td>
								<td>
									16
									<span className='event sm green'>골프</span>
								</td>
								<td>
									17
									<span className='event sm'>비즈니스</span>
								</td>
								<td>
									18
									<span className='event sm red'>쇼핑 동행</span>
								</td>
								<td>19</td>
							</tr>
							<tr>
								<td>
									20<span className='event sm green'>골프</span>
								</td>
								<td>21</td>
								<td>
									22
									<span className='event sm purple'>출/퇴근 자녀 픽업</span>
								</td>
								<td>23</td>
								<td>
									24<span className='event sm'>비즈니스</span>
								</td>
								<td>
									25<span className='event sm green'>골프</span>
								</td>
								<td>26</td>
							</tr>
							<tr>
								<td>27</td>
								<td>28</td>
								<td>
									29
									<span className='event sm purple'>출/퇴근 자녀 픽업</span>
								</td>
								<td>30</td>
								<td>31</td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default MainCalendar;
