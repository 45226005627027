import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  ConciergeAdviceOptions,
  DriverDispatchProducts,
  EtcRequestOptions,
  MonthlyMosilerOption,
  RecruitPurposeOption,
  UsageTimeOptionsDriverDispatch
} from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import BookingItem from '../../molecules/BookingItem';

import Radio from '../../atoms/Radio';
import Input from '../../atoms/Input';

import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDriverDispatchOptionContext } from '../../organisms/DriverDispatchOptionContext';
import { useForm } from 'react-hook-form';
import { updateContractTerms2 } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import { formErrorMessage } from '../../../utils/alertMessage';
import { getEstimateCookieDataAndDecrypt, getDateCompateToString, getDdEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { SetMmEstimateHistory, setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { openLoading, closeLoading, openLoadingWithEstimate, closeLoadingWithEstimate } from '../../../reducers/loadingRedux';
import moment from 'moment';
import BookingItemRadio from '../../molecules/BookingItemRadio';
import { updateDriverDispatchOptionHex, updateDriverDispatchOptions } from '../../../reducers/driverDispatchEstimation';
import BookingFormSummary2 from '../../atoms/BookingFormSummary2';
import { SendSlackDdEstimate } from '../../../lib/SlackApi';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';
import { getCalcSalaryDetailEstimate2, getCalcSalaryDetailEstimateRecruit } from '../../../lib/DriverDispatchApi';
import { transFormWon2 } from '../../../utils/numbers';



/**
 * 이용 정보 페이지
 * name : contractTerms2
 * */
const OptionView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
  const [description, setDescription] = useState([]);
  const { DriverDispatchOption } = useSelector((state) => state);

  useEffect(() => {
    const updatedDescription = [
      `면접자 1일 운행 체험 서비스는
        <i class='blue'>
        ${getValueByTarget(
        MonthlyMosilerOption, selectedOptions?.options?.monthlyMosilerDemo,
        'title')}
        </i> 
         입니다.
         ${selectedOptions?.options?.monthlyMosilerDemo === 'usage1month' ? '<br/>(이력서 검토 + 1차 인터뷰 + 1일 체험 서비스를 원스톱으로 이용할 수 있음. 단, 소정의 비용이 발생)' : ''}
         
         `,
      `채용 목적은
      <i class='blue'>
        ${getValueByTarget(
        RecruitPurposeOption, selectedOptions?.options?.recruitPurpose,
        'title') ?? '미입력'}</i> 
         입니다.`,

    ]

    setDescription(updatedDescription)
  }, [
    selectedOptions.options.monthlyMosilerDemo,
    selectedOptions.options.recruitPurpose,

  ])

  const { handleSubmit, setValue, register, setError, getValues, formState: { errors } } = useForm({
    defaultValues: {
      monthlyMosilerDemo: selectedOptions?.options?.monthlyMosilerDemo,
      recruitPurpose: selectedOptions?.options?.recruitPurpose,
    }
  });

/*
    SetDriverDispatch(getCalcSalaryDetailEstimate2(Number(salary) * 10000))
    SetDriverRecruit(getCalcSalaryDetailEstimateRecruit(Number(salary) * 10000))

*/

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;

    if (validCheck) {
      dispatch(openLoadingWithEstimate())

      let optionHex =
        getValueByTarget(MonthlyMosilerOption, data?.monthlyMosilerDemo, 'optionValue') |
        getValueByTarget(RecruitPurposeOption, data?.recruitPurpose, 'optionValue')
      let cookieInfo = getDdEstimateCookieDataAndDecrypt()
      let ProductStr = `${getValueByTarget(DriverDispatchProducts, DriverDispatchOption?.subscription, 'sheetData')}${getValueByTarget(UsageTimeOptionsDriverDispatch, DriverDispatchOption?.usageTime, 'title')}`


      
      let resultAmount = 0

      // 채용
      if (DriverDispatchOption?.subscription === 'recruit') {
        let tmpObjData = getCalcSalaryDetailEstimateRecruit(  Number(DriverDispatchOption?.usageTime) * 10000)
        resultAmount = transFormWon2(tmpObjData.step2.total)
      }
      // 파견
      else {
        let tmpObjData = getCalcSalaryDetailEstimate2 (  Number(DriverDispatchOption?.usageTime) * 10000)
        resultAmount = transFormWon2(tmpObjData.step1.total + tmpObjData.step3.total + tmpObjData.step4.total)
      }


      let EstimateResponseObj = {
        UserName: cookieInfo.UserName,
        UserPhone: cookieInfo.PhoneNumber,
        UserEmail: cookieInfo.EmailAddress,
        StartAddress: DriverDispatchOption?.basicInfo2?.garageAddress,
        EndAddress: DriverDispatchOption?.basicInfo2?.companyAddress,
        UsageSchedule: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy-MM-DD'),
        // Route: (DriverDispatchOption?.basicInfo3?.route === 'route_etc') ? `기타(${DriverDispatchOption?.basicInfo3?.routeEtcString})` : getValueByTarget(RouteOptions, DriverDispatchOption?.basicInfo3?.route, 'title'),
        Route: '',
        Requestcomment: (DriverDispatchOption?.basicInfo3?.etcRequest === 'yes') ? `있음(${DriverDispatchOption?.basicInfo3?.etcRequestEtcString})` : getValueByTarget(EtcRequestOptions, DriverDispatchOption?.basicInfo3?.etcRequest, 'title'),
        ConciergeString: (DriverDispatchOption?.basicInfo3?.conciergeAdvice === 'etc') ? `기타(${DriverDispatchOption?.basicInfo3?.conciergeAdviceEtcString})` : getValueByTarget(ConciergeAdviceOptions, DriverDispatchOption?.basicInfo3?.conciergeAdvice, 'title'),
        // NeedsString: getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'title'),
        NeedsString: '',
        SelectedProductStr: ProductStr,
        MmOptionVehicles: '',
        MmOptionCarAmount: '',
        MmOptionInsurance: '',
        MmOptionPassenger: '',
        MmOptionService: '',
        DdOptionMonthlyMosiler: getValueByTarget(MonthlyMosilerOption, data?.monthlyMosilerDemo, 'title'),
        DdOptionRecruitPurpose: getValueByTarget(RecruitPurposeOption, data?.recruitPurpose, 'title'),
        MmEstimateAmount: resultAmount,
      }


      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        optionHex,
        options: {
          ...prevOptions.options,
          monthlyMosilerDemo: data?.monthlyMosilerDemo ?? selectedOptions.monthlyMosilerDemo,
          recruitPurpose: data?.recruitPurpose ?? selectedOptions.recruitPurpose,
        }
      }));


      // await하지 않고, 모시러 서버에 저장
      SetMmEstimateHistory({
        ...getDdEstimateCookieDataAndDecrypt(),
        EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
        EstimatePage: PAGE.PAGE_05,
        JsonData: JSON.stringify({
          ...selectedOptions,
          optionHex,
          options: {
            ...selectedOptions.options,
            monthlyMosilerDemo: data?.monthlyMosilerDemo ?? selectedOptions.monthlyMosilerDemo,
            recruitPurpose: data?.recruitPurpose ?? selectedOptions.recruitPurpose,
          }
        })
      })

      setSheetEstimateResponse(EstimateResponseObj)
      SendSlackDdEstimate(EstimateResponseObj)

      dispatch(updateDriverDispatchOptions({
        monthlyMosilerDemo: data?.monthlyMosilerDemo ?? selectedOptions.monthlyMosilerDemo,
        recruitPurpose: data?.recruitPurpose ?? selectedOptions.recruitPurpose,
      }))

      dispatch(updateDriverDispatchOptionHex({
        optionHex
      }))


      dispatch(closeLoadingWithEstimate())
      navigate(URLS.DRIVER_DISPATCH_ESTIMATE)
    }
  });


  return (
    <>

      <div className={classNames('booking-wrap')}>
        <PageTitle title={'옵션 선택'} subtitle={'원하시는 옵션을 선택해주세요'} />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          <div className={classNames('booking-form-list')}>


            {!!MonthlyMosilerOption && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>면접자 1일 운행 체험 서비스</span>
                <RadioGroup
                  classes={''}
                  legend={'monthlyMosilerDemo'}
                  selected={selectedOptions.options.monthlyMosilerDemo}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {MonthlyMosilerOption.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          onChange={e => {
                            setValue('monthlyMosilerDemo', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              options: {
                                ...prevState.options,
                                monthlyMosilerDemo: item.value,
                              },
                            }));

                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.options.monthlyMosilerDemo === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}

            {!!RecruitPurposeOption && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>채용 목적</span>
                <RadioGroup
                  classes={''}
                  legend={'recruitPurpose'}
                  selected={selectedOptions.options.recruitPurpose}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {RecruitPurposeOption.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          onChange={e => {
                            setValue('recruitPurpose', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              options: {
                                ...prevState.options,
                                recruitPurpose: item.value,
                              },
                            }));

                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.options.recruitPurpose === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}



          </div>
          <BookingFormSummary2
            description={description}>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => navigate(URLS.DRIVER_DISPATCH_BASIC_INFO_3)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary2>
        </form>
      </div>
    </>
  )
}

export default OptionView;