import React, { useEffect, useState } from 'react';
import { EncryptAES, getQueryStringObject, isChkResultSuccess, sleep } from '../../../lib/Util';
import AccountApi from '../../../lib/AccountApi';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';
import { closeLoading, openLoading } from '../../../reducers/loadingRedux';
import { useDispatch } from 'react-redux';
import { bookerPageLoginUser, bookerPageLoginUserPool } from '../../../reducers/Users';
import { useCookies } from 'react-cookie';


const KakaoRedirectProcess = ({ type }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies();

    useEffect(() => {
        async function initData() {
            dispatch(openLoading())

            try {
                let obj = getQueryStringObject()


                // 카카오 로그인 인증
                const result = await AccountApi.KakaoOauth(obj.code)
                const loginInfo = await AccountApi.KakaoLoginedInfo(result.access_token)


                if (loginInfo?.id) {
                    let result = await AccountApi.KakaoLogin(loginInfo?.id)
                    if (isChkResultSuccess(result)) {

                        dispatch(bookerPageLoginUser(result.Item))
                        dispatch(bookerPageLoginUserPool(result.ItemList))

                        const expires = new Date()
                        expires.setDate(expires.getDate() + 90)

                        setCookie('li_01', result.infoData, { path: '/', expires })
                        setCookie('li_02', result.Item.LoginEncrypt, { path: '/', expires })
                        setCookie('alchk', 1, { path: '/', expires })
                        setCookie('lgi', EncryptAES(JSON.stringify(result.Item)), { path: '/', expires })
                        setCookie('lgupi', EncryptAES(JSON.stringify(result.ItemList)), { path: '/', expires })

                        await sleep(500)
                        navigate(URLS.RESERVATION_CREATE, { replace: true })

                    }
                    else {
                        if (result.Message.indexOf('현재 임시 계정으로 로그인하셨습니다. 마스터 계정의 승인이 완료되어야만 로그인 가능합니다.') >= 0) {
                            alert(result.Message)
                            navigate(URLS.EMAIL_LOGIN, { replace: true })
                        }
                        else if (result.Message.indexOf('월간모시러 고객이 아닙니다.') >= 0) {
                            alert(result.Message)
                            navigate(URLS.EMAIL_LOGIN, { replace: true })
                        }
                        else {
                            // 전화번호 확인 화면으로 navigate
                            navigate(`${URLS.KAKAO_LOGIN_PHONE_CHECK}?kakaodata=${loginInfo?.id}`)
                        }



                    }
                    // mosiler api 호출하여 해당 ID가 모시러 DB에 존재하는지를 확인
                }
                else {
                    alert('카카오 로그인에 실패 했습니다. 카카오 계정 정보가 맞는지 확인해주세요.')
                    navigate(-1)
                }


            }
            catch (e) {
                window.postMessage(`MOSILER_KAKAO_LOGIN||FAILED@@${e}`)
            }
            dispatch(closeLoading())
        }
        initData()
    }, [])


    return (
        <>

        </>
    )
}

export default KakaoRedirectProcess;