import React from 'react';
import { createGlobalStyle } from 'styled-components';
import appdl from './../../archive/img/app_download_image.png'
import useDetectBrowser from '../../hooks/useDetectBrowser';

const GlobalStyle = createGlobalStyle`
.scroll-to-top3 {position: fixed;bottom: 17.8375rem;right: .8125rem;animation: fadeIn 700ms ease-in-out 1s both;cursor: pointer; z-index: 100;}
.scroll-to-top3 > button {border:none;}
.scroll-to-top3 > button:focus {border: none; outline:none;}
.btn-appdl-qna {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	background: #fff url("${appdl}");
	background-repeat: no-repeat;background-size: 100px;
	background-position: 50% 50%;
	transition: all 0.23s ease-in-out, box-shadow 1.5s linear;
	box-shadow: 0px 10px 15px 0 rgb(0 0 0 / 25%);
}
`;


const AppDownloadButton = (props) => {
    const { Os } = useDetectBrowser()
    const onDownloadButton = () => {
        
        if (Os === 'android') {
            window.open('https://play.google.com/store/apps/details?id=com.mosiler.client')
        }
        else if (Os === 'ios') {
            window.open('https://apps.apple.com/kr/app/%EB%AA%A8%EC%8B%9C%EB%9F%AC-%EC%9A%B4%EC%A0%84-%EB%8F%99%ED%96%89-%EC%84%9C%EB%B9%84%EC%8A%A4/id1366011185')
        }
        else {
            window.open('http://mosiler.com')
        }
    }
    return (
        <>
            <GlobalStyle />
            <div class="scroll-to-top3">
                <button id="channel-add-button" class="btn-appdl-qna" onClick={onDownloadButton}></button>
            </div>
        </>

    )
}

export default AppDownloadButton