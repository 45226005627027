import useCheckPC from '../../../hooks/useCheckPC';
import Header from '../../molecules/Header';
import '../../../styles/profile.css';
import RadialChart from '../../molecules/RadialChart';
import { FEEDBACK, PROFILE_CAREER, PROFILE_CERTIFICATION, PROFILE_EDUCATION } from '../../../mockData/mock';
import Button from '../../atoms/Button';

const Profile = ({ setOpenProfile }) => {
	return (
		<>
			<Header headerTitle={'프로필 보기'} onClickCloseBtn={() => setOpenProfile(false)} />
			<div className='profile-wrap'>
				<div className='profile-section column'>
					<div className='profile-section-img-wrap'>
						<img src='/assets/driver.png' className='image' alt='Driver' />
					</div>
					<span className='name'>모시러 파트너님</span>
					<div className='tag-list'>
						<span className='tag'>30대 초반</span>
						<span className='tag'>모시러 경험자</span>
					</div>
				</div>
				<div className='profile-section'>
					<div className='inner'>
						<div className='inner-item'>
							<div className='chart-wrap'>
								<RadialChart dataList={[40, 60, 77, 29, 86, 47]} />
							</div>
						</div>

						<div className='inner-item'>
							<span className='title'>이력</span>
							<ul className='time-line'>
								{PROFILE_CAREER.map((item, index) => (
									<li key={index}>
										{item.time || (item.startTime && <span className='time'>{item.time ? item.time : item.startTime + ' ~ ' + item.endTime}</span>)}
										<span className='time-line-title'>{item.title}</span>
									</li>
								))}
							</ul>
						</div>
						<div className='inner-item'>
							<span className='title'>학력</span>
							<ul className='time-line'>
								{PROFILE_EDUCATION.map((item, index) => (
									<li key={index}>
										{item.time || (item.startTime && <span className='time'>{item.time ? item.time : item.startTime + ' ~ ' + item.endTime}</span>)}
										<span className='time-line-title'>{item.title}</span>
									</li>
								))}
							</ul>
						</div>
						<div className='inner-item'>
							<span className='title'>자격증</span>
							<ul className='time-line'>
								{PROFILE_CERTIFICATION.map((item, index) => (
									<li key={index}>
										{(item.time || item.startTime) && <span className='time'>{item.time ? item.time : item.startTime + ' ~ ' + item.endTime}</span>}
										<span className='time-line-title'>{item.title}</span>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className='inner'>
						<div className='inner-item'>
							<span className='title'>교관 피드백</span>
							<div className='feedback-wrap'>
								<span className='contents' dangerouslySetInnerHTML={{ __html: FEEDBACK.contents }}></span>
								<span className='label'>
									{FEEDBACK.teacher} · {FEEDBACK.date}
								</span>
							</div>
						</div>
						<div className='inner-item'>
							<span className='title'>거주지역</span>
							<span className='value'>서울 서대문구</span>
						</div>
						<div className='inner-item'>
							<span className='title'>흡연유무</span>
							<span className='value'>금연</span>
						</div>
						<div className='inner-item'>
							<span className='title'>결혼유무</span>
							<span className='value'>기혼</span>
						</div>
						<div className='inner-item'>
							<span className='title'>아이유무</span>
							<span className='value'>있음</span>
						</div>
						<div className='inner-item'>
							<span className='title'>자기소개</span>
							<span className='value'>친절함, 영어가능</span>
						</div>
					</div>
				</div>
			</div>
			<div className='fixed-button-wrap'>
				<div className='button-wrap'>
					<Button styleType='secondary'>풀 제거 요청</Button>
					<Button styleType='secondary'>풀 등록 요청</Button>
					<Button styleType='secondary'>매칭 제외 요청</Button>
				</div>
			</div>
		</>
	);
};

export default Profile;
