import cross_icon from '../../../../atoms/Icon/svg/cross_icon.svg'


const SwalTitleComponent = ({
    title,
    cancelBtn
}) => (
    <div className='title'>
        <div className='title-text'>{title}</div>
        <div className='title-btn'>
            <button onClick={cancelBtn}>
                <img src={cross_icon}/>
            </button>
        </div>
    </div>
)

export default SwalTitleComponent;