import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '../atoms/Icon';
import classNames from 'classnames';
import Steps from '../atoms/Steps';
import Button from '../atoms/Button';
import useCheckPC from '../../hooks/useCheckPC';
import Menu from '../atoms/Menu';
import StepsDriverDispatch from '../atoms/StepsDriverDispatch';
import { DriverDispatchEstimateStepsRecruit, DriverDispatchEstimateStepsDispatch } from '../../utils/steps';
import { getKeyByIndex, camelToKebab } from '../../lib/Util';




const Header2 = (
  {
    menuList,
    currentMenuPath,
    stepList,
    currentStep,
    title,
    hasClose = false,
    hasMenu = false,
    hasPrevButton = false,
    menuOnClick,
    children,
    Subscription,
    CurrentPath
  }) => {
  const { isPC } = useCheckPC();
  const navigate = useNavigate();

  const handlePrevBtnProcess = (Subscription, CurrentPath) => {
    let targetArr = []

    // 어떤 구독을 선택했는가? 리스트 세팅
    if (Subscription === 'recruit') {
      targetArr = DriverDispatchEstimateStepsRecruit
    }
    else {
      targetArr = DriverDispatchEstimateStepsDispatch
    }
    let retIdx = 0;

    // 현재 화면 바로 이전 화면의 idx값을 취득
    for (let i in Object.keys(targetArr)) {
      let data = Object.keys(targetArr)[i]

      if (data === CurrentPath) {
        if (i - 1 <= -1) {
          retIdx = 0;
          break;
        }
        else {
          retIdx = i - 1;
          break;
        }
      }
    }

    // 이전 버튼을 눌렀을 때 history를 push하고, 이전 페이지가 표시 되도록 한다.
    for (let i = 0; i <= retIdx; i++) {
      if (i === 0) {
        navigate(`/driverdispatch/${camelToKebab(Object.keys(targetArr)[i])}`, { replace: true })
      }
      else {
        navigate(`/driverdispatch/${camelToKebab(Object.keys(targetArr)[i])}`)
      }

    }
  }

  return (
    <>
      <nav className={classNames('header')}>
        <div className={classNames('header-menu-list')}>
          {isPC ? (
            <>
              <Link to={'/'} className={classNames('logo header-logo')} >
                <Icon icon={'logo'} classes={'logo'} />
              </Link>
              {stepList && <StepsDriverDispatch stepList={stepList} currentStep={currentStep} />}
              {menuList && <Menu menuList={menuList} currentMenuPath={currentMenuPath} />}
              {/* {hasClose && (
                <Button styleType={'icon'} classes={'header-close transparent'}>
                  <Icon icon={'close'} />
                </Button>
              )} */}
            </>
          ) : (
            <>
              {hasPrevButton ? (
                <Button
                  styleType={'icon'}
                  classes={'transparent'}
                  onClick={() => handlePrevBtnProcess(Subscription, CurrentPath)}
                >
                  <Icon icon={'arrow_left'} />
                </Button>
              ) : (
                <div className={'w-22'}></div>
              )}
              <div className={classNames('title')}>
                <span className={'title-mid-small bold'}>
                  {title}
                </span>
              </div>
              <div className={'w-22'}>
                {hasMenu && (
                  <Button
                    styleType={'icon'}
                    classes={'menu'}
                    onClick={() => menuOnClick(true)}
                  >
                    <Icon icon={'menu'} />
                  </Button>
                )}
              </div>
              {children && children}
            </>
          )}
        </div>
      </nav>
    </>
  )
}

export default Header2