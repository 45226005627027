import React from 'react';
import classNames from 'classnames';
import useCheckPC from '../../hooks/useCheckPC';
import Button from './Button';
import Icon from './Icon';
import { useQnA } from '../organisms/QnAContext';

const PageTitle = ({ title, subtitle, setShowDescription }) => {
	const { isPC } = useCheckPC();
	const { setqnaOpen } = useQnA();

	return (
		<div className={classNames('page-title')}>
			<div className={classNames('page-title-wrap')}>
				{!!title && <span className={classNames('title-small')}>{title}</span>}
				{!!subtitle && (
					<span className={classNames('title-large')}>
						{subtitle}
						<Button styleType={'icon'} classes={'question'} onClick={() => setqnaOpen(true)}>
							<Icon icon={'question'} />
						</Button>
					</span>
				)}
			</div>
			{!isPC && setShowDescription && (
				<Button styleType={'secondary'} classes={'fit-content button-small see-more'} onClick={() => setShowDescription(true)}>
					자세히 보기
				</Button>
			)}
		</div>
	);
};

export default PageTitle;
