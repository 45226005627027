import React from 'react';
import { useForm } from 'react-hook-form';
import { formErrorMessage } from '../../../../utils/alertMessage';
import classNames from 'classnames';
import Input from '../../../atoms/Input';
import Button from '../../../atoms/Button';
import { Link, useNavigate } from 'react-router-dom';
import { URLS } from '../../../../utils/urls';
import Icon from '../../../atoms/Icon';
import { handlePhoneChange } from '../../../../utils/numbers';
import AccountApi from '../../../../lib/AccountApi';
import { isChkResultSuccess, removeHyphen } from '../../../../lib/Util';
import { useDispatch } from 'react-redux';
import { closeLoading, openLoading } from '../../../../reducers/loadingRedux';



/**
 * 비밀번호 찾기
 * path: reservation/login/verify-password
 * **/

const VerifyPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    setValue,
    register,
    setError,
    formState: { errors }
  } = useForm();

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;

    if (!data?.email) {
      validCheck = false;
      setError('email', {
        type: 'focus',
        name: 'email',
        message: formErrorMessage.emailError
      }, { shouldFocus: true });
    }

    if (!data?.phone) {
      validCheck = false;
      setError('phone', {
        type: 'focus',
        name: 'phone',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }

    if (validCheck) {
      dispatch(openLoading())
      // 비밀번호 찾기
      let result = await AccountApi.FindPassword(data?.email, removeHyphen(data?.phone))

      if (isChkResultSuccess(result)) {
        alert(result.Message)
        navigate(URLS.EMAIL_LOGIN, { replace: true })
      }
      else {
        alert(result.Message)
      }
      dispatch(closeLoading())
    }

  });

  return (
    <form onSubmit={onSubmit} className={classNames('login-form')}>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          이메일 ID
        </span>
        <Input
          type={'text'}
          required
          setValue={setValue}
          error={errors.email}
          onChange={(e) => {
            setValue('email', e.target.value)
          }}
          placeholder={'이메일'}
          {...register('email')}
        />
      </div>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          전화번호
        </span>
        <Input
          type={'tel'}
          required
          setValue={setValue}
          maxLength={11}
          error={errors.phone}
          placeholder={'전화번호'}
          {...register('phone')}
          onChange={e => {
            handlePhoneChange(e, setValue, 'phone')
          }}
        />
      </div>
      <Button
        type={'submit'}
        styleType={'secondary'}
        classes={'bold txt-large'}
      >비밀번호 찾기</Button>

    </form>
  )
}

export default VerifyPassword