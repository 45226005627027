//https://learn.microsoft.com/en-us/javascript/api/overview/azure/storage-file-share-readme?view=azure-node-latest
//https://learn.microsoft.com/ko-kr/azure/developer/javascript/tutorial/browser-file-upload-azure-storage-blob
import { BlobServiceClient } from '@azure/storage-blob'
import moment from 'moment'

const containerName = 'monthlymosiler-inflow'
// sasToken 유효기간 : 2026.11.01까지
const sasToken = 'sv=2021-06-08&ss=b&srt=sco&sp=rwdlaciytfx&se=2026-11-01T08:04:35Z&st=2022-11-01T00:04:35Z&spr=https&sig=f8nVbYYvHEuku3H4cIYyq7t9gMZdo6K96kM46595npc%3D'
const storageAccountName = 'mosilerattachfile'

const createBlobInContainer = async (containerClient, file) => {

    let filename = moment().format('yyyyMMDDHHssmmss');
    let result = {
        result: 'success',
        url: '',
        error: ''
    }
    try {
        let splitdata = file.name.split('.')
        filename += '.' + splitdata[splitdata.length - 1]

        // create blobClient for container
        const blobClient = containerClient.getBlockBlobClient(filename);

        // set mimetype as determined from browser with file upload control
        const options = { blobHTTPHeaders: { blobContentType: file.type } };

        await blobClient.uploadData(file, options);

        result = {
            result: 'success',
            url: `https://mosilerattachfile.blob.core.windows.net/${containerName}/${filename}`,
            error: ''
        }
    }
    catch (e) {
        result = {
            result: 'fail',
            url: '',
            error: e
        }
    }

    return result
}

// // return list of blobs in container to display
// const getBlobsInContainer = async (containerClient) => {
//     const returnedBlobUrls = [];

//     // get list of blobs in container
//     // eslint-disable-next-line
//     for await (const blob of containerClient.listBlobsFlat()) {
//         // if image is public, just construct URL
//         returnedBlobUrls.push(
//             `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
//         );
//     }

//     return returnedBlobUrls;
// }

export const uploadFile = (file) => {
    return new Promise(async (resolve) => {
        const blobSerivce = new BlobServiceClient(
            `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
        )
        const containerClient = blobSerivce.getContainerClient(containerName)
        await containerClient.createIfNotExists({ access: 'container' });
        let result2 = await createBlobInContainer(containerClient, file)
        resolve(result2)
    })

}

export const uploadTxt = (content) => {
    return new Promise(async (resolve) => {
        const blobSerivce = new BlobServiceClient(
            `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
        )
        const filename = moment().format('yyyyMMDDHHssmmss') + '.txt';
        const containerClient = blobSerivce.getContainerClient(containerName)
        const blockBlobClient = containerClient.getBlockBlobClient(filename)
        const uploadBlobResponse = await blockBlobClient.upload(content, content.length)

        resolve(uploadBlobResponse)
    })

}

/*
BlobEndpoint=https://mosilerattachfile.blob.core.windows.net/;QueueEndpoint=https://mosilerattachfile.queue.core.windows.net/;FileEndpoint=https://mosilerattachfile.file.core.windows.net/;TableEndpoint=https://mosilerattachfile.table.core.windows.net/;SharedAccessSignature=sv=2021-06-08&ss=b&srt=sco&sp=rwdlaciytfx&se=2022-10-27T09:14:50Z&st=2022-10-27T01:14:50Z&spr=https&sig=JjDVC9hrMZ9ZDGu7cPIoxXpDHpM1FmML1o6753uQd%2BI%3D
?sv=2021-06-08&ss=b&srt=sco&sp=rwdlaciytfx&se=2022-10-27T09:14:50Z&st=2022-10-27T01:14:50Z&spr=https&sig=JjDVC9hrMZ9ZDGu7cPIoxXpDHpM1FmML1o6753uQd%2BI%3D
https://mosilerattachfile.blob.core.windows.net/?sv=2021-06-08&ss=b&srt=sco&sp=rwdlaciytfx&se=2022-10-27T09:14:50Z&st=2022-10-27T01:14:50Z&spr=https&sig=JjDVC9hrMZ9ZDGu7cPIoxXpDHpM1FmML1o6753uQd%2BI%3D
*/


/*
mime type image, pdf


*/