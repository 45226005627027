import React from 'react';
import '../../../styles/ImageBorder.css';
import classNames from 'classnames';

const ImageBorderSet = ({ classes, imageName, title, subtitle, overwrapDesc, wrap = false, border = true }) => {
	return (
		<div className={classNames('image-border', classes && classes)}>
			<div className={classNames('image-border-wrap', border && 'border')}>
				{imageName && <img src={`/assets/${imageName}.png`} alt={`${imageName} image`} className='image' />}
				{wrap && <div className='image-wrap'></div>}
			</div>
			{overwrapDesc && <div className='overwrapDesc' dangerouslySetInnerHTML={{ __html: overwrapDesc }}></div>}
			{title && <div className='title' dangerouslySetInnerHTML={{ __html: title }}></div>}
			{subtitle && <div className='subtitle' dangerouslySetInnerHTML={{ __html: subtitle }}></div>}
		</div>
	);
};

export default ImageBorderSet;
