import React from 'react';
import '../../../styles/blueBorderSet.css';

const BlueBorderSet = ({ children }) => {
	return (
		<>
			<div className='blue-border-set-wrap'>{children}</div>
		</>
	);
};

export default BlueBorderSet;
