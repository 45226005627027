import React from 'react';
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
#container2 > ul > h1 { display:none; color:#fff; font-size: 0.9em; letter-spacing: 1.5px; text-align: center; width: 100%; margin-top: 20px; -webkit-animation: fade 2s infinite; -moz-animation: fade 2s infinite;}
#container2 { position:fixed; z-index:1050; width:100%; height:100vh; background-color: rgba(0, 0, 0, 0.4); top:0px;}
#container2 > ul {width: 180px; padding-top: 50vh; margin: auto; vertical-align: middle; text-align:center;}

#loading2 {
  z-index:1050 !important;
  display: inline-block;
  width: 55px;
  height: 55px;
  border: 7px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

#comment {
  padding-top: 20px;
  word-break: keep-all;
  font-size: 16px;
  color: #fff;
}


@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
`;

const Loading = ({
  estimateMsg
}) => {
  return (
    <>
      <GlobalStyle />
      <div id="container2">
        <ul>
          <div id="loading2"></div>
          {estimateMsg &&
            (<div id="comment">
              현재 견적서 산출중입니다.<br />
              잠시만 기다려주십시오.<br />
            </div>)
          }
        </ul>

      </div>

    </>


  );
}

export default Loading;