import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { URLS } from '../../utils/urls';
import { useNavigate } from 'react-router-dom';
import { EncryptAES, isDev } from '../../lib/Util';
import sha1 from 'sha1';
// import { getGoogleSheetData } from '../../lib/GoogleSheetApiv2';

const Home = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (isDev()) {
			let obj = {
				id: 'kimpaksayosi@mosiler.com',
				pw: '3C295B8A503CCA3DD8FC3446CEAAF164E96A7FDF',
			};

			// console.log(encodeURIComponent(EncryptAES(JSON.stringify(obj))))

			//  console.log(getGoogleSheetData())
		} else {
			if (window.location.href.indexOf('newestimate2.mosiler.com') >= 0) {
				navigate(URLS.BOOKING_ESTIMATION_SUBSCRIPTION, { replace: true });
			} else if (window.location.href.indexOf('mmreserve.mosiler.com') >= 0) {
				navigate(URLS.EMAIL_LOGIN, { replace: true });
			}
		}
	}, []);

	return (
		<div className={'home'}>
			{isDev() && (
				<>
					<div>
						<Link to={URLS.BOOKING_ESTIMATION_LOGIN}>월간모시러 웹견적서 로그인화면</Link>
					</div>
					<div>
						<Link to={URLS.BOOKING_ESTIMATION_SUBSCRIPTION}>견적 페이지로 이동</Link>
					</div>
					<div>
						<Link to={URLS.EMAIL_LOGIN}>이용 예약 페이지로 이동</Link>
					</div>
					<div>
						<Link to={URLS.DRIVER_DISPATCH_SUBSCRIPTION}>수행기사 파견 페이지로 이동</Link>
					</div>
					<div>
						<Link to={URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGIN}>수행기사 파견 ERP 페이지로 이동</Link>
					</div>
					<div>
						<Link to={URLS.INTRODUCTION_MONTHLY_MOSILER}>소개서 월간 모시러 페이지로 이동</Link>
					</div>
					<div>
						<Link to={URLS.INTRODUCTION_DRIVER}>소개서 채용 파견 페이지로 이동</Link>
					</div>
					<div>
						<Link to={URLS.INFORMATION_REQUEST_FORM}>기본 정보 요청서 페이지로 이동</Link>
					</div>
					<div>
						<Link to={URLS.CONTRACT_APPLICATION_FORM}>월간 모시러 계약 신청서로 이동</Link>
					</div>
					<div>
						<Link to={URLS.DRIVER_DISPATCH_PROFILE}>프로필 페이지로 이동</Link>
					</div>
				</>
			)}
		</div>
	);
};

export default Home;
