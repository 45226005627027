import { useMediaQuery } from 'react-responsive';

const useCheckPC = (minWidth = 1000) => {
  const isPC = useMediaQuery({
    query: `(min-width: ${minWidth}px)`,
  });

  return { isPC };
};

export default useCheckPC;
