import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const SelectItemWrapper = ({ children }) => {
  return <div className={classNames('select-item-wrapper')}>{children}</div>;
};

SelectItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
export default SelectItemWrapper;
