import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { useForm } from 'react-hook-form';
import { formErrorMessage } from '../../../utils/alertMessage';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie'
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import { isDev } from '../../../lib/Util';
import { GetMmInflowPhoneCheck } from '../../../lib/GoogleSheetApiv2';
import { setEstimatePageIntroduce } from '../../../lib/EstimateApi';
import LoadingMessage from '../../atoms/LoadingMessage'



const BookingEstimateLogin = () => {
    const {
        getValues,
        handleSubmit,
        setValue,
        register,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (document.location.protocol === 'http:') {
            document.location.replace(`https://newestimate2.mosiler.com/booking/login`);
        }
    }, [])

    const onSubmit = handleSubmit(async data => {
        let validCheck = true;

        if (!data?.UserName) {
            validCheck = false;
            setError('UserName', {
                type: 'focus',
                name: 'UserName',
                message: formErrorMessage.nameError
            }, { shouldFocus: true });
        }

        if (!data?.PhoneNumber) {
            validCheck = false;
            setError('PhoneNumber', {
                type: 'focus',
                name: 'PhoneNumber',
                message: formErrorMessage.phoneError
            }, { shouldFocus: true });
        }

        if (!data?.EmailAddress) {
            validCheck = false;
            setError('EmailAddress', {
                type: 'focus',
                name: 'EmailAddress',
                message: formErrorMessage.emailError
            }, { shouldFocus: true });
        }

        if (validCheck) {
            // dispatch(openLoading())
            var reqObj = {
                UserName: data?.UserName,
                PhoneNumber: data?.PhoneNumber,
                EmailAddress: data?.EmailAddress,
            }

            setLoading(true)
            let result2 = await GetMmInflowPhoneCheck(reqObj.PhoneNumber)

            // true라면
            if (!result2) {
                // inflow : kakao
                setEstimatePageIntroduce({
                    UserName: reqObj.UserName,
                    UserPhone: reqObj.PhoneNumber,
                    UserEmail: reqObj.EmailAddress,
                }, 'kakao_monthly')
            }
            setLoading(false)

            var unicodeStr = encodeURIComponent(JSON.stringify(reqObj));
            var encrypt = btoa(unicodeStr);

            var finalUrl =
                "/booking/subscription" +
                "?estimateinfo=" + encrypt

            if (isDev()) {
                navigate(finalUrl, { replace: true })
            }
            else {
                if (document.location.protocol !== 'https:') {
                    document.location.replace(`https://newestimate2.mosiler.com${finalUrl}`);
                }
                else {
                    navigate(finalUrl, { replace: true })
                }
            }

            // dispatch(closeLoading())
        }

    });

    return (
        <>
            {loading && <LoadingMessage Msg={'현재 정보를 취득하고 있습니다. 잠시만 기다려주십시오.'} />}
            <div className={classNames('booking-login')}>
                <div className={classNames('title')}>
                    <span>아래 사항을 입력하고</span>
                    <span className='bold'>이어서 견적서를 </span>
                    <span>확인해보세요.</span>

                </div>
                <form onSubmit={onSubmit} className={classNames('login-form')}>
                    <div className={classNames('input-set')}>
                        <span className={classNames('txt-large')}>
                            이름
                        </span>
                        <Input
                            type={'text'}
                            required
                            setValue={setValue}
                            error={errors.UserName}
                            onChange={(e) => {
                                setValue('UserName', e.target.value)
                            }}
                            placeholder={'이름'}
                            {...register('UserName')}
                        />
                    </div>
                    <div className={classNames('input-set')}>
                        <span className={classNames('txt-large')}>
                            전화번호
                        </span>
                        <Input
                            type={'text'}
                            required
                            setValue={setValue}
                            error={errors.PhoneNumber}
                            onChange={(e) => {
                                setValue('PhoneNumber', e.target.value)
                            }}
                            placeholder={'휴대전화번호'}
                            {...register('PhoneNumber')}
                        />
                    </div>
                    <div className={classNames('input-set')}>
                        <span className={classNames('txt-large')}>
                            이메일 주소
                        </span>
                        <Input
                            type={'text'}
                            required
                            setValue={setValue}
                            error={errors.EmailAddress}
                            onChange={(e) => {
                                setValue('EmailAddress', e.target.value)
                            }}
                            placeholder={'이메일'}
                            {...register('EmailAddress')}
                        />
                    </div>


                    <Button
                        type={'submit'}
                        styleType={'secondary'}
                        classes={'bold txt-large'}
                    >견적 확인하기</Button>
                </form>
            </div>
        </>

    )
};

export default BookingEstimateLogin