import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as icons from './svg';
import { ICON_NAMES } from './svg';

const Icon = ({ icon, alt, classes, width, height, ...props }) => {
  const SVGIcon = icons[icon];

  return (
    <>
      {alt && <span className={'hidden'}>{alt}</span>}
      <SVGIcon className={classNames(classes, icon)} {...props} />
    </>
  );
};

export default Icon;

Icon.propTypes = {
  icon: PropTypes.oneOf(ICON_NAMES).isRequired,
  alt: PropTypes.string,
  classes: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
