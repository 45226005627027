import '../../../styles/mosilerPartnerStep.css';

const MosilerPartnerStep = () => {
	return (
		<div className='mobile-wrapper'>
			<div className='mosiler-partner-step-wrap'>
				<div className='step'>
					<div className='step-header'>
						<span className='title-wrap'>
							<span className='step-num'>STEP1</span>
							<span className='title'>교육</span>
						</span>
					</div>
					<ul>
						<li>MOT 교육</li>
						<li>주행 교육</li>
						<li>서비스 마인드 교육</li>
					</ul>
				</div>
				<div className='step active'>
					<div className='step-header'>
						<span className='title-wrap'>
							<span className='step-num'>STEP2</span>
							<span className='title'>테스트</span>
						</span>
						<span className='rate'>합격률 38%</span>
					</div>
					<ul>
						<li>주행 테스트</li>
						<li>상황별 테스트</li>
						<li>공항 픽업</li>
						<li>아이 픽업</li>
						<li>골프장</li>
						<li>에스코트</li>
					</ul>
				</div>
				<div className='step'>
					<div className='step-header'>
						<span className='title-wrap'>
							<span className='step-num'>STEP3</span>
							<span className='title'>품질관리</span>
						</span>
					</div>
					<ul>
						<li>장기 서비스 교육</li>
						<li>미스터리 쇼퍼 (분기별 1회)</li>
						<li>클레임 교육</li>
						<li>컴플레인 교육</li>
						<li>사고 교육</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default MosilerPartnerStep;
