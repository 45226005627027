import React from 'react';
import { createGlobalStyle } from 'styled-components';
import kakao from './kakao.svg'

const GlobalStyle = createGlobalStyle`
.scroll-to-top2 {position: fixed;bottom: 7.8375rem;right: 2.8125rem;animation: fadeIn 700ms ease-in-out 1s both;cursor: pointer; z-index: 100;}
.scroll-to-top2 > button {border:none;}
.scroll-to-top2 > button:focus {border: none; outline:none;}
.btn-kakao-qna {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	background: #454545 url("${kakao}");
	background-repeat: no-repeat;background-size: 59px;
	background-position: 50% 50%;
	transition: all 0.23s ease-in-out, box-shadow 1.5s linear;
	box-shadow: 0px 10px 15px 0 rgb(0 0 0 / 25%);
}
`;


const KakaoButton = (props) => {

    const onKakaoButton = () => {
        window.open('http://pf.kakao.com/_uxmjlM/chat', '_blank', 'width=400,height=600')
    }
    return (
        <>
            <GlobalStyle />
            <div class="scroll-to-top2">
                <button id="channel-add-button" class="btn-kakao-qna" onClick={onKakaoButton}></button>
            </div>
        </>

    )
}

export default KakaoButton