import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import useCheckPC from '../../hooks/useCheckPC';

const Menu = ({ menuList, currentMenuPath }) => {
  const { isPC } = useCheckPC();
  return (
    <>
      <div className={classNames('reservation-header')}>
        {isPC && <div className={classNames('w-120')}></div>}
        <ul className={classNames('menu-list')}>
          {menuList.filter((menu) => menu.type === 'menu').map((menu, index) => (
            <li key={index}>
              <Link to={menu.path}>
              <span className={classNames(currentMenuPath === menu.title && 'active', 'bold')}>
                {menu.title}
              </span>
              </Link>
            </li>
          ))}
        </ul>
        <ul className={classNames('menu-list login', isPC && 'w-120')}>
          {menuList.filter((menu) => menu.type === 'login').map((menu, index) => (
            <li key={index}>
              <Link to={menu.path}>
              <span className={classNames(currentMenuPath === menu.title && 'active', 'bold')}>
                {menu.title}
              </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default Menu