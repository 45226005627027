export const alertMessage = {
  departAddress: '출발 지역의 입력란을 모두 입력해 주세요.',
  destinationAddress: '도착 지역의 입력란을 모두 입력해 주세요'
}

export const formErrorMessage = {
  nameError: '이름을 입력해 주세요.',
  zipError: '우편 번호을 입력해 주세요.',
  addressBasicError: '기본 주소를 입력해주세요.',
  address1Error: '주소를 입력해 주세요.',
  address2Error: '상세 주소를 입력해 주세요.',
  businessRegistrationIdError: '사업자 등록번호를 입력해 주세요.',
  businessRegistrationError: '사업자 등록증을 첨부해 주세요.',
  phoneError: '전화번호를 입력해 주세요.',
  signError: '서명을 등록해 주세요.',
  vehicleNameError: '차량 번호를 입력해 주세요.',
  startTime: '시작 시간을 선택해 주세요.',
  endTime: '종료 시간을 선택해 주세요.',
  vehicleInformationError: '차량 정보를 입력해 주세요.',
  timeError: '유효한 시간을 입력해 주세요.',
  emailError: '이메일 주소를 입력해 주세요.',
  passwordError: '비밀번호를 입력해 주세요.',
  confirmPasswordError: '확인 비밀번호를 입력해 주세요.',
  confirmPasswordAccordError: '비밀번호와 확인 비밀번호가 일치하지 않습니다.',
  passengerName: '탑승자 정보를 입력해주세요',
  masterEmailError: '마스터 계정의 이메일 ID를 입력해주세요',
  passengerEtcStringError: '기타 탑승자를 입력해주세요.',
  routeEtcStringError: '기타 알게된 경로를 입력해주세요.',
  companyNameError: '회사명을 입력해주세요.',
  carNumberError: '차량 번호를 입력해주세요',
  carNumberError2: '차량 번호 형태에 맞게 입력해주세요. (123가1234)',
  carModelError: '차량 이름을 입력해주세요',
  etcStringError: '기타 내용을 입력해주세요.',
}