import React, { useEffect, useState } from 'react';
import { getQueryStringObject } from '../../../lib/Util';

const ModuRedirectProcess = () => {

    useEffect(() => {
        function initData() {
            /*
            querystring값을 받아서 확인 실시
            document_rejected일 경우 : ConfirmContract에 실패 이벤트 처리
            https://modusign.co.kr/?documentId=cdcda8e0-5694-11ee-97d5-93911455963c&eventType=document_rejected
    
            document_signed
            document_all_signed
    
            document_rejected
    
            
          // 이벤트 실패
          if (event.data && event.data.indexOf('REDIRECT||REJECT') >= 0) {
            alert('거절하셨습니다.')
            setModal(false)
            setSignatureIframeUrl('')
          }
          else if (event.data && event.data.indexOf('REDIRECT||SUCCESS') >= 0) {
            alert('계약이 완료되었습니다.')
            navigate(URLS.BOOKING_ESTIMATION_COMPLETE_CONTRACT)
    
            */

            try {
                let obj = getQueryStringObject()
                if (obj?.eventType === 'document_rejected') {
                    window.parent.postMessage('REDIRECT||REJECT')
                }
                else if (obj?.eventType === 'document_signed' || obj?.eventType === 'document_all_signed') {
                    window.parent.postMessage('REDIRECT||SUCCESS')
                }
                else {
                    window.parent.postMessage('REDIRECT||FAILED')
                }
            }
            catch (e) {
                window.parent.postMessage('REDIRECT||FAILED')
            }
        }
        initData()
    }, [])


    return (
        <>

        </>
    )
}

export default ModuRedirectProcess;