import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formErrorMessage } from '../../../../utils/alertMessage';
import classNames from 'classnames';
import Input from '../../../atoms/Input';
import Button from '../../../atoms/Button';
import { getQueryStringObject, isChkResultSuccess } from '../../../../lib/Util';
import AccountApi from '../../../../lib/AccountApi';
import sha1 from 'sha1'
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../../utils/urls';

/**
 * 회원가입
 * path: reservation/login/register
 * **/

const DriverManageRegister = () => {
  const {
    handleSubmit,
    setValue,
    register,
    setError,
    formState: { errors }
  } = useForm();
  const [qsdata, setQsData] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    function initData() {
      try {
        let qs = getQueryStringObject()
        if (qs.alskdj.length) {
          setQsData(qs.alskdj)
        }
      }
      catch (e) {
        setQsData('')
      }
    }
    initData()
  }, [])

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;

    if (!data?.username) {
      validCheck = false;
      setError('username', {
        type: 'focus',
        name: 'username',
        message: formErrorMessage.nameError
      }, { shouldFocus: true });
    }

    if (!data?.email) {
      validCheck = false;
      setError('email', {
        type: 'focus',
        name: 'email',
        message: formErrorMessage.emailError
      }, { shouldFocus: true });
    }

    if (!data?.password) {
      validCheck = false;
      setError('password', {
        type: 'focus',
        name: 'password',
        message: formErrorMessage.passwordError
      }, { shouldFocus: true });
    }

    // if (!data?.confirmPassword) {
    //   setError('confirmPassword', {
    //     type: 'focus',
    //     name: 'confirmPassword',
    //     message: formErrorMessage.confirmPasswordError
    //   }, { shouldFocus: true });

    // }

    // if (data?.confirmPassword !== data?.password) {
    //   setError('confirmPassword', {
    //     type: 'focus',
    //     name: 'confirmPassword',
    //     message: formErrorMessage.confirmPasswordAccordError
    //   }, { shouldFocus: true });
    //   return;
    // }

    if (!data?.phonenumber) {
      validCheck = false;
      setError('phonenumber', {
        type: 'focus',
        name: 'phonenumber',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }

    if (!qsdata.length) {
      if (!data?.masteremailid) {
        validCheck = false;
        setError('masteremailid', {
          type: 'focus',
          name: 'masteremailid',
          message: formErrorMessage.masterEmailError
        }, { shouldFocus: true });
      }
    }



    if (validCheck) {
      let result = await AccountApi.SetMemberInvite({
        UserId: data?.email,
        UserPw: sha1(data?.password),
        AccountType: "email",
        CustomerName: data?.username,
        Email: data?.email,
        PhoneNumber: data?.phonenumber,
        HomeAddress: "",
        UseType: 2,
        Roles: 0,
        EncryptInvite: qsdata
      })
      if (isChkResultSuccess(result)) {
        alert('회원가입이 완료 되었습니다.')
        navigate(URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGIN)
      }
      else {
        alert(result.Message)
      }
    }

  });

  return (
    <form onSubmit={onSubmit} className={classNames('login-form')}>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          이름
        </span>
        <Input
          type={'text'}
          required
          setValue={setValue}
          error={errors.username}
          onChange={(e) => {
            setValue('username', e.target.value)
          }}
          placeholder={'이름'}
          {...register('username')}
        />
      </div>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          이메일 주소
        </span>
        <Input
          type={'text'}
          required
          setValue={setValue}
          error={errors.email}
          onChange={(e) => {
            setValue('email', e.target.value)
          }}
          placeholder={'이메일'}
          {...register('email')}
        />
      </div>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          비밀번호
        </span>
        <Input
          type={'password'}
          required
          setValue={setValue}
          error={errors.password}
          onChange={(e) => {
            setValue('password', e.target.value)
          }}
          placeholder={'비밀번호'}
          {...register('password')}
        />
      </div>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          전화번호
        </span>
        <Input
          type={'text'}
          required
          setValue={setValue}
          error={errors.phonenumber}
          onChange={(e) => {
            setValue('phonenumber', e.target.value)
          }}
          placeholder={'전화번호'}
          {...register('phonenumber')}
        />
      </div>
      {!qsdata.length && <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          마스터 계정 이메일 ID
        </span>
        <Input
          type={'text'}
          required
          setValue={setValue}
          error={errors.masteremailid}
          onChange={(e) => {
            setValue('masteremailid', e.target.value)
          }}
          placeholder={'마스터 계정 이메일 ID'}
          {...register('masteremailid')}
        />
      </div>}

      {/* <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          비밀번호 확인
        </span>
        <Input
          type={'password'}
          required
          setValue={setValue}
          error={errors.confirmPassword}
          onChange={(e) => {
            setValue('confirmPassword', e.target.value)
          }}
          placeholder={'비밀번호 확인'}
          {...register('confirmPassword')}
        />
      </div> */}
      <Button
        type={'submit'}
        styleType={'secondary'}
        classes={'bold txt-large'}
      >회원가입</Button>
    </form>
  )
}

export default DriverManageRegister