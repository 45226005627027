
const initialContractMonthArray = {
    select: [
        {
            title: '1개월',
            value: 'month1',
            category: 'periodOfContract',
            numberValue: 1,
            discount: 0,
            description: '기본 비용 / 할인 없음',
            descriptionTitle: '할인 없음'
        },
        {
            title: '4개월',
            value: 'month4',
            category: 'periodOfContract',
            numberValue: 4,
            discount: 100000,
            description: '4개월 할인',
            descriptionTitle: '할인율 3%'
        },
        {
            title: '12개월',
            value: 'month12',
            category: 'periodOfContract',
            numberValue: 12,
            discount: 300000,
            description: '12개월 할인',
            descriptionTitle: '할인율 7%'
        },
    ]
};

const OPTION_UPDATE = 'OPTION/UPDATE_CONTRACT_MONTH';

export const updateSelect = (select) => ({
    type: OPTION_UPDATE,
    payload: { select },
});


export const ContractMonthArray = (state = initialContractMonthArray, action) => {
    switch (action.type) {
        case OPTION_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

