import moment from "moment";
const initialDriverDispatchOption = {
  subscription: null,
  usageTime: null,
  optionHex: null,
  basicInfo1: {
    workStartTime: null,
    workEndTime: null,
    weekendUsage: null,
    weekendUsageEtcString: null,
    usageDate: moment().add(1, 'days').format('yyyy-MM-DD'),
  },
  basicInfo2: {
    selectedSalary: null,
    selectedSalaryEtcString: null,
    garageAddress: null,
    companyAddress: null,
  },
  basicInfo3: {
    conciergeAdvice: null,
    conciergeAdviceEtcString: null,
    etcRequest: null,
    etcRequestEtcString: null,
    route: null,
    routeEtcString: null,
  },
  options: {
    monthlyMosilerDemo: null,
    recruitPurpose: null,
  },
  userInformation: {
    userType: null,
    user: {
      name: null,
      businessAddress: {
        zip: null,
        address1: null,
        address2: null,
      },
      businessRegistrationId: null,
      businessRegistration: null,
      companyName: null,
      personalAddress: null,
      personalAddressDetail: null,
    }
  },
  recruitRequest: {
    workStartDate: null,
    gender: null,
    age20: null,
    age30: null,
    age40: null,
    age50: null,
    age60: null,
    ageboth: null,
    driverSmokingStatus: null,
    driverParkingSupport: null,
    commutingVehicleProvided: null,
    postHireJobResponsibilities: null,
    benefits: null,
    vehicleModelName: null,
    foreignLanguageProficiency: null,
    foreignLanguageProficiencyStr: null,
    driverWaitingRoomAvailable: null,
    actualWorkLocation: null,
    educationalBackground: null,
    specialNotes: null,
  },

  totalAmount: 0,
};

const OPTION_UPDATE = 'OPTION/UPDATE_DRIVER_DISPATCH';
const OPTION_UPDATE_IMAGE = 'OPTION/UPDATE_DRIVER_DISPATCH/IMAGE';
const OPTION_UPDATE_SELECTEDSALARY = 'OPTION/UPDATE_DRIVER/SELECTEDSALARY';


export const updateDriverDispatchOption = DriverDispatchOption => ({ type: OPTION_UPDATE, payload: DriverDispatchOption });

export const updateDriverDispatchSubscription = (subscription) => ({
  type: OPTION_UPDATE,
  payload: { subscription },
});

export const updateDriverDispatchUsageTime = (usageTime) => ({
  type: OPTION_UPDATE,
  payload: { usageTime },
});




export const updateDriverDispatchOptionHex = (optionHex) => ({
  type: OPTION_UPDATE,
  payload: optionHex,
});

export const updateDriverDispatchBasicInfo1 = (basicInfo1) => ({
  type: OPTION_UPDATE,
  payload: { basicInfo1 },
});

export const updateDriverDispatchBasicInfo2 = (basicInfo2) => ({
  type: OPTION_UPDATE,
  payload: { basicInfo2 },
});

export const updateDriverDispatchBasicInfo3 = (basicInfo3) => ({
  type: OPTION_UPDATE,
  payload: { basicInfo3 },
});

export const updateDriverDispatchOptions = (options) => ({
  type: OPTION_UPDATE,
  payload: { options },
});

export const updateDriverDispatchTotalAmount = (totalAmount) => ({
  type: OPTION_UPDATE,
  payload: { totalAmount },
});

export const updateDriverDispatchUserInformation = (userInformation) => ({
  type: OPTION_UPDATE,
  payload: { userInformation },
});

export const updateDriverDispatchSelectedSalary = (SelectedSalary) => ({
  type: OPTION_UPDATE_SELECTEDSALARY,
  payload: SelectedSalary,
});

export const updateDriverDispatchImage = (imgUrl) => ({
  type: OPTION_UPDATE_IMAGE,
  payload: imgUrl,
});

export const updateDriverDispatchRecruitRequest = (recruitRequest) => ({
  type: OPTION_UPDATE,
  payload: { recruitRequest },
});

export const DriverDispatchOption = (state = initialDriverDispatchOption, action) => {
  switch (action.type) {
    case OPTION_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case OPTION_UPDATE_IMAGE:
      return {
        ...state,
        userInformation: {
          ...state.userInformation,
          user: {
            ...state.userInformation.user,
            businessRegistration: action.payload,
          }
        },
      };
    case OPTION_UPDATE_SELECTEDSALARY:
      return {
        ...state,
        basicInfo2: {
          ...state.basicInfo2,
          selectedSalary: action.payload
        },
      };
    default:
      return state;
  }
};

