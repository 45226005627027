import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  WeekendUsageOptions
} from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import BookingItem from '../../molecules/BookingItem';
import BookingItemRadio from '../../molecules/BookingItemRadio';
import Radio from '../../atoms/Radio';
import Input from '../../atoms/Input';

import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDriverDispatchOptionContext } from '../../organisms/DriverDispatchOptionContext';
import { useForm } from 'react-hook-form';
import { updateContractTerms2 } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import { formErrorMessage } from '../../../utils/alertMessage';
import { getEstimateCookieDataAndDecrypt, getDateCompateToString } from '../../../lib/Util';
import { setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import moment from 'moment';
import ScheduleDatePicker from '../../atoms/ScheduleDatePicker';
import ListWithBullet from '../../atoms/ListWithBullet';
import TimeInput3 from '../../molecules/TimeInput3';
import { updateDriverDispatchBasicInfo1 } from '../../../reducers/driverDispatchEstimation';
import ScheduleDatePicker2 from '../../atoms/ScheduleDatePicker2';
import BookingFormSummary2 from '../../atoms/BookingFormSummary2';
import TimeInputSelect from '../../molecules/TimeInputSelect';

/**
 * 이용 정보 페이지
 * name : contractTerms2
 * */
const BasicInfo1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
  const [description, setDescription] = useState([]);
  const { DriverDispatchOption } = useSelector((state) => state);

  useEffect(() => {
    const updatedDescription = [
      `기본 출/퇴근 시간은 
        <i class='blue'>${selectedOptions?.basicInfo1?.workStartTime}</i> 부터
        <i class='blue'>${selectedOptions?.basicInfo1?.workEndTime}</i> 까지 입니다.`,
      `주말 이용 여부는 
        <i class='blue'>
        ${getValueByTarget(
        WeekendUsageOptions, selectedOptions?.basicInfo1?.weekendUsage,
        'title') ?? '미입력'}</i> 
         입니다.`,
      `필요 시점은
      <i class='blue'>${moment(selectedOptions?.basicInfo1?.usageDate).format('yyyy-MM-DD') ?? '미입력'}</i> 
      입니다.`,
    ]

    setDescription(updatedDescription)
  }, [
    selectedOptions.basicInfo1.workStartTime,
    selectedOptions.basicInfo1.workEndTime,
    selectedOptions.basicInfo1.weekendUsage,
    selectedOptions.basicInfo1.usageDate,
  ])



  //   <TimeInputSelect
  //   setValue={setValue}
  //   getValues={getValues}
  //   startHourRegister={'workStartTimeHour'}
  //   startMinuteRegister={'workStartTimeMinute'}
  //   finalMsg={'부터'} />
  // <TimeInputSelect
  //   setValue={setValue}
  //   getValues={getValues}
  //   startHourRegister={'workEndTimeHour'}
  //   startMinuteRegister={'workEndTimeMinute'}
  //   finalMsg={'까지'} />




  const { handleSubmit, setValue, register, setError, getValues, formState: { errors } } = useForm({
    defaultValues: {
      // workStartTime: selectedOptions?.basicInfo1?.workStartTime,
      // workEndTime: selectedOptions?.basicInfo1?.workEndTime,
      weekendUsage: selectedOptions?.basicInfo1?.weekendUsage,
      weekendUsageEtcString: selectedOptions?.basicInfo1?.weekendUsageEtcString,
      usageDate: selectedOptions?.basicInfo1?.usageDate,
      workStartTimeHour: selectedOptions?.basicInfo1?.workStartTime.split(':')[0],
      workStartTimeMinute: selectedOptions?.basicInfo1?.workStartTime.split(':')[1],
      workEndTimeHour: selectedOptions?.basicInfo1?.workEndTime.split(':')[0],
      workEndTimeMinute: selectedOptions?.basicInfo1?.workEndTime.split(':')[1]
    }
  });

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;


    // if (!data?.workStartTime || data?.workStartTime.length !== 5) {
    //   validCheck = false;
    //   setError('workStartTime', {
    //     type: 'focus',
    //     name: 'workStartTime',
    //     // message: formErrorMessage.vehicleNameError
    //   }, { shouldFocus: true });
    // }

    // if (!data?.workEndTime || data?.workEndTime.length !== 5) {
    //   validCheck = false;
    //   setError('workEndTime', {
    //     type: 'focus',
    //     name: 'workEndTime',
    //     // message: formErrorMessage.vehicleNameError
    //   }, { shouldFocus: true });
    // }

    if (data?.weekendUsage === 'etc') {
      if (!data?.weekendUsageEtcString) {
        validCheck = false;
        setError('weekendUsageEtcString', {
          type: 'focus',
          name: 'weekendUsageEtcString',
          message: formErrorMessage.etcStringError
        }, { shouldFocus: true });
      }
    }

    if (!data?.usageDate) {
      validCheck = false;
      setError('usageDate', {
        type: 'focus',
        name: 'usageDate',
        message: formErrorMessage.passengerEtcStringError
      }, { shouldFocus: true });
    }




    if (validCheck) {
      dispatch(openLoading())

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        basicInfo1: {
          ...prevOptions.basicInfo1,
          workStartTime: data?.workStartTime ?? selectedOptions.basicInfo1.workStartTime,
          workEndTime: data?.workEndTime ?? selectedOptions.basicInfo1.workEndTime,
          weekendUsage: data?.weekendUsage ?? selectedOptions.basicInfo1.weekendUsage,
          weekendUsageEtcString: data?.weekendUsageEtcString ?? selectedOptions.basicInfo1.weekendUsageEtcString,
          usageDate: data?.usageDate ?? selectedOptions.basicInfo1.usageDate,
        }
      }));

      // await하지 않고, 모시러 서버에 저장
      // SetMmEstimateHistory({
      //   ...getEstimateCookieDataAndDecrypt(),
      //   EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
      //   EstimatePage: PAGE.PAGE_01,
      //   JsonData: JSON.stringify({
      //     ...selectedOptions,
      //     subscription: data?.subscription ?? selectedOptions.subscription
      //   })
      // })


      dispatch(updateDriverDispatchBasicInfo1({
        workStartTime: data?.workStartTime ?? selectedOptions.basicInfo1.workStartTime,
        workEndTime: data?.workEndTime ?? selectedOptions.basicInfo1.workEndTime,
        weekendUsage: data?.weekendUsage ?? selectedOptions.basicInfo1.weekendUsage,
        weekendUsageEtcString: data?.weekendUsageEtcString ?? selectedOptions.basicInfo1.weekendUsageEtcString,
        usageDate: data?.usageDate ?? selectedOptions.basicInfo1.usageDate,
      }))

      dispatch(closeLoading())
      navigate(URLS.DRIVER_DISPATCH_BASIC_INFO_2)
    }
  });

  const handleTimeChange = (timedata, timeTarget, target) => {
    if (target === 'start') {
      let tmpData = selectedOptions.basicInfo1.workStartTime
      let tmpData2 = tmpData.split(':')
      let tmplate = (timeTarget === 'hour') ? `${timedata}:${tmpData2[1]}` : `${tmpData2[0]}:${timedata}`

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        basicInfo1: {
          ...prevOptions.basicInfo1,
          workStartTime: tmplate,
        }
      }));
    }
    else {
      let tmpData = selectedOptions.basicInfo1.workEndTime
      let tmpData2 = tmpData.split(':')
      let tmplate = (timeTarget === 'hour') ? `${timedata}:${tmpData2[1]}` : `${tmpData2[0]}:${timedata}`

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        basicInfo1: {
          ...prevOptions.basicInfo1,
          workEndTime: tmplate,
        }
      }));
    }
  }

  return (
    <>
      <div className={classNames('booking-wrap')}>
        <PageTitle title={'기본 정보'} subtitle={'기본 정보를 알려주세요'} />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          <div className={classNames('booking-form-list')}>

            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>출/퇴근 시간</span>

              {/* 
              부활할 수 있음
              <TimeInput3
                setError={setError}
                register={register}
                setValue={setValue}
                getValues={getValues}
                workStartTime={'workStartTime'}
                workEndTime={'workEndTime'}
                workStartTimeError={errors.workStartTime}
                workEndTimeError={errors.workEndTime}
                errorMessage={'비어있는 영역을 입력해주세요'}
                onSetSelectedOption={setSelectedOptions}
                selectedOption={selectedOptions}
              /> */}

              <TimeInputSelect
                setValue={setValue}
                getValues={getValues}
                startHourRegister={'workStartTimeHour'}
                startMinuteRegister={'workStartTimeMinute'}
                target={'start'}
                onContextChange={handleTimeChange}
                finalMsg={'부터'} />

              <TimeInputSelect
                setValue={setValue}
                getValues={getValues}
                startHourRegister={'workEndTimeHour'}
                startMinuteRegister={'workEndTimeMinute'}
                target={'end'}
                onContextChange={handleTimeChange}
                finalMsg={'까지'} />

            </div>

            {!!WeekendUsageOptions && (
              <div className={'booking-form-item'} style={{ gap: '20px' }}>
                <span className={classNames('title-mid')}>주말 이용 여부</span>
                <RadioGroup
                  classes={''}
                  legend={'weekendUsage'}
                  selected={selectedOptions.basicInfo1.weekendUsage}
                >
                  <ul className={classNames('booking-item-list row')} style={{ flexDirection: 'column' }}>
                    {WeekendUsageOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('weekendUsage', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              basicInfo1: {
                                ...prevState.basicInfo1,
                                weekendUsage: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItemRadio
                            selected={selectedOptions.basicInfo1.weekendUsage === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>

                      </li>
                    ))}
                  </ul>
                </RadioGroup>
                {selectedOptions.basicInfo1.weekendUsage === 'etc' &&
                  <Input
                    type={'text'}
                    required
                    setValue={setValue}
                    error={errors.weekendUsageEtcString}
                    {...register('weekendUsageEtcString')}
                    onChange={e => {
                      setValue('weekendUsageEtcString', e.target.value)
                      setSelectedOptions((prevState) => ({
                        ...prevState,
                        basicInfo1: {
                          ...prevState.basicInfo1,
                          weekendUsageEtcString: e.target.value
                        },
                      }));
                    }}
                    placeholder={'기타 내용을 입력하세요'}
                  />
                }
              </div>
            )}

            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>필요 시점</span>
              <ScheduleDatePicker2
                selectedDate={selectedOptions?.basicInfo1?.usageDate}
                onChange={(newDate) => {
                  setValue('usageDate', newDate)
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    basicInfo1: {
                      ...prevState.basicInfo1,
                      usageDate: newDate,
                    },
                  }));
                }}
              />

            </div>

          </div>
          <BookingFormSummary2
            description={description}>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => navigate(URLS.DRIVER_DISPATCH_SUBSCRIPTION)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary2>
        </form>
      </div>
    </>
  )
}

export default BasicInfo1;