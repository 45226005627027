import React from 'react';
import '../../../styles/introduction.css';
import GreyIconSet from './GreyIconSet';
import ImageBorderSet from './ImageBorderSet';
import MosilerPartnerStep from './MosilerPartnerStep';
import Input from '../../atoms/Input';
import classNames from 'classnames';
import Button from '../../atoms/Button';
import { useForm } from 'react-hook-form';
import Icon from '../../atoms/Icon';

const Driver = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	// 폼 제출 처리 함수
	const onSubmit = (data) => {
		alert(`견적서 요청이 성공적으로 제출되었습니다! \n이름: ${data.name} \n이메일: ${data.email} \n연락처: ${data.phone}`);
	};
	return (
		<>
			<div className='mobile mobile-nav'>
				<div className='header'>
					<div></div>
					<span className='title'>상품소개</span>
					<Button styleType={'icon'} classes={'menu'}>
						<Icon icon={'menu'} />
					</Button>
				</div>
			</div>
			<div className={'main-banner main2'}>
				<div className='title-wrap'>
					<span className='title'>
						<span>모시러 채용/파견</span>
					</span>
					<span className='sub-title'>
						모시러에서 채용/파견의 고민을
						<br className='mobile' /> 해결해 드립니다.
					</span>
				</div>
			</div>
			<div className='main-content'>
				<div className='section'>
					<div className='section-subtitle'>
						모시러 채용/파견은 퀄리티 높은
						<br className='mobile' /> 수행기사를 <br className='pc' />
						안정적으로 이용할 수 있는
						<br className='mobile' /> 서비스입니다.
					</div>
					<div className='flex-set-3'>
						<ImageBorderSet imageName={'drivers'} title={'드라이버 검증'} subtitle={'운전 뿐만 아니라 서비스 에티튜드, 범죄/사고이력 검증으로 엄선합니다.'} />
						<ImageBorderSet imageName={'admin2'} title={'자체 관리 시스템'} subtitle={'근태 관리 뿐만 아니라 차량 관리/이용 내역을 자체 ERP를 통해 확인 가능합니다.'} />
						<ImageBorderSet imageName={'driver'} title={'보증기간 1년'} subtitle={'분기별 정기 서비스 교육을 통해 드라이버의 퀄리티를 유지합니다.'} />
					</div>
				</div>
				<div className='section'>
					<div className='section-subtitle'>
						모시러는 이미 B2B 프리미엄 기사
						<br className='mobile' /> 서비스로 국내 자산가들의 열렬한 <br className='pc' />
						성원을
						<br className='mobile' /> 받아 왔습니다. 프리미엄의 특성상, 작지만
						<br className='mobile' /> 큰 서비스의 차이를 <br className='pc' />
						만드는 모시러의
						<br className='mobile' /> 철학이 성공을 만들어 냈습니다.
					</div>
					<div className='flex-set-3'>
						<ImageBorderSet imageName={'cars'} />
						<ImageBorderSet imageName={'certification'} />
						<ImageBorderSet imageName={'tada'} />
					</div>
				</div>
				<div className='section'>
					<div className='section-subtitle'>
						모시러의 자체 검증 시스템을 통과한
						<br className='mobile' /> 드라이버들이 서비스를 제공합니다.
						<br className='mobile' /> <br className='pc' />
						의전 교육 뿐만 아니라 범죄 이력 조회 및<br className='mobile' /> 고객 비밀 유지 서약서 작성 등 <br className='pc' />
						까다로운
						<br className='mobile' /> 과정을 거쳐야만 모시러의 파트너가 될 수 있습니다.
					</div>
					<MosilerPartnerStep />
				</div>
				<div className='section'>
					<div className='section-title'>
						모시러
						<br />
						채용/파견 상품
					</div>
					<div className='flex-set-3'>
						<ImageBorderSet imageName={'documents'} title={'채용 (헤드헌팅)'} subtitle={'모시러에서 직접 교육 및 인터뷰를 진행한 수행기사를 고용해 보세요.'} />
						<ImageBorderSet imageName={'cardoor'} title={'파견'} subtitle={'다년간 모시러에서 고객님들께 서비스를 제공해 온 파트너들을 안정적으로 이용해보세요.'} />
					</div>
				</div>
				<div className='section'>
					<div className='section-title'>
						채용/파견 상품
						<br />
						런칭 기념 이벤트
					</div>
					<div className='flex-set-3'>
						<ImageBorderSet imageName={'wine'} subtitle={'프리미엄 생수를 차량에 비치해<br>탑승자에게 제공해 드립니다.<br>(파견 상품에 한함)'} />
						<ImageBorderSet imageName={'purple'} subtitle={'고용하고자 하는 드라이버를 1개월 구독 상품을 통해 경험 해보고 결정하세요'} />
						<ImageBorderSet imageName={'admin'} subtitle={'관제/예약 내역 확인 가능한 ERP 서비스를 무상 제공해드립니다.'} />
					</div>
				</div>
				<div className='section'>
					<div className='section-title'>
						아래 사항을 입력해서
						<br />
						지금 바로 견적서를 <br className='mobile' />
						확인해 보세요
					</div>
					<div className='form-wrap'>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className={classNames('input-set')}>
								<span className={classNames('txt-large')}>이름</span>
								<Input type={'text'} error={errors.name} {...register('name', { required: '이름을 입력해주세요.' })} placeholder={'성함을 입력해 주세요'} />
							</div>
							<div className={classNames('input-set')}>
								<span className={classNames('txt-large')}>이메일 주소</span>
								<Input
									type={'text'}
									error={errors.email}
									{...register('email', {
										required: '이메일을 입력해주세요.',
										pattern: {
											value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
											message: '유효한 이메일 주소를 입력해주세요.',
										},
									})}
									placeholder={'이메일을 입력해 주세요'}
								/>
							</div>
							<div className={classNames('input-set')}>
								<span className={classNames('txt-large')}>연락처</span>
								<Input
									type={'tel'}
									error={errors.phone}
									{...register('phone', {
										required: '연락처를 입력해주세요.',
										pattern: {
											value: /^[0-9]{10,11}$/,
											message: '유효한 연락처를 입력해주세요.',
										},
									})}
									placeholder={'연락처를 입력해 주세요'}
								/>
							</div>
							<Button type={'submit'} styleType={'secondary'} classes={'bold txt-large'}>
								견적 확인하기
							</Button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Driver;
