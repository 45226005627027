import axios from "axios";
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL
const TIME_OUT = 10000
function customRound(number) {
    // 1의 자리 숫자를 구함
    let lastDigit = number % 10;

    // 1의 자리가 6 이상이면 반올림
    if (lastDigit >= 6) {
        return number + (10 - lastDigit);
    }

    // 그 외의 경우는 그대로 반환
    return number;
}

function truncateLastDigit(number) {
    return Math.floor(number / 10) * 10;
}

export const getCalcSalaryDetailEstimate = (amount) => {
    let obj = {
        총연봉액: 0,
        totalEstimateAmount: 0,
        step1: {
            연봉액월환산: 0,
            퇴직급여충당금: 0,
            연차급여충당금: 0,
            total: 0
        },
        step2: {
            중식보조금: 0,
            국민연금료: 0,
            건강보험료: 0,
            노인장기요양보험료: 0,
            산재보험료: 0,
            고용보험료: 0,
            장애자고용분담금: 0,
            사업소세: 0,
            total: 0
        },
        step3: {
            복리후생비: 0,
            교육훈련비: 0,
            채용선발비: 0,
            일반관리비: 0,
            기타직접비: 0,
            total: 0
        },
        step4: {
            파견이익금: 0,
            total: 0
        }
    }

    // Step1
    obj.총연봉액 = amount;
    obj.step1.연봉액월환산 = customRound(amount / 12)
    obj.step1.퇴직급여충당금 = 0
    obj.step1.연차급여충당금 = 0
    obj.step1.total = Object.keys(obj.step1)
        .filter(key => (key !== 'total'))  // 'total'을 제외
        .reduce((acc, key) => acc + obj.step1[key], 0);  // 총합 계산

    // Step2
    obj.step2.중식보조금 = 200000;
    obj.step2.국민연금료 = Math.floor((obj.step1.total - obj.step2.중식보조금) * 0.045)
    obj.step2.건강보험료 = Math.floor((obj.step1.total - obj.step2.중식보조금) * 0.03545)
    obj.step2.노인장기요양보험료 = Math.floor(obj.step2.건강보험료 * 0.1281)
    obj.step2.산재보험료 = Math.floor((obj.step1.total - obj.step2.중식보조금) * 0.019)
    obj.step2.고용보험료 = Math.floor((obj.step1.total - obj.step2.중식보조금) * 0.0115)
    //https://www.kead.or.kr/pymntinchr/cntntsPage.do?menuId=MENU0657
    obj.step2.장애자고용분담금 = Math.round(2010580 * 0.031)
    obj.step2.사업소세 = Math.floor(obj.step1.연봉액월환산 * 0.005)
    obj.step2.total = Object.keys(obj.step2)
        .filter(key => (key !== 'total' && key !== '중식보조금'))  // 'total'을 제외
        .reduce((acc, key) => acc + obj.step2[key], 0);  // 총합 계산

    // Step3
    obj.step3.복리후생비 = 20000
    obj.step3.교육훈련비 = truncateLastDigit(Math.floor((obj.step1.total + obj.step2.total + obj.step3.복리후생비) * 0.04))
    obj.step3.채용선발비 = truncateLastDigit(Math.floor((obj.step1.total + obj.step2.total + obj.step3.복리후생비) * 0.01))
    obj.step3.일반관리비 = truncateLastDigit(Math.floor((obj.step1.total + obj.step2.total + obj.step3.복리후생비) * 0.05))
    obj.step3.기타직접비 = truncateLastDigit(Math.floor((obj.step1.total + obj.step2.total + obj.step3.복리후생비) * 0.02))
    obj.step3.total = Object.keys(obj.step3)
        .filter(key => (key !== 'total'))  // 'total'을 제외
        .reduce((acc, key) => acc + obj.step3[key], 0);  // 총합 계산

    // Step4
    obj.step4.파견이익금 = truncateLastDigit(Math.floor((obj.step1.total + obj.step2.total + obj.step3.total) * 0.05))
    obj.step4.total = Object.keys(obj.step4)
        .filter(key => (key !== 'total'))  // 'total'을 제외
        .reduce((acc, key) => acc + obj.step4[key], 0);  // 총합 계산

    // 총합
    obj.totalEstimateAmount = Object.keys(obj)
        .filter(key => (key !== '총연봉액' && key !== 'totalEstimateAmount'))
        .reduce((acc, key) => acc + obj[key].total, 0);  // 총합 계산

    return obj;
}

// 파견
export const getCalcSalaryDetailEstimate2 = (amount) => {
    let obj = {
        총연봉액: 0,
        totalEstimateAmount: 0,
        step1: {
            기본급: 0,
            중식보조금: 0,
            시간외근로수당: 0,
            // 퇴직급여충당금: 0,
            // 연차급여충당금: 0
            total: 0
        },
        step2: {
            customDriverEdu: 1750000,                 // 연 4회 맞춤형 기사 교육
            driverMgmtProgram: 1100000,               // 수행기사 자동 관리 프로그램 제공
            total: 0
        },
        step3: {
            국민연금료: 0,
            건강보험료: 0,
            노인장기요양보험료: 0,
            산재보험료: 0,
            고용보험료: 0,
            장애자고용분담금: 0,
            사업소세: 0,
            total: 0
        },
        step4: {
            복리후생비: 0,
            교육훈련비: 0,
            채용선발비: 0,
            일반관리비: 0,
            기타직접비: 0,
            이익준비금: 0,
            total: 0
        }
    }

    //파견 8시간
    if (Number(amount) === 45000000) {
        obj.totalSalary = 45000000
        obj.step1.기본급 = 3550000
        obj.step1.중식보조금 = 200000
        obj.step1.시간외근로수당 = 0

        obj.step3.국민연금료 = 159750
        obj.step3.건강보험료 = 125848
        obj.step3.노인장기요양보험료 = 16120
        obj.step3.산재보험료 = 67450
        obj.step3.고용보험료 = 40824
        obj.step3.장애자고용분담금 = 62328
        obj.step3.사업소세 = 17750

        obj.step4.복리후생비 = 20000
        obj.step4.교육훈련비 = 170410
        obj.step4.채용선발비 = 42610
        obj.step4.일반관리비 = 213010
        obj.step4.기타직접비 = 85210
        obj.step4.이익준비금 = 238570
    }
    //파견 10시간
    else {
        obj.totalSalary = 42000000
        obj.step1.기본급 = 2460000
        obj.step1.중식보조금 = 200000
        obj.step1.시간외근로수당 = 840000

        obj.step3.국민연금료 = 148500
        obj.step3.건강보험료 = 116985
        obj.step3.노인장기요양보험료 = 14980
        obj.step3.산재보험료 = 62700
        obj.step3.고용보험료 = 37950
        obj.step3.장애자고용분담금 = 62328
        obj.step3.사업소세 = 16500

        obj.step4.복리후생비 = 20000
        obj.step4.교육훈련비 = 159200
        obj.step4.채용선발비 = 39800
        obj.step4.일반관리비 = 199000
        obj.step4.기타직접비 = 79600
        obj.step4.이익준비금 = 222880
    }

    obj.step1.total = Object.keys(obj.step1)
        .filter(key => (key !== 'total'))  // 'total'을 제외
        .reduce((acc, key) => acc + obj.step1[key], 0);  // 총합 계산

    obj.step3.total = Object.keys(obj.step3)
        .filter(key => (key !== 'total'))  // 'total'을 제외
        .reduce((acc, key) => acc + obj.step3[key], 0);  // 총합 계산

    obj.step4.total = Object.keys(obj.step4)
        .filter(key => (key !== 'total'))  // 'total'을 제외
        .reduce((acc, key) => acc + obj.step4[key], 0);  // 총합 계산





    return obj;
}



// 채용
export const getCalcSalaryDetailEstimateRecruit = (amount) => {
    let obj = {
        totalSalary: 0,
        totalEstimateAmount: 0,
        step1: {                                // service
            customDriverEdu: 1750000,                 // 연 4회 맞춤형 기사 교육
            driverMgmtProgram: 1100000,               // 수행기사 자동 관리 프로그램 제공
            total: 0
        },
        step2: {                                // 채용수수료
            교육훈련비: 0,           // 교육훈련비
            채용선발비: 0,        // 채용선발비
            일반관리비: 0,           // 일반관리비
            기타직접비: 0,                 // 기타직접비
            이익준비금: 0,               // 이익준비금
            total: 0
        },
    }

    if (Number(amount) === 45000000) {
        obj.totalSalary = 2700000
        obj.step2.교육훈련비 = 1534079
        obj.step2.채용선발비 = 383587
        obj.step2.일반관리비 = 1917576
        obj.step2.기타직접비 = 767084
        obj.step2.이익준비금 = 2147674

    }
    else {
        obj.totalSalary = 3500000
        obj.step2.교육훈련비 = 1534079
        obj.step2.채용선발비 = 383587
        obj.step2.일반관리비 = 1917576
        obj.step2.기타직접비 = 767084
        obj.step2.이익준비금 = 2147674
    }

    obj.step2.total = (obj.step2.교육훈련비 + obj.step2.채용선발비 + obj.step2.일반관리비 + obj.step2.기타직접비 + obj.step2.이익준비금)


    return obj;
}


export const SetDriverDispatchEstimateJoinMember = (obj) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${WEB_API_URL}/SetDriverDispatchEstimateJoinMember`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: obj
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}
