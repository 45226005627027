import '../../../styles/mainTable.css';

const YearlyMosilerProductTable = () => {
	return (
		<>
			<div className='main-table td-set-3'>
				<table>
					<thead>
						<tr>
							<td className={'w-200'}></td>
							<td>100시간 상품</td>
							<td>200시간 상품</td>
							<td>300시간 상품</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className={'w-200 left grey'}>기본시간</td>
							<td>100시간</td>
							<td>200시간</td>
							<td>300시간</td>
						</tr>
						<tr>
							<td className={'w-200 left grey'}>최소 이용 시간</td>
							<td>8시간</td>
							<td>8시간</td>
							<td>8시간</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};
export default YearlyMosilerProductTable;
