import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import useBodyScrollLock from '../../hooks/useBodyScrollLock';

const Dim = ({ onClick, children }) => {
  const scrollableElRef = useRef(null);
  useBodyScrollLock(scrollableElRef);
  return (
    <div
      ref={scrollableElRef}
      className={'dim'}
      onClick={e => {
        e.preventDefault();
        onClick && onClick();
      }}
    >
      {children}
    </div>
  );
};

Dim.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};
export default Dim;
