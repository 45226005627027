import '../../../styles/mosilerScheduleExample.css';

const MosilerScheduleExample = () => {
	return (
		<div className='mobile-wrapper'>
			<div className='mosiler-schedule-example-wrap'>
				<div className='month'>
					<span>1월</span>
					<span>2월</span>
					<span className={'active'}>3월</span>
					<span className={'active'}>4월</span>
					<span className={'active'}>5월</span>
					<span className={'active'}>6월</span>
					<span className={'active'}>7월</span>
					<span className={'active'}>8월</span>
					<span>9월</span>
					<span className={'active'}>10월</span>
					<span className={'active'}>11월</span>
					<span className={'active'}>12월</span>
				</div>
				<div className='status'>
					<span className='inactive span-2'>미사용</span>
					<span className='active span-6'>
						월간 모시러 이용
						<span className='line'></span>
					</span>
					<span className='inactive span-1'>미사용</span>
					<span className='active span-3'>
						월간 모시러 이용
						<span className='line'></span>
					</span>
				</div>
			</div>
		</div>
	);
};

export default MosilerScheduleExample;
