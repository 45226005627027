import React, { useEffect, useState } from 'react';
import { getCalcSalaryDetailEstimate } from '../../../lib/DriverDispatchApi';
import { useDriverDispatchOptionContext } from '../../organisms/DriverDispatchOptionContext';

const Test = () => {
    const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
    
    return (
        <>

        </>
    )
}

export default Test;
