import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { calculateDriverDispatchTotalPrice, calculateTotalPrice } from '../../utils/numbers'
import { updateTotalAmount } from '../../reducers/bookingEstimation';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';



const DriverDispatchOptionContext = createContext();
export default DriverDispatchOptionContext;

export const DriverDispatchOptionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { DriverDispatchOption } = useSelector((state) => state);

  const [selectedOptions, setSelectedOptions] = useState({
    subscription: DriverDispatchOption?.subscription ?? 'recruit',
    usageTime: DriverDispatchOption?.usageTime ?? '5500',
    optionHex: DriverDispatchOption?.optionHex ?? 0,
    basicInfo1: {
      workStartTime: DriverDispatchOption?.basicInfo1?.workStartTime ?? '09:00',
      workEndTime: DriverDispatchOption?.basicInfo1?.workEndTime ?? '18:00',
      weekendUsage: DriverDispatchOption?.basicInfo1?.weekendUsage ?? 'no',
      weekendUsageEtcString: DriverDispatchOption?.basicInfo1?.weekendUsageEtcString ?? '',
      usageDate: DriverDispatchOption?.basicInfo1?.usageDate ?? new Date(),
    },
    basicInfo2: {
      selectedSalary: DriverDispatchOption?.basicInfo2?.selectedSalary ?? '5500',
      selectedSalaryEtcString: DriverDispatchOption?.basicInfo2?.selectedSalaryEtcString ?? '',
      garageAddress: DriverDispatchOption?.basicInfo2?.garageAddress ?? null,
      companyAddress: DriverDispatchOption?.basicInfo2?.companyAddress ?? null,
    },
    basicInfo3: {
      conciergeAdvice: DriverDispatchOption?.basicInfo3?.conciergeAdvice ?? 'am',
      conciergeAdviceEtcString: DriverDispatchOption?.basicInfo3?.conciergeAdviceEtcString ?? '',
      etcRequest: DriverDispatchOption?.basicInfo3?.etcRequest ?? 'no',
      etcRequestEtcString: DriverDispatchOption?.basicInfo3?.etcRequestEtcString ?? null,
      route: DriverDispatchOption?.basicInfo3?.route ?? 'route_instagram',
      routeEtcString: DriverDispatchOption?.basicInfo3?.routeEtcString ?? null,
    },
    options: {
      monthlyMosilerDemo: DriverDispatchOption?.options?.monthlyMosilerDemo ?? 'mmnothing',
      recruitPurpose: DriverDispatchOption?.options?.recruitPurpose ?? 'driver',
    },
    userInformation: {
      userType: DriverDispatchOption?.userInformation?.userType ?? 'personal',
      user: {
        name: DriverDispatchOption?.userInformation?.user?.name ?? null,
        businessAddress: {
          zip: DriverDispatchOption?.userInformation?.user?.businessAddress?.zip ?? null,
          address1: DriverDispatchOption?.userInformation?.user?.businessAddress?.address1 ?? null,
          address2: DriverDispatchOption?.userInformation?.user?.businessAddress?.address1 ?? null,
        },
        businessRegistrationId: DriverDispatchOption?.userInformation?.user?.businessRegistrationId ?? null,
        businessRegistration: DriverDispatchOption?.userInformation?.user?.businessRegistration ?? null,
        companyName: DriverDispatchOption?.userInformation?.user?.companyName ?? null,
        personalAddress: DriverDispatchOption?.userInformation?.user?.personalAddress ?? null,
        personalAddressDetail: DriverDispatchOption?.userInformation?.user?.personalAddressDetail ?? null,
      }
    },
    recruitRequest: {
      workStartDate: DriverDispatchOption?.recruitRequest?.workStartDate ?? moment().add(1, 'days').format('yyyy-MM-DD'),
      gender: DriverDispatchOption?.recruitRequest?.gender ?? 'men',
      age20: DriverDispatchOption?.recruitRequest?.age20 ?? false,
      age30: DriverDispatchOption?.recruitRequest?.age30 ?? false,
      age40: DriverDispatchOption?.recruitRequest?.age40 ?? false,
      age50: DriverDispatchOption?.recruitRequest?.age50 ?? false,
      age60: DriverDispatchOption?.recruitRequest?.age60 ?? false,
      ageboth: DriverDispatchOption?.recruitRequest?.ageboth ?? false,
      driverSmokingStatus: DriverDispatchOption?.recruitRequest?.driverSmokingStatus ?? 'nosmoking',
      driverParkingSupport: DriverDispatchOption?.recruitRequest?.driverParkingSupport ?? 'possible',
      commutingVehicleProvided: DriverDispatchOption?.recruitRequest?.commutingVehicleProvided ?? 'possible',
      postHireJobResponsibilities: DriverDispatchOption?.recruitRequest?.postHireJobResponsibilities ?? null,
      benefits: DriverDispatchOption?.recruitRequest?.benefits ?? null,
      vehicleModelName: DriverDispatchOption?.recruitRequest?.vehicleModelName ?? null,
      foreignLanguageProficiency: DriverDispatchOption?.recruitRequest?.foreignLanguageProficiency ?? 'impossible',
      foreignLanguageProficiencyStr: DriverDispatchOption?.recruitRequest?.foreignLanguageProficiencyStr ?? null,
      driverWaitingRoomAvailable: DriverDispatchOption?.recruitRequest?.driverWaitingRoomAvailable ?? 'possible',
      actualWorkLocation: DriverDispatchOption?.recruitRequest?.actualWorkLocation ?? null,
      educationalBackground: DriverDispatchOption?.recruitRequest?.educationalBackground ?? 'both',
      specialNotes: DriverDispatchOption?.recruitRequest?.specialNotes ?? null,
    },

    totalAmount: DriverDispatchOption?.totalAmount ?? 0,
  });



  // 견적 금액 총 합 산출
  useEffect(() => {
    const hasPaymentAmountOptions = {
      subscription: selectedOptions.subscription,
      monthlyMosilerDemo: selectedOptions.options.monthlyMosilerDemo,
      recruitPurpose: selectedOptions.options.recruitPurpose,

    }
    const totalPrice = calculateDriverDispatchTotalPrice(
      hasPaymentAmountOptions
    );
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      totalAmount: totalPrice,
    }));
    dispatch(updateTotalAmount(totalPrice));
  }, [
    selectedOptions.subscription,
    selectedOptions.options.monthlyMosilerDemo,
    selectedOptions.options.recruitPurpose,

  ]);

  useEffect(() => {
    // async function getData() {
    //   console.log('DriverDispatchOptionContext UseEffect')
    //   console.log(await TestApiTest())
    // }
    // getData()
  }, [

  ]);


  const state = useMemo(
    () => ({
      selectedOptions,
      setSelectedOptions,
    }),
    [selectedOptions]
  );

  return (
    <DriverDispatchOptionContext.Provider value={state}>
      {children}
    </DriverDispatchOptionContext.Provider>
  );
};

DriverDispatchOptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDriverDispatchOptionContext = () => {
  const context = useContext(DriverDispatchOptionContext);
  if (!context) {
    throw new Error('useDriverDispatchOptionContext must be used within a DriverDispatchOptionProvider');
  }
  return context;
};

export const withDriverDispatchProvider = WrappedComponent => {
  const Component = props => {
    return (
      <DriverDispatchOptionProvider>
        <WrappedComponent {...props} />
      </DriverDispatchOptionProvider>
    );
  };

  Component.displayName = `useDriverDispatchOptionContext(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return Component;
}
