import React from 'react';
import classNames from 'classnames';
import Button from '../atoms/Button';

const TableRow = (
  {
    row,
    large = false,
    colspan,
    classes,
    seeDetail = false,
    onClickSeeDetail,
  }) => {
  return (
    <>
      {typeof row == 'string' ? (
        <tr>
          <td
            dangerouslySetInnerHTML={{ __html: row }}
            colSpan={colspan}
            className={classNames(large && 'large', classes ?? classes)}>
          </td>
        </tr>
      ) : (
        <tr>
          {row?.map((item, index) => {
            console.log(item)
            return (
              <td
                key={index}
                dangerouslySetInnerHTML={{ __html: item }}
                className={classNames(large && 'large', classes ?? classes)}>
              </td>
            )
          })}
          {seeDetail && (
            <td className={classNames('align-right')}>
              <Button
                type={'text'}
                className='txt-small grey-3 table-summary-button'
                onClick={onClickSeeDetail}
              >
                더보기
              </Button>
            </td>
          )}
        </tr>
      )}
    </>
  );
};

export default TableRow;
