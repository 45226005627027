import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { useSelector } from 'react-redux';
import ListWithCheckIcon from '../../atoms/ListWithCheckIcon';
import Button from '../../atoms/Button';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';
import { transFormWon } from '../../../utils/numbers';
import moment from 'moment';

/**
 * 계약 완료 페이지
 * name : completeContract
 * */
const CompleteContract = () => {
  const navigate = useNavigate();
  const { BookingOption } = useSelector((state) => state);
  const [description, setDescription] = useState([])


  useEffect(() => {
    const CompleteContractDescription = [
      '월간 모시러 계약이 완료 되었습니다.',
      '날인 해주신 계약서는 담당자님 메일로 발송이 되었습니다.',
      `입금 금액은 <i class='blue txt-large'>${transFormWon(BookingOption?.totalAmount)}</i> 이며 <br>
       입금 계좌는 (주)버틀러 신한은행 140-011-170175 입니다. <br>
       결제 기한: <i class='blue txt-large'>${moment().add(3, 'days').format('yyyy년 MM월 DD일')} 17시</i>`,
      '입금이 확인 되면 예약 계정을 생성해 드립니다.',
      `<a href='${URLS.BOOKING_ESTIMATION_SUBSCRIPTION}' class='txt-large underline' target='_blank'>첫 화면으로 이동</a>`
    ]
    setDescription(CompleteContractDescription)
  }, [BookingOption])

  return (
    <>
      {description && (
        <div className={classNames('booking-wrap')}>
          <PageTitle title={'계약 완료'} subtitle={'계약이 완료되었습니다'} />
          <div className={classNames('confirm-booking-wrap')}>
            <div className={classNames('confirm-booking-content')}>
              <ListWithCheckIcon listItem={description} />
            </div>
          </div>

          <BookingFormSummary
            hasAmonut={false}
          >
            <div className={classNames('button-set')}>
              <Button
                styleType={'secondary'}
                onClick={() => {
                  // navigate(URLS.EMAIL_LOGIN)
                  window.open('https://docs.google.com/forms/d/e/1FAIpQLSepAfOU0V5TM-2BNQ4H9MsHQFMRjvPeULGJ3PfzGleDbpmcJA/viewform')
                }}
              >기본정보 입력하기</Button>
            </div>
          </BookingFormSummary>
        </div>
      )}
    </>
  )
}

export default CompleteContract;