import Select from '../atoms/Select';

const SelectSet = ({ label, defaultValue, options, error, selectedOption, setSelectedOption }) => (
	<div className='input-set'>
		<span className='txt-large'>{label}</span>
		<div className='select-wrap'>
			<Select defaultValue={defaultValue} value={selectedOption} selectedOption={selectedOption} options={options} onOptionChange={(value) => setSelectedOption(value)} />
		</div>
		{error && <span className='error-message'>{error.message}</span>}
	</div>
);

export default SelectSet;
