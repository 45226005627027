import React from 'react';
import { useForm } from 'react-hook-form';
import { formErrorMessage } from '../../../../utils/alertMessage';
import classNames from 'classnames';
import Input from '../../../atoms/Input';
import Button from '../../../atoms/Button';
import { Link } from 'react-router-dom';
import { URLS } from '../../../../utils/urls';
import Icon from '../../../atoms/Icon';
import { handlePhoneChange } from '../../../../utils/numbers';
import AccountApi from '../../../../lib/AccountApi';
import { isChkResultSuccess, removeHyphen } from '../../../../lib/Util';
import { useNavigate } from 'react-router-dom';

/**
 * 아이디 찾기
 * path: reservation/login/verify-email
 * **/

const VerifyEmail = () => {
  const navigate = useNavigate()
  const {
    handleSubmit,
    setValue,
    register,
    setError,
    formState: { errors }
  } = useForm();

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;

    if (!data?.phone) {
      validCheck = false;
      setError('phone', {
        type: 'focus',
        name: 'phone',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }

    if (validCheck) {
      // 아이디 찾기



      let result = await AccountApi.FindUserId(removeHyphen(data?.phone))
      if (isChkResultSuccess(result)) {
        if (result?.Message?.indexOf('kakao@') >= 0) {
          alert('카카오 계정으로 가입되어 있습니다. 카카오 로그인을 진행해주세요.')
        }
        else if (result?.Message?.indexOf('apple@') >= 0) {
          alert('애플 계정으로 가입되어 있습니다. 애플 로그인을 진행해주세요.')
        }
        else {
          alert(`고객님의 아이디는\n${result?.Message}\n입니다.`)
        }
        navigate(URLS.EMAIL_LOGIN, { replace: true })

      }
      else {
        alert(result.Message)
      }
    }

  });

  return (
    <form onSubmit={onSubmit} className={classNames('login-form')}>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          전화번호
        </span>
        <Input
          type={'tel'}
          required
          setValue={setValue}
          maxLength={11}
          error={errors.phone}
          placeholder={'전화번호'}
          {...register('phone')}
          onChange={e => {
            handlePhoneChange(e, setValue, 'phone')
          }}
        />
      </div>
      <Button
        type={'submit'}
        styleType={'secondary'}
        classes={'bold txt-large'}
      >아이디 찾기</Button>
      {/* <div className={classNames('link-set')}>
        <Link to={URLS.EMAIL_LOGIN} className={classNames('bold')}>로그인</Link>
        <Icon icon={'dot'} />
        <Link to={URLS.VERIFY_PASSWORD} className={classNames('bold')}>비밀번호 찾기</Link>
        <Icon icon={'dot'} />
        <Link to={URLS.REGISTER} className={classNames('bold')}>회원가입</Link>
      </div> */}
    </form>
  )
}

export default VerifyEmail