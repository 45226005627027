import React, { useState, useEffect } from 'react';
import { URLS } from '../../../../utils/urls';
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom';
import AccountApi from '../../../../lib/AccountApi';
import { useSelector } from 'react-redux';
import { isChkResultSuccess } from '../../../../lib/Util';

/**
 * 로그아웃
 * path: reservation/login/email-login
 * **/


const DriverManageLogout = () => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const loginInfo = useSelector(state => state.Login);
    const navigate = useNavigate();

    useEffect(() => {
        async function process() {
            let result = await AccountApi.SetLogOutDd(loginInfo.User.CustomerNo)
            if (isChkResultSuccess(result)) {
                removeCookie('li_01_dd', { path: '/' })
                removeCookie('li_02_dd', { path: '/' })
                removeCookie('alchk_dd', { path: '/' })
                removeCookie('lgi_dd', { path: '/' })
                removeCookie('lgupi_dd', { path: '/' })
                
                navigate(URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGIN)
            }
        }

        process()

    }, [])


    return (
        <></>
    )
};

export default DriverManageLogout