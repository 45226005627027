import axios from 'axios';
import moment from 'moment'
import qs from 'qs'
// import { getEnglishDayToKorean } from './Util';

const saveGoogleSheet = process.env.REACT_APP_GOOGLE_SHEET_API_URL
// const ETC_ID = 100
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL
//const MOSILER_API_URL = 'https://api.mosiler.com'

/************************** Mosiler API ******************************** */
/// http://localhost:5701/SetMmEstimateHistory
/// http://localhost:5701/GetMmEstimateLatestHistory?estimateinfo={ESTIMATEINFO}

export const SetMmEstimateHistory = async (obj) => {
    if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
        return true;
    }
    let Url = `${WEB_API_URL}/SetMmEstimateHistory`
    const result = await axios({
        url: Url,
        method: 'POST',
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json'
        },
        data: obj
    })
    return result.data
}

export const GetMmEstimateLatestHistory = async (data) => {
    if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
        return true;
    }
    let Url = `${WEB_API_URL}/GetMmEstimateLatestHistory?estimateinfo=${data}`
    const result = await axios({
        url: Url,
        method: 'GET',
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return result.data
}





/*
let EstimateResponseObj = {
        UserName: userInfoDecrypt.UserName,
        UserPhone: userInfoDecrypt.PhoneNumber,
        UserEmail: userInfoDecrypt.EmailAddress,
        StartAddress: BookingOption?.contractTerms1?.serviceArea?.departure?.address1,
        EndAddress: BookingOption?.contractTerms1?.serviceArea?.destination?.address1,
        SelectedProductStr: SelectedProduct,
        Route: '',
        ConciergeString: '(New)웹견적서',
        Requestcomment: '',
        UsageSchedule: BookingOption?.contractTerms1?.schedule,
      }
*/

/************************** Google Sheet API ******************************** */

export const setSheetEstimateResponse = (obj) => {
    if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
        return true;
    }
    const saveSheet = '견적응답(웹)'
    let form = qs.stringify({
        '1. 성함': obj.UserName,
        '2. 연락처': obj.UserPhone,
        '3. 이메일 주소': obj.UserEmail,
        '4. 출발지와 종료지 주소를 알려주세요': obj.StartAddress + "/" + obj.EndAddress,
        '5. 월간 모시러의 어떤 상품 사용을 원하시나요? ': obj.SelectedProductStr,
        '6. 서비스 이용을 하신다면 시점은 언제인가요? ': obj.UsageSchedule,
        '7. 월간 모시러를 알게 된 경로는 어떻게 되시나요? ': obj.Route,
        '8. 특별히 요청할 내용이 있으면 기재해 주시기 바랍니다.': obj.Requestcomment,
        '9. 컨시어지 상담을 원하시면 알려주세요.': '(웹)' + obj.ConciergeString,
        '타임스탬프': moment().format('yyyy-MM-DD HH:mm'),
        '이용목적': obj.NeedsString,
        '계약기간': obj?.ContractMonth,
        // '옵션': obj.selectedOption,
        '월모옵션_등록가능대수': obj.MmOptionVehicles,
        '월모옵션_차량가격': obj.MmOptionCarAmount,
        '월모옵션_보험': obj.MmOptionInsurance,
        '월모옵션_탑승고객': obj.MmOptionPassenger,
        '월모옵션_서비스': obj.MmOptionService,
        '수행파견옵션_면접자운행서비스': obj.DdOptionMonthlyMosiler,
        '수행파견옵션_채용목적': obj.DdOptionRecruitPurpose,
        '월간모시러_견적금액': obj?.MmEstimateAmount
    });

    return new Promise((resolve, reject) => {
        axios({
            url: `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then(async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch((err) => {
            resolve('fail')
        });
    })
}

// 이 파트너를 원해요를 눌렀을 때 저장되는 정보
export const setSheetOshiNoPartner = (obj) => {
    if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
        return true;
    }
    
    const saveSheet = '원하는파트너정보'
    let form = qs.stringify({
        '성함': obj.UserName,
        '연락처': obj.UserPhone,
        '이메일주소': obj.UserEmail,
        '원하는파트너': obj.PartnerName,
        '원하는파트너코드': obj.DriverNo,
        '타임스탬프': moment().format('yyyy-MM-DD HH:mm:ss'),
    });

    return new Promise((resolve, reject) => {
        axios({
            url: `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then(async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch((err) => {
            resolve('fail')
        });
    })
}

// 이 파트너를 원해요를 눌렀을 때 저장되는 정보
export const setSheetOutcallReqInfo = (obj) => {
    if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
        return true;
    }
    
    const saveSheet = '상담사통화요청'
    let form = qs.stringify({
        '성함': obj.UserName,
        '연락처': obj.UserPhone,
        '이메일주소': obj.UserEmail,
        '입력한성함': obj.InputName,
        '입력한연락처': obj.InputPhone,
        '연락가능시간대': obj.availableTime,
        '타임스탬프': moment().format('yyyy-MM-DD HH:mm'),
    });

    return new Promise((resolve, reject) => {
        axios({
            url: `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then(async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch((err) => {
            resolve('fail')
        });
    })
}
/*
ContractName: data?.name,
Address: data?.address1,
CarInfo: '',		// 추가필요
UsageDate: moment(BookingOption?.contractTerms1?.schedule).format('yyyy-MM-DD'),
ContractMonth: (SubscriptionType === 'annual') ? '' : getValueByTarget(ContractPeriodOptions, BookingOption?.contractTerms1?.periodOfContract, 'title'),
NeedsString: getValueByTarget(PurposeOfContractOptions, data?.purposeOfContract, 'title'),	// 탑승자
BookerName: userInfoDecrypt.UserName,
BookerPhoneNumber: userInfoDecrypt.PhoneNumber,
BookerEmailAddress: userInfoDecrypt.EmailAddress,
// new 웹견적서
UserType: (data?.userType === 'personal') ? '개인' : '법인',
// 계약자 성함
BusinessCode: data?.businessRegistrationId,
BusinessLicense: 'URL',
ContractProduct: SelectedProduct
*/

export const setSheetContractResponse = (obj) => {
    if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
        return true;
    }
    const saveSheet = '3. 계약응답(웹)'

    let form = qs.stringify({
        "1. 계약자의 성함을 알려주세요.": obj.ContractName,
        "2. 계약자의 상세 주소를 알려주세요.": obj.Address,
        "3. 차량의 모델명을 알려주세요": obj.CarInfo,
        "4. 이용 시작일은 언제 부터 인가요?  ": obj.UsageDate,
        "5. 원하시는 계약 기간은 어떻게 되시나요? ": obj.ContractMonth,
        "6. 서비스 이용의 목적은 어떻게 되시나요?": obj.NeedsString,
        "7. 담당자님의 성함을 알려주세요": obj.BookerName,
        "8. 담당자님의 연락처를 알려주세요": obj.BookerPhoneNumber,
        "9. 담당자님의 이메일 주소를 알려주세요": obj.BookerEmailAddress,
        '10. 컨시어지 상담을 원하시면 알려주세요.': 'new 웹견적서',
        '타임스탬프': moment().format('yyyy-MM-DD HH:mm'),
        // "전화번호" : userinfo.UserPhone,
        // "이메일주소" : userinfo.UserEmail,
        "고객유형": obj.UserType,
        "계약자 성함": obj.ContractName,
        "사업자등록번호": (obj.UserType === '개인') ? '없음(개인고객)' : obj.BusinessCode,
        "사업자 등록증": (obj.UserType === '개인') ? '없음(개인고객)' : obj.BusinessLicense,
        "계약요청상품": obj.ContractProduct,

        "출근일_수행파견_채용의뢰": obj?.recruitRequest?.workStartDate,
        "성별_수행파견_채용의뢰": obj?.recruitRequest?.gender,
        "20대_수행파견_채용의뢰": obj?.recruitRequest?.age20,
        "30대_수행파견_채용의뢰": obj?.recruitRequest?.age30,
        "40대_수행파견_채용의뢰": obj?.recruitRequest?.age40,
        "50대_수행파견_채용의뢰": obj?.recruitRequest?.age50,
        "60대_수행파견_채용의뢰": obj?.recruitRequest?.age60,
        "연령무관_수행파견_채용의뢰": obj?.recruitRequest?.ageboth,
        "기사흡연여부_수행파견_채용의뢰": obj?.recruitRequest?.driverSmokingStatus,
        "기사주차지원_수행파견_채용의뢰": obj?.recruitRequest?.driverParkingSupport,
        "출퇴근차량제공_수행파견_채용의뢰": obj?.recruitRequest?.commutingVehicleProvided,
        "입사후담당업무_수행파견_채용의뢰": obj?.recruitRequest?.postHireJobResponsibilities,
        "복리후생_수행파견_채용의뢰": obj?.recruitRequest?.benefits,
        "차량모델명_수행파견_채용의뢰": obj?.recruitRequest?.vehicleModelName,
        "외국어가능여부_수행파견_채용의뢰": obj?.recruitRequest?.foreignLanguageProficiency,
        "가능언어_수행파견_채용의뢰": obj?.recruitRequest?.foreignLanguageProficiencyStr,
        "기사대기실유무_수행파견_채용의뢰": obj?.recruitRequest?.driverWaitingRoomAvailable,
        "실근무지_수행파견_채용의뢰": obj?.recruitRequest?.actualWorkLocation,
        "학력_수행파견_채용의뢰": obj?.recruitRequest?.educationalBackground,
        "특이사항_수행파견_채용의뢰": obj?.recruitRequest?.specialNotes
    });













    return new Promise((resolve, reject) => {
        axios({
            url: `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then(async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch((err) => {
            resolve('fail')
        });
    })
}

export const setSheetEstimateResponseTest = (obj, userinfo) => {
    const saveSheet = '견적응답(웹)'
    let form = qs.stringify({
        '1. 성함': 'userinfo.UserName',
        '2. 연락처': 'userinfo.UserPhone',
        '3. 이메일 주소': 'userinfo.UserEmail',
        '4. 출발지와 종료지 주소를 알려주세요': 'obj.formData.StartArea + "/" + obj.formData.EndArea',
        '5. 월간 모시러의 어떤 상품 사용을 원하시나요? ': 'obj.productCategory',
        '6. 서비스 이용을 하신다면 시점은 언제인가요? ': 'obj.UseServiceString',
        '7. 월간 모시러를 알게 된 경로는 어떻게 되시나요? ': 'Number(obj.formData.Route) === ETC_ID ? obj.formData.RouteEtcStr : obj.RouteString',
        '8. 특별히 요청할 내용이 있으면 기재해 주시기 바랍니다.': 'obj.formData.EtcRequest',
        '9. 컨시어지 상담을 원하시면 알려주세요.': '(웹)' + 'obj.ConciergeString',
        '타임스탬프': moment().format('yyyy-MM-DD HH:mm'),
        '이용목적': 'obj.NeedsString',
    });

    return new Promise((resolve, reject) => {
        axios({
            url: `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then(async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch((err) => {
            resolve('fail')
        });
    })
}

export const setSheetContractResponseTest = (obj) => {
    const saveSheet = '3. 계약응답(웹)'

    let form = qs.stringify({

        "1. 계약자의 성함을 알려주세요.": 'obj.ContractName',
        "2. 계약자의 상세 주소를 알려주세요.": 'obj.ContractAddress ',
        "3. 차량의 모델명을 알려주세요": 'obj.CarModel',
        "4. 이용 시작일은 언제 부터 인가요?  ": 'test',
        "5. 원하시는 계약 기간은 어떻게 되시나요? ": 'obj.ContractMonthString',
        "6. 서비스 이용의 목적은 어떻게 되시나요?": 'obj.PurposeString',
        "7. 담당자님의 성함을 알려주세요": 'obj.BookerName',
        "8. 담당자님의 연락처를 알려주세요": 'obj.BookerPhone',
        "9. 담당자님의 이메일 주소를 알려주세요": 'obj.BookerEmail',
        '10. 컨시어지 상담을 원하시면 알려주세요.': '웹견적서',
        '타임스탬프': moment().format('yyyy-MM-DD HH:mm'),
        // "전화번호" : userinfo.UserPhone,
        // "이메일주소" : userinfo.UserEmail,
        "고객유형": 'obj.CustomerTypeString',
        "계약자 성함": 'obj.ContractName',
        "사업자등록번호": 'obj.CustomerType',
        "사업자 등록증": 'obj.CustomerType',
        "계약요청상품": 'obj.ContractReqProductString'
    });


    return new Promise((resolve, reject) => {
        axios({
            url: `${saveGoogleSheet}?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then(async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch((err) => {
            resolve('fail')
        });
    })
}

export const getEstimateHistoryInfo = (data1, data2) => {

    return new Promise((resolve, reject) => {
        axios({
            url: `${WEB_API_URL}/GetMmEstimateLatestHistory?estimateInfo=${data1}&estimateType=${data2}`,
            method: 'GET',
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(async res => {
            resolve(res.data)
        }).catch((err) => {
            resolve('fail')
        });
    })
}



export const setEstimatePageIntroduce = (userinfo, inflow) => {
    const saveUrl = 'https://script.google.com/macros/s/AKfycbyJeVzlvCYwPhqcny1ISEryrDEjqf9q5XE0r-ujh71xMTV03iM/exec'
    let form = qs.stringify({
        '이름': userinfo.UserName,
        '연락처': userinfo.UserPhone,
        '이메일': userinfo.UserEmail,
        '상품': inflow,
        '타임스탬프' : new Date().toLocaleDateString(),
    });

    return new Promise( (resolve, reject)=>{
        axios({
            url : saveUrl,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then( async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve('fail')
        });
    })
}