import '../../../styles/mainTable.css';

const MonthlyMosilerProductTable = () => {
	return (
		<div className='main-table td-set-4'>
			<table>
				<thead>
					<tr>
						<td className={'w-200'}></td>
						<td>10일</td>
						<td>14일</td>
						<td>20일</td>
						<td>30일</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className={'w-200 left grey'}>기본시간</td>
						<td>80시간</td>
						<td>112시간</td>
						<td>160시간</td>
						<td>240시간</td>
					</tr>
					<tr>
						<td className={'w-200 left grey'}>최소 이용 시간</td>
						<td>8시간</td>
						<td>8시간</td>
						<td>8시간</td>
						<td>8시간</td>
					</tr>
					<tr>
						<td className={'w-200 left grey'}>드라이버 풀</td>
						<td>5명</td>
						<td>3명</td>
						<td>3명</td>
						<td>3명</td>
					</tr>
					<tr>
						<td className={'w-200 left grey'}>추천 드라이버</td>
						<td>5명</td>
						<td>5명</td>
						<td>10명</td>
						<td>10명</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};
export default MonthlyMosilerProductTable;
