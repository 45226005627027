import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import TableRow from '../../molecules/TableRow';
import Table from '../../organisms/Table';
import {reservationHistoryData, subscriptionData} from '../../../archive/temporaryData';
import {transFormWon} from '../../../utils/numbers';
import Button from '../../atoms/Button';
import WhiteBox from '../../atoms/WhiteBox';
import useCheckPC from '../../../hooks/useCheckPC';
import MobileDescriptionPop from '../../organisms/MobileDescriptionPop';
import TableDescriptPopContents from '../../molecules/TableDescriptPopContents';
import Pagination from '../../molecules/Pagination';

/**
 * 결제 관리 페이지
 * name : manage payments
 * TODO: 고객 데이터로 값 대체, button events
 * */

const ManagePayments = () => {
  const { isPC } = useCheckPC();
  let totalPendingAmount = 0;
  const currentDate = new Date();
  const [ showDescriptionIndex, setShowDescriptionIndex ] = useState(-1)
  const formattedDate = `${currentDate.getFullYear()}.${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getDate().toString().padStart(2, '0')}`;

  /** pagination **/
  // 한 페이지에 보여줄 게시물 개수
  const limit = 10;
  const [ page, setPage ] = useState(1);
  const [ offset, setOffset ] = useState(1);

  useEffect(() => {
    const tempPage = (page - 1) * limit;
    setOffset(tempPage)
  }, [page])

  const customerTitleList = [
    { title: '고객명' },
    { title: '운행구분' },
    { title: '이용회차' },
    { title: '이용 중 상품' }
  ]

  const subscriptionHistoryTitleList = [
    { title: '이용회차' },
    { title: '기간' },
    { title: '상품명' },
    { title: '적용 단가' },
    { title: '선청구액' },
    { title: '후청구액' }
  ]

  const subscriptionHistoryTitleSummaryList = [
    { title: '이용회차' },
    { title: '선청구액' },
    { title: '' },
  ]

  let subscription;
  let subscriptionOption;
  switch (reservationHistoryData.subscription) {
    case 'montly':
      subscription = '월간모시러'
      subscriptionOption = `<i class='blue txt-regular'>${reservationHistoryData.subscriptionOrder}개월</i>차 이용중`
      break;
    case 'annual':
      subscription = '연간모시러'
      subscriptionOption = `<i class='blue txt-regular'>${reservationHistoryData.subscriptionOrder}년</i>차 이용중`
      break;
  }

  let subscriptionSummary = `
    <span class='txt-regular item-set'>
        <i class='blue txt-regular'>${reservationHistoryData.subscriptionHour}시간</i> 상품
    </span>
    <span class='txt-regular item-set'>
        ${reservationHistoryData.period.start} ~ ${reservationHistoryData.period.end}
    </span>
  `

  const customerSubscriptionInfoList = [
    reservationHistoryData.name,
    subscription,
    subscriptionOption,
    subscriptionSummary
  ]

  const onClickExtend = (value) => {

  }

  const subscriptionHistoryRowList = subscriptionData.map((subscription, index) => {
    let orderNumber = `${subscription.orderNumber}개월차`
    let orderNumberLabel;
    let beforePaidAmount = `
      <span class='txt-regular item-set'>
          ${transFormWon(subscription.beforePaidAmount.amount)}
      </span>
      <div class='label-wrap'>
          <span class='bg-grey'>
              ${subscription.beforePaidAmount.paidDate} 결제완료
          </span>
      </div>
    `;

    let afterPaidAmount = ``;
    if (subscription.active !== 'completed') {
      if (subscription.active === 'using') {
        orderNumberLabel = `
          <div class='label-wrap'>
            <span class='order-num-label using'>
              이용중
            </span>
          </div>
          `
      }
      if (subscription.active === 'pending') {
        orderNumberLabel = `
          <div class='label-wrap'>
            <span class='order-num-label pending'>
               이용예정
            </span>
          </div>
          `
        const value = '';
        beforePaidAmount = `
          <span class='txt-regular item-set'>
              ${transFormWon(subscription.beforePaidAmount.amount)}
          </span>
          <div class='label-wrap'>
            <Button class='bg-blue' onClick='onClickExtend(${value})'>
              연장하기
            </Button>
          </div>
          <span class='item-set label-small light-blue bold'>
              (결제기한 ~ ${subscription.beforePaidAmount.dueDate}) 
          </span>
        `;
        totalPendingAmount += subscription.beforePaidAmount.amount;
      }
      orderNumber += orderNumberLabel
    }
    /** 후청구액 미결제 */
    if (!subscription.afterPaidAmount.paid) {
      if (subscription.active === 'completed') {
        let value = ''
        afterPaidAmount = `
          <span class='txt-regular item-set'>
              ${transFormWon(subscription.afterPaidAmount.amount)}
          </span>
          <div class='label-wrap'>
            <Button class='bg-blue' onClick='onClickExtend(${value})'>
              연장하기
            </Button>
          </div>
          <span class='item-set label-small light-blue bold'>
              (결제기한 ~ ${subscription.afterPaidAmount.dueDate}) 
          </span>
        `;
      }

      if (subscription.active === 'using') {
        let value = ''
        afterPaidAmount = `
          <span class='txt-regular item-set'>
              ${transFormWon(subscription.afterPaidAmount.amount)}
          </span>
          <div class='label-wrap'>
            <span class='bg-grey-3 fit-content'>
              집계중
            </span>
          </div>
          <span class='item-set label-small grey-3 bold'>
              (${formattedDate} 기준) 
          </span>
        `;
      }

      totalPendingAmount += subscription.afterPaidAmount.amount;

    } else {
      afterPaidAmount = `
          <span class='txt-regular item-set'>
              ${transFormWon(subscription.afterPaidAmount.amount)}
          </span>
          <div class='label-wrap'>
              <span class='bg-grey'>
                  ${subscription.afterPaidAmount.paidDate} 결제완료
              </span>
          </div>
        `;
    }

    const period = `
      <span class='txt-regular item-set'>
          ${subscription.period.start} ~ ${subscription.period.end}
      </span>
    `
    const title = subscription.productTitle;
    const productPricePerItem = transFormWon(subscription.productPricePerItem);

    return (
      {
        all: [
          orderNumber,
          period,
          title,
          productPricePerItem,
          beforePaidAmount,
          afterPaidAmount
        ],
        summary: [
          orderNumber,
          beforePaidAmount,
        ]
      }
    )
  })


  const totalPendingAmountHtml = `
    <div class='label-wrap ${isPC && 'align-right'}'>
      <span class=''>
        미결제액 ${transFormWon(totalPendingAmount)}
      </span>
    </div>
      `

  const onClickSeeDetailHandler = (index) => {
    setShowDescriptionIndex(index)
  }

  return (
    <>
      <div className={classNames('manage-payments table')}>
        <WhiteBox>
          <ul className={classNames('customer-subscription-info')}>
            {customerTitleList.map((title, index) => {
              return (
                <li key={index}>
                  <span className={classNames('txt-large bold title')}>{title.title}</span>
                  <span className={classNames('description')}
                        dangerouslySetInnerHTML={{ __html: customerSubscriptionInfoList[index] }}
                  ></span>
                </li>
              )
            })}
          </ul>
        </WhiteBox>
        <div className={classNames('subscription-history')}>
          <WhiteBox>
            <div className={'table-title'}>
              <span className={classNames(isPC ? 'txt-large' : 'title-mid')}>결제내역</span>
            </div>
            <Table
              titleList={isPC ? subscriptionHistoryTitleList : subscriptionHistoryTitleSummaryList}>
              {subscriptionHistoryRowList.slice(offset, offset + limit).map((row, index) => (
                <TableRow
                  key={index}
                  row={isPC ? row.all : row.summary}
                  large={true}
                  seeDetail={!isPC && true}
                  onClickSeeDetail={()=>onClickSeeDetailHandler(index)}
                />
              ))}
              <TableRow
                row={totalPendingAmountHtml}
                colspan={isPC ? subscriptionHistoryTitleList.length : subscriptionHistoryTitleSummaryList.length + 1}
                classes={'td-grey'}
              />
            </Table>
            {/** table pagination **/}
            <Pagination
              page={page}
              setPage={setPage}
              totalPosts={subscriptionHistoryRowList.length}
              limit={limit}/>
          </WhiteBox>
        </div>
        <div className={classNames('manage-payment-button-wrap')}>
          <Button
            styleType={'secondary'}
            classes={'fit-content padding-large'}
          >
            미결제액({transFormWon(totalPendingAmount)}) 일괄결제하기
          </Button>
          <span className={classNames('align-center txt-small description grey-2')}>
            일괄 결제 합산액은 2개월차 후청구액, 4개월차 선청구액이 포함되었으며,
            3개월차 후청구액은 산출이 완료되지 않아 제외된 것이니 참고 바랍니다.
          </span>
        </div>
      </div>
      {showDescriptionIndex >= 0 && !isPC && (
        <MobileDescriptionPop
          title={'결제내역'}
          setShowDescriptionIndex={setShowDescriptionIndex}
        >
          <TableDescriptPopContents
            theadList={subscriptionHistoryTitleList}
            tbody={subscriptionHistoryRowList[showDescriptionIndex]}
          />
        </MobileDescriptionPop>
      )}
    </>
  )
}

export default ManagePayments