import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Select from '../atoms/Select';
import { hourList3, minuteList3 } from '../../utils/numbers';

const TimeInputSelect = (
    {
        setValue,
        getValues,
        startHourRegister,
        startMinuteRegister,
        finalMsg,
        target,
        onContextChange
    }) => {

    const [selectedStartHour, setSelectedStartHour] = useState(getValues(startHourRegister))
    const [selectedStartMinute, setSelectedStartMinute] = useState(getValues(startMinuteRegister))

    return (
        <>
            <div className={classNames('time-input')}>
                <Select
                    value={getValues('startHour')}
                    classes={classNames('select-small')}
                    selectedOption={selectedStartHour}
                    options={hourList3}
                    onOptionChange={hour => {
                        setValue(startHourRegister, hour)
                        setSelectedStartHour(hour)
                        onContextChange(hour, 'hour', target)
                    }}
                />
                <span>시</span>
                <Select
                    value={getValues('startMinute')}
                    classes={classNames('select-small')}
                    selectedOption={selectedStartMinute}
                    options={minuteList3}
                    onOptionChange={minute => {
                        setValue(startMinuteRegister, minute)
                        setSelectedStartMinute(minute)
                        onContextChange(minute, 'minute', target)
                    }}
                />
                <span>분 {finalMsg}</span>

            </div>

        </>
    )
}

export default TimeInputSelect