import React from 'react';
import { useSelector } from 'react-redux';
import {transFormWon} from '../../utils/numbers';
import classNames from 'classnames';
import useCheckPC from '../../hooks/useCheckPC';

const TotalAmount = (
  {
    styleType = 'primary'
  }) => {
  const { isPC } = useCheckPC();
  const { BookingOption } = useSelector((state) => state);

  return (
    <div className={classNames('total-amount')}>
      <span className={classNames('grey-2', styleType === 'secondary' ? 'title-mid' : 'txt-large')}>예상금액</span>
      <span className={classNames(
        isPC ?
          (styleType === 'secondary' ? 'title-mid' : 'txt-large')
          : 'title-mid'
      )}>{transFormWon(BookingOption?.totalAmount)}</span>
    </div>
  );
};

export default TotalAmount;