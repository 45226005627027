import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import {
  BookingProducts,
  ConfirmEstimateDescription,
  ContractPeriodOptions,
  NumberOfVehiclesOptions,
  PassengerOptions,
  PurposeOfContractOptions,
  UsageTimeOptions,
  CarAmountOptions,
  InsuranceOptions,
  PassengerPersonOptions,
  UsageTimeOptionsAnnual,
  UsageTimeOptionsMonthly
} from '../../../utils/EstimateFormData';

import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { updateSavedSign } from '../../../reducers/bookingEstimation';
import { URLS } from '../../../utils/urls';
import Button from '../../atoms/Button';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import Icon from '../../atoms/Icon';
import useCheckPC from '../../../hooks/useCheckPC';
import { getModusignEmbedLink, getTemplateDocuments, getRequestWithTemplate, getModusignContractDraft, getEmbeddedViewUrl, SetModusignInfo } from '../../../lib/SignatureApi';
import { getBusinessCodeFormat, sleep } from '../../../lib/Util';
import { closeLoading, openLoading } from '../../../reducers/loadingRedux';

import { transFormWon2 } from '../../../utils/numbers';
import moment from 'moment-timezone';
import { getValueByTarget } from '../../../utils/parse';
import { getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import Modal from '../../molecules/Modal';
import Modal2 from '../../molecules/Modal2';

import AccountApi from '../../../lib/AccountApi';
import { SetSendMailContractCompleteMm } from '../../../lib/MailApi';
import sha1 from 'sha1'
import Modal3 from '../../molecules/Modal3';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, PAGE } from '../../../utils/constant';
import { SetMmEstimateHistory } from '../../../lib/EstimateApi';
import { SendSlackContractComplete } from '../../../lib/SlackApi';



/**
 * 계약서 확인 > 계약서 확인 페이지
 * name : ConfirmContract
 * */

const ConfirmContract = () => {
  const { isPC } = useCheckPC();

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [description, setDescription] = useState([]);
  const { selectedOptions, setSelectedOptions } = useBookingOptionContext();
  const [file, setFile] = useState(selectedOptions.userInformation.user.businessRegistration ?? null);
  const isExistsImages = useMemo(() => file?.length > 0, [file?.length]);
  const { BookingOption } = useSelector((state) => state);
  const [iframeUrl, setIframeUrl] = useState('')
  const [signatureIframeUrl, setSignatureIframeUrl] = useState('')
  const [isModal, setModal] = useState(false)
  const [previewUrl, setPreviewUrl] = useState('')


  const unmount = () => {
    if (isPC) {
      document.getElementById('bookingboxId').style.paddingLeft = 50 + 'px';
      document.getElementById('bookingboxId').style.paddingRight = 50 + 'px';
    }
    else {
      document.getElementById('bookingboxId').style.paddingLeft = 25 + 'px';
      document.getElementById('bookingboxId').style.paddingRight = 25 + 'px';

    }
  }

  const unmount2 = () => {
    if (isPC) {
      
    }
    else {
      let root = document.getElementById('root')
      let iframeDoc = document.getElementById('iframe')
      let bookingBoxDoc = document.getElementById('bookingboxId')
      let steplistDoc = document.getElementById('step-list')
          
      bookingBoxDoc.style.marginBottom = '300px'
      iframeDoc.style.transform = ''
      iframeDoc.style.transformOrigin = ''
      iframeDoc.style.width = '100%'
      iframeDoc.style.height = '100%'
      root.style.overflowX = ''   
      steplistDoc.style.width=''
    }
  }

  useEffect(() => {
    return () => unmount()
  }, [])


  useEffect(() => {
    const updatedDescription = [
      `${file ? '서명이 등록되었습니다.' : '서명이 등록되지 않았습니다.'}`,
    ]
    setDescription(updatedDescription)
  }, [
    file,
  ])


  const messageHandler = async (event) => {

    try {
      // 이벤트 실패
      if (event.data && event.data.indexOf('REDIRECT||REJECT') >= 0) {
        alert('계약을 거절 하셨습니다.')

        localStorage.removeItem('SignatureDocumentId')
        localStorage.removeItem('SignaturePartcipantsId')
        localStorage.removeItem('SignatureUrl')
        localStorage.removeItem('SignatureExpiredDate')

        setModal(false)
        setSignatureIframeUrl('')
        setIframeUrl(previewUrl)
      }
      else if (event.data && event.data.indexOf('REDIRECT||SUCCESS') >= 0) {
        let cookieInfo = getEstimateCookieDataAndDecrypt()
        let UserJoin = {
          AccountType: 'email',
          CustomerCategory: 3,
          CustomerName: BookingOption?.userInformation?.user?.name,
          CustomertProductType: 1,
          Email: cookieInfo.EmailAddress,     // cookie 사용
          HomeAddress: (BookingOption?.userInformation?.userType === 'personal') ?
            BookingOption?.userInformation?.user?.personalAddress : BookingOption?.userInformation?.user?.businessAddress?.address1,
          MmPaymentYn: 'N',
          PhoneNumber: cookieInfo.PhoneNumber,         // cookie 사용
          Roles: 0,
          UseType: 1,
          UserId: cookieInfo.EmailAddress,    // cookie 사용
          UserPw: sha1(cookieInfo.PhoneNumber)
        }

        let tmpMmData = {
          ContractTypeDay: 0,
          ContractTypeHour: 0,
          ContractOneDayAvaliableHour: 0,
          MmHourPrice: 0,
        }

        if (BookingOption?.subscription === 'annual') {
          tmpMmData.ContractTypeDay = getValueByTarget(UsageTimeOptionsAnnual, BookingOption?.usageTime, 'estimateDay')
          tmpMmData.ContractTypeHour = getValueByTarget(UsageTimeOptionsAnnual, BookingOption?.usageTime, 'estimateHour')
          tmpMmData.ContractOneDayAvaliableHour = getValueByTarget(UsageTimeOptionsAnnual, BookingOption?.usageTime, 'estimateMinHour')
          tmpMmData.MmHourPrice = getValueByTarget(UsageTimeOptionsAnnual, BookingOption?.usageTime, 'hourPrice')
        }
        else {
          tmpMmData.ContractTypeDay = getValueByTarget(UsageTimeOptionsMonthly, BookingOption?.usageTime, 'estimateDay')
          tmpMmData.ContractTypeHour = getValueByTarget(UsageTimeOptionsMonthly, BookingOption?.usageTime, 'estimateHour')
          tmpMmData.ContractOneDayAvaliableHour = getValueByTarget(UsageTimeOptionsMonthly, BookingOption?.usageTime, 'estimateMinHour')
          tmpMmData.MmHourPrice = getValueByTarget(UsageTimeOptionsMonthly, BookingOption?.usageTime, 'hourPrice')
        }

        let monthNum = getValueByTarget(ContractPeriodOptions, BookingOption?.contractTerms1?.periodOfContract, 'numberValue')

        let MonthlyMosiler = {
          ContractEndDate: moment(BookingOption?.contractTerms1?.schedule).add(monthNum, 'months').add(-1, 'days').format('yyyy-MM-DD'),
          ContractOneDayAvaliableHour: tmpMmData.ContractOneDayAvaliableHour,
          ContractRemark: "",
          ContractStartDate: moment(BookingOption?.contractTerms1?.schedule).format('yyyy-MM-DD'),
          ContractTypeDay: tmpMmData.ContractTypeDay,
          ContractTypeHour: tmpMmData.ContractTypeHour,
          ContractRangeType: 1,
          CustomerName2: "",
          CustomerPhone2: "",
          ExtrafeeFlagArea: 1,
          ExtrafeeFlagNight: 1,
          GroupNumber: 1,
          LiveFlag: 0,
          MmHourPrice: tmpMmData.MmHourPrice,
          Month: 1,
          OptionHex: BookingOption?.optionHex,
          OvercarriedMinute: 0,
          PostPaymentFlag: 0,
          PrePaymentFlag: 0,
          RealPassengerName: "",
          RealPassengerPhone: "",
          SuspendDay: 0
        }

        // 사업자 등록번호 정보는 개인일 경우 들어가지 않도록 처리할 필요가 있음.
        let BusinessLicense = {
          Address1: BookingOption?.userInformation?.user?.businessAddress?.address1,
          Address2: BookingOption?.userInformation?.user?.businessAddress?.address2,
          BusinessCode: BookingOption?.userInformation?.user?.businessRegistrationId,
          ImageUrl: BookingOption?.userInformation?.user?.businessRegistration,
          RepresentativeName: BookingOption?.userInformation?.user?.name,
        }

        let sendObj = {
          UserJoin,
          MonthlyMosiler,
          BusinessLicense: (BookingOption?.userInformation?.userType === 'personal') ? null : BusinessLicense
        }

        SetMmEstimateHistory({
          ...getEstimateCookieDataAndDecrypt(),
          EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
          EstimatePage: PAGE.PAGE_07,
          JsonData: JSON.stringify(BookingOption)
        })


        SendSlackContractComplete({
          ...getEstimateCookieDataAndDecrypt(),
          ProductName: BookingOption?.subscription === 'annual' ? '연간모시러' : '월간모시러'
        })



        // 당분간 기능 죽이기
        // await AccountApi.SetMmEstimateJoinMember(sendObj)
        // await SetSendMailContractCompleteMm(UserJoin)

        alert('계약이 완료 되었습니다.')

        localStorage.removeItem('SignatureDocumentId')
        localStorage.removeItem('SignaturePartcipantsId')
        localStorage.removeItem('SignatureUrl')
        localStorage.removeItem('SignatureExpiredDate')

        setModal(false)
        setSignatureIframeUrl('')
        navigate(URLS.BOOKING_ESTIMATION_COMPLETE_CONTRACT)
      }
      // else {
      //   alert('계약 도중 문제가 발생했습니다.')
      //   setModal(false)
      //   setSignatureIframeUrl('')
      // }
    }
    catch (e) {
      // alert('계약 도중 문제가 발생했습니다.(2)')
      // setModal(false)
      // setSignatureIframeUrl('')
    }


  }

  useEffect(() => {
    window.addEventListener('message', messageHandler);
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('message', messageHandler);
    };

  }, [])



  useEffect(() => {
    async function getData() {
      // let result = await getModusignEmbedLink()
      // let result = await getTemplateDocuments()
      //let result = await getRequestWithTemplate()
      dispatch(openLoading())

      let dataurlLoading = null
      while (true) {
        // console.log('while statement')
        await sleep(1000)
        dataurlLoading = localStorage.getItem('estimate_img_processed')
        if (dataurlLoading !== null && dataurlLoading === 'false') {
          localStorage.setItem('estimate_img_processed', true)
          break;
        }
      }

      dataurlLoading = localStorage.getItem('estimate_img_processed')
      if (dataurlLoading === 'true') {

        let monthNum = getValueByTarget(ContractPeriodOptions, BookingOption?.contractTerms1?.periodOfContract, 'numberValue')
        let isMonthly = (BookingOption?.subscription === 'monthly')

        // 모두싸인 데이터 초기화
        let EstimateInfo = {
          CompanyName: (BookingOption?.userInformation?.userType === 'personal') ?
            BookingOption?.userInformation?.user?.name : BookingOption?.userInformation?.user?.companyName,

          ProductAllAmount: transFormWon2((BookingOption?.totalAmount * 1.1)),
          ContractStartDate: moment(BookingOption?.contractTerms1?.schedule).format('yyyy년 MM월 DD일'),
          ContractEndDate: isMonthly ? moment(BookingOption?.contractTerms1?.schedule).add(monthNum, 'months').add(-1, 'days').format('yyyy년 MM월 DD일') : moment(BookingOption?.contractTerms1?.schedule).add(1, 'years').add(-1, 'days').format('yyyy년 MM월 DD일'),
          ContractMonth: isMonthly ? getValueByTarget(ContractPeriodOptions, BookingOption?.contractTerms1?.periodOfContract, 'title') : '1년',
          CompanyName2: BookingOption?.userInformation?.user?.name,
          BusinessCode: (BookingOption?.userInformation?.userType === 'personal') ?
            '' : getBusinessCodeFormat(BookingOption?.userInformation?.user?.businessRegistrationId),
          Address: (BookingOption?.userInformation?.userType === 'personal') ?
            BookingOption?.userInformation?.user?.personalAddress + ' ' + BookingOption?.userInformation?.user?.personalAddressDetail : BookingOption?.userInformation?.user?.businessAddress?.address1 + ' ' + BookingOption?.userInformation?.user?.businessAddress?.address2,
          ContractName: BookingOption?.userInformation?.user?.name,
          EstimateImage: localStorage.getItem('estimate_img')
        }

        let cookieObj = getEstimateCookieDataAndDecrypt()
        let reqData = {
          templateId: "aa8e5680-8845-11ee-87ba-97646ef4c31c",
          document: {
            participantMappings: [
              {
                excluded: false,
                signingMethod: {
                  type: "SECURE_LINK",
                  value: cookieObj.EmailAddress
                },
                signingDuration: 20160,
                locale: "ko",
                name: EstimateInfo.ContractName,
                role: "고객"
              }
            ],
            requesterInputMappings: [
              { dataLabel: "CompanyName", value: EstimateInfo.CompanyName },
              { dataLabel: "ProductAllAmount", value: EstimateInfo.ProductAllAmount },
              { dataLabel: "ContractStartDate", value: EstimateInfo.ContractStartDate },
              { dataLabel: "ContractEndDate", value: EstimateInfo.ContractEndDate },
              { dataLabel: "ContractMonth", value: EstimateInfo.ContractMonth },
              { dataLabel: "CompanyName2", value: EstimateInfo.CompanyName },
              { dataLabel: "BusinessCode", value: EstimateInfo.BusinessCode },
              { dataLabel: "Address", value: EstimateInfo.Address },
              { dataLabel: "NowDate", value: moment().format('yyyy년 MM월 DD일') },
              { dataLabel: "ContractName", value: EstimateInfo.ContractName },
              { dataLabel: "EstimateImage", value: { base64: EstimateInfo.EstimateImage } }
            ],
            title: "2023 서명테스트"
          },
        }


        let result = await getModusignContractDraft(reqData)

        setIframeUrl(result.embeddedUrl + '&mode=preview')
        setPreviewUrl(result.embeddedUrl + '&mode=preview')
      }

      const getTransformStr = (viewport_width, left_padding, right_padding, scale_factor, iframe_width) => {
        
        //  실제 사용 가능한 너비 (padding을 제외한 너비)
        let available_width = viewport_width - left_padding - right_padding

        //  iframe이 축소된 후의 너비
        let scaled_iframe_width = iframe_width * scale_factor

        // # 가운데 정렬을 위해 필요한 translation (translateX)
        // # (사용 가능한 너비 - 축소된 iframe 너비) / 2
        let translate_x = (available_width - scaled_iframe_width) / 2

        // # translateY는 0이 될 것이므로, scale에 영향을 주지 않음
        let translate_y = 0

        // # translateX 값을 퍼센테이지로 변환하기 위해 전체 뷰포트 너비 대비 계산
        let translate_x_percentage = (translate_x / viewport_width) * 100

        // # translateY 값도 퍼센테이지로 변환 (여기서는 0이므로 0%)
        let translate_y_percentage = 0

        return `translate(${translate_x_percentage}%, ${translate_y_percentage}%) scale(${scale_factor})` 
      }


      // 모바일 이라면 양옆 패딩을 줄여서 계약서가 한번에 보이도록 세팅
      if (!isPC) {
        let containerWidth = document.getElementById('bookingboxId').offsetWidth
        let contentWidth = document.getElementById('iframe').offsetWidth
        let bookingBoxDoc = document.getElementById('bookingboxId')
        

        // containerWidth가 380 미만일때
        if (containerWidth < 380) {
          let root = document.getElementById('root')
          let iframeDoc = document.getElementById('iframe')
          let steplistDoc = document.getElementById('step-list')

          
          // iframe의 퍼센트를 80%로 낮추고
          iframeDoc.style.transform = getTransformStr(containerWidth, 25, 25, 0.8, 400)
          iframeDoc.style.transformOrigin = 'left top'
          // iframe의 width를 380으로 설정
          iframeDoc.style.width = '400px'
          iframeDoc.style.height = '600px'
          root.style.overflowX = 'hidden'
          steplistDoc.style.width = containerWidth-50 + 'px'
          
          // <iframe id="iframe" src={iframeUrl} style={{ width: '100%', height: '100%', border: '1px solid #a5a5a5' }} />


          
        }
        else {
          let padding = (containerWidth - 380) / 2; // 양쪽에 동일한 패딩을 설정하기 위해 2로 나눕니다.

          document.getElementById('bookingboxId').style.paddingLeft = padding + 'px';
          document.getElementById('bookingboxId').style.paddingRight = padding + 'px';
  
          // iframe은 각각 별도의 URL이기 때문에 Cross-origin 문제로 인해 접근이 불가
          // 따라서 스크롤바도 없애지 못함
          // 이거 관련해서 없애달라고 얘기해도 모두싸인 답변은 못한다 일듯.
          // 전에 + 버튼, - 버튼을 이쪽에서 수정할 수 있도록 요청했지만 거절했음.  
        }

        bookingBoxDoc.style.marginBottom = '150px'


        
      }


      dispatch(closeLoading())




      //"https://app.modusign.co.kr/embedded-draft/c1116370-55c3-11ee-93a6-9fcbccb24f6e?at=eyJhbGciOiJIUzI1NiIsInR5cCI6ImFwcGxpY2F0aW9uL2F0K2p3dCJ9.eyJzY29wZSI6WyIqKiJdLCJ1cmxQYXRocyI6WyIqKiJdLCJpYXQiOjE2OTUwMDA5NzMsImV4cCI6MTY5NTAxNTM3MywiYXVkIjoiKi5tb2R1c2lnbi5jby5rciIsImlzcyI6ImF1dGgubW9kdXNpZ24uY28ua3IiLCJzdWIiOiJVU0VSfGM1YmVmZGYyLTRhOWUtMTFlZC1hZjIwLTIzZGQ0MDIzMDBmYyJ9.I777pFY_CKqBIQ-8kidE46hNhFPwutCib9gYfeA7dmA"

      /*
      1. request-with-template에 아래처럼 호출한다.
      requesterInputMappings 속성에 미리 템플릿에 정의한 데이터가 들어간다.

      [REQUEST]      
      {
        "document": {
          "participantMappings": [
            {
              "excluded": false,
              "signingMethod": {
                "type": "SECURE_LINK",
                "value": "rise@mosiler.com"
              },
              "signingDuration": 20160,
              "locale": "ko",
              "name": "김상우",
              "role": "고객"
            }
          ],
          "requesterInputMappings": [
            {"dataLabel": "data1", "value": "hello"},
            {"dataLabel": "data2", "value": "wolrd"},
            {"dataLabel": "data3", "value": "yurucamp"},
            {"dataLabel": "data4", "value": "bokunohero"},
            {"dataLabel": "data5", "value": "monsterhunter"}
          ],
          "title": "2023 서명테스트"
        },
        "templateId": "eb934a30-52b9-11ee-aebd-7d78b1afc970"
      }

      [RESPONSE]      
      {
        "id": "881794a0-5306-11ee-be3e-a5d70021026e",
        "title": "2023 서명테스트",
        "status": "ON_PROCESSING",
        "requester": {
            "email": "rise@mosiler.com",
            "name": "김상우"
        },
        "participants": [
            {
                "id": "88326fa0-5306-11ee-8c4a-eb8161e14e3c",
                "name": "김상우",
                "signingOrder": 1,
                "signingDue": {
                    "valid": false,
                    "datetime": null
                },
                "signingMethod": {
                    "type": "SECURE_LINK",
                    "value": "rise@mosiler.com"
                },
                "locale": "ko"
            }
        ],
        "currentSigningOrder": 0,
        "signings": [],
        "accessibleByParticipant": true,
        "abort": null,
        "metadatas": [],
        "file": {
            "downloadUrl": "https://api.modusign.co.kr/documents/881794a0-5306-11ee-be3e-a5d70021026e/file?signedUrlToken=documents%2Fc5befdf2-4a9e-11ed-af20-23dd402300fc%2F87c81511-5306-11ee-953d-01085af0bf99.pdf%3FX-Amz-Algorithm%3DAWS4-HMAC-SHA256%26X-Amz-Credential%3DAKIA3EQMQCJYW3XJ6IGM%252F20230914%252Fap-northeast-2%252Fs3%252Faws4_request%26X-Amz-Date%3D20230914T135642Z%26X-Amz-Expires%3D600%26X-Amz-Signature%3Dc7555dcbe624523574c8512f84bb5e530bc2747b895a62102b96a37956fc4596%26X-Amz-SignedHeaders%3Dhost"
        },
        "auditTrail": null,
        "updatedAt": "2023-09-14T13:56:42.000Z",
        "createdAt": "2023-09-14T13:56:41.000Z"
    }


    2. https://api.modusign.co.kr/documents/881794a0-5306-11ee-be3e-a5d70021026e/participants/88326fa0-5306-11ee-8c4a-eb8161e14e3c/embedded-view
    호출
    [RESPONSE] 
    {
      "embeddedUrl": "https://app.modusign.co.kr/embedded-participant?di=881794a0-5306-11ee-be3e-a5d70021026e&pi=88326fa0-5306-11ee-8c4a-eb8161e14e3c&ci=cmlzZUBtb3NpbGVyLmNvbQ&sm=SECURE_LINK&token=sha256.ex2HGsDcgOSLh90wviCZTVfLidv1dU6s2towT0-059c&expiry=1694700595828"
  }

    3. 2번의 Response 데이터를 iframe에 뿌림
    https://app.modusign.co.kr/embedded-participant?di=881794a0-5306-11ee-be3e-a5d70021026e&pi=88326fa0-5306-11ee-8c4a-eb8161e14e3c&ci=cmlzZUBtb3NpbGVyLmNvbQ&sm=SECURE_LINK&token=sha256.ex2HGsDcgOSLh90wviCZTVfLidv1dU6s2towT0-059c&expiry=1694700595828


    4. iframe에 
    모바일에서는 새로운창이 뜨면서 진행되어야 하지 않나? iframe이라면 뭔가...
    
    5. 무튼 서명이 완료 되면 메일로 관련 내용이 옴..

    */

    }
    getData()
  }, [])





  const {
    handleSubmit, setValue, register, setError, formState: { errors } } = useForm({
      defaultValues: {
        savedSign: selectedOptions?.savedSign,
      }
    });

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;

    // if (!file) {
    //   validCheck = false;
    //   setError('savedSign', {
    //     type: 'focus',
    //     name: 'savedSign',
    //     message: formErrorMessage.signError
    //   }, { shouldFocus: true });
    // }

    if (validCheck) {
      setSelectedOptions((prevState) => ({
        ...prevState,
        savedSign: data?.savedSign,
      }));
      dispatch(updateSavedSign({
        savedSign: data?.savedSign ?? selectedOptions?.savedSign,
      }))
      navigate(URLS.BOOKING_ESTIMATION_COMPLETE_CONTRACT)
    }
  })

  const handleImages = (e) => {
    const target = {
      images: Array.from(e.target.files),
    };
    if (target) {
      setFile(target);
      setError('savedSign', null);
      setValue('savedSign', target)
    }
  };


  const ProcessSignature = () => {


    /*
      1. 계약서 초안 URL
      // https://api.modusign.co.kr/embedded-drafts/create-with-template

      1. 로딩을 띄운다 -> 팝업을 띄운다 -> LocalStorage확인후 모두싸인 API를 호출한다.
      2. 서명하기를 누르면  아래 처리를 한다.
        if (기존에 서명하기한 정보가 localStorage에 남아있나?) {
          if (만료 시간을 넘겼는가?) {
            * 모두 싸인 API를 호출하여 데이터 취득
            * 데이터 취득 정보를 localStorage에 업데이트
            * 데이터 취득한 정보를 표시
          }
          // 만료시간을 넘기지 않았다면
          else {
            localStorage의 정보를 취득
            localStorage 정보를 표시
          }
        }
        // localStorage에 남아있지 않다면
        else {
          * 모두 싸인 API를 호출하여 데이터 취득
          * 데이터 취득 정보를 localStorage에 업데이트
          * 데이터 취득한 정보를 표시
        }
      */
  }

  const SignatureModal = () => {
    const pdfDown = localStorage.getItem('SignaturePdfDownloadUrl')
    return (
      <Modal3
        title={'서명하기'}
        style={{}}
      >
        <div className={classNames('main-set')}>
          <iframe id="iframe2" src={signatureIframeUrl} style={{ width: '100%', height: '650px', border: '1px solid #a5a5a5', transform: 'scale(1)' }} />
        </div>
        <div className={classNames('button-set')}>

          {pdfDown?.length && (<Button
            // styleType={'secondary'}
            onClick={async () => {
              window.open(pdfDown, '_blank')
            }}
          >
            PDF 다운로드
          </Button>)}
          <Button
            // styleType={'secondary'}
            onClick={async () => {
              setModal(false)
              setSignatureIframeUrl('')
              setIframeUrl(previewUrl)
              if (isPC) {
                document.getElementById('bookingboxId').style.paddingLeft = 50 + 'px';
                document.getElementById('bookingboxId').style.paddingRight = 50 + 'px';
              }
              else {
                document.getElementById('bookingboxId').style.paddingLeft = 25 + 'px';
                document.getElementById('bookingboxId').style.paddingRight = 25 + 'px';
              }
            }}
          >
            닫기
          </Button>
        </div>

      </Modal3>
    )
  }

  const handleContractDocView = async () => {
    // 로딩
    dispatch(openLoading())
    let SignatureUrl = localStorage.getItem('SignatureUrl')
    let SignatureExpiredDate = localStorage.getItem('SignatureExpiredDate')

    let monthNum = getValueByTarget(ContractPeriodOptions, BookingOption?.contractTerms1?.periodOfContract, 'numberValue')
    let isMonthly = (BookingOption?.subscription === 'monthly')

    // 모두싸인 데이터 초기화
    let EstimateInfo = {
      CompanyName: (BookingOption?.userInformation?.userType === 'personal') ?
        BookingOption?.userInformation?.user?.name : BookingOption?.userInformation?.user?.companyName,

      ProductAllAmount: transFormWon2(BookingOption?.totalAmount * 1.1),
      ContractStartDate: moment(BookingOption?.contractTerms1?.schedule).format('yyyy-MM-DD'),
      ContractEndDate: isMonthly ? moment(BookingOption?.contractTerms1?.schedule).add(monthNum, 'months').add(-1, 'days').format('yyyy-MM-DD') : moment(BookingOption?.contractTerms1?.schedule).add(1, 'years').add(-1, 'days').format('yyyy-MM-DD'),
      ContractMonth: isMonthly ? getValueByTarget(ContractPeriodOptions, BookingOption?.contractTerms1?.periodOfContract, 'title') : '1년',
      CompanyName2: BookingOption?.userInformation?.user?.name,
      BusinessCode: (BookingOption?.userInformation?.userType === 'personal') ?
        '' : getBusinessCodeFormat(BookingOption?.userInformation?.user?.businessRegistrationId),
      Address: (BookingOption?.userInformation?.userType === 'personal') ?
        BookingOption?.userInformation?.user?.personalAddress + ' ' + BookingOption?.userInformation?.user?.personalAddressDetail : BookingOption?.userInformation?.user?.businessAddress?.address1 + ' ' + BookingOption?.userInformation?.user?.businessAddress?.address2,
      ContractName: BookingOption?.userInformation?.user?.name,
      EstimateImage: localStorage.getItem('estimate_img')
    }

    let cookieObj = getEstimateCookieDataAndDecrypt()

    let reqObj = {

      templateId: "aa8e5680-8845-11ee-87ba-97646ef4c31c",
      document: {
        participantMappings: [
          {
            excluded: false,
            signingMethod: {
              type: "SECURE_LINK",
              value: cookieObj.EmailAddress
            },
            signingDuration: 20160,
            locale: "ko",
            name: EstimateInfo.ContractName,
            role: "고객"
          }
        ],

        requesterInputMappings: [
          { dataLabel: "CompanyName", value: EstimateInfo.CompanyName },
          { dataLabel: "ProductAllAmount", value: EstimateInfo.ProductAllAmount },
          { dataLabel: "ContractStartDate", value: EstimateInfo.ContractStartDate },
          { dataLabel: "ContractEndDate", value: EstimateInfo.ContractEndDate },
          { dataLabel: "ContractMonth", value: EstimateInfo.ContractMonth },
          { dataLabel: "CompanyName2", value: EstimateInfo.CompanyName },
          { dataLabel: "BusinessCode", value: EstimateInfo.BusinessCode },
          { dataLabel: "Address", value: EstimateInfo.Address },
          { dataLabel: "NowDate", value: moment().format('yyyy년 MM월 DD일') },
          { dataLabel: "ContractName", value: EstimateInfo.ContractName },
          { dataLabel: "EstimateImage", value: { base64: EstimateInfo.EstimateImage } }
        ],
        carbonCopies: [{
          contact: cookieObj.EmailAddress
        }],
        title: `${moment().format('yyyyMMDD')}_${EstimateInfo.ContractName}_월간모시러_계약`
      },
    }

    let expired = ''
    let currentTime = moment().tz('Asia/Seoul')
    if (SignatureExpiredDate !== null) {
      expired = moment(SignatureExpiredDate).tz('Asia/Seoul')
    }
    // console.log(reqObj)


    if (SignatureUrl !== null || SignatureExpiredDate !== null) {
      if ((currentTime.diff(expired, 'minutes') >= 10)) {
        let result = await getRequestWithTemplate(reqObj)
        //let embeddedData = await getEmbeddedViewUrl(result?.id, result?.participants[0]?.id, 'https://localhost:3000/booking/modu-redirect')
        let embeddedData = await getEmbeddedViewUrl(result?.id, result?.participants[0]?.id, 'https://newestimate2.mosiler.com/booking/modu-redirect')

        await SetModusignInfo({
          ...getEstimateCookieDataAndDecrypt(),
          DocumentId: result?.id,
          ParticipantsId: result?.participants[0]?.id
        })


        localStorage.setItem('SignatureDocumentId', result?.id)
        localStorage.setItem('SignaturePartcipantsId', result?.participants[0]?.id)
        localStorage.setItem('SignatureUrl', embeddedData.embeddedUrl)
        localStorage.setItem('SignatureExpiredDate', result?.createdAt)
        localStorage.setItem('SignaturePdfDownloadUrl', result?.file.downloadUrl)
        setSignatureIframeUrl(embeddedData.embeddedUrl)
        setModal(true)
        dispatch(closeLoading())
      }
      else {
        // 아래 값을 저장할 게 아니라 문서 ID, participants[0]?.id를 저장해야할 것.
        let docid = localStorage.getItem('SignatureDocumentId')
        let partid = localStorage.getItem('SignaturePartcipantsId')

        await SetModusignInfo({
          ...getEstimateCookieDataAndDecrypt(),
          DocumentId: docid,
          ParticipantsId: partid
        })

        //let embeddedData = await getEmbeddedViewUrl(docid, partid, 'https://localhost:3000/booking/modu-redirect')
        let embeddedData = await getEmbeddedViewUrl(docid, partid, 'https://newestimate2.mosiler.com/booking/modu-redirect')
        setSignatureIframeUrl(embeddedData.embeddedUrl)
        setModal(true)
        dispatch(closeLoading())
      }
    }
    else {
      let result = await getRequestWithTemplate(reqObj)
      let embeddedData = await getEmbeddedViewUrl(result?.id, result?.participants[0]?.id, 'https://newestimate2.mosiler.com/booking/modu-redirect')
      //let embeddedData = await getEmbeddedViewUrl(result?.id, result?.participants[0]?.id, 'https://localhost:3000/booking/modu-redirect')

      await SetModusignInfo({
        ...getEstimateCookieDataAndDecrypt(),
        DocumentId: result?.id,
        ParticipantsId: result?.participants[0]?.id
      })

      localStorage.setItem('SignatureDocumentId', result?.id)
      localStorage.setItem('SignaturePartcipantsId', result?.participants[0]?.id)
      localStorage.setItem('SignatureUrl', embeddedData.embeddedUrl)
      localStorage.setItem('SignatureExpiredDate', result?.createdAt)
      localStorage.setItem('SignaturePdfDownloadUrl', result?.file?.downloadUrl)
      setSignatureIframeUrl(embeddedData.embeddedUrl)
      setModal(true)
      dispatch(closeLoading())
    }

    setIframeUrl('')
  }
  

  return (
    <>
      {isModal && SignatureModal()}
      <div className={classNames('booking-wrap')}>
        <PageTitle title={'계약서 확인'} subtitle={'계약서를 확인해 주세요'} />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          {/* <div className={classNames('booking-form-list')}> */}
          <div style={{ width: '100%' }}>
            <div className={'booking-form-item'}>
              <div id={'contract-slide'} className={classNames('contract-slide')} style={!isPC ? { height: '500px' } : { height: '700px' }}>
                <iframe id="iframe" src={iframeUrl} style={{ width: '100%', height: '100%', border: '1px solid #a5a5a5' }} />
                {/* , transform: !isPC ? 'scale(0.85)' : 'scale(1)'  */}
              </div>
              <div className={classNames('contract-confirm-controls')}>
                <div className={classNames('button-set')}>
                {isPC && (<>
                  <Button
                    styleType={'tertiary'}
                    onClick={handleContractDocView}>

                    계약하기
                  </Button>
                  <Button
                    styleType={'tertiary'}
                    onClick={async () => {
                      navigate(URLS.BOOKING_ESTIMATION_USER_INFORMATION)
                    }}>

                    이전
                  </Button>
                  </>)}
                  


                  {/* <div className={'file-form-set'}>
                    <div className={classNames('file-button-set')}>
                      <input className='hidden' ref={inputRef} type='file' accept='image/*' multiple onChange={handleImages} />
                      <Button
                        styleType='icon'
                        onClick={() => inputRef.current.click()}
                        disabled={isExistsImages}
                      >
                        <Icon icon={'attach'} />
                        서명 불러오기
                      </Button>
                    </div>
                    {file && <span className={'file-name'}>{file?.images[0].name}</span>}
                  </div> */}
                </div>

                {/* <div className={classNames('contact-slide-controls')}>
                  <span>
                    <Icon icon={'arrow_left'} />
                  </span>
                  <span className={classNames('grey-1', isPC ? 'txt-large' : 'txt-regular')}>
                    1/3
                  </span>
                  <span>
                    <Icon icon={'arrow_right'} />
                  </span>
                </div> */}
              </div>
              {errors.savedSign && !file && (
                <span className={classNames('error')}>
                  {errors.savedSign.message}
                </span>
              )}
            </div>
          </div>



          {!isPC && (
            <BookingFormSummary
              hasAmonut={false}
              description={description}>
              <div className={classNames('button-set')}>
                <Button
                  onClick={
                    () => {
                      unmount2();
                      navigate(URLS.BOOKING_ESTIMATION_USER_INFORMATION);
                    }
                  }
                >이전</Button>
                <Button
                  styleType={'secondary'}
                  onClick={handleContractDocView}
                >계약하기</Button>
              </div>
            </BookingFormSummary>
          )}

        </form>

        {/* DATAURL 정보를 localstorage에 저장한다. */}
        <div style={{ width: '1px', height: '1px', visibility:'hidden' }}>
          <iframe src={URLS.BOOKING_ESTIMATION_DOWNLOAD_DATAURL} style={{ width: '1px', height: '1px', }} />
        </div>

      </div>
    </>
  )
}

export default ConfirmContract