import { format } from 'date-fns';
import { OptionDict, OptionDictDriverDispatch } from './EstimateFormData';
import { formErrorMessage } from './alertMessage';

export const timeList = [
  '오전 12시',
  '오전 1시',
  '오전 2시',
  '오전 3시',
  '오전 4시',
  '오전 5시',
  '오전 6시',
  '오전 7시',
  '오전 8시',
  '오전 9시',
  '오전 10시',
  '오전 11시',
  '오후 12시',
  '오후 1시',
  '오후 2시',
  '오후 3시',
  '오후 4시',
  '오후 5시',
  '오후 6시',
  '오후 7시',
  '오후 8시',
  '오후 9시',
  '오후 10시',
  '오후 11시',
];

export const hourList = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12
]

export const hourList2 = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]

export const minuteList2 = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
]


export const hourList3 = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]

export const minuteList3 = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
]

export const minuteList = ['0분', '10분', '20분', '30분', '40분', '50분'];

/**
 * 시간 오전/오후 00시 형태로 변경
 * @param hour: number ex) 10, 18, 23
 * @returns 오전 10시, 오후 6시, 오후 11시 형태
 * */
export const transformHour = hour => {
  const period = hour >= 12 ? '오후' : '오전';
  const twelveHourFormat = hour % 12 === 0 ? 12 : hour % 12;
  return `${period} ${twelveHourFormat}시`;
};

/**
 * 분 10분 단위로 올림 형태로 변경
 * @param minute: number ex) 13, 23
 * @returns 20분, 30분 형태
 * */

export const transformMinute = minute => {
  const roundedMinute = minute >= 50 ? 0 : Math.ceil(minute / 10) * 10;
  return `${roundedMinute}분`;
};

/**
 * 0000.00.00 형태로 변경
 * @param date: object ex) Wed Jul 19 2023 13:20:00 GMT+0900 (한국 표준시)
 * return 2023.07.19
 * */
export const transformDate = date => {
  const formattedDate = format(new Date(date), 'yyyy.MM.dd');
  return formattedDate;
};

/**
 * 000,000,000 형태로 변경
 * @param amount: int ex) 20000000
 * return 20,000,000원
 * */
export const transFormWon = amount => {
  try {
    amount = parseFloat(amount);
  } catch (error) {
    return '유효하지 않은 입력입니다.';
  }

  if (amount === 0) {
    return '0원';
  }

  let amountStr = amount.toLocaleString(undefined, { maximumFractionDigits: 0 });

  amountStr += '원';
  return amountStr;
}

export const transFormWon2 = amount => {
  try {
    amount = parseFloat(amount);
  } catch (error) {
    return '유효하지 않은 입력입니다.';
  }

  if (amount === 0) {
    return '0원';
  }

  let amountStr = amount.toLocaleString(undefined, { maximumFractionDigits: 0 });


  return amountStr;
}

/**
 * 010-1234-1234 형식으로 변경
 * @param event: string | number
 * @returns 010-1234-1234
 */
export const handlePhoneChange = (event, setValue, register) => {
  const phone = event.target.value;
  setValue(
    register,
    phone
      .replace(/[^0-9]/g, '')
      .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]{4})?([0-9]{4})$/, '$1-$2-$3')
      .replace('--', '-'),
  );
};

/**
 * 00:00 형식으로 변경
 * @param event: string | number
 * @returns 00:00
 */
export const handleTimeChange = (event, setValue, register, setError) => {
  const inputValue = event.target.value;
  const formattedValue = inputValue
    .replace(/[^0-9]/g, '') // 입력값에서 숫자 이외의 문자를 제거
    .replace(/^([0-1]?[0-9]|2[0-3])([0-5][0-9])$/, (match, hour, minute) => {
      const hour12 = hour > 12 ? hour - 12 : hour;
      return `${hour12}:${minute}`;
    });

  setValue(register, formattedValue);

};

/**
 * 00:00 형식으로 변경
 * @param event: string | number
 * @returns 00:00
 */
export const handleTimeChange2 = (event, setValue, register, setError) => {
  const inputValue = event.target.value;
  const formattedValue = inputValue
    .replace(/[^0-9]/g, '') // 입력값에서 숫자 이외의 문자를 제거
    .replace(/^([0-1]?[0-9]|2[0-3])([0-5][0-9])$/, (match, hour, minute) => {

      return `${hour}:${minute}`;
    });

  setValue(register, formattedValue);

};

/**
 * totalAmount 계산
 * return totalAmount
 * */
export const calculateTotalPrice = (hasPaymentAmountOptions) => {
  let totalPrice = 0;

  for (const selectedValue of Object.values(hasPaymentAmountOptions)) {
    if (selectedValue) {
      const priceInfo = OptionDict[selectedValue];
      console.log(`${selectedValue} ${priceInfo.amount} ${priceInfo.discount}`)
      if (priceInfo) {
        const { amount = 0, discount = 0 } = priceInfo;
        totalPrice += +amount - +discount;
      }
    }
  }

  // 이부분이 왜 500000인지는 확인필요.
  if (hasPaymentAmountOptions.departure !== '') {
    totalPrice += 0;
  }

  return totalPrice;
};

/**
 * 계약기간 별도 계산
 * return totalAmount
 * */
export const calculateContractMonthPrice = (selectedMonth, array) => {
  let selected = array.filter((data) => selectedMonth === data.value)
  return -selected[0]?.discount
};


/**
 * totalAmount 계산
 * return totalAmount
 * */
export const calculateDriverDispatchTotalPrice = (hasPaymentAmountOptions) => {
  let totalPrice = 0;

  for (const selectedValue of Object.values(hasPaymentAmountOptions)) {
    if (selectedValue) {
      const priceInfo = OptionDictDriverDispatch[selectedValue];
      if (priceInfo) {
        const { amount = 0, discount = 0 } = priceInfo;
        totalPrice += +amount - +discount;
      }
    }
  }

  // 이부분이 왜 500000인지는 확인필요.
  if (hasPaymentAmountOptions.departure !== '') {
    totalPrice += 0;
  }

  return totalPrice;
};

