import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { useSelector } from 'react-redux';
import ListWithCheckIcon from '../../atoms/ListWithCheckIcon';
import Button from '../../atoms/Button';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';
import { transFormWon } from '../../../utils/numbers';
import { getCalcSalaryDetailEstimateRecruit } from '../../../lib/DriverDispatchApi';

/**
 * 계약 완료 페이지
 * name : completeContract
 * */
const CompleteContract = () => {
  const navigate = useNavigate();
  const { BookingOption } = useSelector((state) => state);
  const [description, setDescription] = useState([])
  const { DriverDispatchOption } = useSelector((state) => state);
  const [titleDesc, setTitleDesc] = useState({
    title: '',
    subTitle: ''
  })




  // SetDriverRecruit(getCalcSalaryDetailEstimateRecruit(Number(salary) * 10000))
  useEffect(() => {
    const CompleteContractDescription = []
    if (DriverDispatchOption?.subscription === 'recruit') {
      CompleteContractDescription.push(`채용 상품 계약이 완료되었습니다.`)
      CompleteContractDescription.push(`계약서는 입력해 주신 메일로 발송되었습니다.`)
      CompleteContractDescription.push(`채용 수수료 ${transFormWon(getCalcSalaryDetailEstimateRecruit(Number(DriverDispatchOption?.basicInfo2?.selectedSalary) * 10000)?.step2?.total)}은 채용 인원 확정 후 청구됩니다.<br/>(채용 수수료는 채용 확정된 인원에 따라 변동될 수 있습니다.)`)
      CompleteContractDescription.push(`입력 정보에 따라 채용 인원 선별 후 담당자가 순차적으로 연락드리겠습니다.`)
      setTitleDesc({
        title: '계약 완료',
        subTitle: '계약이 완료되었습니다.'
      })
    }
    else {
      CompleteContractDescription.push(`파견 상품 의뢰가 완료되었습니다.`)
      CompleteContractDescription.push(`입력 정보에 따라 파견 인원 선별 후 담당자가 순차적으로 연락드리겠습니다.`)
      CompleteContractDescription.push(`파견 인원이 확정된 후 계약서를 발송해 드리겠습니다.`)


      setTitleDesc({
        title: '파견 의뢰 완료',
        subTitle: '파견 의뢰가 완료되었습니다.'
      })
    }

    // const CompleteContractDescription = [
    //    '수행기사/파견 상품의 계약이 완료 되었습니다.',
    //   '날인 해주신 계약서는 담당자님 메일로 발송이 되었습니다.',
    //   `입금 금액은 <i class='blue txt-large'>${transFormWon(BookingOption?.totalAmount)}</i> 이며 <br>
    //    입금 계좌는 (주)버틀러 신한은행 123-456-7890 입니다. <br>
    //    결제 기한: <i class='blue txt-large'>2023년 8월 20일 19시</i>`,
    //   '입금이 확인 되면 예약 계정을 생성해 드립니다.',
    //   `<a href='${URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGIN}' class='txt-large underline' target='_blank'>예약 페이지 둘러보기</a>`
    // ]
    setDescription(CompleteContractDescription)
  }, [BookingOption])

  return (
    <>
      {description && (
        <div className={classNames('booking-wrap')}>
          <PageTitle title={titleDesc.title} subtitle={titleDesc.subTitle} />
          <div className={classNames('confirm-booking-wrap')}>
            <div className={classNames('confirm-booking-content')}>
              <ListWithCheckIcon listItem={description} />
            </div>
          </div>

          {/* <BookingFormSummary
            hasAmonut={false}
          >
            <div className={classNames('button-set')}>
              <Button
                styleType={'secondary'}
                onClick={() => navigate(URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGIN)}
              >예약 페이지로 이동</Button>
            </div>
          </BookingFormSummary> */}
        </div>
      )}
    </>
  )
}

export default CompleteContract;