import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, useRef, useState } from 'react';


const Textarea = forwardRef(
  (
    {
      type = 'text',
      variant = 'underline',
      placeholder,
      error = false,
      classes,
      value,
      readOnly = false,
      onChange,
      onInvalid,
      required,
      setValue,
      setValueName,
      ...props
    },
    ref,
  ) => {
    const defaultRef = useRef();
    const inputRef = ref ?? defaultRef;
    const [isFocused, setIsFocused] = useState(false);
    return (
      <div
        className={classNames(
          error && 'error',
          classes,
          'input'
        )}
      >
        <textarea
          ref={inputRef}
          value={value}
          className={classNames('textarea-custom')}
          style={{marginBottom:'7px'}}
          onChange={(e) => {
            onChange(e)
          }}
          placeholder={placeholder}
          readOnly={readOnly}

          autoComplete='new-password'
          onFocus={e => {
            setIsFocused(true);
            props.onFocus?.(e);
          }}
          onBlur={e => {
            setIsFocused(false);
            props.onBlur?.(e);
          }}
          {...props}
        />
        {error.message && <span className={classNames('error')}>{error.message}</span>}
      </div>
    );
  },
);
export default Textarea;

Textarea.propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'search', 'tel']),
  variant: PropTypes.oneOf(['underline', 'white', 'grey']),
  showDelete: PropTypes.bool,
  leftIconNode: PropTypes.node,
  rightIconNode: PropTypes.node,
  classes: PropTypes.string,
};
