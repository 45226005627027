export const BookingEstimateSteps = {
  subscription: {
    index: 1,
    title: '상품 선택'
  },
  usageTime: {
    index: 2,
    title: '이용 시간'
  },
  contractTerms1: {
    index: 3,
    title: '계약 조건'
  },
  vehicleOption: {
    index: 4,
    title: '옵션 선택'
  },
  // contractTerms2: {
  //   index: 5,
  //   title: '이용 정보'
  // },
  confirmEstimate: {
    index: 5,
    title: '견적 확인'
  },
  userInformation: {
    index: 6,
    subIndex: 1,
    title: '계약서 확인',
  },
  // paymentMethod: {
  //   index: 7,
  //   subIndex: 2,
  //   title: '계약서 확인'
  // },
  confirmContract: {
    index: 6,
    subIndex: 2,
    title: '계약서 확인'
  },
  completeContract: {
    index: 7,
    title: '계약 완료'
  }
}

export const DriverDispatchEstimateSteps = {
  subscription: {
    index: 1,
    title: '상품 선택'
  },
  usageTime: {
    index: 2,
    title: '상품 선택'
  },
  basicInfo1: {
    index: 3,
    subIndex: 1,
    title: '기본 정보'
  },
  basicInfo2: {
    index: 3,
    subIndex: 2,
    title: '기본 정보'
  },
  basicInfo3: {
    index: 3,
    subIndex: 3,
    title: '기본 정보'
  },
  option: {
    index: 4,
    title: '옵션 선택'
  },
  estimate: {
    index: 5,
    title: '견적 확인'
  },

  recruitRequest: {
    index: 6,
    subIndex: 1,
    title: '채용 의뢰서'
  },
  userInformation: {
    index: 6,
    subIndex: 2,
    title: '채용 의뢰서'
  },
  confirmContract: {
    index: 7,
    title: '채용 의뢰서 확인'
  },
  confirmDispatchRequest: {
    index: 8,
    title: '채용 의뢰서 확인'
  },
  completeContract: {
    index: 9,
    title: '계약 완료'
  }

}

export const DriverDispatchEstimateStepsRecruit = {
  subscription: {
    index: 1,
    title: '상품 선택'
  },
  usageTime: {
    index: 2,
    title: '상품 선택'
  },
  // basicInfo1: {
  //   index: 3,
  //   subIndex: 1,
  //   title: '기본 정보'
  // },
  basicInfo2: {
    index: 3,
    subIndex: 1,
    title: '기본 정보'
  },
  basicInfo3: {
    index: 3,
    subIndex: 2,
    title: '기본 정보'
  },
  option: {
    index: 4,
    title: '옵션 선택'
  },
  estimate: {
    index: 5,
    title: '견적 확인'
  },
  recruitRequest: {
    index: 6,
    subIndex: 1,
    title: '계약 정보'
  },
  userInformation: {
    index: 6,
    subIndex: 2,
    title: '계약 정보'
  },
  confirmContract: {
    index: 7,
    title: '계약서 확인'
  },
  completeContract: {
    index: 8,
    title: '계약 완료'
  }

}

export const DriverDispatchEstimateStepsDispatch = {
  subscription: {
    index: 1,
    title: '상품 선택'
  },
  usageTime: {
    index: 2,
    title: '상품 선택'
  },
  // basicInfo1: {
  //   index: 3,
  //   subIndex: 1,
  //   title: '기본 정보'
  // },
  basicInfo2: {
    index: 3,
    subIndex: 1,
    title: '기본 정보'
  },
  basicInfo3: {
    index: 3,
    subIndex: 2,
    title: '기본 정보'
  },
  option: {
    index: 4,
    title: '옵션 선택'
  },
  estimate: {
    index: 5,
    title: '견적 확인'
  },

  recruitRequest: {
    index: 6,
    subIndex: 1,
    title: '파견 의뢰 정보'
  },
  userInformation: {
    index: 6,
    subIndex: 2,
    title: '파견 의뢰 정보'
  },

  confirmDispatchRequest: {
    index: 7,
    title: '파견 의뢰서 확인'
  },
  completeContract: {
    index: 8,
    title: '파견 의뢰 완료'
  }

}