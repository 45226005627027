import React from 'react';
import classNames from 'classnames';

const PercentageGraphCustom = ({ child, parent, purechild, originTime, reserveTime, useTime, exceedTimes, overcarriedMinute }) => {

  const baseTime = originTime + overcarriedMinute;

  let usePercent = 0, reservePercent = 0, exceedPercent = 0, remainPercent = 0;
  let useMin = 0, reserveMin = 0, exceedMin = 0, remainMin = 0;


  // 기본 시간 + 연장 시간을 초과 했을 때
  if ((useTime + reserveTime) > baseTime) {
    // 이용완료시간, 예약시간 표시
    // 잔여시간 비표시
    
    usePercent = (useTime / (useTime + reserveTime)) * 100;
    reservePercent = (reserveTime / (useTime + reserveTime)) * 100;
    remainPercent = 0;

    useMin = useTime
    reserveMin = reserveTime


  }
  else {
    // 이용완료시간, 예약시간, 잔여시간 표시
    usePercent = (useTime / baseTime) * 100;
    reservePercent = (reserveTime / baseTime) * 100;
    remainPercent = ((baseTime - useTime - reserveTime) / baseTime) * 100;

    useMin = useTime
    reserveMin = reserveTime
    remainMin = baseTime - useTime - reserveTime
  }







  /*
  <div class="tooltip-container">
              <img src="../assets/icon_question.svg" alt="Your Image Description" style=' width:15px' />&nbsp;&nbsp;
              <div class="tooltip-text">실 이용이 적어도 최소이용시간으로 차감됩니다.</div>
            </div>`
  */

  return (
    <>
      <div className={classNames('mb-8')} style={{ marginBottom: '30px' }}>
        <i className={classNames('blue')}>{Math.floor((useTime + reserveTime) / 60)}시간 {(useTime + reserveTime) % 60}분</i> 이용
        {/*  ({Math.floor(percentage)}%) */}
      </div>

      <div className={classNames('percentage-graph')}>
        <div
          className={classNames('rate tooltip-container')}
          style={{
            width: `${usePercent}%`,
          }}
        >
          {useMin >= 1 && <span className={classNames('tooltip-text small')}>이용 ({Math.floor((useMin) / 60)}시간 {(useMin) % 60}분)</span>}
          {/* <span className={classNames('label-info')}>이용</span> */}
        </div>

        <div
          className={classNames('rate3 tooltip-container')}
          style={{
            left: `${usePercent}%`,
            width: `${reservePercent}%`,
          }}
        >
          {reserveMin >= 1 && <span className={classNames('tooltip-text small')}>예약 ({Math.floor((reserveMin) / 60)}시간 {(reserveMin) % 60}분)</span>}
          {/* <span className={classNames('label-info')}>예약</span> */}
        </div>

        {remainPercent >= 1 && (
          <div
            className={classNames('rate4 tooltip-container')}
            style={{
              left: `${usePercent + exceedPercent + reservePercent}%`,
              width: `${remainPercent}%`,

            }}
          >
            {remainMin >= 1 && <span className={classNames('tooltip-text small')}>잔여 ({Math.floor((remainMin) / 60)}시간 {(remainMin) % 60}분)</span>}
            {/* <span className={classNames('label-info')}>잔여</span> */}
          </div>
        )}

      </div>
    </>
  )
}

export default PercentageGraphCustom
