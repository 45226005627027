import React from 'react';
import classNames from 'classnames';
import Checkbox from '../atoms/CheckBox';

const ReservationItemWithCheckbox = ({
  title,
  checkboxText,
  onCheckChange,
  children }) => {
  return (
    <div className={classNames('reservation-item')}>
      <span>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <span className={classNames('txt-large')}>{title}</span>
          <span className={classNames('txt-large')}>
            <Checkbox
              label={checkboxText}
              onClick={e => {
                onCheckChange(e.target.checked)
              }} />
          </span>
        </div>
      </span>
      <div>
        {children}
      </div>
    </div>
  )
}

export default ReservationItemWithCheckbox
