import classNames from 'classnames';
import useCheckPC from '../../../hooks/useCheckPC';
import '../../../styles/informationRequestForm.css';
import Button from '../../atoms/Button';
import Header from '../../molecules/Header';
import Input from '../../atoms/Input';
import { useForm } from 'react-hook-form';

const InformationRequestForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm();

	const driveStyles = watch('driveStyles', []) || [];
	const dislikes = watch('dislikes', []) || [];

	const driveStylesArray = Array.isArray(driveStyles) ? driveStyles : [];
	const dislikesArray = Array.isArray(dislikes) ? dislikes : [];

	const otherStyleSelected = driveStylesArray.filter((style) => style.startsWith('기타'));
	const otherDislikeSelected = dislikesArray.filter((style) => style.startsWith('기타'));

	const onSubmit = (data) => {
		console.log('Form Submitted:', data);
	};

	const InputSet = ({ label, name, placeholder, validation, white = false }) => (
		<div className={classNames('input-set gap-14')}>
			<span className={classNames('txt-large')}>{label}</span>
			<Input type='text' classes={classNames(white && 'white')} placeholder={placeholder} {...register(name, validation)} error={errors[name]} />
		</div>
	);

	const RadioSet = ({ label, name, options }) => (
		<div className='input-set'>
			<span className={classNames('txt-large')}>{label}</span>
			<div className='radio-set'>
				{options.map((option) => (
					<label key={option.value} className={classNames('radio', watch(name) === option.value && 'selected')}>
						<input type='radio' value={option.value} {...register(name)} />
						{option.label}
					</label>
				))}
			</div>
		</div>
	);

	return (
		<>
			<Header headerTitle='기본 정보 요청서' onClickCloseBtn={() => console.log('닫기')} />
			<div className='request-form-wrap'>
				<form className='request-form' onSubmit={handleSubmit(onSubmit)}>
					<div className='field-wrap'>
						<span className='field-title'>상품정보</span>
						<div className='flex justify-between'>
							<span>상품</span>
							<span>월간 모시러 10일 상품</span>
						</div>
					</div>

					<div className='field-wrap'>
						<span className='field-title'>
							실탑승자 기본정보
							<Button styleType='text' classes='blue bold'>
								수정하기
							</Button>
						</span>
						<InputSet label='실탑승자 이름' name='name' placeholder='이름을 입력하세요' validation={{ required: '이름을 입력해 주세요.' }} />
						<InputSet
							label='휴대전화번호'
							name='phone'
							placeholder='휴대전화번호를 입력하세요'
							validation={{
								required: '휴대전화번호를 입력해 주세요.',
								pattern: {
									value: /^[0-9]{10,11}$/,
									message: '올바른 전화번호를 입력해 주세요.',
								},
							}}
						/>
						<InputSet
							label='이메일 주소'
							name='email'
							placeholder='이메일 주소를 입력하세요'
							validation={{
								required: '이메일 주소를 입력해 주세요.',
								pattern: {
									value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
									message: '올바른 이메일 주소를 입력해 주세요.',
								},
							}}
						/>
					</div>

					<div className='field-wrap'>
						<span className='field-title'>
							위치, 차량 등 정보
							<Button styleType='text' classes='blue bold'>
								수정하기
							</Button>
						</span>
						<InputSet label='차량 모델명' name='carModel' placeholder='차량 모델명을 입력하세요' validation={{ required: '차량 모델명을 입력해 주세요.' }} />
						<RadioSet
							label='선호연령'
							name='ageGroup'
							options={[
								{ value: '30대', label: '30대' },
								{ value: '40대', label: '40대' },
								{ value: '50대', label: '50대' },
								{ value: '상관없음', label: '상관없음' },
							]}
						/>
						<InputSet label='자택주소' name='homeAddress' placeholder='자택주소를 입력하세요' validation={{ required: '자택주소를 입력해 주세요.' }} />
						<InputSet label='회사주소' name='workAddress' placeholder='회사주소를 입력하세요' validation={{ required: '회사주소를 입력해 주세요.' }} />
						<RadioSet
							label='주차가능여부'
							name='parking'
							options={[
								{ value: '불가', label: '불가' },
								{ value: '가능', label: '가능' },
							]}
						/>
					</div>

					<div className='field-wrap'>
						<span className='field-title'>드라이버에게 전달할 내용</span>
						<InputSet white={true} label='호칭' name='title' placeholder='드라이버에게 불릴 호칭을 적어주세요.' validation={{ required: '호칭을 입력해 주세요.' }} />
						<RadioSet
							label='흡연가능여부'
							name='smoking'
							options={[
								{ value: '금연', label: '금연' },
								{ value: '흡연', label: '흡연' },
								{ value: '전자담배', label: '전자담배' },
							]}
						/>
						<InputSet white={true} label='키 수령' name='keyPickup' placeholder='키 위치를 알려주세요.' validation={{ required: '키 위치를 입력해 주세요.' }} />
						<InputSet white={true} label='만나는 장소' name='meetingPlace' placeholder='만나고 싶은 장소를 적어주세요.' validation={{ required: '만나는 장소를 입력해 주세요.' }} />

						{/* 선호 운행 스타일 */}
						<div className='input-set'>
							<span className={classNames('txt-large')}>선호 운행 스타일</span>
							<div className='checkbox-set'>
								{['최대한 안전하게', '최대한 신속하게', '교통상황에 맞춰서 센스있게', '상황에 맞춰 직접 말을 하겠다', '기타1'].map((style) => (
									<label key={style} className={classNames('checkbox', driveStylesArray.includes(style) && 'checked')}>
										<input type='checkbox' value={style} {...register('driveStyles')} />
										{style.startsWith('기타') ? '기타' : style}
									</label>
								))}
							</div>
							{otherStyleSelected.map((style, index) => (
								<div key={style} className='input-set'>
									<textarea placeholder={`기타 요청 사항 ${index + 1}에 대해 작성해 주세요.`} {...register(`otherStyle_${index + 1}`)} className='textarea' />
								</div>
							))}
						</div>

						{/* 싫어하는 것 */}
						<div className='input-set'>
							<span className={classNames('txt-large')}>싫어하는 것</span>
							<div className='checkbox-set'>
								{['흡연', '과도한 향수 또는 섬유유연제', '과도한 안전운전', '선제적 말걸기', '정돈되지 않은 외형', '기타2'].map((style) => (
									<label key={style} className={classNames('checkbox', dislikesArray.includes(style) && 'checked')}>
										<input type='checkbox' value={style} {...register('dislikes')} />
										{style.startsWith('기타') ? '기타' : style}
									</label>
								))}
							</div>
							{otherDislikeSelected.map((style, index) => (
								<div key={style} className='input-set'>
									<textarea placeholder={`기타 요청 사항 ${index + 1}에 대해 작성해 주세요.`} {...register(`otherDislike_${index + 1}`)} className='textarea' />
								</div>
							))}
						</div>

						<RadioSet
							label='결혼유무'
							name='marriage'
							options={[
								{ value: '미혼', label: '미혼' },
								{ value: '기혼', label: '기혼' },
							]}
						/>

						<RadioSet
							label='아이유무'
							name='children'
							options={[
								{ value: '없음', label: '없음' },
								{ value: '있음', label: '있음' },
							]}
						/>

						{/* 주 사용 예정 요일 */}
						<div className='input-set'>
							<span className={classNames('txt-large')}>주 사용 예정 요일</span>
							<div className='checkbox-set grid-2'>
								{['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일'].map((day) => (
									<label key={day} className={classNames('checkbox', driveStylesArray.includes(day) && 'checked')}>
										<input type='checkbox' value={day} {...register('driveStyles')} />
										{day}
									</label>
								))}
							</div>
						</div>

						{/* 이용시간 패턴 */}
						<div className='input-set'>
							<span className={classNames('txt-large')}>이용시간 패턴</span>
							<div className='flex gap-12'>
								<Input white={true} type='text' classes='white' placeholder='00:00' {...register('startTime')} />
								<Input white={true} type='text' classes='white' placeholder='00:00' {...register('endTime')} />
							</div>
						</div>

						{/* 별도 요청사항 */}
						<div className='input-set'>
							<span className={classNames('txt-large')}>별도 요청사항 (선택)</span>
							<textarea placeholder='별도 요청사항이 있다면 입력해주세요.' {...register('etc')} className='textarea' />
						</div>
					</div>
					<Button type='submit' styleType='secondary' classes='bold txt-large p-16'>
						저장하기
					</Button>
				</form>
			</div>
		</>
	);
};

export default InformationRequestForm;
