import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formErrorMessage } from '../../../../utils/alertMessage';
import classNames from 'classnames';
import Input from '../../../atoms/Input';
import Button from '../../../atoms/Button';
import { getNumber2Ciphers, getQueryStringObject, isChkResultSuccess } from '../../../../lib/Util';
import AccountApi from '../../../../lib/AccountApi';
import sha1 from 'sha1'
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../../utils/urls';

/**
 * 회원가입
 * path: reservation/login/register
 * **/

const Register = () => {
  const {
    handleSubmit,
    setValue,
    register,
    setError,
    getValues,
    clearErrors,
    formState: { errors }
  } = useForm();
  const [qsdata, setQsData] = useState('')
  const [kakaoid, setKakaoid] = useState('')
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([])

  const [StepTwoFlag, setStepTwoFlag] = useState(false)
  const [TmpAuthCode, setTmpAuthCode] = useState('')
  const [AuthNumberButtonDisabled, SetAuthNumberButtonDisabled] = useState(false)
  const [NowTime, SetNowTime] = useState('')

  var typingTimer = null;
  var doneTypingInterval = 300;


  useEffect(() => {
    function initData() {
      try {
        let qs = getQueryStringObject()
        if (qs.alskdj.length) {
          setQsData(qs.alskdj)
        }
      }
      catch (e) {
        setQsData('')
      }

      // kakao id가 있을 경우
      try {
        let qs = getQueryStringObject()
        if (qs.kakaoid.length) {
          setKakaoid(qs.kakaoid)
        }
      }
      catch (e) {
        setKakaoid('')
      }
    }
    initData()
  }, [])


  const getUserId = (data) => {
    let retData = ''
    if (kakaoid && kakaoid.length) {
      retData = 'kakao@' + kakaoid
    }
    else {
      retData = data
    }
    return retData;
  }

  const getAccountType = () => {
    let retData = ''
    if (kakaoid && kakaoid.length) {
      retData = 'kakao'
    }
    else {
      retData = 'email'
    }
    return retData;
  }

  const limitTimer = () => {
    let time = 180;   // 3분
    let min = '';
    let sec = '';
    window.timer = setInterval(() => {
      min = parseInt(time / 60);
      sec = time % 60;
      // document.querySelector('.text-darkblue').innerHTML =
      SetNowTime(`${getNumber2Ciphers(min)}:${getNumber2Ciphers(sec)}`)
      time--;

      if (time < 0) {
        // this.setState({
        //   responseAuthNum: '',
        //   authCheckFlag: 1
        // })
        alert('인증 시간이 지났습니다. 다시 인증해 주세요.')
        clearErrors('authNumber')
        setStepTwoFlag(false)
        SetNowTime('')
        setValue('authNumber', '')
        clearInterval(window.timer)
        // document.querySelector('.is-successed').style.display="none"
        // document.querySelector('.is-successed').innerHTML = '';
        // document.querySelector('.is-errored').style.display=""
        // document.querySelector('.is-errored').innerHTML = '인증시간이 초과되었습니다.';
        // document.querySelector('.text-darkblue').innerHTML = '';
      }
    }, 1000);
  }

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;


    if (!data?.username) {
      validCheck = false;
      setError('username', {
        type: 'focus',
        name: 'username',
        message: formErrorMessage.nameError
      }, { shouldFocus: true });
    }

    if (!data?.email) {
      validCheck = false;
      setError('email', {
        type: 'focus',
        name: 'email',
        message: formErrorMessage.emailError
      }, { shouldFocus: true });
    }

    if (!data?.password) {
      validCheck = false;
      setError('password', {
        type: 'focus',
        name: 'password',
        message: formErrorMessage.passwordError
      }, { shouldFocus: true });
    }

    // if (!data?.confirmPassword) {
    //   setError('confirmPassword', {
    //     type: 'focus',
    //     name: 'confirmPassword',
    //     message: formErrorMessage.confirmPasswordError
    //   }, { shouldFocus: true });

    // }

    // if (data?.confirmPassword !== data?.password) {
    //   setError('confirmPassword', {
    //     type: 'focus',
    //     name: 'confirmPassword',
    //     message: formErrorMessage.confirmPasswordAccordError
    //   }, { shouldFocus: true });
    //   return;
    // }

    if (!data?.phonenumber) {
      validCheck = false;
      setError('phonenumber', {
        type: 'focus',
        name: 'phonenumber',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }

    if (!qsdata.length) {
      if (!data?.masteremailid) {
        validCheck = false;
        setError('masteremailid', {
          type: 'focus',
          name: 'masteremailid',
          message: formErrorMessage.masterEmailError
        }, { shouldFocus: true });
      }
    }




    if (validCheck) {
      if (!AuthNumberButtonDisabled) {
        alert('전화번호 인증을 진행해주세요.')
        return;
      }


      if (qsdata.length) {
        let result = await AccountApi.SetMemberInvite({
          UserId: data?.email,
          UserPw: sha1(data?.password),
          AccountType: "email",
          CustomerName: data?.username,
          Email: data?.email,
          PhoneNumber: data?.phonenumber,
          HomeAddress: "",
          UseType: 2,
          Roles: 0,
          EncryptInvite: qsdata
        })
        if (isChkResultSuccess(result)) {
          alert('회원가입이 완료 되었습니다.')
          navigate(URLS.RESERVATION_MY_PAGE)
        }
        else {
          alert(result.Message)
        }
      }
      else {
        let result = await AccountApi.SetMemberTemp({
          UserId: getUserId(data?.email),
          UserPw: sha1(data?.password),
          AccountType: getAccountType(),
          CustomerName: data?.username,
          Email: data?.email,
          PhoneNumber: data?.phonenumber,
          HomeAddress: "",
          UseType: 2,
          Roles: 0,
          MasterEmailId: data?.masteremailid
        })
        if (isChkResultSuccess(result)) {
          alert('회원 가입이 완료 되었습니다.\n마스터 계정의 승인이 완료될 때 까지 이용이 제한됩니다.')
          navigate(URLS.EMAIL_LOGIN)
        }
        else {
          alert(result.Message)
        }
      }

    }

  });

  // 검색 개선버전.
  // 검색시 타이머 적용. 콜수 최소화
  const asyncSearchEmailId = async (e) => {
    clearTimeout(typingTimer)
    try {
      if (e.target.value.length >= 1) {
        // addressName이 존재할때 timeout을 건다.
        typingTimer = setTimeout(async () => {
          let result = await AccountApi.GetSearchMasterEmailId(e.target.value)
          if (isChkResultSuccess(result)) {
            setAddressList(result.ItemList);
          }
          else {
            setAddressList([])
          }
        }, doneTypingInterval)
      }
      else {
        setAddressList([])
      }
    }
    catch (e) {
      setAddressList([])
    }

  }

  const handlePhoneAuth = async () => {
    let phone = getValues('phonenumber')
    let validCheck = true;

    if (!phone?.length) {
      validCheck = false;
      setError('phonenumber', {
        type: 'focus',
        name: 'phonenumber',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }
    if (validCheck) {

      try {
        let result = await AccountApi.SetVerifySms(phone)
        if (isChkResultSuccess(result)) {
          alert('입력하신 전화번호로 인증번호를 발송했습니다.')
          clearErrors('phonenumber')
          setStepTwoFlag(true)
          setTmpAuthCode(result.Message)
          SetAuthNumberButtonDisabled(false)
          limitTimer()
        }
        else {
          alert(result.Message)
        }

      }
      catch (e) {
        console.log(e)
      }

    }
  }

  // 인증 버튼 눌렀을때
  const handleAuthCodeCheck = async () => {
    let authNumber = getValues('authNumber')
    let phone = getValues('phonenumber')
    let validCheck = true;

    if (!authNumber?.length) {
      validCheck = false;
      setError('authNumber', {
        type: 'focus',
        name: 'authNumber',
        message: '인증번호를 입력해주세요.'
      }, { shouldFocus: true });
    }
    if (validCheck) {

      if (authNumber === TmpAuthCode) {
        try {
          clearErrors('authNumber')
          SetAuthNumberButtonDisabled(true)

          clearInterval(window.timer)
        }
        catch (e) {
          console.log(e)
        }



      }
      else {

        setError('authNumber', {
          type: 'focus',
          name: 'authNumber',
          message: '인증번호가 일치하지 않습니다.'
        }, { shouldFocus: true });
      }

    }




  }


  return (
    <form onSubmit={onSubmit} className={classNames('login-form')}>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          이름
        </span>
        <Input
          type={'text'}
          required
          setValue={setValue}
          error={errors.username}
          onChange={(e) => {
            setValue('username', e.target.value)
          }}
          placeholder={'이름'}
          {...register('username')}
        />
      </div>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          {(getAccountType() === 'email') ? '이메일 ID' : '이메일 주소'}
        </span>
        <Input
          type={'text'}
          required
          setValue={setValue}
          error={errors.email}
          onChange={(e) => {
            setValue('email', e.target.value)
          }}
          placeholder={'이메일'}
          {...register('email')}
        />
      </div>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          비밀번호
        </span>
        <Input
          type={'password'}
          required
          setValue={setValue}
          error={errors.password}
          onChange={(e) => {
            setValue('password', e.target.value)
          }}
          placeholder={'비밀번호'}
          {...register('password')}
        />
      </div>
      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          전화번호
        </span>
        <Input
          type={'text'}
          required
          setValue={setValue}
          error={errors.phonenumber}
          onChange={(e) => {
            setValue('phonenumber', e.target.value)
          }}
          placeholder={'전화번호'}
          {...register('phonenumber')}
        />
        <Button
          type={'button'}
          styleType={AuthNumberButtonDisabled ? 'primary' : 'secondary'}
          classes={'bold txt-large'}
          onClick={handlePhoneAuth}
          disabled={AuthNumberButtonDisabled}
          style={AuthNumberButtonDisabled ? { backgroundColor: '#cbcbcb' } : {}}
        >전화번호인증</Button>
      </div>

      {StepTwoFlag && (<>
        <div className={classNames('input-set')}>
          <span className={classNames('txt-large')}>
            인증번호 입력
          </span>
          <Input
            type={'text'}
            required
            {...register('authNumber')}
            setValue={setValue}
            error={errors.authNumber}
            onChange={(e) => {
              setValue('authNumber', e.target.value)
            }}
            placeholder={'인증번호 입력'}
            disabled={AuthNumberButtonDisabled}

          />
          <span className={classNames('txt-regular')}>
            {AuthNumberButtonDisabled ? '인증이 완료되었습니다.' : `제한시간 ${NowTime}`}
          </span>
        </div>

        <Button
          type={'button'}
          styleType={AuthNumberButtonDisabled ? 'primary' : 'secondary'}
          classes={'bold txt-large'}
          onClick={handleAuthCodeCheck}
          disabled={AuthNumberButtonDisabled}
          style={AuthNumberButtonDisabled ? { backgroundColor: '#cbcbcb' } : {}}
        >{AuthNumberButtonDisabled ? '인증완료' : '인증'}</Button>

      </>)}

      {!qsdata.length && <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          마스터 계정 이메일 ID
        </span>
        <Input
          type={'text'}
          required
          {...register('masteremailid')}
          setValue={setValue}
          error={errors.masteremailid}
          onChange={(e) => {
            setValue('masteremailid', e.target.value)
            asyncSearchEmailId(e)
          }}
          placeholder={'마스터 계정 이메일 ID'}
        />



        {addressList.length >= 1 && <>
          <div style={{ display: 'flex', flexDirection: 'column', background: '#fff', border: '1px solid #eee' }}>
            {addressList.map((data, idx) => {
              if (addressList.length <= 0) {
                return (<div style={{ padding: '10px 10px', border: '1px solid #eee' }} key={idx}>검색 결과가 없습니다.</div>)
              }
              return (
                <div
                  style={{ padding: '10px 10px', border: '1px solid #eee' }}
                  key={idx}
                  onClick={() => {
                    setValue('masteremailid', data.MasterEmailId)
                    setAddressList([])
                  }}

                >{data.CustomerName} / {data.MasterEmailEnc}</div>
              )
            })}
          </div>
        </>}




      </div>}

      {/* <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          비밀번호 확인
        </span>
        <Input
          type={'password'}
          required
          setValue={setValue}
          error={errors.confirmPassword}
          onChange={(e) => {
            setValue('confirmPassword', e.target.value)
          }}
          placeholder={'비밀번호 확인'}
          {...register('confirmPassword')}
        />
      </div> */}
      <Button
        type={'submit'}
        styleType={'secondary'}
        classes={'bold txt-large'}
      >회원가입</Button>
    </form>
  )
}

export default Register