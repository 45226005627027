import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import '../../../styles/bookingLayout.css';
import BookingBox from './BookingBox';
import { getLastURLSegment, getValueByTarget } from '../../../utils/parse';
import { useBookingOptionContext, withBookingProvider } from '../../organisms/BookingOptionContext';
import { BookingEstimateSteps } from '../../../utils/steps';
import Header from '../../molecules/Header';
import Steps from '../../atoms/Steps';
import useCheckPC from '../../../hooks/useCheckPC';
import { getEstimateHistoryInfo } from '../../../lib/EstimateApi';
import { getEstimateCookieData, getQueryStringObject, isChkEstimateCookieData, base64EncodeUnicode, getKeyByIndex, sleep, isDev } from '../../../lib/Util';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateAll } from '../../../reducers/bookingEstimation';
import { URLS } from '../../../utils/urls';
import { closeLoading, openLoading } from '../../../reducers/loadingRedux';
import { BookingProducts, UsageTimeOptionsMonthly } from '../../../utils/EstimateFormData';
import { updateSelect } from '../../../reducers/monthlyContractMonthArray';
import ShareButton from '../../etc/ShareButton';
import KakaoButton from '../../etc/KakaoButton';
import AppDownloadButton from '../../etc/AppDownloadButton';
import QnA from './QnA';
import QnAContext from '../../organisms/QnAContext';
import classNames from 'classnames';

const BookingEstimation = () => {
	const { isPC } = useCheckPC();
	const location = useLocation();
	const [currentStep, setCurrentStep] = useState([]);
	const [stepList, setStepList] = useState([]);
	const [qnaOpen, setqnaOpen] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { selectedOptions, setSelectedOptions } = useBookingOptionContext();

	useEffect(() => {
		if (location.pathname) {
			const currentPath = location.pathname;
			const transformedPath = getLastURLSegment(currentPath);
			const stepList = BookingEstimateSteps[transformedPath];
			setCurrentStep(stepList);
		}
		const uniqueStepTitles = Array.from(new Set(Object.values(BookingEstimateSteps).map((step) => step.title)));

		setStepList(uniqueStepTitles);
		document.title = '월간모시러 웹 견적서';
	}, [location, BookingEstimateSteps]);

	const getSituationPageLink = (idx) => {
		let retData = '';
		// 견적서 진입 완료시
		if (idx === 4 || idx === 5) {
			retData = URLS.BOOKING_ESTIMATION_CONFIRM_ESTIMATE;
		}
		// 계약 초안화면 진입 완료시
		else if (idx === 6) {
			retData = URLS.BOOKING_ESTIMATION_CONFIRM_CONTRACT;
		}
		// 계약 완료시
		else if (idx === 7) {
			retData = URLS.BOOKING_ESTIMATION_COMPLETE_CONTRACT;
		} else {
			retData = URLS.BOOKING_ESTIMATION_SUBSCRIPTION;
		}
		return retData;
	};

	useEffect(() => {
		const initEstimateInfo = async () => {
			let reqData = '';
			let plainData = '';
			let isData = false;
			let result = {};

			try {
				let qs = getQueryStringObject();

				if (qs.estimateinfo.length) {
					reqData = qs.estimateinfo; // estimateinfo를 복호화 된 데이터를 넣는다.
					isData = true;
				}
			} catch (e) {
				// 쿠키 체크
				if (isChkEstimateCookieData()) {
					reqData = getEstimateCookieData();
					isData = true;
				}
			}

			plainData = decodeURIComponent(atob(reqData));

			if (isData) {
				try {
					result = await getEstimateHistoryInfo(base64EncodeUnicode(plainData), 1);
					if (result.Item.ResultCode === 200) {
						dispatch(openLoading());
						let resultJson = JSON.parse(result.ItemArray[0].JsonData);
						setSelectedOptions({ ...resultJson });

						//월간모시러 금액  2950000 * 0.03 = 88500 / 2950000 *  0.07 = 206500
						if (resultJson.subscription === 'monthly') {
							let MmPlusUsage = getValueByTarget(BookingProducts, resultJson.subscription, 'price') + getValueByTarget(UsageTimeOptionsMonthly, resultJson.usageTime, 'price');
							dispatch(
								updateSelect([
									{
										title: '1개월',
										value: 'month1',
										category: 'periodOfContract',
										numberValue: 1,
										discount: 0,
										description: '기본 비용 / 할인 없음',
										descriptionTitle: '할인 없음',
									},
									{
										title: '4개월',
										value: 'month4',
										category: 'periodOfContract',
										numberValue: 4,
										discount: Math.floor(MmPlusUsage * 0.03),
										description: '4개월 할인',
										descriptionTitle: '할인율 3%',
									},
									{
										title: '12개월',
										value: 'month12',
										category: 'periodOfContract',
										numberValue: 12,
										discount: Math.floor(MmPlusUsage * 0.07),
										description: '12개월 할인',
										descriptionTitle: '할인율 7%',
									},
								])
							);
						}

						dispatch(updateAll(resultJson));
						//booking context에도 반영

						await sleep(500);

						dispatch(closeLoading());
						// navigate(`/booking/${getKeyByIndex(BookingEstimateSteps, result.ItemArray[0].EstimatePage)}`)
						navigate(getSituationPageLink(Number(result.ItemArray[0].EstimatePage)));
					}
				} catch (e) {}
			}
		};

		initEstimateInfo();
	}, []);

	return (
		<>
			{location?.pathname?.indexOf('estimate') >= 0 || location?.pathname?.indexOf('confirm-contract') >= 0 || location?.pathname?.indexOf('estimate-download') >= 0 ? null : (
				<>
					<ShareButton type={'monthly'} />
				</>
			)}
			<KakaoButton />

			{/* <AppDownloadButton /> */}
			<div className='page-wrap'>
				<div className={classNames('booking-estimation', qnaOpen && 'qna-open')}>
					<Header stepList={stepList} currentStep={currentStep} hasClose={true} hasPrevButton={true} title={'견적서 작성'} />
					<BookingBox>
						{!isPC && <Steps currentStep={currentStep} stepList={stepList} />}
						<QnAContext.Provider value={{ setqnaOpen }}>
							<Outlet />
						</QnAContext.Provider>
					</BookingBox>
				</div>
				{qnaOpen && <QnA setqnaOpen={setqnaOpen} />}
			</div>
		</>
	);
};

export default withBookingProvider(BookingEstimation);
