import { useForm } from 'react-hook-form';
import Header from '../../molecules/Header';
import '../../../styles/contractApplicationForm.css';
import '../../../styles/informationRequestForm.css';
import Input from '../../atoms/Input';
import classNames from 'classnames';
import SelectSet from '../../molecules/SelectSet';
import { useState } from 'react';
import DatePickerInput from '../../molecules/DatePickerInput';
import Button from '../../atoms/Button';

const ContractApplicationForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
	} = useForm();

	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [servicePurposes, setServicePurposes] = useState([]);

	const options = ['option1', 'option2', 'option3'];

	const handleOptionChange = (selectedOption) => {
		setSelectedProduct(selectedOption);
		setValue('product', selectedOption.value);
	};

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setValue('date', date ? date.toISOString().split('T')[0] : ''); // ISO 포맷으로 날짜 저장
	};

	const onSubmit = (data) => {
		console.log('Form Submitted:', data);
	};

	const InputSet = ({ label, subLabel, name, placeholder, validation }) => (
		<div className={classNames('input-set gap-14')}>
			<span className={classNames('txt-large')}>{label}</span>
			{subLabel && <span className={'sub-label'} dangerouslySetInnerHTML={{ __html: subLabel }}></span>}
			<Input type='text' placeholder={placeholder} className={'white'} {...register(name, validation)} error={errors[name]} />
		</div>
	);

	const RadioSet = ({ label, name, options }) => (
		<div className='input-set'>
			<span className={classNames('txt-large')}>{label}</span>
			<div className='radio-set'>
				{options.map((option) => (
					<label key={option.value} className={classNames('radio', watch(name) === option.value && 'selected')}>
						<input type='radio' value={option.value} {...register(name)} />
						{option.label}
					</label>
				))}
			</div>
		</div>
	);

	return (
		<>
			<Header headerTitle='월간 모시러 계약 신청서' onClickCloseBtn={() => console.log('닫기')} />
			<div className='request-form-wrap'>
				<form className='request-form' onSubmit={handleSubmit(onSubmit)}>
					<div className='field-wrap'>
						<span className='description light-grey-4 lh12'>
							안녕하세요 고객님. <br />
							시간제 수행기사 서비스 모시러 입니다. <br /> <br />
							모시러에 깊은 관심과 사랑 주셔서 진심으로 감사드립니다. <br /> <br />
							월간 모시러 계약서를 고객님께 안내드리기 위해 <br />
							아래의 질문에 답해주시면 감사하겠습니다.
						</span>
					</div>

					<div className='field-wrap'>
						<InputSet
							label='계약자의 성함을 알려주세요.'
							subLabel={'사업자일 경우 기업명과 대표님 성함을 함께 알려주세요.<br> ex)  (주)버틀러 - 홍길동 대표'}
							name='name'
							placeholder='성함을 입력해 주세요.'
							validation={{ required: '성함을 입력해 주세요.' }}
						/>
						<InputSet
							label='계약자의 상세 주소를 알려주세요.'
							subLabel={'사업자일 경우 사업자 등록증을 첨부해 주세요.'}
							name='address'
							placeholder='상세 주소를 입력해 주세요.'
							validation={{ required: '상세 주소를 입력해 주세요.' }}
						/>
						<InputSet
							label='차량의 모델명을 알려주세요.'
							subLabel={'ex) 벤츠 S580'}
							name='carModel'
							placeholder='차량 모델명을 입력해 주세요.'
							validation={{ required: '차량 모델명을 입력해 주세요.' }}
						/>

						<div className='input-set'>
							<span className='txt-large'>계약 날짜를 선택해 주세요.</span>
							<DatePickerInput selectedDate={selectedDate} onDateChange={handleDateChange} placeholder='--.--.--' />
							{errors.date && <span className='error-message'>{errors.date.message}</span>}
						</div>
						<SelectSet
							defaultValue={'상품을 선택해 주세요.'}
							label='계약을 원하시는 상품을 선택해 주세요.'
							options={options}
							selectedOption={selectedProduct}
							setSelectedOption={setSelectedProduct}
							error={errors.product}
						/>
						<RadioSet
							label='원하시는 계약 기간을 선택해 주세요.'
							name='period'
							options={[
								{ value: '3개월', label: '3개월' },
								{ value: '6개월', label: '6개월' },
								{ value: '12개월', label: '12개월' },
								{ value: '기타', label: '기타' },
							]}
						/>
						<div className='input-set'>
							<span className='txt-large'>서비스 이용 목적을 선택해 주세요.</span>
							<div className='checkbox-set'>
								{['회사의 대표님 또는 특정 임원의 수행', '가정 + 회사에서 함께 사용할 목적', 'VIP 고객 의전 수행', '기타'].map((style) => (
									<label key={style} className={classNames('checkbox', servicePurposes.includes(style) && 'checked')}>
										<input
											type='checkbox'
											value={style}
											{...register('driveStyles')}
											onChange={(e) => {
												if (e.target.checked) {
													setServicePurposes([...servicePurposes, e.target.value]);
												} else {
													setServicePurposes(servicePurposes.filter((item) => item !== e.target.value));
												}
											}}
										/>
										{style.startsWith('기타') ? '기타' : style}
									</label>
								))}
							</div>
							{servicePurposes.includes('기타') && (
								<div className='input-set'>
									<textarea placeholder='기타 요청 사항에 대해 작성해 주세요.' {...register('etc')} className='textarea' />
								</div>
							)}
						</div>

						<InputSet
							label='담당자님의 성함을 입력해 주세요.'
							name='managerName'
							placeholder='담당자님의 성함을 입력해 주세요.'
							validation={{ required: '담당자님의 성함을 입력해 주세요.' }}
						/>
						<InputSet
							label='담당자님의 연락처를 입력해 주세요.'
							subLabel={'ex) 01098765432'}
							name='managerPhoneNumber'
							placeholder='담당자님의 연락처를 입력해 주세요.'
							validation={{ required: '담당자님의 연락처를 입력해 주세요.' }}
						/>
						<InputSet
							label='담당자님의 이메일 주소를 입력해 주세요.'
							subLabel={'ex) honggildong@google.com'}
							name='managerEmail'
							placeholder='담당자님의 이메일 주소를 입력해 주세요.'
							validation={{ required: '담당자님의 이메일 주소를 입력해 주세요.' }}
						/>
						<InputSet
							label='레퍼럴 코드 입력하기'
							subLabel={'레퍼럴 코드를 입력해 주시면 확인 후 계약시 반영드립니다.<br>유선 상담은 순차적으로 연락 드리겠습니다.'}
							name='referalCode'
							placeholder='레퍼럴 코드를 입력해 주세요.'
							validation={{ required: '레퍼럴 코드를 입력해 주세요.' }}
						/>
					</div>

					<Button type='submit' styleType='secondary' classes='bold txt-large p-16'>
						저장하기
					</Button>
				</form>
			</div>
		</>
	);
};

export default ContractApplicationForm;
