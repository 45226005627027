import axios from 'axios';
import { Token } from '../constantData';

import sha1 from 'sha1';

const USER_API_URL = (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) ? 'https://apitest.client.mosiler.com' : process.env.REACT_APP_USER_API_URL



const TIME_OUT = 10000;
const MyPageApi = {};
const CookieData = document.cookie
    .split(';')
    .reduce((res, c) => {
        const [key, val] = c.trim().split('=').map(decodeURIComponent)
        try {
            return Object.assign(res, { [key]: JSON.parse(val) })
        } catch (e) {
            return Object.assign(res, { [key]: val })
        }
    }, {});

MyPageApi.GetMyPage = (CustomerNo) => {
    let urlData = `${USER_API_URL}/v3/GetMyPage?CustomerNo=${CustomerNo}`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.SetMyPageAddress = (CustomerNo, Alias, Address, Lat, Lng) => {
    let urlData = `${USER_API_URL}/v3/SetMyPageAddress`
    let objData = {
        CustomerNo,
        Alias,
        Address,
        Lat,
        Lng
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.UpdateMyPageAddress = (Id, Alias, Address, Lat, Lng) => {
    let urlData = `${USER_API_URL}/v3/UpdateMyPageAddress`
    let objData = {
        Id,
        Alias,
        Address,
        Lat,
        Lng
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.DeleteMyPageAddress = (Id) => {
    let urlData = `${USER_API_URL}/v3/DeleteMyPageAddress`
    let objData = {
        Id
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.SetMyPageCarInfo = (CustomerNo, CarModel, CarNumber) => {
    let urlData = `${USER_API_URL}/v3/SetMyPageCarInfo`
    let objData = {
        CustomerNo,
        CarModel,
        CarNumber
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.UpdateMyPageCarInfo = (Id, CarModel, CarNumber) => {
    let urlData = `${USER_API_URL}/v3/UpdateMyPageCarInfo`
    let objData = {
        Id,
        CarModel,
        CarNumber
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.DeleteMyPageCarInfo = (Id) => {
    let urlData = `${USER_API_URL}/v3/DeleteMyPageCarInfo`
    let objData = {
        Id
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


MyPageApi.SetMember = (UserId, UserPw, CustomerName, PhoneNumber, ParentCustomerNo, UseType) => {
    let urlData = `${USER_API_URL}/v3/SetMember`
    let objData = {
        UserId,
        UserPw: sha1(UserPw),
        AccountType: 'email',
        CustomerName,
        Email: UserId,
        PhoneNumber,
        HomeAddress: '',
        ParentCustomerNo,
        UseType,
        Roles: 0,
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.UpdateMember = (CustomerNo, CustomerName, UserId, UserPw, PhoneNumber, UseType) => {
    let urlData = `${USER_API_URL}/v3/UpdateMember`
    let objData = {
        CustomerNo,
        CustomerName,
        UserId,
        UserPw: sha1(UserPw),
        Email: UserId,
        PhoneNumber,
        UseType,
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.DeleteMember = (CustomerNo) => {
    let urlData = `${USER_API_URL}/v3/DeleteMember`
    let objData = {
        CustomerNo
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}



MyPageApi.GetMyPageDriverDispatch = (CustomerNo) => {
    let urlData = `${USER_API_URL}/v3/GetMyPageDriverDispatch?CustomerNo=${CustomerNo}`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02_dd,
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.SetMyPageWorkTime = (obj) => {
    let urlData = `${USER_API_URL}/v3/SetMyPageWorkTime`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02_dd,
                'Token': Token
            },
            data: obj
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}




/*

[Route("v3/InviteCustomer")] POST

        public List<string> EmailList { get; set; }
        // 초대한 계정의 이름
        public string RequestName { get; set; }
        // 초대한 계정의 고객코드
        public int MasterCustomerNo { get; set; }
        // 초대한 계정의 이메일 아이디
        public int MasterRequestUserId { get; set; }
    "MasterCustomerNo":12642,
    "MasterRequestUserId": "risetest@mosiler.com"

*/

MyPageApi.InviteCustomer = (EmailArr, RequestName, MasterCustomerNo, MasterRequestUserId) => {
    let urlData = `${USER_API_URL}/v3/InviteCustomer`
    let objData = {
        EmailList: EmailArr,
        RequestName,
        MasterCustomerNo,
        MasterRequestUserId,
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.InviteCustomerDriverDispatch = (EmailArr, RequestName, MasterCustomerNo, MasterRequestUserId) => {
    let urlData = `${USER_API_URL}/v3/InviteCustomerDriverDispatch`
    let objData = {
        EmailList: EmailArr,
        RequestName,
        MasterCustomerNo,
        MasterRequestUserId,
    }

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02_dd,
                'Token': Token
            },
            data: objData
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.GetTempMemberList = (MasterEmailId) => {
    let urlData = `${USER_API_URL}/v3/GetTempMemberList?MasterEmailId=${MasterEmailId}`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },

        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.SetMemberTempApproval = (obj) => {
    let urlData = `${USER_API_URL}/v3/SetMemberTempApproval`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: obj

        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MyPageApi.SetMemberTempReject = (obj) => {
    let urlData = `${USER_API_URL}/v3/SetMemberTempReject`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            data: obj

        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


/*
[HttpPost]
[Route("v3/SetMemberInvite")]


*/

export default MyPageApi;