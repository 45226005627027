import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useLayout from '../../hooks/useLayout';
import Button from '../atoms/Button';
import Dim from '../atoms/Dim';
import Icon from '../atoms/Icon';

const Modal = ({ title, subTitle, onClickConfirm, modalClose, showClose = false, header = false, headerTitle, onClose, children }) => {
	const { closeModal } = useLayout();
	// const [nowPosition, setNowPosition] = useState(300)

	// const event = () => {
	//   //스크롤을 할 때마다 로그로 현재 스크롤의 위치가 찍혀나온다.
	//   // alert('스크롤 이벤트 발생')
	//   // alert(`${window.scrollX}, ${window.scrollY}`)
	//   if (window.scrollX === 0 && window.scrollY === 0) {

	//   }
	//   window.scrollTo(0, nowPosition)
	// }
	// useEffect(() => {
	//   // alert('div 로드 완료')
	//   window.addEventListener('scroll', event);
	//   return () => {
	//     // alert('모달 닫힘')
	//     window.removeEventListener('scroll', event);
	//     window.scrollTo(0, nowPosition)
	//   }

	// }, [])

	return (
		<>
			<div className={'modal'}>
				{header && (
					<div className='modal-header'>
						<span className='modal-header-title'>{headerTitle}</span>
						<Button
							styleType='icon'
							classes={'modal-close'}
							onClick={() => {
								closeModal();
								onClose();
							}}
						>
							<Icon icon='close' />
						</Button>
					</div>
				)}
				{(title || subTitle) && (
					<div className={classNames('title-set')}>
						{!!title && <span className={classNames('title-mid bold')}>{title}</span>}
						{!!subTitle && <span className={classNames('txt-regular lh12 grey-2')}>{subTitle}</span>}
					</div>
				)}
				{!!children && children}
				{(modalClose || showClose) && (
					<div className={classNames('button-set')}>
						<Button
							styleType={'secondary'}
							onClick={() => {
								if (onClickConfirm) {
									onClickConfirm();
								}
								closeModal();
							}}
						>
							닫기
						</Button>
					</div>
				)}
			</div>
			<Dim onClick={() => closeModal()} />
		</>
	);
};
Modal.propTypes = {
	title: PropTypes.string,
	onClickConfirm: PropTypes.func,
	modalClose: PropTypes.func,
	showClose: PropTypes.bool,
	children: PropTypes.node,
};
export default Modal;
