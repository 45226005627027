import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import BookingItem from '../../molecules/BookingItem';
import Button from '../../atoms/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';

import { BookingProducts } from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import { useDispatch } from 'react-redux';
import { updateSubscription, updateUsageTime } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { getValueByTarget } from '../../../utils/parse'
import useCheckPC from '../../../hooks/useCheckPC';
import MobileDescriptionPop from '../../organisms/MobileDescriptionPop';

import { getQueryStringObject, getEstimateCookieData, getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { useCookies } from 'react-cookie'
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';
import {
	setSheetContractResponseTest,
	setSheetEstimateResponseTest,
	setSheetContractResponse,
	setSheetEstimateResponse,
	SetMmEstimateHistory,
	GetMmEstimateLatestHistory
} from '../../../lib/EstimateApi';
import BookingItem2 from '../../molecules/BookingItem2';
import BookingFormSummary3 from '../../atoms/BookingFormSummary3';
import BookingItem2SizeUp from '../../molecules/BookingItem2SizeUp';
/*
dispatch(openLoading())
dispatch(closeLoading())
		// await하지 않고, 모시러 서버에 저장
		await SetMmEstimateHistory({
			UserName: 'rise',
			PhoneNumber: '01087223834',
			EmailAddress: 'rise@mosiler.com',
			EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
			EstimatePage: PAGE.PAGE_01,
			JsonData: JSON.stringify({
				...selectedOptions,
				subscription: data?.subscription ?? selectedOptions.subscription
			})
		})

*/



/**
 * 상품 선택 페이지
 * name : subscription
 * */

const Subscription = () => {
	const { isPC } = useCheckPC();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { selectedOptions, setSelectedOptions } = useBookingOptionContext();
	const [showDescription, setShowDescription] = useState(false)
	const [cookies, setCookie, removeCookie] = useCookies();

	const { handleSubmit, setValue } = useForm();

	useEffect(() => {
		function initData() {
			try {
				let qs = getQueryStringObject()
				if (qs.estimateinfo.length) {
					setCookie('estimateinfo', qs.estimateinfo, { path: '/' })
				}

			}
			catch (e) {

			}
		}
		initData()
	}, [])




	const onSubmit = handleSubmit(async data => {

		// Loading Start
		dispatch(openLoading())

		/*setSelectedOptions((prevOptions) => ({
			...prevOptions,
			subscription: data?.subscription ?? selectedOptions.subscription,
		}));*/

		setSelectedOptions((prevOptions) => {
			// console.log(prevOptions)
			return ({
				...prevOptions,
				subscription: data?.subscription ?? selectedOptions.subscription,
			})
		});

		// await하지 않고, 모시러 서버에 저장
		SetMmEstimateHistory({
			...getEstimateCookieDataAndDecrypt(),
			EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
			EstimatePage: PAGE.PAGE_01,
			JsonData: JSON.stringify({
				...selectedOptions,
				subscription: data?.subscription ?? selectedOptions.subscription
			})
		})

		dispatch(updateSubscription(data?.subscription ?? selectedOptions.subscription))
		dispatch(updateUsageTime(selectedOptions.usageTime))
		// Loading End
		dispatch(closeLoading())


		navigate(URLS.BOOKING_ESTIMATION_USAGE_TIME)



		/*
		BookingOptionContext에 있는 값중  아래 값에 대해서
		초기화 하는 작업이 필요하고
		그 Key값은 
		EstimateFormData상의 OptionDict에 있는 데이터
		  
		  usageTime: selectedOptions.usageTime,
		  numberOfVehicles: selectedOptions.vehicleOption.numberOfVehicles,
		  roleOfVehicles: selectedOptions.vehicleOption.roleOfVehicles,
		  periodOfContract: selectedOptions.contractTerms1.periodOfContract,
		  departure: selectedOptions.contractTerms1.serviceArea.departure,
		*/
	});

	const updateInitializeAmount = (source, subscription) => {
		if (subscription === 'annual') {
			return {
				...source,
				usageTime: 'annual_1year_100h',
				subscription: subscription,
				vehicleOption: {
					...source.vehicleOption,
					numberOfVehicles: 'twoVehicles',
					roleOfVehicles: 'driver',
					carAmount: 'twomilliondown',
					insurance: 'insubasic',
					passengerPerson: 'persontwo',
				},
				contractTerms1: {
					...source.contractTerms1,
					periodOfContract: 'month1',
				},
			}
		}

		/*
		setValue('numberOfVehicles', selectedOptions?.vehicleOption?.numberOfVehicles)
			  setValue('roleOfVehicles', selectedOptions?.vehicleOption?.roleOfVehicles)
			  setValue('carAmount', selectedOptions?.vehicleOption?.carAmount)
			  setValue('insurance', selectedOptions?.vehicleOption?.insurance)
			  setValue('passengerPerson', selectedOptions?.vehicleOption?.passengerPerson)
		*/

		return {
			...source,
			usageTime: 'day10',
			subscription: subscription,
			vehicleOption: {
				...source.vehicleOption,
				numberOfVehicles: 'twoVehicles',
				roleOfVehicles: 'driver',
				carAmount: 'twomilliondown',
				insurance: 'insubasic',
				passengerPerson: 'persontwo',
			},
			contractTerms1: {
				...source.contractTerms1,
				periodOfContract: 'month1',
			},
		}
		//    
	}

	// 맨처음 상품을 변경할 때,금액 초기화 처리 필요.
	// 

	return (
		<>
			<div className={classNames('booking-wrap')}>
				<PageTitle
					title={'상품 선택'}
					subtitle={'이용하실 상품을 선택하세요'}
					setShowDescription={setShowDescription}
				/>
				<form className={classNames('booking-form')} onSubmit={onSubmit}>
					<div className={classNames('booking-select-list')}>
						{!!BookingProducts && (
							<RadioGroup
								classes={''}
								legend={'subscription'}
								selected={selectedOptions.subscription}
							>
								<ul className={classNames('booking-item-list')}>
									{BookingProducts.map((item) => (
										<li key={item.title}>
											<Radio
												key={item.title}
												value={item.value}
												onChange={e => {

													setValue('subscription', item.value);
													// setSelectedOptions((prevOptions) => ({
													//   ...prevOptions,
													//   subscription: item.value,
													//   usageTime: 'day10'
													// }));
													setSelectedOptions((prevOptions) => updateInitializeAmount(prevOptions, item.value));

													// usageTime을 day10으로 하니 초기화가 되었음
													// 이게 확인 되었으므로 item.value 값에 따라 요소들을 초기화 시켜주면됨.
												}}
											>
												<BookingItem2SizeUp
													selected={selectedOptions.subscription === item.value}
													title={item?.title}
													subtitle={item?.subtitle}
													priceTitle={''}
													price={item?.price}
													priceUnstable={true}
													priceVisibleFlag={false}
												/>
											</Radio>
										</li>
									))}
								</ul>
							</RadioGroup>
						)}
					</div>
					<BookingFormSummary3
						description={
							getValueByTarget(
								BookingProducts,
								selectedOptions.subscription,
								'description'
							)
						}>
						<div className={classNames('button-set')}>
							<Button
								styleType={'secondary'}
								type={'submit'}
							>다음</Button>
						</div>
					</BookingFormSummary3>
				</form>
			</div>
			{showDescription && !isPC && (
				<MobileDescriptionPop
					targetDictList={BookingProducts}
					setShowDescription={setShowDescription}
					selectedOptions={selectedOptions}
				/>
			)}
		</>
	)
}
export default Subscription