import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { useSelector } from 'react-redux';
import ListWithCheckIcon from '../../atoms/ListWithCheckIcon';
import Button from '../../atoms/Button';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';
import { transFormWon } from '../../../utils/numbers';
import moment from 'moment';
import Input from '../../atoms/Input';
import { useForm } from 'react-hook-form';


const EstimateCustomize = () => {
    const navigate = useNavigate();
    const { BookingOption } = useSelector((state) => state);
    const [description, setDescription] = useState([])
    const {
        getValues,
        handleSubmit,
        setValue,
        register,
        setError,
        clearErrors,
        formState: { errors }
      } = useForm({
        defaultValues: {
          startHour: '00',
          startMinute: '00',
          endTime: '00:00',
          PassengerUserName: '',
          PassengerPhoneNo: '',
          CarModel: '',
          CarNumber: '',
          CustomerComment: '',
        }
      });

    useEffect(() => {
        const CompleteContractDescription = [
            '월간 모시러 계약이 완료 되었습니다.',
            '날인 해주신 계약서는 담당자님 메일로 발송이 되었습니다.',
            `입금 금액은 <i class='blue txt-large'>${transFormWon(BookingOption?.totalAmount)}</i> 이며 <br>
       입금 계좌는 (주)버틀러 신한은행 140-011-170175 입니다. <br>
       결제 기한: <i class='blue txt-large'>${moment().add(3, 'days').format('yyyy년 MM월 DD일')} 17시</i>`,
            '입금이 확인 되면 예약 계정을 생성해 드립니다.',
            `<a href='${URLS.BOOKING_ESTIMATION_SUBSCRIPTION}' class='txt-large underline' target='_blank'>첫 화면으로 이동</a>`
        ]
        setDescription(CompleteContractDescription)
    }, [BookingOption])

    return (
        <>
            <div style={{ padding: '30px 30px' }}>
                <div className={classNames('booking-wrap')}>
                    <PageTitle title={''} subtitle={'고객정보'} />
                    <div className={classNames('confirm-booking-wrap')}>
                    <Input
                        type={'text'}
                        required
                        setValue={setValue}
                        error={errors.email}
                        onChange={(e) => {
                            setValue('email', e.target.value)
                        }}
                        placeholder={'이름'}
                        {...register('email')}
                    />
                    <Input
                        type={'text'}
                        required
                        setValue={setValue}
                        error={errors.email}
                        onChange={(e) => {
                            setValue('email', e.target.value)
                        }}
                        placeholder={'전화번호'}
                        {...register('email')}
                    />
                    <Input
                        type={'text'}
                        required
                        setValue={setValue}
                        error={errors.email}
                        onChange={(e) => {
                            setValue('email', e.target.value)
                        }}
                        placeholder={'이메일'}
                        {...register('email')}
                    />
                    </div>
                    
                    <PageTitle title={''} subtitle={'상품'} />
                    <div className={classNames('confirm-booking-wrap')}>
                        <div className='input'>
                            {/*  onChange={setFormMember} value={memberObj.UseType} disabled={settingModalStatus.MemberDeleteFlag} */}
                            <select id="UseType" >
                                <option value="0">상품 선택</option>
                                <option value="1">월간모시러</option>
                                <option value="2">연간모시러</option>
                            </select>
                        </div>
                        <div className='input'>
                            {/*  onChange={setFormMember} value={memberObj.UseType} disabled={settingModalStatus.MemberDeleteFlag} */}
                            <select id="UseType" >
                                <option value="0">세부 상품 선택</option>
                                <option value="1">10일 상품</option>
                                <option value="2">14일 상품</option>
                                <option value="2">20일 상품</option>
                                <option value="2">30일 상품</option>
                                <option value="2">커스텀</option>
                            </select>
                        </div>
                        <div className='input'>
                            {/*  onChange={setFormMember} value={memberObj.UseType} disabled={settingModalStatus.MemberDeleteFlag} */}
                            <select id="UseType" >
                                <option value="0">세부 상품 선택</option>
                                <option value="1">100시간 상품</option>
                                <option value="1">200시간 상품</option>
                                <option value="1">300시간 상품</option>
                                <option value="1">250시간 상품</option>
                                <option value="1">500시간 상품</option>
                            </select>
                        </div>
                        <div className='input'>
                            {/*  onChange={setFormMember} value={memberObj.UseType} disabled={settingModalStatus.MemberDeleteFlag} */}
                            <select id="UseType" >
                                <option value="0">세부 상품 선택</option>
                                <option value="1">10일 상품</option>
                                <option value="2">14일 상품</option>
                            </select>
                        </div>

                    </div>

                    <PageTitle title={''} subtitle={'견적서 커스터마이징'} />
                    <div className={classNames('confirm-booking-wrap')}>
                        <div className='input'>
                            {/*  onChange={setFormMember} value={memberObj.UseType} disabled={settingModalStatus.MemberDeleteFlag} */}
                            <select id="UseType" >
                                <option value="0">상품 선택</option>
                                <option value="1">월간모시러</option>
                                <option value="2">연간모시러</option>
                            </select>
                        </div>
                        <div className='input'>
                            {/*  onChange={setFormMember} value={memberObj.UseType} disabled={settingModalStatus.MemberDeleteFlag} */}
                            <select id="UseType" >
                                <option value="0">세부 상품 선택</option>
                                <option value="1">10일 상품</option>
                                <option value="2">14일 상품</option>
                                <option value="2">20일 상품</option>
                                <option value="2">30일 상품</option>
                                <option value="2">커스텀</option>
                            </select>
                        </div>
                        <div className='input'>
                            {/*  onChange={setFormMember} value={memberObj.UseType} disabled={settingModalStatus.MemberDeleteFlag} */}
                            <select id="UseType" >
                                <option value="0">세부 상품 선택</option>
                                <option value="1">10일 상품</option>
                                <option value="2">14일 상품</option>
                            </select>
                        </div>

                    </div>

                    <BookingFormSummary
                        hasAmonut={false}
                    >
                        <div className={classNames('button-set')}>
                            <Button
                                styleType={'secondary'}
                                onClick={() => {
                                    alert()
                                }}
                            >견적서 다운로드</Button>
                        </div>
                    </BookingFormSummary>
                </div>
            </div>


        </>
    )
}

export default EstimateCustomize;