import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const RadarChart = ({ dataList }) => {
	const data = {
		labels: ['고객평', '근무일수', '주행', '운행경험', '서비스', '센스'],
		datasets: [
			{
				label: '',
				data: dataList,
				backgroundColor: 'rgba(30, 175, 210, 0.6)',
				borderWidth: 0,
				pointRadius: 0,
			},
		],
	};

	const options = {
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			r: {
				ticks: {
					display: false,
					stepSize: 25,
				},
				grid: {
					display: true,
					color: (context) => {
						const outermostGridLineIndex = context.chart.getDatasetMeta(0).data.length;
						return context.index === outermostGridLineIndex - 1 ? 'rgba(152, 155, 162, 1)' : 'rgba(194, 196, 200, 1)';
					},
				},
				angleLines: {
					display: true,
					color: 'rgba(194, 196, 200, 1)',
				},
				beginAtZero: true,
				suggestedMin: 0,
				suggestedMax: 100,
				pointLabels: {
					display: true,
					color: (context) => {
						const labelColors = ['#1EAFD2', '#171719', '#1EAFD2', '#171719', '#1EAFD2', '#171719'];
						return labelColors[context.index % labelColors.length];
					},
					font: {
						size: 10,
						weight: 500,
					},
					padding: 5,
				},
			},
		},
		elements: {
			line: {
				borderWidth: 3,
			},
		},
	};

	return (
		<>
			<Radar data={data} options={options} />
		</>
	);
};

export default RadarChart;
