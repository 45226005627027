import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Input from '../../../atoms/Input';
import { useForm } from 'react-hook-form';
import Checkbox from '../../../atoms/CheckBox';
import Button from '../../../atoms/Button';
import { Link } from 'react-router-dom';
import { URLS } from '../../../../utils/urls';
import Icon from '../../../atoms/Icon';
import { formErrorMessage } from '../../../../utils/alertMessage';
import AccountApi from '../../../../lib/AccountApi'
import { isChkResultSuccess, sleep, EncryptAES, isChkDdManageCookieData } from '../../../../lib/Util';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bookerPageLoginUser, bookerPageLoginUserPool } from '../../../../reducers/Users';
import { useCookies } from 'react-cookie'
import CookieApi from '../../../../lib/CookieApi';
import { openLoading, closeLoading } from '../../../../reducers/loadingRedux';

/**
 * 로그인
 * path: reservation/login/email-login
 * **/


const DriverDispatchManageEmailLogin = () => {
	const {
		getValues,
		handleSubmit,
		setValue,
		register,
		setError,
		formState: { errors }
	} = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [cookies, setCookie, removeCookie] = useCookies();
	const [Loading, setLoading] = useState(false)

	useEffect(() => {
		function loginCheck() {
			if (isChkDdManageCookieData()) {
				navigate(URLS.DRIVER_DISPATCH_MANAGE_MYPAGE)
			}
		}
		loginCheck()
	}, [])

	const onSubmit = handleSubmit(async data => {
		let validCheck = true;

		if (!data?.email) {
			validCheck = false;
			setError('email', {
				type: 'focus',
				name: 'email',
				message: formErrorMessage.emailError
			}, { shouldFocus: true });
		}

		if (!data?.password) {
			validCheck = false;
			setError('password', {
				type: 'focus',
				name: 'password',
				message: formErrorMessage.passwordError
			}, { shouldFocus: true });
		}

		if (validCheck) {
			dispatch(openLoading())
			// 로그인
			let result = await AccountApi.requestLogin(getValues('email'), getValues('password'))
			if (isChkResultSuccess(result)) {

				dispatch(bookerPageLoginUser(result.Item))
				dispatch(bookerPageLoginUserPool(result.ItemList))

				const expires = new Date()
				expires.setDate(expires.getDate() + 90)

				// 자동로그인 true일때
				if (data?.autoLogin) {
					setCookie('li_01_dd', result.infoData, { path: '/', expires })
					setCookie('li_02_dd', result.Item.LoginEncrypt, { path: '/', expires })
					setCookie('alchk_dd', 1, { path: '/', expires })
					setCookie('lgi_dd', EncryptAES(JSON.stringify(result.Item)), { path: '/', expires })
					setCookie('lgupi_dd', EncryptAES(JSON.stringify(result.ItemList)), { path: '/', expires })
				}
				else {
					// 세션이 남아있을때만 유효
					setCookie('li_01_dd', result.infoData, { path: '/' })
					setCookie('li_02_dd', result.Item.LoginEncrypt, { path: '/' })
					setCookie('alchk_dd', 0, { path: '/' })
					setCookie('lgi_dd', EncryptAES(JSON.stringify(result.Item)), { path: '/' })
					setCookie('lgupi_dd', EncryptAES(JSON.stringify(result.ItemList)), { path: '/' })
				}

				await sleep(1000)
				navigate(URLS.DRIVER_DISPATCH_MANAGE_MYPAGE)

				//CookieApi.SettingCookie(setCookie, result.infoData, result.Item.LoginEncrypt, getValues('autoLogin'))
				// ID, PW 암호화 데이터 쿠키에 저장
				// li_01 : ID, PW
				// li_02 : LoginEncrypt
				// 쿠키에 로그인, API 호출 키 관련 정보 암호화
				// Redux에 데이터 넣는것
				// rise1234test@mosiler.com // 1234
			}
			else {
				alert(result.Message)
			}
			dispatch(closeLoading())
		}

	});

	return (
		<form onSubmit={onSubmit} className={classNames('login-form')}>
			<div className={classNames('input-set')}>
				<span className={classNames('txt-large')}>
					이메일 주소
				</span>
				<Input
					type={'text'}
					required
					setValue={setValue}
					error={errors.email}
					onChange={(e) => {
						setValue('email', e.target.value)
					}}
					placeholder={'이메일'}
					{...register('email')}
				/>
			</div>
			<div className={classNames('input-set')}>
				<span className={classNames('txt-large')}>
					비밀번호
				</span>
				<Input
					type={'password'}
					required
					setValue={setValue}
					error={errors.password}
					onChange={(e) => {
						setValue('password', e.target.value)
					}}
					placeholder={'비밀번호'}
					{...register('password')}
				/>

			</div>
			<Checkbox
				label={'자동로그인'}
				checked={getValues('autoLogin')}
				onClick={e => {
					setValue('autoLogin', e.target.checked)
				}}
			/>
			<Button
				type={'submit'}
				styleType={'secondary'}
				classes={'bold txt-large'}
			>로그인</Button>
			<div className={classNames('link-set')}>
				<Link to={URLS.VERIFY_EMAIL} className={classNames('bold')}>아이디 찾기</Link>
				<Icon icon={'dot'} />
				<Link to={URLS.VERIFY_PASSWORD} className={classNames('bold')}>비밀번호 찾기</Link>
				<Icon icon={'dot'} />
				<Link to={URLS.REGISTER} className={classNames('bold')}>회원가입</Link>
			</div>
			{/* <div className={classNames('social-button-set')}>
				<Button
					styleType={'light-grey-2'}
					classes={'h-48 flex'}
					onClick={async () => {
						await AccountApi.test()

					}}
				>
					<Icon icon={'kakao'} />
					<span className={classNames('lh12 bold')}>카카오로 계속하기</span>
				</Button>
				<Button
					styleType={'light-grey-2'}
					classes={'h-48 flex'}
				>
					<Icon icon={'apple'} />
					<span className={classNames('lh12 bold')}>Apple로 계속하기</span>
				</Button>
			</div> */}
		</form>
	)
};

export default DriverDispatchManageEmailLogin