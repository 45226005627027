import { URLS } from "../../../utils/urls";
import { Navigate } from "react-router-dom";
import { isChkDriverManageCookieData } from "../../../lib/Util";

function CookieCheck () {
    if (!isChkDriverManageCookieData()) {
        alert('잘못된 접근입니다.\n로그인 해주세요')
        return false
    }
    return true
}


export const ProtectedRouteDriverDispatch = ({children}) => {
    const isLoggedIn = CookieCheck()
    if (!isLoggedIn) {
        return <Navigate to ={URLS.DRIVER_DISPATCH_MANAGE_EMAIL_LOGIN} replace />
    }
    return children
}