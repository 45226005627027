import React from 'react';
import classNames from 'classnames';
import { transFormWon } from '../../utils/numbers';
import useCheckPC from '../../hooks/useCheckPC';

const ListBox2 = (
  {
    title,
    listItem,
  }) => {
  const { isPC } = useCheckPC();
  return (
    <div className={classNames('list-box')} style={{ padding: '10px 0', border: 'none' }}>
      {title && (
        <span className={classNames(listItem ? 'grey-3 txt-regular' : 'title-mid bold')}>
          {title}
        </span>
      )}
      {listItem && (
        <ul className={classNames('list-box-list')}>
          {listItem.map((item, index) => {
            return (
              <li key={index} className={classNames('list-item')}>
                {item.title && (
                  <span className={classNames(isPC ? 'txt-large' : 'txt-regular', 'bold')}>{item.title}</span>
                )}
                {item.price >= 0 && (
                  <span className={classNames(isPC ? 'txt-large' : 'txt-regular', 'bold')}>{transFormWon(item.price)}</span>
                )}
                {item.discount >= 0 && (
                  <span className={classNames(isPC ? 'txt-large' : 'txt-regular', 'bold')}>{item.discount > 0 && '-'}{transFormWon(item.discount)}</span>
                )}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default ListBox2