import { URLS } from "../../../utils/urls";
import { Navigate } from "react-router-dom";
import { isChkDdEstimateCookieData, isChkReservationCookieData } from "../../../lib/Util";

function CookieCheck() {
    if (!isChkDdEstimateCookieData()) {
        alert('잘못된 접근입니다.\n소개 화면에서 \n기본정보를 다시 입력해주세요.')
        return false
    }
    return true
}


export const ProtectedRouteDd = ({ children }) => {
    const isLoggedIn = CookieCheck()
    if (!isLoggedIn) {
        window.location.href = process.env.REACT_APP_DISPATCH_LOGIN_URL
        return <></>

    }
    return children
}