import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import {
	BookingProducts,
	ConfirmEstimateDescription,
	ContractPeriodOptions,
	NumberOfVehiclesOptions,
	PassengerOptions,
	PurposeOfContractOptions,
	RoleOfVehiclesOptions,
	UsageTimeOptions,
	CarAmountOptions,
	InsuranceOptions,
	PassengerPersonOptions,
	UsageTimeOptionsAnnual,
	UsageTimeOptionsMonthly,
} from '../../../utils/EstimateFormData';
import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListBox from '../../atoms/ListBox';
import TotalAmount from '../../atoms/TotalAmount';
import useCheckPC from '../../../hooks/useCheckPC';
import ListWithBullet from '../../atoms/ListWithBullet';
import ListBoxWithIcon from '../../atoms/ListBoxWithIcon';
import moment from 'moment';
import { SetMmEstimateHistory, setSheetOutcallReqInfo } from '../../../lib/EstimateApi';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, PAGE } from '../../../utils/constant';
import { getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import Modal from '../../molecules/Modal';
import useLayout from '../../../hooks/useLayout';
import Input from '../../atoms/Input';
import { useForm } from 'react-hook-form';
import ComparisonEstimate from './ComparisonEstimate';
import { useQnA } from '../../organisms/QnAContext';
import Icon from '../../atoms/Icon';
import ListBoxWithPartner from '../../atoms/ListBoxWithPartner';
import ProfileEstimate from '../DriverDispatchManage/ProfileEstimate';
import ComparisonPartnerProfileFrame from './ComparisonPartnerProfileFrame';
import Select from '../../atoms/Select';
import { SendEztocConsult } from '../../../lib/EztocApi';
import withReactContent from 'sweetalert2-react-content';
import SwalTitleComponent from '../ManageReservation/Modal/Common/SwalTitleComponent';
import Swal from 'sweetalert2';

/**
 * 견적 확인 페이지
 * name : confirmEstimate
 * */

const ConfirmEstimate = () => {
	const navigate = useNavigate();
	const { isPC } = useCheckPC();
	const { openModal, closeModal, isModalOpen } = useLayout();
	const { setqnaOpen } = useQnA();
	const { BookingOption, ContractMonthArray } = useSelector((state) => state);

	/** 상품명 **/
	const [subscription, setSubscription] = useState([]);
	/** 이용 시간**/
	const [usageTime, setUsageTime] = useState([]);
	/** 차량 옵션 **/
	const [vehicleOption, setVehicleOption] = useState([]);
	/** 계약 조건 **/
	const [periodOfContract, setPeriodOfContract] = useState([]);
	/** 이용 지역 **/
	const [serviceArea, setServiceArea] = useState([]);
	/** 이용 정보 **/
	const [contractTerms2, setContractTerm2] = useState([]);
	/** 비교견적 **/
	const [openComparisonEstimate, setOpenComparisionEstimate] = useState(false);
	/** 상담사 통화요청 **/
	const [requestAdvisorOpen, setRequestAdvisorOpen] = useState(false);
	/** 파트너프로필 **/
	const [openProfile, setOpenProfile] = useState(false);
	/** 프로필 데이터 **/
	const [profileInfo, setProfileInfo] = useState([]);
	/** 프로필 인덱스 데이터 **/
	const [selectedProfileInfo, setSelectedProfileInfo] = useState({});

	const [ConfirmEstimateDescription2, SetConfirmEstimateDescription2] = useState([]);

	const [availableTime, setAvailableTime] = useState('선택');

	

	// useForm 훅을 사용하여 폼 상태를 관리
	const {
		register,
		handleSubmit,
		setValue,
		setError,
		getValues,
		clearErrors,
		formState: { errors },
	} = useForm();

	useEffect(()=>{
		let userInfoDecrypt = getEstimateCookieDataAndDecrypt();
		setValue('name', userInfoDecrypt.UserName)
		setValue('phonenumber', userInfoDecrypt.PhoneNumber)
	}, [])
	

	const showModalDetail = async (title, content) => {
		//console.log(reportResult.SelectDriverFee)
		const mySwal = withReactContent(Swal)
		mySwal.fire({
			title: (
				<SwalTitleComponent
					title={title}
					cancelBtn={mySwal.clickCancel} />
			),
			html: (
				<>
					<div className='desc'>
						{content}
					</div>
				</>

			),
			preConfirm: () => {

			},
			confirmButtonText: '확인',
			confirmButtonColor: '#1eafd2',



		})
	}

	  

	const onSubmit = handleSubmit(async data => {
		let validCheck = true;

		if (!data?.name) {
			validCheck = false;
			setError('name', {
				type: 'focus',
				name: 'name',
				message: '이름을 입력해주세요.'
			}, { shouldFocus: true });
		}

		if (!data?.phonenumber) {
			validCheck = false;
			setError('phonenumber', {
				type: 'focus',
				name: 'phonenumber',
				message: '휴대전화번호를 입력해주세요.'
			}, { shouldFocus: true });
		}

		if (availableTime === '선택') {
			validCheck = false;
			setError('availableTime', {
				type: 'focus',
				name: 'availableTime',
				message: '연락 가능 시간대를 선택해주세요.'
			}, { shouldFocus: true });
		}

		if (validCheck) {
			let userInfoDecrypt = getEstimateCookieDataAndDecrypt();
			let EstimateResponseObj = {
				UserName: userInfoDecrypt.UserName,
				UserPhone: userInfoDecrypt.PhoneNumber,
				UserEmail: userInfoDecrypt.EmailAddress,
				InputName: data?.name,
				InputPhone: data?.phonenumber,
				availableTime

			}
			SendEztocConsult(userInfoDecrypt.UserName, userInfoDecrypt.PhoneNumber)
			setSheetOutcallReqInfo(EstimateResponseObj)
			setRequestAdvisorOpen(false); // 폼 제출 후 모달 닫기
			closeModal()
			showModalDetail('상담사 통화요청 완료', '상담사 통화요청이 완료 되었습니다.')
		}
	})



	// 폼 제출 핸들러
	useEffect(() => {
		if (BookingOption.subscription === 'annual') {
			setSubscription([
				{
					title: `${getValueByTarget(BookingProducts, BookingOption.subscription, 'title')} ${getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'title')}`,
					price: getValueByTarget(BookingProducts, BookingOption.subscription, 'price') + getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'price'),
				},
			]);
		} else {
			setSubscription([
				{
					title: `${getValueByTarget(BookingProducts, BookingOption.subscription, 'title')} ${getValueByTarget(UsageTimeOptions, BookingOption.usageTime, 'title')}`,
					price: getValueByTarget(BookingProducts, BookingOption.subscription, 'price') + getValueByTarget(UsageTimeOptions, BookingOption.usageTime, 'price'),
				},
			]);
		}

		/** 차량 옵션 **/
		const updatedVehicleOption = [
			{
				title: `등록 차량 대수: ${getValueByTarget(NumberOfVehiclesOptions, BookingOption.vehicleOption.numberOfVehicles, 'title')}`,
				price: getValueByTarget(NumberOfVehiclesOptions, BookingOption.vehicleOption.numberOfVehicles, 'price'),
			},
			{
				title: `차량 가격: ${getValueByTarget(CarAmountOptions, BookingOption.vehicleOption.carAmount, 'title')}`,
				price: getValueByTarget(CarAmountOptions, BookingOption.vehicleOption.carAmount, 'price'),
			},
			{
				title: `보험: ${getValueByTarget(InsuranceOptions, BookingOption.vehicleOption.insurance, 'title')}`,
				discount: getValueByTarget(InsuranceOptions, BookingOption.vehicleOption.insurance, 'discount'),
			},
			// {
			//   title: `탑승 고객: ${getValueByTarget(
			//     PassengerPersonOptions,
			//     BookingOption.vehicleOption.passengerPerson,
			//     'title'
			//   )}`,
			//   price: getValueByTarget(
			//     PassengerPersonOptions,
			//     BookingOption.vehicleOption.passengerPerson,
			//     'price'
			//   )
			// },
			{
				title: `서비스: ${getValueByTarget(RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime), BookingOption.vehicleOption.roleOfVehicles, 'title')}`,
				price: getValueByTarget(RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime), BookingOption.vehicleOption.roleOfVehicles, 'price'),
			},
		];
		setVehicleOption(updatedVehicleOption);

		/** 계약 조건 **/
		const updatedPeriodOfContract = [
			{
				title: `계약 기간: ${getValueByTarget(ContractMonthArray.select, BookingOption.contractTerms1.periodOfContract, 'title')}`,
				discount: getValueByTarget(ContractMonthArray.select, BookingOption.contractTerms1.periodOfContract, 'discount'),
			},
		];
		setPeriodOfContract(updatedPeriodOfContract);

		/** 이용 지역 **/
		const updatedServiceArea = [
			{
				title: `차고지: 
          ${BookingOption?.contractTerms1?.serviceArea?.departure?.address1} 
          ${BookingOption?.contractTerms1?.serviceArea?.departure?.address2}`,
			},
			BookingOption?.contractTerms1?.serviceArea?.destination?.address1 && {
				title: `도착 지역: 
          ${BookingOption.contractTerms1.serviceArea.destination.address1} 
          ${BookingOption.contractTerms1.serviceArea.destination.address2}`,
			},
		].filter(Boolean);
		setServiceArea(updatedServiceArea);

		/** 이용 정보 **/
		const updatedContractTerms2 = [
			{ title: `이용 목적: ${getValueByTarget(PurposeOfContractOptions, BookingOption?.contractTerms2?.purposeOfContract, 'title')}` },
			{ title: `탑승자: ${getValueByTarget(PassengerOptions, BookingOption.contractTerms2.passenger, 'title')}` },
			{ title: `차량 번호 1: ${BookingOption.contractTerms2.vehicleName1}` },
			{ title: `차량 번호 2: ${BookingOption.contractTerms2.vehicleName2}` },
		];

		if (VehiclesOptionCount(BookingOption?.vehicleOption?.numberOfVehicles) === 3) {
			updatedContractTerms2.push({ title: `차량 번호 3: ${BookingOption.contractTerms2.vehicleName3}` });
		}

		setContractTerm2(updatedContractTerms2);

		SetConfirmEstimateDescription2([
			'본 견적 내 항목은 모두 VAT 별도입니다.',
			'실 탑승자 4인 까지를 기준으로 한 견적이며, 5인 이상 이용이 필요하신 경우 컨시어지로 문의 바랍니다.',
			'기본 이용시간 외 추가 이용도 가능하며, 시간당 단가가 반영되어 추가 청구됩니다.',
			'본 견적서 내 포함되지 않은 항목이라도 실제 서비스 이용에 따라 할증 요금이 사전 안내 후 청구될 수 있습니다.',
			`본 견적서의 내용은 ${moment().add(1, 'months').format('yyyy년 MM월 DD일')}까지 유효합니다.`,
		]);
	}, [BookingOption]);

	useEffect(() => {
		if (requestAdvisorOpen) {
			openModal({
				title: '상담사 통화 요청',
				closeBtn: false,
				confirmBtn: false,
			});
		}
	}, [requestAdvisorOpen]);

	useEffect(() => {
		if (openComparisonEstimate) {
			setqnaOpen(false);
		}
	}, [openComparisonEstimate]);

	

	return (
		<>
			<div className={classNames('booking-wrap')}>
				<PageTitle title={'견적 확인'} subtitle={'견적을 확인해 주세요'} />
				<div className={classNames('confirm-booking-wrap')}>
					<div className={classNames('confirm-booking-content')}>
						{/** title **/}
						{BookingOption.subscription === 'monthly' && <ListBoxWithIcon title={`월간 모시러`} downloadFlag={true} url={URLS.BOOKING_ESTIMATION_DOWNLOAD} />}

						{BookingOption.subscription === 'annual' && <ListBoxWithIcon title={`연간 모시러`} downloadFlag={true} url={URLS.BOOKING_ESTIMATION_DOWNLOAD} />}

						{/** 상품명 **/}
						<ListBox title={'상품명'} listItem={subscription} />

						{/** 옵션 **/}
						<ListBox title={'옵션'} listItem={vehicleOption} />

						{/** 계약 조건 **/}
						{BookingOption.subscription === 'monthly' && <ListBox title={'계약 조건'} listItem={periodOfContract} />}

						{/** 이용하실 지역 **/}
						<ListBox title={'이용하실 지역'} listItem={serviceArea} />
						{/* {(profileInfo.length >= 1) && <ListBoxWithPartner title={'계약시 우선매칭될 파트너 리스트'} listItem={profileInfo} setOpenProfile={setOpenProfile} selectedPartner={setSelectedProfileInfo} />} */}
						

		
						{/** 이용 정보 **/}
						{/* <ListBox
							title={'이용 정보'}
							listItem={contractTerms2}
							/> */}

						<TotalAmount styleType={'secondary'} />
					</div>

					<BookingFormSummary hasAmonut={false} description={ConfirmEstimateDescription2}>
						<div className={classNames('button-set mb-20')}>
							<Button onClick={() => navigate(URLS.BOOKING_ESTIMATION_VEHICLE_OPTION)}>이전</Button>
							<Button
								styleType={'secondary'}
								onClick={() => {
									// await하지 않고, 모시러 서버에 저장
									SetMmEstimateHistory({
										...getEstimateCookieDataAndDecrypt(),
										EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
										EstimatePage: PAGE.PAGE_05,
										JsonData: JSON.stringify(BookingOption),
									});

									navigate(URLS.BOOKING_ESTIMATION_USER_INFORMATION);
								}}
							>
								다음
							</Button>
						</div>
						<div className='sub-button-set'>
							<Button styleType='text' classes={'blue bold flex gap-4 mb-12'} onClick={() => setOpenComparisionEstimate(true)}>
								견적 비교하기
								<Icon icon={'blue_right_arrow'} />
							</Button>
							<Button styleType='text' classes={'blue bold flex gap-4 mb-12'} onClick={() => setRequestAdvisorOpen(true)}>
								상담사 통화 요청
								<Icon icon={'blue_right_arrow'} />
							</Button>
							<Button styleType='text' classes={'blue bold flex gap-4'} onClick={() =>  { setOpenProfile(true); setqnaOpen(false); } }>
								계약시 우선 매칭될 파트너리스트
								<Icon icon={'blue_right_arrow'} />
							</Button>
						</div>
					</BookingFormSummary>
				</div>
			</div>
			{!isPC && (
				<div className={classNames('box-light-grey-1 footer-contents')}>
					<ListWithBullet description={ConfirmEstimateDescription2} />
				</div>
			)}
			{isModalOpen && (
				<Modal header={true} headerTitle={'상담사 통화 요청'} onClose={() => setRequestAdvisorOpen(false)}>
					<form onSubmit={onSubmit} className='flex column gap-24 mw-340'>
						<span className='desc'>
							이름과 전화번호가 맞는지 확인해 주세요.
							<br className='pc' /> 상담사와 통화를 원하시면 통화요청 버튼을
							<br className='pc' /> 눌러주세요. 순차적으로 연락드리겠습니다.
						</span>
						<div className={classNames('input-set')}>
							<span className={classNames('txt-large')}>이름</span>
							<Input type={'text'} required setValue={setValue} error={errors.name} onChange={(e) => { }} placeholder={'이름'} {...register('name')} />
						</div>
						<div className={classNames('input-set')}>
							<span className={classNames('txt-large')}>전화번호</span>
							<Input type={'text'} required setValue={setValue} error={errors.phonenumber} onChange={(e) => { }} placeholder={'전화번호'} {...register('phonenumber')} />
						</div>
						<div className={classNames('input-set')}>
							<span className={classNames('txt-large')}>연락 가능 시간대</span>
							<Select
								selectedOption={availableTime}
								options={['선택', '오전', '오후', '상관없음']}
								onOptionChange={data => {
									setAvailableTime(data)
									if (data !== '선택')
										clearErrors('availableTime')
								}}
							/>
							{errors.availableTime && <span className={classNames('error')}>{errors.availableTime.message}</span>}
						</div>
						<Button type={'submit'} styleType={'secondary'} classes={'bold txt-large'}>
							통화요청
						</Button>
					</form>
				</Modal>
			)}
			{openComparisonEstimate && <ComparisonEstimate setOpenComparisionEstimate={setOpenComparisionEstimate} />}
			{openProfile && <ComparisonPartnerProfileFrame  setOpenPartnerProfile={setOpenProfile} />}
		</>
	);
};

export default ConfirmEstimate;
