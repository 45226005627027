import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  WeekendUsageOptions,
  SalaryOptions
} from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import BookingItem from '../../molecules/BookingItem';

import Radio from '../../atoms/Radio';
import Input from '../../atoms/Input';

import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDriverDispatchOptionContext } from '../../organisms/DriverDispatchOptionContext';
import { useForm } from 'react-hook-form';
import { updateContractTerms2 } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import { formErrorMessage } from '../../../utils/alertMessage';
import { getEstimateCookieDataAndDecrypt, getDateCompateToString, getDdEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { SetMmEstimateHistory, setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import moment from 'moment';
import ScheduleDatePicker from '../../atoms/ScheduleDatePicker';
import ListWithBullet from '../../atoms/ListWithBullet';
import AddressInput2 from '../../molecules/AddressInput2';
import { updateDriverDispatchBasicInfo1, updateDriverDispatchBasicInfo2 } from '../../../reducers/driverDispatchEstimation';
import BookingFormSummary2 from '../../atoms/BookingFormSummary2';
import { Range, getTrackBackground } from 'react-range';
import ScheduleDatePicker2 from '../../atoms/ScheduleDatePicker2';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';

/**
 * 이용 정보 페이지
 * name : contractTerms2
 * */
const BasicInfo2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
  const [description, setDescription] = useState([]);
  const { DriverDispatchOption } = useSelector((state) => state);
  const [RangeValue, SetRangeValue] = useState([0])

  useEffect(() => {
    const updatedDescription = [
      // `희망 채용 연봉은 
      //   <i class='blue'>
      //   ${getValueByTarget(
      //   SalaryOptions, selectedOptions?.basicInfo2?.selectedSalary,
      //   'title') ?? '미입력'}</i> 
      //    입니다.`,
      `차고지 주소는
      <i class='blue'>${selectedOptions?.basicInfo2?.garageAddress ?? '미입력'}</i> 
      입니다.`,
      `회사 주소는
      <i class='blue'>${selectedOptions?.basicInfo2?.companyAddress ?? '미입력'}</i> 
      입니다.`,
      `필요 시점은
      <i class='blue'>${moment(selectedOptions?.basicInfo1?.usageDate).format('yyyy-MM-DD') ?? '미입력'}</i> 
      입니다.`,
    ]

    setDescription(updatedDescription)
  }, [
    // selectedOptions.basicInfo2.selectedSalary,
    // selectedOptions.basicInfo2.selectedSalaryEtcString,
    selectedOptions.basicInfo2.garageAddress,
    selectedOptions.basicInfo2.companyAddress,
    selectedOptions.basicInfo1.usageDate,
  ])

  const { handleSubmit, setValue, register, setError, getValues, formState: { errors } } = useForm({
    defaultValues: {
      // selectedSalary: selectedOptions?.basicInfo2?.selectedSalary,
      // selectedSalaryEtcString: selectedOptions?.basicInfo2?.selectedSalaryEtcString,
      garageAddress: selectedOptions?.basicInfo2?.garageAddress,
      companyAddress: selectedOptions?.basicInfo2?.companyAddress,
      usageDate: selectedOptions?.basicInfo1?.usageDate,

    }
  });

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;

    // if (!data?.selectedSalary) {
    //   validCheck = false;
    //   setError('selectedSalary', {
    //     type: 'focus',
    //     name: 'selectedSalary',
    //     message: formErrorMessage.vehicleNameError
    //   }, { shouldFocus: true });
    // }

    if (!data?.garageAddress) {
      validCheck = false;
      setError('garageAddress', {
        type: 'focus',
        name: 'garageAddress',
        message: formErrorMessage.address1Error
      }, { shouldFocus: true });
    }

    if (!data?.companyAddress) {
      validCheck = false;
      setError('companyAddress', {
        type: 'focus',
        name: 'companyAddress',
        message: formErrorMessage.address1Error
      }, { shouldFocus: true });
    }

    if (!data?.usageDate) {
      validCheck = false;
      setError('usageDate', {
        type: 'focus',
        name: 'usageDate',
        message: formErrorMessage.passengerEtcStringError
      }, { shouldFocus: true });
    }



    if (validCheck) {
      dispatch(openLoading())

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        basicInfo1: {
          ...prevOptions.basicInfo1,
          usageDate: data?.usageDate ?? selectedOptions.basicInfo1.usageDate,
        },
        basicInfo2: {
          ...prevOptions.basicInfo2,
          // selectedSalary: data?.selectedSalary ?? selectedOptions.selectedSalary,
          // selectedSalaryEtcString: data?.selectedSalaryEtcString ?? selectedOptions.selectedSalaryEtcString,
          garageAddress: data?.garageAddress ?? selectedOptions.garageAddress,
          companyAddress: data?.companyAddress ?? selectedOptions.companyAddress,
        }
      }));


      // await하지 않고, 모시러 서버에 저장
      SetMmEstimateHistory({
        ...getDdEstimateCookieDataAndDecrypt(),
        EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
        EstimatePage: PAGE.PAGE_03,
        JsonData: JSON.stringify({
          ...selectedOptions,
          basicInfo1: {
            ...selectedOptions.basicInfo1,
            usageDate: data?.usageDate ?? selectedOptions.basicInfo1.usageDate,
          },
          basicInfo2: {
            ...selectedOptions.basicInfo2,
            // selectedSalary: data?.selectedSalary ?? selectedOptions.selectedSalary,
            // selectedSalaryEtcString: data?.selectedSalaryEtcString ?? selectedOptions.selectedSalaryEtcString,
            garageAddress: data?.garageAddress ?? selectedOptions.garageAddress,
            companyAddress: data?.companyAddress ?? selectedOptions.companyAddress,
          }
        })
      })

      dispatch(updateDriverDispatchBasicInfo1({
        ...DriverDispatchOption?.basicInfo1,
        usageDate: data?.usageDate ?? selectedOptions.basicInfo1.usageDate,
      }))

      dispatch(updateDriverDispatchBasicInfo2({
        ...DriverDispatchOption?.basicInfo2,
        garageAddress: data?.garageAddress ?? selectedOptions.garageAddress,
        companyAddress: data?.companyAddress ?? selectedOptions.companyAddress,
      }))

      dispatch(closeLoading())
      navigate(URLS.DRIVER_DISPATCH_BASIC_INFO_3)
    }
  });


  const handleAddressChange = (subField, value, target) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      basicInfo2: {
        ...prevState.basicInfo2,
        [subField]: value,
      },
    }));
  };
  const handleAddressChange2 = (subField, value, target) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      basicInfo2: {
        ...prevState.basicInfo2,
        [target]: value,
      },
    }));
  };


  return (
    <>

      <div className={classNames('booking-wrap')}>
        <PageTitle title={'기본 정보'} subtitle={'기본 정보를 알려주세요'} />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          <div className={classNames('booking-form-list')}>

            {/* {!!SalaryOptions && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>희망 채용 연봉</span>
                <RadioGroup
                  classes={''}
                  legend={'selectedSalary'}
                  selected={selectedOptions.basicInfo2.selectedSalary}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {SalaryOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('selectedSalary', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              basicInfo2: {
                                ...prevState.basicInfo2,
                                selectedSalary: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.basicInfo2.selectedSalary === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>

                      </li>
                    ))}
                  </ul>
                </RadioGroup>

              </div>
            )} */}

            {/* 
            구간 설정
            <div style={{ padding: '0 40px' }}>
              <Range
                step={100}
                min={0}
                max={1000}
                values={RangeValue}
                onChange={(values) => { SetRangeValue(values) }}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: "36px",
                      display: "flex",
                      width: "100%"
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: "5px",
                        width: "100%",
                        borderRadius: "4px",
                        background: getTrackBackground({
                          values: RangeValue,
                          colors: ["#548BF4", "#ccc"],
                          min: 0,
                          max: 1000
                        }),
                        alignSelf: "center"
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "42px",
                      width: "42px",
                      borderRadius: "4px",
                      backgroundColor: "#FFF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 2px 6px #AAA"
                    }}
                  >
                    <div
                      style={{
                        height: "16px",
                        width: "5px",
                        backgroundColor: isDragged ? "#548BF4" : "#CCC"
                      }}
                    />
                  </div>
                )}
              />
              {Number(RangeValue) + Number(selectedOptions.basicInfo2.selectedSalary)}
            </div> */}


            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>차고지 주소</span>
              <AddressInput2
                register={register}
                setValue={setValue}
                address1Error={errors.garageAddress}
                address1Register={'garageAddress'}
                setSelectedOptions={setSelectedOptions}
                handleAddressChange={handleAddressChange2}
                target={'garageAddress'}
              />
            </div>
            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>회사 주소</span>
              <AddressInput2
                register={register}
                setValue={setValue}
                address1Error={errors.companyAddress}
                address1Register={'companyAddress'}
                setSelectedOptions={setSelectedOptions}
                handleAddressChange={handleAddressChange2}
                target={'companyAddress'}

              />
            </div>

            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>필요 시점</span>
              <ScheduleDatePicker2
                selectedDate={selectedOptions?.basicInfo1?.usageDate}
                onChange={(newDate) => {
                  setValue('usageDate', newDate)
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    basicInfo1: {
                      ...prevState.basicInfo1,
                      usageDate: newDate,
                    },
                  }));
                }}
              />

            </div>

          </div>
          <BookingFormSummary2
            description={description}>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => navigate(URLS.DRIVER_DISPATCH_USAGE_TIME)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary2>
        </form>
      </div>
    </>
  )
}

export default BasicInfo2;