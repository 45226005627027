import moment from "moment";
const initialBookingOption = {
  subscription: 'monthly',
  usageTime: 'day10',
  optionHex: 0,
  vehicleOption: {
    numberOfVehicles: 'twoVehicles',
    roleOfVehicles: 'driver',
    carAmount: 'twomilliondown',
    insurance: 'insubasic',
    passengerPerson: 'persontwo'
  },
  contractTerms1: {
    periodOfContract: 'month1',
    serviceArea: {
      departure: {
        zip: '',
        address1: '',
        address2: '',
      },
      destination: {
        zip: '',
        address1: '',
        address2: '',
      },
    },
    schedule: moment().add(1, 'days').format('yyyy-MM-DD'),
  },
  contractTerms2: {
    purposeOfContract: 'business',
    passenger: 'ceo',
    passengerEtcString: '',
    route: 'route_instagram',
    routeEtcString: '',
    vehicleName1: '',
    vehicleName2: '',
    vehicleName3: '',
  },
  userInformation: {
    userType: 'personal',
    user: {
      name: '',
      businessAddress: {
        zip: '',
        address1: '',
        address2: '',
      },
      businessRegistrationId: '',
      businessRegistration: '',
      companyName: '',
      personalAddress: '',
      personalAddressDetail: '',
    }
  },
  paymentMethod: {
    method: null,
    taxBill: false,
    cashReceiptPhoneNumber: null,
  },
  savedSign: null,
  totalAmount: 0,
};

const OPTION_UPDATE = 'OPTION/UPDATE';
const OPTION_UPDATE_IMAGE = 'OPTION/UPDATE_IMAGE';

export const updateOption = BookingOption => ({ type: OPTION_UPDATE, payload: BookingOption });

export const updateSubscription = (subscription) => ({
  type: OPTION_UPDATE,
  payload: { subscription },
});

export const updateUsageTime = (usageTime) => ({
  type: OPTION_UPDATE,
  payload: { usageTime },
});

export const updateOptionHex = (optionHex) => ({
  type: OPTION_UPDATE,
  payload: optionHex,
});

export const updateVehicleOption = (vehicleOption) => ({
  type: OPTION_UPDATE,
  payload: { vehicleOption },
});

export const updateContractTerms1 = (contractTerms1) => ({
  type: OPTION_UPDATE,
  payload: { contractTerms1 },
});

export const updatePeriodOfContract = (contractTerms1) => ({
  type: OPTION_UPDATE,
  payload: { contractTerms1 },
});

export const updateContractTerms2 = (contractTerms2) => ({
  type: OPTION_UPDATE,
  payload: { contractTerms2 },
});

export const updateUserInformation = (userInformation) => ({
  type: OPTION_UPDATE,
  payload: { userInformation },
});

export const updatePaymentMethod = (paymentMethod) => ({
  type: OPTION_UPDATE,
  payload: { paymentMethod },
});

export const updateSavedSign = (savedSign) => ({
  type: OPTION_UPDATE,
  payload: { savedSign },
});

export const updateSchedule = (schedule) => ({
  type: OPTION_UPDATE,
  payload: { contractTerms: { schedule } },
});

export const updateTotalAmount = (totalAmount) => ({
  type: OPTION_UPDATE,
  payload: { totalAmount },
});

export const updateBusinessLicenseImage = (imgUrl) => ({
  type: OPTION_UPDATE_IMAGE,
  payload: imgUrl,
});

export const updateAll = (allData) => ({
  type: OPTION_UPDATE,
  payload: allData,
});

export const BookingOption = (state = initialBookingOption, action) => {
  switch (action.type) {
    case OPTION_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case OPTION_UPDATE_IMAGE:
      return {
        ...state,
        userInformation: {
          ...state.userInformation,
          user: {
            ...state.userInformation.user,
            businessRegistration: action.payload,
          }
        },
      };
    default:
      return state;
  }
};

