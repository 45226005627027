import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { layout } from './layout';
import { BookingOption } from './bookingEstimation'
import { Reservation } from './reservation';
import { Login } from './Users';
import { MyPageRedux } from './MyPage';
import { ReportRedux } from './Report';
import { LoadingRedux } from './loadingRedux';
import { DriverDispatchOption } from './driverDispatchEstimation';
import { ContractMonthArray } from './monthlyContractMonthArray';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'layout',
    'BookingOption',
    'Reservation',
    'Login',
    'MyPageRedux',
    'ReportRedux',
    'DriverDispatchOption',
    'ContractMonthArray'
  ]
};

export const rootReducer = combineReducers({
  layout,
  BookingOption,
  Reservation,
  Login,
  MyPageRedux,
  ReportRedux,
  LoadingRedux,
  DriverDispatchOption,
  ContractMonthArray

});

export const persistedReducer = persistReducer(persistConfig, rootReducer);
