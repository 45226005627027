import axios from 'axios';


const TIME_OUT = 10000
const MODUSIGH_URL = 'https://api.modusign.co.kr'

// 테스트 계정 API KEY
// const API_KEY = 'Basic cmlzZUBtb3NpbGVyLmNvbTpOelEzTlRka05tTXRZekpqWWkwME1UVTJMVGxqT0RVdFpUTXhNVEE0T0RNNFlqRmk='

// 본 계정 API KEY
const API_KEY = 'Basic bW9udGhseTJAbW9zaWxlci5jb206WWpneU5qQXdObUl0TkRjd09DMDBNVE0xTFRrd1l6VXRPVGN6Tmpsak5UWTBORFJr'



//https://developers.modusign.co.kr/docs
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL




export const SetModusignInfo = async (obj) => {
    const url = `${WEB_API_URL}/SetModusignInfo`
    const result = await axios({
        url: url,
        method: 'POST',
        timeout: TIME_OUT,
        headers: {
            'Content-Type': 'application/json',
        },
        data: obj
    })

    return result.data;
}


export const getModusignEmbedLink = async () => {
    const url = `${MODUSIGH_URL}/embedded-drafts`
    const result = await axios({
        url: url,
        method: 'POST',
        timeout: TIME_OUT,
        headers: {
            'Accept': 'application/json',
            'Authorization': API_KEY,
            'Content-Type': 'application/json',
        },
        data: {
            title: '[샘플] 서명요청 체험문서'
        }
    })

    return result.data;
}

export const getModusignContractDraft = async (obj) => {
    const url = `${MODUSIGH_URL}/embedded-drafts/create-with-template`
    const result = await axios({
        url: url,
        method: 'POST',
        timeout: TIME_OUT,
        headers: {
            'Accept': 'application/json',
            'Authorization': API_KEY,
            'Content-Type': 'application/json',
        },
        data: obj
    })

    return result.data;
}



export const getTemplateDocuments = async () => {
    const url = `/documents?offset=0&limit=10`
    const result = await axios({
        url: `${MODUSIGH_URL}${url}`,
        method: 'GET',
        timeout: TIME_OUT,
        headers: {
            'Accept': 'application/json',
            'Authorization': API_KEY,
            'Content-Type': 'application/json',
        },
        data: {
            title: '[샘플] 서명요청 체험문서'
        }
    })

    return result.data;
}

export const getRequestWithTemplate = async (obj) => {
    const url = `/documents/request-with-template`
    const result = await axios({
        url: `${MODUSIGH_URL}${url}`,
        method: 'POST',
        timeout: TIME_OUT,
        headers: {
            'Accept': 'application/json',
            'Authorization': API_KEY,
            'Content-Type': 'application/json',
        },
        data: obj
    })

    return result.data;
}

export const getEmbeddedViewUrl = async (documentId, participantsId, redirectUrl) => {
    const url = `/documents/${documentId}/participants/${participantsId}/embedded-view?redirectUrl=${encodeURIComponent(redirectUrl)}`
    const result = await axios({
        url: `${MODUSIGH_URL}${url}`,
        method: 'GET',
        timeout: TIME_OUT,
        headers: {
            'Accept': 'application/json',
            'Authorization': API_KEY,
            'Content-Type': 'application/json',
        },

    })

    return result.data;
}


