import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const SelectItem = ({ onClick, isSelected, children }) => {
  return (
    <div className={classNames('select-item', isSelected && 'selected')} onClick={onClick}>
      {children}
    </div>
  );
};

SelectItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default SelectItem;
