import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, useRef, useState } from 'react';


const Input = forwardRef(
  (
    {
      type = 'text',
      variant = 'underline',
      placeholder,
      error = false,
      classes,
      value,
      readOnly = false,
      onChange,
      onInvalid,
      required,
      setValue,
      setValueName,
      ...props
    },
    ref,
  ) => {
    const defaultRef = useRef();
    const inputRef = ref ?? defaultRef;
    const [isFocused, setIsFocused] = useState(false);
    return (
      <div
        className={classNames(
          error && 'error',
          classes,
          'input'
        )}
      >
        <input
          ref={inputRef}
          value={value}
          type={type}
          onChange={(e) => {
            onChange(e)
          }}
          placeholder={placeholder}
          readOnly={readOnly}
          className={classNames(
            classes
          )}
          autoComplete='new-password'

          onInput={e => {
            if (['number', 'tel'].includes(type)) {
              const target = e.currentTarget;
              const hasInvalidKey = /[^0-9]/g.test(target.value);

              if (hasInvalidKey) {
                const { selectionStart } = target;
                target.value = target.value.replace(/[^0-9]*/g, '');
                if (typeof selectionStart === 'undefined' || selectionStart === null) {
                  target.setSelectionRange(selectionStart - 1, selectionStart - 1);
                }
                e.preventDefault();
              }
            }
            props.onInput?.(e);
          }}
          onFocus={e => {
            setIsFocused(true);
            props.onFocus?.(e);
          }}
          onBlur={e => {
            setIsFocused(false);
            props.onBlur?.(e);
          }}
          {...props}
        />
        {error.message && <span className={classNames('error')}>{error.message}</span>}
      </div>
    );
  },
);
export default Input;

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'search', 'tel']),
  variant: PropTypes.oneOf(['underline', 'white', 'grey']),
  showDelete: PropTypes.bool,
  leftIconNode: PropTypes.node,
  rightIconNode: PropTypes.node,
  classes: PropTypes.string,
};
