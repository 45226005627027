import { detect } from 'detect-browser';

const useDetectBrowser = () => {
    const browser2 = detect()
    let retData = ''
    if (browser2.os.toLowerCase().indexOf('android') >= 0) {
        retData = 'android'
    }
    else if (browser2.os.toLowerCase().indexOf('ios') >= 0) {
        retData = 'ios'
    }
    else {
        retData = 'pc'
    }

    const Os = retData
    return { Os };
};

export default useDetectBrowser;
