import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { transFormWon } from '../../utils/numbers';
import classNames from 'classnames';
import useCheckPC from '../../hooks/useCheckPC';
import ListBox from './ListBox';
import { getValueByTarget, VehiclesOptionCount } from '../../utils/parse';
import {
  BookingProducts,
  ConfirmEstimateDescription,
  ContractPeriodOptions,
  NumberOfVehiclesOptions,
  PassengerOptions,
  PurposeOfContractOptions,
  UsageTimeOptions,
  CarAmountOptions,
  InsuranceOptions,
  PassengerPersonOptions,
  UsageTimeOptionsAnnual,
  UsageTimeOptionsMonthly
} from '../../utils/EstimateFormData';
import ListBox2 from './ListBox2';
import { useBookingOptionContext } from '../organisms/BookingOptionContext';



const TotalAmount2 = (
  {
    styleType = 'primary',
    pageType
  }) => {
  const { isPC } = useCheckPC();
  const { BookingOption } = useSelector((state) => state);
  const [pageTypeList, SetPageTypeList] = useState([])
  const { selectedOptions, setSelectedOptions } = useBookingOptionContext();

  /** 상품명 **/
  const [subscription, setSubscription] = useState([]);

  /** 이용 시간**/
  const [usageTime, setUsageTime] = useState([]);

  /** 차량 옵션 **/
  // const [vehicleOption, setVehicleOption] = useState([]);

  /** 계약 조건 **/
  // const [periodOfContract, setPeriodOfContract] = useState([]);

  /** 이용 지역 **/
  // const [serviceArea, setServiceArea] = useState([])

  /** 이용 정보 **/
  // const [contractTerms2, setContractTerm2] = useState([]);

  useEffect(() => {
    /** 상품명 **/
    const updatedSubscription = [
      {
        // title: getValueByTarget(BookingProducts,BookingOption.subscription,'title'),
        title: '기본금액',
        price: getValueByTarget(
          BookingProducts,
          BookingOption.subscription,
          'price'
        )
      }]
    setSubscription(updatedSubscription)



    if (BookingOption.subscription === 'annual') {
      setUsageTime([
        {
          // title: getValueByTarget(UsageTimeOptionsAnnual,BookingOption.usageTime,'title'),
          title: '추가 이용시간',
          price: getValueByTarget(
            UsageTimeOptionsAnnual,
            BookingOption.usageTime,
            'price'
          )
        }])
    }
    else {
      setUsageTime([
        {
          // title: getValueByTarget(UsageTimeOptions,BookingOption.usageTime,'title'),
          title: '추가 이용시간',
          price: getValueByTarget(
            UsageTimeOptions,
            BookingOption.usageTime,
            'price'
          )
        }])
    }



  }, [BookingOption])

  return (
    <div style={{ paddingBottom: '10px', paddingTop: '10px', borderTop: '1px solid var(--light-grey-1)' }}>
      <div>
        <ListBox2
          listItem={subscription}
        />
      </div>
      <div>
        <ListBox2
          listItem={usageTime}
        />
      </div>


    </div>

  );
};

export default TotalAmount2;
