import React, {useState} from 'react';
import classNames from 'classnames';
import PageButton from './PageButton';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon'

const Pagination = ({ page, totalPosts, limit, setPage}) => {
  const numPages = Math.ceil(totalPosts / limit)
  const [currPage, setCurrPage] = useState(page)
  let firstNum = currPage - (currPage % 5) + 1;
  return (
    <div className={classNames('pagination-wrap')}>
      <div className={classNames('pagination')}>
        <Button
          styleType={'icon'}
          classes={'transparent control'}
          onClick={() => {
            setPage(page - 1);
            setCurrPage(page - 2);
          }}
          disabled={page === 1}>
          <Icon icon={'page_prev'} />
        </Button>
        <div className={classNames('pagination-numbers')}>
          {Array(5).fill().map((_, i) =>{
            if (firstNum + i <= numPages) {
              return (<>
                <PageButton
                  pageNo={firstNum + i}
                  currPage={page}
                  key={i + 1}
                  onClick={() => {setPage(firstNum + i)}}
                />
              </>)
            }
          })}
        </div>
        <Button
          styleType={'icon'}
          classes={'transparent control'}
          onClick={() => {
            setPage(page + 1);
            setCurrPage(page);
          }}
          disabled={page === numPages}>
          <Icon icon={'page_next'} />
        </Button>

      </div>
    </div>
  )
}

export default Pagination