import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import WhiteBox from '../../atoms/WhiteBox';
import { reservationHistoryData } from '../../../archive/temporaryData';
import PercentageGraph from '../../molecules/PercentageGraph';
import PaymentHistory from '../../organisms/PaymentHistory';
import Button from '../../atoms/Button';
import Table from '../../organisms/Table';
import { ReservationTableTitleList, ReservationTableTitleSummaryList } from '../../../utils/ReservationFormData';
import TableRow from '../../molecules/TableRow';
import { transFormWon } from '../../../utils/numbers';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import Pagination from '../../molecules/Pagination';
import MobileDescriptionPop from '../../organisms/MobileDescriptionPop';
import TableDescriptPopContents from '../../molecules/TableDescriptPopContents';
import useCheckPC from '../../../hooks/useCheckPC';
import ReportApi from '../../../lib/ReportApi';
import { useSelector, useDispatch } from 'react-redux';
import { getMasterAccountCustomerNo, isChkResultSuccess, getEnglishDayToKorean, getTotalMinuteToHourMinuteStr, getTotalMinuteToHourMinuteStr2, getCookieInfo, DecryptAES } from '../../../lib/Util';
import { reportContractDateList, reportSummary, reportDrivingList } from '../../../reducers/Report';
import icon_report_date_left from '../../../styles/img/icon_report_date_left.svg';
import icon_report_date_right from '../../../styles/img/icon_report_date_right.svg';
import moment from 'moment';
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import ReserveApi from '../../../lib/ReserveApi';
import TableRowCustom from '../../molecules/TableRowCustom';
import TableDescriptPopContentsCustom from '../../molecules/TableDescriptPopContentsCustom';
import PercentageGraphCustom from '../../molecules/PercentageGraphCustom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import qs from 'qs';
import SwalTitleComponent from './Modal/Common/SwalTitleComponent';
import Icon from '../../atoms/Icon';

const ADMIN_FLAG = qs.parse(document.location.search, { ignoreQueryPrefix: true })?.flag === 'external';
const ADMIN_CUSTOMERNO = qs.parse(document.location.search, { ignoreQueryPrefix: true })?.customerno ?? 0;

/**
 * 이용 내역 페이지
 * name : reservation history
 * TODO: 고객 데이터로 값 대체
 * */

const ReservationHistory = () => {
	const [AdminFlag, SetAdminFlag] = useState(false);

	const { isPC } = useCheckPC();
	const [period, setPeriod] = useState({});
	const [rowList, setRowList] = useState([]);
	const [rowListMobile, setRowListMobile] = useState([]);
	const [filterTarget, setFilterTarget] = useState('all');
	const [showDescriptionIndex, setShowDescriptionIndex] = useState(-1);

	const [modalTest, setModalTest] = useState(false);

	/** pagination **/
	// 한 페이지에 보여줄 게시물 개수
	const limit = 10;
	const [page, setPage] = useState(1);
	const [offset, setOffset] = useState(1);

	const [reportDateIndex, SetReportDateIndex] = useState(0);
	const [reportDateMaxLength, SetReportDateMaxLength] = useState(0);
	const [reportDateFirstLoading, SetReportDateFirstLoading] = useState(false);
	const [avaliableData, SetAvaliableData] = useState({
		UseTime: 0,
		RemainTime: 0,
		AvaliableTime: 0,
		UsePercent: 0,
	});
	const [reportList, SetReportList] = useState({
		usageTimeInfo: {
			ContractTypeDay: 0,
			ContractTypeHour: 0,
			RemainTimes: 0,
		},
		expence: [
			{
				StartDate: '2023-05-19T14:00:00',
				ItemCode: '0',
				DrivingNo: 0,
				UseAmount: 0,
				BillingYN: 'N',
			},
		],
		otherAmount: [
			{
				StartDate: '2023-05-19T14:00:00',
				Category: '',
				DrivingNo: 0,
				UseAmount: 0,
				BillingYN: 'N',
			},
		],
		drivingList: [
			{
				DrivingNo: 0,
				DriverNo: 0,
				DriverName: '',
				DrivingStatus: 'use',
				RevStartDt: '2022-09-07T17:00:00',
				RevEndDt: '2022-09-08T01:00:00',
				StartDate: '2022-09-07T16:44:58.323',
				EndDate: '2022-09-07T16:45:08',
				ReserveHourMin: '00:00',
				UseHourMin: '00:00',
				DiffHourMin: '00:00',
				ReserveMin: 0,
				UseMin: 1,
				DiffMin: 0,
				StartAreaFee: 0,
				AreaFee: 0,
				NightFee: 0,
				ExtraTimeFee: 0,
				SelectDriverFee: 0,
				ReserveCancelFee: 0,
				ParkingFee: 0,
				TollgateFee: 0,
				EtcFee: 0,
				UseTime: 0,
				ResultUseTime: 0,
				ExceedAmount: 0,
				UseTimeDeductionAfterAmount: 0,
				RealUseTime: 0,
				RealTimeDeductionAfterAmount: 0,
				ReserveTime: 0,
				RemainTime: 0,
				MinimumTimeApplyFlag: 0,
				DrivingStatusCode: '5',
				CancelType: 0,
				CancelTime: 0,
				CancelAmount: 0,
				BillingYN: 'N',
				ExceedTime: 0,
				RevStartAddress: '',
				RevEndAddress: '',
				RevWaypointAddress: '',
				StartAddress: '',
				EndAddress: '',
				WaypointAddress: '',
				MmHourPriceHistory: 0,
				OvercarriedMinute: null,
			},
		],
	});

	const loginInfo = useSelector((state) => state.Login);
	const reportInfo = useSelector((state) => state.ReportRedux);
	const dispatch = useDispatch();
	const [mmCustomerInfo, setMmCustomerInfo] = useState({
		Id: 0,
		CustomerNo: 0,
		GroupNumber: 1,
		ContractStartDate: '2022-09-26T00:00:00',
		Month: 0,
		ContractEndDate: '2024-05-25T00:00:00',
		ContractRangeType: 0,
		SuspendDay: 0,
		ContractTypeDay: 0,
		ContractTypeHour: 0,
		OvercarriedMinute: 0,
		ContractOneDayAvaliableHour: 1,
		MmHourPrice: 0,
		ExtrafeeFlagArea: 0,
		ExtrafeeFlagNight: 0,
		LiveFlag: 0,
		ContractRemark: '',
		DeleteYN: 'N',
		RegDt: '2022-09-26T14:27:27.74',
		UpdateDt: '2023-09-07T10:58:12.167',
		PrePaymentAmount: 0,
		PostPaymentAmount: 0,
		PrePaymentFlag: 0,
		PostPaymentFlag: 0,
		CustomerName: null,
		EmailAddress: null,
	});

	useEffect(() => {
		const tempPage = (page - 1) * limit;
		setOffset(tempPage);
	}, [page]);

	const getCustomerNoSwitching = () => {
		return ADMIN_FLAG ? ADMIN_CUSTOMERNO : getMasterAccountCustomerNo(loginInfo.UserPool);
	};

	// 최초 로딩 시의 기본 데이터
	useEffect(() => {
		async function getData() {
			dispatch(openLoading());

			if (ADMIN_FLAG) {
				document.querySelector('.header').style.display = 'none';
				document.querySelector('.manage-reservation').style.paddingTop = '20px';

				const style = document.createElement('style');
				style.type = 'text/css';
				style.innerHTML = `* { -webkit-touch-callout: default; -webkit-user-select: auto; }`;
				document.head.appendChild(style);

				// style={{WebkitTouchCallout:'default', WebkitUserSelect:'auto'}}
			}

			//console.log(ADMIN_FLAG)
			//console.log(ADMIN_CUSTOMERNO)

			let result1 = await ReportApi.GetMmReportContractDateList(getCustomerNoSwitching());
			if (isChkResultSuccess(result1)) {
				dispatch(reportContractDateList(result1.ItemList));
			}

			let result2 = await ReportApi.GetMmReportList(getCustomerNoSwitching(), result1.ItemList[result1.ItemList.length - 1].StartDate, result1.ItemList[result1.ItemList.length - 1].EndDate);
			if (isChkResultSuccess(result2)) {
				dispatch(reportSummary(result2.Item));
				// dispatch(reportDrivingList(result2.ItemList))
				SetReportList({
					expence: result2.Item.expence,
					otherAmount: result2.Item.otherAmount,
					drivingList: result2.ItemList.sort((a, b) => {
						const dateA = new Date(a.RevStartDt);
						const dateB = new Date(b.RevStartDt);

						// 내림차순 정렬을 위해 b와 a의 순서를 바꿈
						return dateB - dateA;
					}),
				});
				SetReportDateMaxLength(result1.ItemList.length);
				SetReportDateIndex(result1.ItemList.length - 1);
			}

			let result4 = await ReserveApi.MmCustomerInfo(getCustomerNoSwitching());
			if (isChkResultSuccess(result4)) {
				setMmCustomerInfo(result4.Item.MmData);
			}

			dispatch(closeLoading());
		}
		getData();
	}, []);

	// 로딩 후 날짜 변경시의 처리
	useEffect(() => {
		async function getData() {
			// dispatch(openLoading())

			if (!reportDateFirstLoading) {
				SetReportDateFirstLoading(true);
			} else {
				let result2 = await ReportApi.GetMmReportList(getCustomerNoSwitching(), reportInfo.ContractDateList[reportDateIndex].StartDate, reportInfo.ContractDateList[reportDateIndex].EndDate);

				if (isChkResultSuccess(result2)) {
					dispatch(reportSummary(result2.Item));
					// dispatch(reportDrivingList(result2.ItemList))
					SetReportList({
						expence: result2.Item.expence,
						otherAmount: result2.Item.otherAmount,
						drivingList: result2.ItemList.sort((a, b) => {
							const dateA = new Date(a.RevStartDt);
							const dateB = new Date(b.RevStartDt);

							// 내림차순 정렬을 위해 b와 a의 순서를 바꿈
							return dateB - dateA;
						}),
					});
				}
			}
			dispatch(closeLoading());
		}
		getData();
	}, [reportDateIndex]);

	const filterList = [
		{
			value: 'all',
			title: '전체',
		},
		{
			value: 'pending',
			title: '이용예정',
		},
		{
			value: 'completed',
			title: '이용완료',
		},
		{
			value: 'canceled',
			title: '취소완료',
		},
	];

	const convertContractRanges = (contracts) => {
		const years = {};
		contracts.forEach((contract) => {
			const year = contract.StartDate.substring(0, 4);
			if (!years[year]) {
				years[year] = [];
			}
			years[year].push({
				StartDate: contract.StartDate,
				EndDate: contract.EndDate,
			});
		});

		for (const year in years) {
			years[year].sort((a, b) => a.StartDate.localeCompare(b.StartDate));
		}

		const converted = Object.keys(years)
			.sort()
			.map((year) => ({
				year: year,
				dateList: years[year],
			}));

		return converted;
	};

	//console.log();

	// 이용예정, 이요완료, 취소를 눌렀을 때 실행
	// 분석하느라 시간 좀 소요될 것 같음..  이부분은 패스?
	useEffect(() => {
		setPeriod(reservationHistoryData.period);
		// const history = reservationHistoryData.history
		const emptyRow = '-';
		const tmpDrivingStatus = (status) => {
			let retData = '';
			switch (status) {
				case 'reserve':
					retData = 'pending';
					break;
				case 'use':
					retData = 'completed';
					break;
				case 'cancel':
					retData = 'canceled';
					break;
				default:
					retData = '';
					break;
			}
			return retData;
		};

		//getEnglishDayToKorean
		const tmpDateTime = (Driving, type = 'pc') => {
			let hourMinuteStr = '';
			if (Driving.DrivingStatus === 'use') {
				hourMinuteStr = getTotalMinuteToHourMinuteStr(moment(Driving.EndDate).diff(moment(Driving.StartDate), 'minutes'));
			} else {
				hourMinuteStr = getTotalMinuteToHourMinuteStr(moment(Driving.RevEndDt).diff(moment(Driving.RevStartDt), 'minutes'));
			}

			if (type === 'pc') {
				return Driving.DrivingStatus === 'use'
					? `${moment(Driving.StartDate).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.StartDate).format('ddd'))})<br/> ${moment(Driving.StartDate).format('HH:mm')}~${moment(
							Driving.EndDate
					  ).format('HH:mm')}<br/>(${hourMinuteStr})`
					: `${moment(Driving.RevStartDt).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.RevStartDt).format('ddd'))})<br/> ${moment(Driving.RevStartDt).format('HH:mm')}~${moment(
							Driving.RevEndDt
					  ).format('HH:mm')}<br/>(${hourMinuteStr})`;
			}

			return Driving.DrivingStatus === 'use'
				? `${moment(Driving.StartDate).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.StartDate).format('ddd'))}) ${moment(Driving.StartDate).format('HH:mm')}~${moment(
						Driving.EndDate
				  ).format('HH:mm')}`
				: `${moment(Driving.RevStartDt).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.RevStartDt).format('ddd'))}) ${moment(Driving.RevStartDt).format('HH:mm')}~${moment(
						Driving.RevEndDt
				  ).format('HH:mm')}`;
		};

		const tmpDateTime2 = (Driving, type = 'pc') => {
			let hourMinuteStr = '';
			if (Driving.DrivingStatus === 'use') {
				hourMinuteStr = getTotalMinuteToHourMinuteStr2(moment(Driving.EndDate).diff(moment(Driving.StartDate), 'minutes'));
			} else {
				hourMinuteStr = getTotalMinuteToHourMinuteStr2(moment(Driving.RevEndDt).diff(moment(Driving.RevStartDt), 'minutes'));
			}
			return hourMinuteStr;
		};

		const tmpItemOne = (Driving) => {
			return {
				type: Driving.ExceedTime > 1 ? (Driving.DrivingStatus === 'reserve' ? '초과예약' : '초과이용') : '기본이용',
				usedTime: Driving.ExceedTime > 1 ? `${Math.floor(Driving.ExceedTime / 60)}시간 ${Driving.ExceedTime % 60}분` : '',
				resultUsedTime: `${Math.floor(Driving.ResultUseTime / 60)}시간 ${Driving.ResultUseTime % 60}분`,
				additionalFee: Driving.ExceedAmount,
			};
		};

		const tmpEtcAmount = (Driving, Expenses) => {
			let tmpArr = [];

			const getExpenceTypeToStr = (type) => {
				let retData = '-';
				switch (type) {
					case '1':
						retData = '주차비';
						break;
					case '2':
						retData = '통행료';
						break;
					case '3':
						retData = '교통비';
						break;
					case '4':
						retData = '기타';
						break;
					default:
						break;
				}
				return retData;
			};

			const getExpenceArrIdx = (expenceArr, drivingno) => {
				let retData = [];
				for (let i in expenceArr) {
					if (expenceArr[i].DrivingNo === drivingno) {
						retData.push(i);
					}
				}
				return retData;
			};

			let areaFeeSum = 0;

			// 할증 관련 처리
			if (Driving.StartAreaFee >= 1) {
				areaFeeSum += Driving.StartAreaFee;
			}
			if (Driving.AreaFee >= 1) {
				areaFeeSum += Driving.AreaFee;
			}
			if (Driving.NightFee >= 1) {
				areaFeeSum += Driving.NightFee;
			}
			if (areaFeeSum >= 1) {
				tmpArr.push({
					type: '할증',
					title: '',
					amount: areaFeeSum,
					additionalFee: areaFeeSum,
				});
			}

			// 결제대행 처리
			let tmpExpenseArr = getExpenceArrIdx(Expenses, Driving.DrivingNo);

			if (tmpExpenseArr.length >= 1) {
				let sum = 0;
				let sum2 = 0;
				for (let i in tmpExpenseArr) {
					sum += Expenses[tmpExpenseArr[i]]?.UseAmount;
					sum2 += Expenses[tmpExpenseArr[i]]?.UseAmount + Expenses[tmpExpenseArr[i]]?.UseAmount * 0.2;
				}

				tmpArr.push({
					type: '결제대행',
					title: '',
					amount: sum,
					additionalFee: sum2,
				});
			}

			return tmpArr;
		};

		const tmpOtherAmount = (Driving, otherAmountArr) => {
			let tmpArr = [];
			let tmpExpenseArr = getExpenceArrIdx(otherAmountArr, Driving.DrivingNo);
			if (tmpExpenseArr.length >= 1) {
				let sum = reportList.otherAmount.map((a) => a.UseAmount).reduce((a, b) => a + b);
				tmpArr.push({
					type: '기타금액',
					title: '',
					amount: sum,
					additionalFee: sum,
				});
			}
			return tmpArr;
		};

		const tmpSelctDriverFee = (Driving) => {
			let tmpArr = [];
			if (Driving.SelectDriverFee >= 1) {
				tmpArr.push({
					type: '지명비',
					title: '',
					amount: Driving.SelectDriverFee,
					additionalFee: Driving.SelectDriverFee,
				});
			}

			return tmpArr;
		};

		let tmpHistory = [];
		for (let i in reportList.drivingList) {
			tmpHistory.push({
				drivingno: reportList.drivingList[i].DrivingNo,
				status: tmpDrivingStatus(reportList.drivingList[i].DrivingStatus),
				usedDate: tmpDateTime(reportList.drivingList[i]),
				usedDateMobile: tmpDateTime(reportList.drivingList[i], 'mobile'),
				usedDateMobileHourMinute: tmpDateTime2(reportList.drivingList[i], 'mobile'),
				items: [
					{
						...tmpItemOne(reportList.drivingList[i]),
					},
					...tmpEtcAmount(reportList.drivingList[i], reportList.expence),
					...tmpOtherAmount(reportList.drivingList[i], reportList.otherAmount),
					...tmpSelctDriverFee(reportList.drivingList[i]),
				],
				diffFlag: reportList.drivingList[i].ResultUseTime === reportList.drivingList[i].UseMin,
			});
		}
		const history = tmpHistory;

		let condition;
		switch (filterTarget) {
			case 'pending':
				condition = (historyItem) => historyItem.status === 'pending';
				break;
			case 'completed':
				condition = (historyItem) => historyItem.status === 'completed';
				break;
			case 'canceled':
				condition = (historyItem) => historyItem.status === 'canceled';
				break;
			default:
				condition = () => true;
		}

		/*
    공통
    BillingYn이 N일 경우 : 집계중
    BillingYn이 Y일 경우 : 금액노출

    * 구분
    DrivingStatus가 reserve : 이용예정
    DrivingStatus가 use : 이용예정
    DrivingStatus가 cancel : 취소

    * 날짜
    1. DrivingStatus가 use일 경우
    StartDate, EndDate로 표시
    1. DrivingStatus가 reserve, cancel일 경우
    RevStartDt, RevEndDt로 표시

    * 항목
     - 상단
       기본 이용
          Exceedtime이 0일 경우는 "기본이용" 표현
       추가 이용
          Exceedtime이 1 이상일 경우는 "추가이용" 표현
          Exceedtime(minute)를 "시간 분" 형태로 표현
    
    * 반영시간
        ResultUseTime을 "시간 분 " 형태로 표현

    * 후불 청구액
         - 상단
             ExceedAmount가 1 이상이면 금액 표시
          - 하단
             ExceedAmount가 1 이상이면 금액 표시
    */

		const tempRowList = history.filter(condition).map((historyItem) => {
			// filter에 condition func를 넣어서 필터링 하고, 그 결과를 map처리
			let statusText;
			let status;
			switch (historyItem.status) {
				case 'completed':
					statusText = '이용완료';
					break;
				case 'pending':
					statusText = '이용예정';
					break;
				case 'canceled':
					statusText = '취소완료';
					break;
			}
			let rowTypeHtml = ``;
			let additionalFeeHtml = ``;
			let cancelBtnHtml = ``;
			let detailBtnHtml = ``;

			historyItem.items.map((item, index) => {
				const title = item.type;
				let summary;
				switch (item.type) {
					case '기본이용':
						summary = ' - ';
						break;
					case '할증':
					case '기타금액':
					case '지명비':
						summary = item.title;
						break;
					case '초과이용':
					case '초과예약':
						summary = item.usedTime;
						break;
					case '결제대행':
						summary = item.title + ' ' + transFormWon(item.amount);
						break;
				}
				status = `
                    <span class='status ${historyItem.status} fit-content'>${statusText}</span>  
                    `;

				let rowType = '';
				if (title === '기본이용' || title === '할증' || title === '기타금액' || title === '지명비' || title === '결제대행') {
					rowType = ` <span key=${index} class='item-set w-full'>
            <span>${title}</span>
          </span>`;
				} else {
					rowType = ` <span key=${index} class='item-set w-full'>
            <span>${title}</span>
            <span>/</span>
            <span>${summary}</span>
          </span>`;
				}

				let additionalFee = `<span key=${index} class='item-set w-full'>
                                <span>${transFormWon(item.additionalFee)}</span>
                              </span>`;

				rowTypeHtml += rowType;
				additionalFeeHtml += additionalFee;
			});

			let cancelBtn = `cancel_${historyItem.drivingno}`;
			cancelBtnHtml += historyItem.status === 'pending' ? cancelBtn : emptyRow;

			let detailBtn = `detail_${historyItem.drivingno}`;
			detailBtnHtml += detailBtn;

			let toolTipVisible = '';
			if (!historyItem.diffFlag && historyItem.status === 'completed') {
				toolTipVisible = `
          <br/>
          <div class="tooltip-container">
            <img src="../assets/icon_question.svg" alt="Your Image Description" style='padding-top:5px; width:20px' />
            <div class="tooltip-text">최소이용시간으로 차감됩니다.</div>
          </div>`;
			}

			return {
				all: [
					status,
					historyItem.usedDate,
					historyItem.items.filter((item) => item.type === '기본이용' || item.type === '초과이용' || item.type === '초과예약').map((item) => item.resultUsedTime + toolTipVisible),
					rowTypeHtml,

					additionalFeeHtml,
					cancelBtnHtml,
					detailBtnHtml,
				],
				summary: [status, historyItem.usedDate],
			};
		});

		const tempRowListMobile = history.filter(condition).map((historyItem) => {
			// filter에 condition func를 넣어서 필터링 하고, 그 결과를 map처리
			let statusText;
			let status;
			switch (historyItem.status) {
				case 'completed':
					statusText = '이용완료';
					break;
				case 'pending':
					statusText = '이용예정';
					break;
				case 'canceled':
					statusText = '취소완료';
					break;
			}
			let rowTypeHtml = ``;
			let additionalFeeHtml = ``;
			let cancelBtnHtml = ``;
			let PostPaymentArr = [];
			let detailBtnHtml = '';

			historyItem.items.map((item, index) => {
				const title = item.type;
				let summary;
				let tmpObj = {
					title: '',
					amountStr: '',
				};

				switch (item.type) {
					case '기본이용':
						tmpObj.title = `${item.type}`;
						break;
					case '할증':
					case '기타금액':
					case '지명비':
						tmpObj.title = `${item.type}`;
						break;
					case '초과이용':
					case '초과예약':
						tmpObj.title = `${item.type} / ${item.usedTime}`;
						break;
					case '결제대행':
						tmpObj.title = `${item.type}`;
						// summary = item.title + ' ' + transFormWon(item.amount)
						break;
				}
				tmpObj.amountStr = transFormWon(item.additionalFee);
				PostPaymentArr.push(tmpObj);
				status = `<span class='status ${historyItem.status} fit-content'>${statusText}</span>`;

				let rowType = '';
				if (title === '기본이용' || title === '할증' || title === '기타금액' || title === '지명비' || title === '결제대행') {
					rowType = ` <span key=${index} class='item-set w-full'>
            <span>${title}</span>
          </span>`;
				} else {
					rowType = ` <span key=${index} class='item-set w-full'>
            <span>${title}</span>
            <span>/</span>
            <span>${summary}</span>
          </span>`;
				}

				let additionalFee = `<span key=${index} class='item-set w-full'>
                                <span>${transFormWon(item.additionalFee)}</span>
                              </span>`;

				rowTypeHtml += rowType;
				additionalFeeHtml += additionalFee;
			});

			let cancelBtn = `cancel_${historyItem.drivingno}`;
			cancelBtnHtml += historyItem.status === 'pending' ? cancelBtn : emptyRow;

			let detailBtn = `detail_${historyItem.drivingno}`;
			detailBtnHtml += detailBtn;

			let toolTipVisible = '';
			if (!historyItem.diffFlag && historyItem.status === 'completed') {
				toolTipVisible = `
          <div class="tooltip-container">
            <img src="../assets/icon_question.svg" alt="Your Image Description" style=' width:15px' />&nbsp;&nbsp;
            <div class="tooltip-text">최소이용시간으로 차감됩니다.</div>
          </div>`;
			}

			return {
				all: [
					status,
					historyItem.usedDateMobile,
					historyItem.items.filter((item) => item.type === '기본이용' || item.type === '초과이용' || item.type === '초과예약').map((item) => toolTipVisible + item.resultUsedTime),
					rowTypeHtml,

					additionalFeeHtml,
					cancelBtnHtml,
					detailBtnHtml,
					PostPaymentArr,
					historyItem.usedDateMobileHourMinute,
				],
				summary: [status, historyItem.usedDate],
			};
		});

		setRowList(tempRowList);
		setRowListMobile(tempRowListMobile);
		setPage(1);
	}, [reservationHistoryData, filterTarget, reportList]);

	const getDrivingStatusToStr = (DrivingStatus) => {
		let retData = '';
		switch (DrivingStatus) {
			case 'reserve':
				retData = '이용예정';
				break;
			case 'use':
				retData = '이용완료';
				break;
			case 'cancel':
				retData = '취소완료';
				break;
			default:
				retData = '알수없음';
				break;
		}
		return retData;
	};

	const getAddressSummary = (Address) => {
		let tmpArr = Address.split(' ');
		let retData = '';

		if (tmpArr?.length >= 3) {
			retData += `${tmpArr[0]} ${tmpArr[1]} ${tmpArr[2]}`;
		} else {
			retData = Address;
		}

		return retData;
	};

	const getStartEndDate = (StartDt, EndDt) => {
		return `${moment(StartDt).format('HH:mm')} ~ ${moment(EndDt).format('HH:mm')}`;
	};

	const getSumExpence = (expenceArr, DrivingNo) => {
		let retData = 0;
		let tmpArr = [];
		for (let i in expenceArr) {
			if (expenceArr[i].DrivingNo === DrivingNo) {
				tmpArr.push(i);
			}
		}

		for (let s in tmpArr) {
			retData += expenceArr[tmpArr[s]].UseAmount;
		}
		return retData;
	};

	const numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	const Commission20 = (amount) => {
		return amount * 0.2;
	};

	const getExpenceArrIdx = (expenceArr, drivingno) => {
		let retData = [];
		for (let i in expenceArr) {
			if (expenceArr[i].DrivingNo === drivingno) {
				retData.push(i);
			}
		}
		return retData;
	};

	const getExpenceTypeToStr = (type) => {
		let retData = '-';
		switch (type) {
			case '1':
				retData = '주차비';
				break;
			case '2':
				retData = '통행료';
				break;
			case '3':
				retData = '교통비';
				break;
			case '4':
				retData = '기타';
				break;
			default:
				break;
		}
		return retData;
	};

	/*
  * DrivingStatus reserve, use, cancel 한국어 변경필요
  DrivingStatus가 reserve일 경우 
  RevStartDt, RevEndDt가 표시
  RevStartAddress, RevEndAddress가 표시

  DrivingStatus가 use일 경우 
    DrivingStatusCode가 4일 경우 
      RevStartDt, RevEndDt가 표시
      RevStartAddress, RevEndAddress가 표시

    DrivingStatusCode가 5일 경우 
      RevStartDt, RevEndDt가 표시
      StartDate, EndDate가 표시
      StartAddress, EndAddress가 표시
  */

	const showModalDetail = async (reportResult, expenseResult, otherAmountResult) => {
		//console.log(reportResult.SelectDriverFee)
		const mySwal = withReactContent(Swal);
		mySwal.fire({
			title: <SwalTitleComponent title={'운행건 상세정보'} cancelBtn={mySwal.clickCancel} />,
			html: (
				<>
					<div className='title-reportdetail'>운행건</div>
					<div className='content'>
						<div className='content-sub-container'>
							<div className='component'>
								<div>상태</div>
								<div>{getDrivingStatusToStr(reportResult['DrivingStatus'])}</div>
							</div>
							<div className='component'>
								<div>일자</div>
								<div>{moment(reportResult['RevStartDt']).format('yyyy-MM-DD')}</div>
							</div>
							<div className='component'>
								<div>예약시간</div>
								<div>{getStartEndDate(reportResult['RevStartDt'], reportResult['RevEndDt'])}</div>
							</div>

							{reportResult['DrivingStatus'] === 'use' && (
								<div className='component'>
									<div>실 이용시간</div>
									<div>{getStartEndDate(reportResult['StartDate'], reportResult['EndDate'])}</div>
								</div>
							)}

							{(reportResult['DrivingStatus'] === 'reserve' || reportResult['DrivingStatus'] === 'cancel') && (
								<>
									<div className='component'>
										<div>예약 출발지</div>
										<div>{getAddressSummary(reportResult['RevStartAddress'])}</div>
									</div>
									<div className='component'>
										<div>예약 종료지</div>
										<div>{getAddressSummary(reportResult['RevEndAddress'])}</div>
									</div>
								</>
							)}

							{reportResult['DrivingStatus'] === 'use' && (
								<>
									<div className='component'>
										<div>실 출발지</div>
										<div>{getAddressSummary(reportResult['StartAddress'])}</div>
									</div>
									<div className='component'>
										<div>실 종료지</div>
										<div>{getAddressSummary(reportResult['EndAddress'])}</div>
									</div>
								</>
							)}

							{reportResult['DrivingStatus'] === 'cancel' ? (
								<>
									<div className='component'>
										<div>차감시간</div>
										<div>{getTotalMinuteToHourMinuteStr(reportResult['CancelTime'])}</div>
									</div>
								</>
							) : (
								<>
									<div className='component'>
										<div>차감시간</div>
										<div>{getTotalMinuteToHourMinuteStr(reportResult['ResultUseTime'])}</div>
									</div>
								</>
							)}
						</div>
					</div>

					{reportResult.DrivingStatus !== 'cancel' && (
						<>
							{reportResult.ExceedAmount >= 1 ||
							reportResult.SelectDriverFee >= 1 ||
							getSumExpence(expenseResult, reportResult.DrivingNo) >= 1 ||
							reportResult.StartAreaFee >= 1 ||
							reportResult.AreaFee >= 1 ||
							reportResult.NightFee >= 1 ? (
								<>
									<div className='title-reportdetail'>청구 비용 {reportResult.BillingYN === 'N' ? '(예정)' : '(확정)'}</div>
									<div className='content'>
										<div className='content-sub-container'>
											<>
												{reportResult.ExceedAmount >= 1 ||
												reportResult.SelectDriverFee >= 1 ||
												getSumExpence(expenseResult, reportResult.DrivingNo) >= 1 ||
												reportResult.StartAreaFee >= 1 ||
												reportResult.AreaFee >= 1 ||
												reportResult.NightFee >= 1 ? (
													<>
														{reportResult.ExceedAmount >= 1 ? (
															<>
																<div className='component bold'>
																	<div className=''>
																		<span>초과차감정보</span>
																	</div>
																	<div className=''>
																		<span></span>
																	</div>
																</div>
																<div className='component'>
																	<div className='indent'>
																		<span>{reportResult.DrivingStatus !== 'use' ? '초과예약시간' : '초과이용시간'}</span>
																	</div>
																	<div className=''>
																		<span>{getTotalMinuteToHourMinuteStr(reportResult.ExceedTime)}</span>
																	</div>
																</div>
																<div className='component'>
																	<div className='indent'>
																		<span>시간당단가</span>
																	</div>
																	<div className=''>
																		<span>{numberWithCommas(reportResult.MmHourPriceHistory + '')}원</span>
																	</div>
																</div>

																<div className='component'>
																	<div className='indent'>
																		<span>{reportResult.DrivingStatus !== 'use' ? '초과예약' : '초과이용'}</span>
																	</div>
																	<div className=''>
																		<span>{numberWithCommas(reportResult.ExceedAmount + '')}원</span>
																	</div>
																</div>
															</>
														) : null}

														<>
															{reportResult.SelectDriverFee >= 1 && (
																<>
																	<div className='component bold'>
																		<div className=''>
																			<span>지명비</span>
																		</div>
																		<div className=''>
																			<span>{numberWithCommas(reportResult.SelectDriverFee)}원</span>
																		</div>
																	</div>
																</>
															)}
														</>

														<>
															{(reportResult.StartAreaFee >= 1 || reportResult.AreaFee >= 1 || reportResult.NightFee >= 1) && (
																<>
																	<div className='component bold'>
																		<div className=''>
																			<span>할증</span>
																		</div>
																		<div className=''>
																			<span>{numberWithCommas(reportResult.StartAreaFee + reportResult.AreaFee + reportResult.NightFee + '')}원</span>
																		</div>
																	</div>

																	{reportResult.StartAreaFee !== 0 || reportResult.AreaFee !== 0 ? (
																		<div className='component'>
																			<div className='indent'>
																				<span>지역할증</span>
																			</div>
																			<div className=''>
																				<span>{numberWithCommas(reportResult.StartAreaFee + reportResult.AreaFee + '')}원</span>
																			</div>
																		</div>
																	) : null}

																	{reportResult.NightFee !== 0 ? (
																		<div className='component'>
																			<div className='indent'>
																				<span>심야할증</span>
																			</div>
																			<div className=''>
																				<span>{numberWithCommas(reportResult.NightFee + '')}원</span>
																			</div>
																		</div>
																	) : null}
																</>
															)}
														</>

														{/* 결제대행 (실비) */}
														{getSumExpence(expenseResult, reportResult.DrivingNo) >= 1 ? (
															<>
																<div className='component bold'>
																	<div className=''>
																		<span>결제대행</span>
																	</div>
																	<div className=''>
																		<span>
																			{numberWithCommas(
																				getSumExpence(expenseResult, reportResult.DrivingNo) +
																					Commission20(getSumExpence(expenseResult, reportResult.DrivingNo)) +
																					''
																			)}
																			원
																		</span>
																	</div>
																</div>
															</>
														) : null}
													</>
												) : null}

												{getExpenceArrIdx(expenseResult, reportResult.DrivingNo).length >= 1 ? (
													<>
														{/* {tmpHeaderComp('결제대행내역')} */}
														{getExpenceArrIdx(expenseResult, reportResult.DrivingNo).map((tmpdata) => {
															return (
																<>
																	<div className='component'>
																		<div className='indent'>
																			<span>{getExpenceTypeToStr(expenseResult[tmpdata].ItemCode)}</span>
																		</div>
																		<div className=''>
																			<span>{numberWithCommas(expenseResult[tmpdata].UseAmount)}원</span>
																		</div>
																	</div>
																</>
															);
														})}
														<div className='component'>
															<div className='indent'>
																<span>대행수수료</span>
															</div>
															<div className=''>
																<span>{numberWithCommas(Commission20(getSumExpence(expenseResult, reportResult.DrivingNo))) + ''}원</span>
															</div>
														</div>
													</>
												) : null}

												{getExpenceArrIdx(otherAmountResult, reportResult.DrivingNo).length >= 1 ? (
													<>
														<div className='component bold'>
															<div className=''>
																<span>기타금액</span>
															</div>
															<div className=''>
																<span>{numberWithCommas(reportResult.BillingOtherAmount)}원</span>
															</div>
														</div>
														{/* {tmpHeaderComp('기타금액')} */}
														{getExpenceArrIdx(otherAmountResult, reportResult.DrivingNo).map((tmpdata) => {
															return (
																<>
																	<div className='component'>
																		<div className='indent'>
																			<span>기타금액 ({otherAmountResult[tmpdata].Category})</span>
																		</div>
																		<div className=''>
																			<span>{numberWithCommas(otherAmountResult[tmpdata].UseAmount)}원</span>
																		</div>
																	</div>
																</>
															);
														})}
													</>
												) : null}
											</>
										</div>
									</div>
								</>
							) : (
								<></>
							)}
						</>
					)}

					{reportResult.DrivingStatus === 'cancel' && (
						<>
							<div className='title-reportdetail'>취소수수료</div>
							<div className='content'>
								<div className='content-sub-container'>
									<>
										{/* {tmpHeaderComp('취소 수수료')} */}
										<div className='component bold'>
											<div className=''>
												<span>취소수수료</span>
											</div>
											<div className=''>
												<span>{reportResult.CancelTime >= 1 || reportResult.CancelAmount >= 1 ? '발생' : '미발생'}</span>
											</div>
										</div>

										{reportResult.CancelTime >= 1 ? (
											<div className='component'>
												<div className='indent'>
													<span>취소차감시간</span>
												</div>
												<div className=''>
													<span>{getTotalMinuteToHourMinuteStr(reportResult.CancelTime)}</span>
												</div>
											</div>
										) : null}
										{reportResult.CancelAmount >= 1 ? (
											<div className='component'>
												<div className='indent'>
													<span>취소차감금액</span>
												</div>
												<div className=''>
													<span>{numberWithCommas(reportResult.CancelAmount + '')}</span>
												</div>
											</div>
										) : null}
									</>
								</div>
							</div>
						</>
					)}
				</>
			),
			preConfirm: () => {
				// alert('showDetail');
			},
			confirmButtonText: '확인',
			confirmButtonColor: '#1eafd2',

			// cancelButtonText:'취소',
			// showCancelButton:true,
			// cancelButtonColor: '#f6f6f6',
		});
	};

	const getPartnerLoginedInfo = () => {
		let cookieInfo = getCookieInfo();

		return {
			User: JSON.parse(DecryptAES(cookieInfo?.lgi)),
			UserPool: JSON.parse(DecryptAES(cookieInfo?.lgupi)),
		};
	};

	const onClickSeeDetailHandler = (index) => {
		let drivingNo = index.split('_')[1];

		// retType : object   (항상존재)
		let reportResult = reportList.drivingList.find((data) => data.DrivingNo === Number(drivingNo));
		// retType : array    (없을 수 있음.)
		let expenseResult = reportList.expence.filter((data) => data.DrivingNo === Number(drivingNo));
		// retType : array    (없을 수 있음.)
		let otherAmountResult = reportList.otherAmount.filter((data) => data.DrivingNo === Number(drivingNo));

		// console.log(reportResult)
		// console.log(expenseResult)
		showModalDetail(reportResult, expenseResult, otherAmountResult);

		// alert(index)
	};

	const onClickContractDateList = () => {
		// console.log(convertContractRanges(reportInfo.ContractDateList))
	};

	// 취소시
	const onClickCancelHandler = async (id) => {
		if (ADMIN_FLAG) {
			alert('이 상태에서는 취소가 불가능합니다.');
			return;
		}

		let drivingNo = id.split('_')[1];
		dispatch(openLoading());
		let result = await ReserveApi.CanCancelDriving(drivingNo);
		if (isChkResultSuccess(result)) {
			if (result.Item.CanCancel) {
				if (window.confirm('예약 취소를 진행하시겠습니까?')) {
					let result2 = await ReserveApi.CancelDrivingReservation(getMasterAccountCustomerNo([]), drivingNo, getPartnerLoginedInfo().User.UseType, getPartnerLoginedInfo().User.CustomerNo);
					if (isChkResultSuccess(result2)) {
						alert('취소 완료 되었습니다.');

						let result3 = await ReportApi.GetMmReportList(
							getMasterAccountCustomerNo(loginInfo.UserPool),
							reportInfo.ContractDateList[reportDateIndex].StartDate,
							reportInfo.ContractDateList[reportDateIndex].EndDate
						);

						if (isChkResultSuccess(result3)) {
							dispatch(reportSummary(result3.Item));
							// dispatch(reportDrivingList(result2.ItemList))
							SetReportList({
								expence: result3.Item.expence,
								otherAmount: result2.Item.otherAmount,
								drivingList: result3.ItemList.sort((a, b) => {
									const dateA = new Date(a.RevStartDt);
									const dateB = new Date(b.RevStartDt);

									// 내림차순 정렬을 위해 b와 a의 순서를 바꿈
									return dateB - dateA;
								}),
							});
						}
					} else {
						alert(result2.Message);
					}
				}
			} else {
				alert(result.Message);
			}
		} else {
			alert(result.Message);
		}
		dispatch(closeLoading());
	};

	return (
		<>
			<div className={classNames('reservation-history table')}>
				<div className={classNames('reservation-history-summary')}>
					<div className={classNames('reservation-history-section')}>
						<WhiteBox title={'기간'}>
							<span className={classNames('txt-regular')}>
								{/* 
                1. Array.map 처리 
                2. length가 2 이상일 경우 에는 양옆에 화살표 버튼 추가.
                3. 화살표 버튼 누를때마다 날짜 바뀌고, Report 내용도 업데이트 되도록 처리 필요. (기존 월간모시러 Report 참조.)
                */}
								<div className={classNames('date-flex')} style={{ marginBottom: '17px' }}>
									{reportDateIndex <= 0 ? (
										<div>
											<span style={{ width: '100%', transform: 'scale(0.5)', color: 'gray', fontWeight: 'bold' }}>{'<'}</span>

											{/* <img src={icon_report_date_left} style={{ width: '100%', transform: 'scale(0.3)', opacity: '0' }} alt="" /> */}
										</div>
									) : (
										<div
											onClick={() => {
												dispatch(openLoading());
												SetReportDateIndex(reportDateIndex - 1);
											}}
											style={{ cursor: 'pointer' }}
										>
											<span style={{ width: '100%', transform: 'scale(0.5)', color: '#1eafd2', fontWeight: 'bold' }}>{'<'}</span>
											{/* <img src={icon_report_date_left} style={{ width: '100%', transform: 'scale(0.3)' }} alt="" /> */}
										</div>
									)}

									<div
										onClick={() => {
											onClickContractDateList();
										}}
									>
										<span>
											{reportInfo.ContractDateList[reportDateIndex].StartDate} ~ {reportInfo.ContractDateList[reportDateIndex].EndDate}
										</span>
									</div>

									{reportDateIndex >= reportDateMaxLength - 1 ? (
										<div>
											<span style={{ width: '100%', transform: 'scale(0.5)', color: 'gray', fontWeight: 'bold' }}>{'>'}</span>
											{/* <img src={icon_report_date_right} style={{ width: '100%', transform: 'scale(0.3)', opacity: '0' }} alt="" /> */}
										</div>
									) : (
										<div
											onClick={() => {
												dispatch(openLoading());
												SetReportDateIndex(reportDateIndex + 1);
											}}
											style={{ cursor: 'pointer' }}
										>
											<span style={{ width: '100%', transform: 'scale(0.5)', color: '#1eafd2', fontWeight: 'bold' }}>{'>'}</span>
											{/* <img src={icon_report_date_right} style={{ width: '100%', transform: 'scale(0.3)' }} alt="" /> */}
										</div>
									)}
								</div>
							</span>
						</WhiteBox>
						<WhiteBox title={'이용시간'}>
							{/* {avaliableData.UseTime >= 1 && avaliableData.AvaliableTime >= 1 && } */}
							<PercentageGraphCustom
								originTime={reportInfo.Summary.ContractTypeDay * reportInfo.Summary.ContractTypeHour * 60}
								useTime={reportInfo.Summary.UseTimes}
								reserveTime={reportInfo.Summary.ReserveTimes}
								exceedTimes={reportInfo.Summary.ExceedTimes}
								overcarriedMinute={reportInfo.Summary.OvercarriedMinute}
							/>
						</WhiteBox>
					</div>
					<div className={classNames('reservation-history-section')}>
						{/* <PaymentHistory title={'결제'} reservationHistory={reservationHistoryData} /> */}
						<PaymentHistory title={'결제'} reservationHistory={reportInfo.Summary} mmCustomerInfo={mmCustomerInfo} />
					</div>

					<div className={classNames('reservation-history-section')}>
						<WhiteBox title={'계약정보'}>
							<div className={classNames('pending-payment-item')}>
								<div className={classNames('payment-wrap')}>
									<span className={classNames('txt-regular')}>이용상품</span>
									<span className={'amount-set'}>
										<span className={classNames('txt-regular')}>{mmCustomerInfo.ContractTypeDay <= 1 ? '연간모시러' : '월간모시러'}</span>
									</span>
								</div>

								<div className={classNames('payment-wrap')}>
									<span className={classNames('txt-regular')}>계약기간</span>
									<span className={'amount-set'}>
										<span className={classNames('txt-regular')}>
											{moment(mmCustomerInfo.ContractStartDate).format('yyyy-MM-DD')}~{moment(mmCustomerInfo.ContractEndDate).format('yyyy-MM-DD')}
										</span>
									</span>
								</div>

								<div className={classNames('payment-wrap')}>
									<span className={classNames('txt-regular')}>시간당 단가</span>
									<span className={'amount-set'}>
										<span className={classNames('txt-regular')}>{transFormWon(mmCustomerInfo.MmHourPrice)}</span>
									</span>
								</div>

								<div className={classNames('payment-wrap')}>
									<span className={classNames('txt-regular')}>{mmCustomerInfo.ContractTypeDay <= 1 ? '연' : '월'} 기본시간</span>
									<span className={'amount-set'}>
										<span className={classNames('txt-regular blue')}>{mmCustomerInfo.ContractTypeDay * mmCustomerInfo.ContractTypeHour}시간</span>
									</span>
								</div>

								{reportInfo?.Summary?.OvercarriedMinute >= 1 && (
									<div className={classNames('payment-wrap')}>
										<span className={classNames('txt-regular')}>프로모션시간</span>
										<span className={'amount-set'}>
											<span className={classNames('txt-regular blue')}>
												{Math.floor(reportInfo.Summary.OvercarriedMinute / 60)}시간 {Math.floor(reportInfo.Summary.OvercarriedMinute % 60)}분
											</span>
										</span>
									</div>
								)}

								{/* <i className={classNames('blue')}>{Math.floor((useTime + reserveTime) / 60)}시간 {(useTime + reserveTime) % 60}분</i> 이용 ({Math.floor(percentage)}%) */}
								{/* OvercarriedMinute */}

								<div className={classNames('payment-wrap')}>
									<span className={classNames('txt-regular')}>1회 최소이용시간</span>
									<span className={'amount-set'}>
										<span className={classNames('txt-regular')}>{mmCustomerInfo.ContractOneDayAvaliableHour}시간</span>
									</span>
								</div>
							</div>
						</WhiteBox>
					</div>
				</div>
				<div className='alert-list'>
					<WhiteBox classes={'mb-20'}>
						<div className='flex justify-between gap-20 align-center mobile-column'>
							<div className='flex gap-12 align-center mobile-w-full'>
								<Icon icon={'bell'} />
								<span className='lh1'>112시간 중 100시간을 사용하셨습니다.</span>
							</div>
							<div className='button-set mobile-w-full'>
								<Button classes={'w-100 p-12 grey-4'}>확인</Button>
							</div>
						</div>
					</WhiteBox>
					<WhiteBox classes={'mb-20'}>
						<div className='flex justify-between gap-20 align-center mobile-column '>
							<div className='flex gap-12 align-center mobile-w-full'>
								<Icon icon={'bell'} />
								<span className='lh1'>초과 이용하셨기에 XXX 상품을 추천드립니다.</span>
							</div>
							<div className='button-set gap-8 mobile-w-full'>
								<Button classes={'w-100 p-12 grey-4'}>닫기</Button>
								<Button styleType='pale-blue' classes={'w-100 p-12 grey-4'}>
									변경하기
								</Button>
							</div>
						</div>
					</WhiteBox>
				</div>

				{isPC ? (
					<WhiteBox>
						<div className={classNames('reservation-history-table')}>
							{/** table title **/}
							<div className={'table-title'}>
								<div className={classNames('table-title-left')}>
									<span className={classNames('title-mid')}>상세 내역</span>
									<RadioGroup legend={'filter'} selected={filterTarget} classes={'filter-list radio-list'}>
										{filterList.map((target) => {
											return (
												<span className={classNames('filter-item')} key={target.value}>
													<Radio
														key={target.value}
														value={target.value}
														onChange={(e) => {
															setFilterTarget(target.value);
														}}
														label={target.title}
													/>
												</span>
											);
										})}
									</RadioGroup>
								</div>

								<Button
									styleType={'grey-2'}
									classes={`${isPC ? 'txt-regular' : 'txt-small'} download`}
									onClick={async () => {
										dispatch(openLoading());

										await ReportApi.GetReportExcelDownload(
											getCustomerNoSwitching(),
											moment(reportInfo.ContractDateList[reportDateIndex].StartDate).format('yyyy-MM-DD'),
											moment(reportInfo.ContractDateList[reportDateIndex].EndDate).format('yyyy-MM-DD')
										);
										dispatch(closeLoading());
									}}
								>
									파일 다운로드
								</Button>
							</div>

							{/** table **/}
							<Table titleList={isPC ? ReservationTableTitleList : ReservationTableTitleSummaryList}>
								{rowList.slice(offset, offset + limit).map((row, index) => {
									return (
										<TableRowCustom
											key={index}
											row={isPC ? row.all : row.summary}
											seeDetail={!isPC && true}
											onClickSeeDetail={onClickSeeDetailHandler}
											onClickCancel={onClickCancelHandler}
										/>
									);
								})}
							</Table>

							{/** table pagination **/}
							<Pagination page={page} setPage={setPage} totalPosts={rowList.length} limit={limit} />
						</div>
					</WhiteBox>
				) : (
					<div className={classNames('reservation-history-table')}>
						<WhiteBox>
							{/** table title **/}
							<div className={'table-title'}>
								<div className={classNames('table-title-left')}>
									<span className={classNames('title-mid')}>상세 내역</span>
									<RadioGroup legend={'filter'} selected={filterTarget} classes={'filter-list radio-list'}>
										{filterList.map((target) => {
											return (
												<span className={classNames('filter-item')} key={target.value}>
													<Radio
														key={target.value}
														value={target.value}
														onChange={(e) => {
															setFilterTarget(target.value);
														}}
														label={target.title}
													/>
												</span>
											);
										})}
									</RadioGroup>
								</div>
							</div>

							<Pagination page={page} setPage={setPage} totalPosts={rowListMobile.length} limit={limit} />
						</WhiteBox>

						{rowListMobile.slice(offset, offset + limit).map((row, index) => {
							return (
								<>
									<div className={classNames('white-box')} style={{ marginTop: '10px', marginBottom: '10px' }}>
										<div className={classNames('pending-payment-item')}>
											<div className={classNames('title-set')} style={{ gap: '5px' }}>
												<div dangerouslySetInnerHTML={{ __html: row.all[0] }} />
											</div>

											<div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
												<span className={classNames('txt-regular')}>일시</span>
												<span className={'amount-set'}>
													<span className={classNames('txt-regular')}>
														<div className={classNames('txt-regular')} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{ __html: row.all[1] }} />
													</span>
												</span>
											</div>
											<div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
												<span className={classNames('txt-regular')}>실이용시간</span>
												<span className={'amount-set'}>
													<span className={classNames('txt-regular')}>
														<div className={classNames('txt-regular')} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{ __html: row.all[8] }} />
													</span>
												</span>
											</div>
											<div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
												<span className={classNames('txt-regular')}>차감시간</span>
												<span className={'amount-set'}>
													<span className={classNames('txt-regular')}>
														<div className={classNames('txt-regular')} style={{ display: 'flex' }} dangerouslySetInnerHTML={{ __html: row.all[2] }} />
													</span>
												</span>
											</div>

											<div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
												<span className={classNames('txt-regular')}>후불청구액</span>
											</div>

											<ul className={classNames('pending-details')}>
												{row.all[7].map((data, idx) => {
													return (
														<li key={idx}>
															<span className={classNames('txt-small grey-3')}>{data.title}</span>
															<span className={classNames('txt-small grey-3')}>{data.amountStr}</span>
														</li>
													);
												})}
											</ul>

											{row.all[5].indexOf(`cancel_`) >= 0 && (
												<div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
													<Button
														className={'bg-red'}
														style={{ width: '100%', height: '40px' }}
														onClick={() => {
															onClickCancelHandler(row.all[5]);
														}}
													>
														예약취소하기
													</Button>
												</div>
											)}

											{row.all[6].indexOf(`detail_`) >= 0 && (
												<div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
													<Button
														className={'bg-green'}
														style={{ width: '100%', height: '40px' }}
														onClick={() => {
															onClickSeeDetailHandler(row.all[6]);
														}}
													>
														상세보기
													</Button>
												</div>
											)}
										</div>
									</div>
								</>
							);
						})}
					</div>
				)}
			</div>
			{showDescriptionIndex >= 0 && !isPC && (
				<MobileDescriptionPop title={'결제내역'} setShowDescriptionIndex={setShowDescriptionIndex}>
					<TableDescriptPopContentsCustom theadList={ReservationTableTitleList} tbody={rowList[showDescriptionIndex]} onClickCancel={onClickCancelHandler} />
				</MobileDescriptionPop>
			)}

			{/* <Button type='button' onClick={() => { setModalTest(true) }}>헬로우월드</Button> */}

			{/* <Modal
        isOpen={modalTest}
        onRequestClose={() => { setModalTest(false) }}
        contentLabel="Example Modal"
        overlayClassName=""
        className="modal"
        bodyOpenClassName="modal-scrolllock" >
        <section className="modal-container">
          <div className="modal">
            <div className="modal-head">
              <h1 className="modal-tit" tabindex="0">할증 요금 안내</h1>
            </div>
            <div className="modal-body">
              <div className="modal-contents">
                <div className="modal-contents-body">
                  <div className="component-wrap">
                    <div className="inner-container">

                      <div className="form-controls type-carinfo" style={{ paddingBottom: '10px' }}>
                        <div className="row">
                          <input type="text" name="customerName" id="" placeholder="차량 소유주 이름" title="" className='input type1' />
                          <input type="text" name="carNo" id="" placeholder="차량 번호 ex: 12가1234" title="" className='input type1' />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button className="btn-top-close" onClick={() => { setModalTest(false) }}>
              <span className="blind">팝업 닫기</span>
            </button>


          </div>
        </section>
      </Modal> */}
		</>
	);
};

export default ReservationHistory;
