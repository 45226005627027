import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import WhiteBox from '../../atoms/WhiteBox';
import { reservationHistoryData } from '../../../archive/temporaryData';
import PercentageGraph from '../../molecules/PercentageGraph';
import PaymentHistory from '../../organisms/PaymentHistory';
import Button from '../../atoms/Button';
import Table from '../../organisms/Table';
import { ReservationTableTitleList, ReservationTableTitleSummaryList, DriverDispatchTableTitleList, DriverDispatchTableTitleSummaryList } from '../../../utils/ReservationFormData';
import TableRow from '../../molecules/TableRow';
import { transFormWon } from '../../../utils/numbers';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import Pagination from '../../molecules/Pagination';
import MobileDescriptionPop from '../../organisms/MobileDescriptionPop';
import TableDescriptPopContents from '../../molecules/TableDescriptPopContents';
import useCheckPC from '../../../hooks/useCheckPC';
import ReportApi from '../../../lib/ReportApi'
import { useSelector, useDispatch } from 'react-redux';
import { getMasterAccountCustomerNo, isChkResultSuccess, getEnglishDayToKorean, getHourMinute, getMasterAccountCustomerNoDd } from '../../../lib/Util'
import { reportContractDateList, reportSummary, reportDrivingList } from '../../../reducers/Report'
import icon_report_date_left from '../../../styles/img/icon_report_date_left.svg'
import icon_report_date_right from '../../../styles/img/icon_report_date_right.svg'
import moment from 'moment';
import MyPageApi from '../../../lib/MyPageApi';
import { closeLoading, openLoading } from '../../../reducers/loadingRedux';

/**
 * 이용 내역 페이지
 * name : reservation history
 * TODO: 고객 데이터로 값 대체
 * */

const DriverDispatchManageHistory = () => {
  const { isPC } = useCheckPC();
  const [period, setPeriod] = useState({});
  const [rowList, setRowList] = useState([]);
  const [filterTarget, setFilterTarget] = useState('all');
  const [showDescriptionIndex, setShowDescriptionIndex] = useState(-1)

  /** pagination **/
  // 한 페이지에 보여줄 게시물 개수
  const limit = 10;
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(1);

  const [reportDateIndex, SetReportDateIndex] = useState(0)
  const [reportDateMaxLength, SetReportDateMaxLength] = useState(0)
  const [reportDateFirstLoading, SetReportDateFirstLoading] = useState(false)
  const [avaliableData, SetAvaliableData] = useState({
    diffKiloMeter: 0,
    weekdayGeneralWorkTime: 0,
    weekdayOverWorkTime: 0,
    weekendGeneralWorkTime: 0,
    weekendOverWorkTime: 0,
    nightWorkTime: 0,
    ExpensesAmountAll: 0,
  })
  const [reportList, SetReportList] = useState({
    expence: [{
      StartDate: moment().format('yyyy-MM-DD HH:mm'),
      ItemCode: "3",
      DrivingNo: 329,
      UseAmount: 10680,
      BillingYN: "N"
    }],
    drivingList: [{
      DrivingNo: 0,
      DriverNo: 0,
      DriverName: "",
      CustomerNo: 0,
      CustomerName: "",
      StartDate: moment().format('yyyy-MM-DD HH:mm'),
      EndDate: moment().format('yyyy-MM-DD HH:mm'),
      WorkedMinute: 0,
      StartAddress: "",
      EndAddress: "",
      DrivingType: "100",
      ReserveType: "0",
      DrivingStatus: "5",
      StartKiloMeter: 0,
      EndKiloMeter: 0,
      DiffKiloMeter: 0,
      WorkStartDt: moment().format('yyyy-MM-DD HH:mm'),
      WorkEndDt: moment().format('yyyy-MM-DD HH:mm'),
      CustomerSettingWorkedMinute: 0,
      RestMinute: 0,
      TotalWorkTime: 0,
      RegularWorkTime: 0,
      Overtime: 0,
      WeekendRegularWorkTime: 0,
      WeekendOvertime: 0,
      NightTime: 0,
      RegDt: moment().format('yyyy-MM-DD HH:mm'),
    },]
  })

  const loginInfo = useSelector(state => state.Login);
  const reportInfo = useSelector(state => state.ReportRedux);
  const dispatch = useDispatch();

  useEffect(() => {
    const tempPage = (page - 1) * limit;
    setOffset(tempPage)
  }, [page])

  // 최초 로딩 시의 기본 데이터
  useEffect(() => {
    async function getData() {
      dispatch(openLoading())
      let dateArr = [];
      let result3 = await MyPageApi.GetMyPageDriverDispatch(getMasterAccountCustomerNoDd(loginInfo.UserPool))
      if (isChkResultSuccess(result3)) {
        dateArr = ReportApi.GetContractDateToArr(result3.Item.DriverDispatchInfo.ContractDate)
        dispatch(reportContractDateList(dateArr))
      }

      let result2 = await ReportApi.GetDriverDispatchReportList(getMasterAccountCustomerNoDd(loginInfo.UserPool), dateArr[dateArr.length - 1].StartDate, dateArr[dateArr.length - 1].EndDate)

      if (isChkResultSuccess(result2)) {
        dispatch(reportSummary(result2.Item))
        // dispatch(reportDrivingList(result2.ItemList))
        SetReportList({
          expence: result2.Item.expence,
          drivingList: result2.ItemList.sort((a, b) => {
            const dateA = new Date(a.StartDate);
            const dateB = new Date(b.EndDate);

            // 내림차순 정렬을 위해 b와 a의 순서를 바꿈
            return dateB - dateA;
          })
        })

        SetReportDateMaxLength(dateArr.length)
        SetReportDateIndex(dateArr.length - 1)
        SetAvaliableData(result2.Item)
      }
      dispatch(closeLoading())
    }

    getData()
  }, [])

  // 로딩 후 날짜 변경시의 처리
  useEffect(() => {
    async function getData() {

      dispatch(openLoading())
      if (!reportDateFirstLoading) {
        SetReportDateFirstLoading(true)
      }
      else {
        let result2 = await ReportApi.GetDriverDispatchReportList(
          getMasterAccountCustomerNoDd(loginInfo.UserPool),
          reportInfo.ContractDateList[reportDateIndex].StartDate,
          reportInfo.ContractDateList[reportDateIndex].EndDate
        )


        if (isChkResultSuccess(result2)) {
          dispatch(reportSummary(result2.Item))
          // dispatch(reportDrivingList(result2.ItemList))
          SetReportList({
            expence: result2.Item.expence,
            drivingList: result2.ItemList.sort((a, b) => {
              const dateA = new Date(a.RevStartDt);
              const dateB = new Date(b.RevStartDt);

              // 내림차순 정렬을 위해 b와 a의 순서를 바꿈
              return dateB - dateA;
            })
          })
        }

      }
      dispatch(closeLoading())
    }
    getData();
  }, [reportDateIndex])


  const filterList = [
    {
      value: 'all',
      title: '전체',
    },
    // {
    //   value: 'pending',
    //   title: '이용예정'
    // },
    // {
    //   value: 'completed',
    //   title: '이용완료'
    // },
    // {
    //   value: 'canceled',
    //   title: '취소'
    // }
  ]



  // 이용예정, 이요완료, 취소를 눌렀을 때 실행
  // 분석하느라 시간 좀 소요될 것 같음..  이부분은 패스?
  useEffect(() => {
    setPeriod(reservationHistoryData.period)
    // const history = reservationHistoryData.history
    const emptyRow = '-'
    const tmpDrivingStatus = (status) => {
      let retData = ''
      switch (status) {
        case 'reserve':
          retData = 'pending'
          break;
        case 'use':
          retData = 'completed'
          break;
        case 'cancel':
          retData = 'canceled'
          break;
        default:
          retData = 'completed'
          break;
      }
      return retData
    }


    //getEnglishDayToKorean
    const tmpDateTime = (Driving) => (
      Driving.DrivingStatus === '5' ?
        `${moment(Driving.StartDate).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.StartDate).format('ddd'))}) ${moment(Driving.StartDate).format('HH:mm')}~${moment(Driving.EndDate).format('HH:mm')}` :
        `${moment(Driving.RevStartDt).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.RevStartDt).format('ddd'))}) ${moment(Driving.RevStartDt).format('HH:mm')}~${moment(Driving.RevEndDt).format('HH:mm')}`
    )


    const tmpItemOne = (Driving) => {
      let tmpArr = []
      let tmpObjKeySetting = [
        { koreanName: '평일 기본 근무', key: 'RegularWorkTime', },
        { koreanName: '평일 시간 외 근무', key: 'Overtime', },
        { koreanName: '주말 기본 근무', key: 'WeekendRegularWorkTime', },
        { koreanName: '주말 시간 외 근무', key: 'WeekendOvertime', },
        { koreanName: '야간 근무', key: 'NightTime', }
      ]

      for (let i in tmpObjKeySetting) {

        if (Driving[tmpObjKeySetting[i].key] >= 1) {
          let tmpData = Driving[tmpObjKeySetting[i].key]
          tmpArr.push({
            type: tmpObjKeySetting[i].koreanName,
            usedTime: `${Math.floor(tmpData / 60)}시간 ${tmpData % 60}분`,
            resultUsedTime: '',
            additionalFee: 0
          })
        }
      }


      return tmpArr

    }



    const tmpEtcAmount = (Driving, Expenses) => {
      let tmpArr = [];

      const getExpenceTypeToStr = (type) => {
        let retData = '-';
        switch (type) {
          case '1':
            retData = '주차비'
            break;
          case '3':
            retData = '교통비'
            break;
          case '4':
            retData = '기타'
            break;
          default:
            break;
        }
        return retData;
      }

      const getExpenceArrIdx = (expenceArr, drivingno) => {
        let retData = [];
        for (let i in expenceArr) {
          if (expenceArr[i].DrivingNo === drivingno) {
            retData.push(i);
          }
        }
        return retData;
      }


      // 할증 관련 처리
      if (Driving.StartAreaFee >= 1) {
        tmpArr.push({
          type: '할증',
          title: '출발지할증',
          amount: Driving.StartAreaFee,
          additionalFee: Driving.StartAreaFee
        })
      }
      if (Driving.AreaFee >= 1) {
        tmpArr.push({
          type: '할증',
          title: '지역할증',
          amount: Driving.AreaFee,
          additionalFee: Driving.AreaFee
        })
      }
      if (Driving.NightFee >= 1) {
        tmpArr.push({
          type: '할증',
          title: '심야할증',
          amount: Driving.NightFee,
          additionalFee: Driving.NightFee
        })
      }
      // 결제대행 처리
      let tmpExpenseArr = getExpenceArrIdx(Expenses, Driving.DrivingNo)

      if (tmpExpenseArr.length >= 1) {
        for (let i in tmpExpenseArr) {
          tmpArr.push({
            type: '결제대행',
            title: getExpenceTypeToStr(Expenses[tmpExpenseArr[i]]?.ItemCode),
            amount: Expenses[tmpExpenseArr[i]]?.UseAmount,
            additionalFee: Expenses[tmpExpenseArr[i]]?.UseAmount + (Expenses[tmpExpenseArr[i]]?.UseAmount * 0.2)
          })
        }
      }

      return tmpArr

    }



    let tmpHistory = []
    for (let i in reportList.drivingList) {
      tmpHistory.push({
        status: tmpDrivingStatus(reportList.drivingList[i].DrivingStatus),
        usedDate: tmpDateTime(reportList.drivingList[i]),
        items: [
          ...tmpItemOne(reportList.drivingList[i]),
          ...tmpEtcAmount(reportList.drivingList[i], reportList.expence)
        ],
        totalWorkTime: reportList.drivingList[i].TotalWorkTime,
        useageKiloMeter: reportList.drivingList[i].DiffKiloMeter,
      })
    }
    const history = tmpHistory



    let condition;
    switch (filterTarget) {
      case 'pending':
        condition = historyItem => historyItem.status === 'pending';
        break;
      case 'completed':
        condition = historyItem => historyItem.status === 'completed';
        break;
      case 'canceled':
        condition = historyItem => historyItem.status === 'canceled';
        break;
      default:
        condition = () => true;
    }


    const tempRowList =
      history.filter(condition).map((historyItem) => {  // filter에 condition func를 넣어서 필터링 하고, 그 결과를 map처리
        let statusText;
        let status;
        switch (historyItem.status) {
          case 'completed':
            statusText = '이용완료'
            break
          case 'pending':
            statusText = '이용예정'
            break
          case 'canceled':
            statusText = '취소'
            break
        }
        let rowTypeHtml = ``;
        let additionalFeeHtml = ``;

        historyItem.items.map((item, index) => {
          const title = item.type
          let summary;
          switch (item.type) {
            case '기본이용':
              summary = ' - '
              break
            case '할증':
              summary = item.title
              break
            case '추가이용':
            case '평일 기본 근무':
            case '평일 시간 외 근무':
            case '주말 기본 근무':
            case '주말 시간 외 근무':
            case '야간 근무':
              summary = item.usedTime
              break
            case '결제대행':
              summary = item.title + ' ' + transFormWon(item.amount)
              break
          }
          status = `
                    <span class='status ${historyItem.status} fit-content'>${statusText}</span>  
                    `
          let rowType = ` <span key=${index} class='item-set w-full'>
                            <span>${title}</span>
                            <span>/</span>
                            <span>${summary}</span>
                          </span>`

          let additionalFee = `<span key=${index} class='item-set w-full'>
                                <span>${transFormWon(item.additionalFee)}</span>
                              </span>`

          rowTypeHtml += rowType
          additionalFeeHtml += additionalFee
        })
        return (
          {
            all: [
              status,
              historyItem.usedDate,
              rowTypeHtml,
              `${Math.floor(historyItem.totalWorkTime / 60)}시간 ${historyItem.totalWorkTime % 60}분`,
              additionalFeeHtml,
              `${historyItem.useageKiloMeter}km`,
              emptyRow
            ],
            summary: [
              status,
              historyItem.usedDate,
            ]
          }
        )
      })
    setRowList(tempRowList)
    setPage(1);
  }, [reservationHistoryData, filterTarget, reportList])

  const onClickSeeDetailHandler = (index) => {
    setShowDescriptionIndex(index)
  }

  return (
    <>
      <div className={classNames('reservation-history table')}>
        <div className={classNames('reservation-history-summary')}>
          <div className={classNames('reservation-history-section')}>
            <WhiteBox title={'기간'}>
              <span className={classNames('txt-regular')}>
                {/* 
                1. Array.map 처리 
                2. length가 2 이상일 경우 에는 양옆에 화살표 버튼 추가.
                3. 화살표 버튼 누를때마다 날짜 바뀌고, Report 내용도 업데이트 되도록 처리 필요. (기존 월간모시러 Report 참조.)
                */}
                <div className={classNames('date-flex')} style={{ marginBottom: '17px' }}>
                  {reportDateIndex <= 0 ?
                    <div>
                      <span style={{ width: '100%', transform: 'scale(0.5)', color: 'gray', fontWeight: 'bold' }}>{'<'}</span>

                      {/* <img src={icon_report_date_left} style={{ width: '100%', transform: 'scale(0.3)', opacity: '0' }} alt="" /> */}
                    </div> :
                    <div onClick={() => SetReportDateIndex(reportDateIndex - 1)}>
                      <span style={{ width: '100%', transform: 'scale(0.5)', color: '#1eafd2', fontWeight: 'bold' }}>{'<'}</span>
                      {/* <img src={icon_report_date_left} style={{ width: '100%', transform: 'scale(0.3)' }} alt="" /> */}
                    </div>
                  }

                  <div>
                    <span>{reportInfo.ContractDateList[reportDateIndex].StartDate} ~ {reportInfo.ContractDateList[reportDateIndex].EndDate}</span>
                  </div>

                  {reportDateIndex >= reportDateMaxLength - 1 ?
                    <div>

                      <span style={{ width: '100%', transform: 'scale(0.5)', color: 'gray', fontWeight: 'bold' }}>{'>'}</span>
                      {/* <img src={icon_report_date_right} style={{ width: '100%', transform: 'scale(0.3)', opacity: '0' }} alt="" /> */}
                    </div> :
                    <div onClick={() => SetReportDateIndex(reportDateIndex + 1)}>
                      <span style={{ width: '100%', transform: 'scale(0.5)', color: '#1eafd2', fontWeight: 'bold' }}>{'>'}</span>
                      {/* <img src={icon_report_date_right} style={{ width: '100%', transform: 'scale(0.3)' }} alt="" /> */}
                    </div>
                  }

                </div>
              </span>
            </WhiteBox>
            <WhiteBox title={'이용시간'}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '6px 0' }}>
                  <span>평일 기본 근무</span>
                  <span>{getHourMinute(avaliableData.weekdayGeneralWorkTime)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '6px 0' }}>
                  <span>평일 시간 외 근무</span>
                  <span>{getHourMinute(avaliableData.weekdayOverWorkTime)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '6px 0' }}>
                  <span>주말 기본 근무 </span>
                  <span>{getHourMinute(avaliableData.weekendGeneralWorkTime)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '6px 0' }}>
                  <span>주말 시간 외 근무</span>
                  <span>{getHourMinute(avaliableData.weekendOverWorkTime)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '6px 0' }}>
                  <span>야간 근무</span>
                  <span>{getHourMinute(avaliableData.nightWorkTime)}</span>
                </div>
              </div>



            </WhiteBox>
          </div>
          <div className={classNames('reservation-history-section')}>
            <WhiteBox title={'비용'}>
              <div className={classNames('pending-payment-item')}>
                <div className={classNames('payment-wrap')}>
                  <span className={classNames('txt-regular blue')}>후불 청구액</span>
                  <span className={'amount-set'}>
                    <span className={classNames('txt-regular blue')}>
                      {transFormWon(avaliableData.ExpensesAmountAll)}
                    </span>

                  </span>
                </div>
                <ul className={classNames('pending-details')}>
                  <li>
                    <span className={classNames('txt-small grey-3')}>결제대행</span>
                    <span className={classNames('txt-small grey-3')}>{transFormWon(avaliableData.ExpensesAmountAll)}</span>
                  </li>
                </ul>
                <span className={classNames('txt-small light-grey-3')}>결제대행 금액은 대행 수수료 20%가 포함된 금액입니다.</span>
              </div>
            </WhiteBox>
          </div>
          <div className={classNames('reservation-history-section')}>
            <WhiteBox title={'총 이용 키로수'}>
              <span className={classNames('txt-regular')}>
                {avaliableData.diffKiloMeter}km
              </span>
            </WhiteBox>
          </div>
        </div>
        <WhiteBox>
          <div className={classNames('reservation-history-table')}>

            {/** table title **/}
            <div className={'table-title'}>
              <div className={classNames('table-title-left')}>
                <span className={classNames('title-mid')}>상세 내역</span>
                <RadioGroup
                  legend={'filter'}
                  selected={filterTarget}
                  classes={'filter-list radio-list'}
                >
                  {filterList.map((target) => {
                    return (
                      <span className={classNames('filter-item')} key={target.value}>
                        <Radio
                          key={target.value}
                          value={target.value}
                          onChange={e => {
                            setFilterTarget(target.value)
                          }}
                          label={target.title}
                        />
                      </span>
                    )
                  })}
                </RadioGroup>
              </div>
              {/* <Button styleType={'grey-2'} classes={`${isPC ? 'txt-regular' : 'txt-small'} download`}>파일 다운로드</Button> */}
            </div>

            {/** table **/}

            <Table titleList={isPC ? DriverDispatchTableTitleList : DriverDispatchTableTitleSummaryList}>
              {rowList.slice(offset, offset + limit).map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    row={isPC ? row.all : row.summary}
                    seeDetail={!isPC && true}
                    onClickSeeDetail={() => onClickSeeDetailHandler(index)}
                  />
                )
              })}
            </Table>

            {/** table pagination **/}
            <Pagination
              page={page}
              setPage={setPage}
              totalPosts={rowList.length}
              limit={limit} />
          </div>
        </WhiteBox>
      </div>
      {showDescriptionIndex >= 0 && !isPC && (
        <MobileDescriptionPop
          title={'결제내역'}
          setShowDescriptionIndex={setShowDescriptionIndex}
        >
          <TableDescriptPopContents
            theadList={ReservationTableTitleList}
            tbody={rowList[showDescriptionIndex]}
          />
        </MobileDescriptionPop>
      )}
    </>
  )
}

export default DriverDispatchManageHistory