import { useState, useEffect } from 'react';
import '../../../styles/comparisonEstimate.css';
import { COMPARISON_ESTIMATE_OPTIONS_INFO, COMPARISON_ESTIMATE_OPTION_LIST } from '../../../utils/constant';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import Header from '../../molecules/Header';
import classNames from 'classnames';
import useCheckPC from '../../../hooks/useCheckPC';
import { updateSubscription, updateUsageTime } from '../../../reducers/bookingEstimation';
import { useDispatch, useSelector } from 'react-redux';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';

import withReactContent from 'sweetalert2-react-content';
import SwalTitleComponent from '../ManageReservation/Modal/Common/SwalTitleComponent';
import Swal from 'sweetalert2';
import { getDateCompateToString, getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { getValueByTarget } from '../../../utils/parse';
import { RoleOfVehiclesOptions } from '../../../utils/EstimateFormData';
import { calculateContractMonthPrice, calculateTotalPrice, transFormWon2 } from '../../../utils/numbers';
import { setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { SendSlackEstimate } from '../../../lib/SlackApi';
import {
	NumberOfVehiclesOptions,
	// RoleOfVehiclesOptions,
	VehicleOptionDescription,
	CarAmountOptions,
	InsuranceOptions,
	PassengerPersonOptions,
	PurposeOfContractOptions,
	BookingProducts,
	UsageTimeOptionsAnnual,
	UsageTimeOptionsMonthly,
	ContractPeriodOptions,
  
  } from '../../../utils/EstimateFormData';




const ComparisonEstimate = ({ setOpenComparisionEstimate }) => {
	const { isPC } = useCheckPC();
	const dispatch = useDispatch();
	
	const { selectedOptions, setSelectedOptions } = useBookingOptionContext();

	const idxinfo = COMPARISON_ESTIMATE_OPTIONS_INFO.filter((datadata)=>( selectedOptions.subscription === datadata.subscription && selectedOptions.usageTime === datadata.usageTime))[0]

	const [selectedOption1, setSelectedOption1] = useState(COMPARISON_ESTIMATE_OPTION_LIST.filter((data)=>data === idxinfo.title)[0]);
	const [selectedOption2, setSelectedOption2] = useState(COMPARISON_ESTIMATE_OPTION_LIST[0]);
	const [selectedOption3, setSelectedOption3] = useState(COMPARISON_ESTIMATE_OPTION_LIST[0]);

	const selectedOptionInfo1 = COMPARISON_ESTIMATE_OPTIONS_INFO.find((item) => item.title === selectedOption1);
	const selectedOptionInfo2 = COMPARISON_ESTIMATE_OPTIONS_INFO.find((item) => item.title === selectedOption2);
	const selectedOptionInfo3 = COMPARISON_ESTIMATE_OPTIONS_INFO.find((item) => item.title === selectedOption3);

	const { BookingOption, ContractMonthArray } = useSelector((state) => state);

	useEffect(()=>{
		if (localStorage.getItem('selectedOption2')	!== null) {
			setSelectedOption2(localStorage.getItem('selectedOption2'))
		}

		if (localStorage.getItem('selectedOption3')	!== null) {
			setSelectedOption3(localStorage.getItem('selectedOption3'))
		}
	}, []) 


	const showModalDetail = async (title, content) => {
		//console.log(reportResult.SelectDriverFee)
		const mySwal = withReactContent(Swal)
		mySwal.fire({
			title: (
				<SwalTitleComponent
					title={title}
					cancelBtn={mySwal.clickCancel} />
			),
			html: (
				<>
					<div className='desc'>
						{content}
					</div>
				</>

			),
			preConfirm: () => {

			},
			confirmButtonText: '확인',
			confirmButtonColor: '#1eafd2',



		})
	}


	const HandleSelectedProduct = (subscription, usageTime) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			subscription,
			usageTime,
		}));
		dispatch(updateSubscription(subscription))
		dispatch(updateUsageTime(usageTime))
		
		console.log(selectedOption2, selectedOption3)
		localStorage.setItem('selectedOption2', selectedOption2)
		localStorage.setItem('selectedOption3', selectedOption3)

		setOpenComparisionEstimate(false)

		// 구글 시트쪽에 로우 추가 필요(?)
	}

	const blockColor = (label, value) => {
		if (label === '총액' && value === ' '){
			return '#dbdcdf'
		}
		else if (value === ' ') {
			return '#eaebec'
		}
		else {
			return 'var(--txt-color-default)'
		}
	}

	const googleProcess = (selectedOptionInfoData)=>{
		let userInfoDecrypt = getEstimateCookieDataAndDecrypt();
		let SelectedProduct = ''
		SelectedProduct += getValueByTarget(BookingProducts, selectedOptionInfoData.subscription, 'title') + ' ';
		SelectedProduct += (selectedOptionInfoData.subscription === 'annual') ?
		  getValueByTarget(UsageTimeOptionsAnnual, selectedOptionInfoData.usageTime, 'title') + ' ' + getValueByTarget(UsageTimeOptionsAnnual, selectedOptionInfoData.usageTime, 'subtitle') + ' ' + getValueByTarget(UsageTimeOptionsAnnual, selectedOptionInfoData.usageTime, 'hourPrice') + '원' :
		  getValueByTarget(UsageTimeOptionsMonthly, selectedOptionInfoData.usageTime, 'title') + ' ' + getValueByTarget(UsageTimeOptionsMonthly, selectedOptionInfoData.usageTime, 'subtitle') + ' ' + getValueByTarget(UsageTimeOptionsMonthly, selectedOptionInfoData.usageTime, 'hourPrice') + '원';
	

		let totalPrice = calculateTotalPrice(
			{
				subscription: selectedOptionInfoData.subscription,
				usageTime: selectedOptionInfoData.usageTime,
				numberOfVehicles: selectedOptions.vehicleOption.numberOfVehicles,
				carAmount: selectedOptions.vehicleOption.carAmount,
				insurance: selectedOptions.vehicleOption.insurance,
			}
		);

		totalPrice += calculateContractMonthPrice(selectedOptions.contractTerms1.periodOfContract, ContractMonthArray.select)
		totalPrice += getValueByTarget(RoleOfVehiclesOptions(selectedOptionInfoData.subscription, selectedOptionInfoData.usageTime), selectedOptions.vehicleOption.roleOfVehicles, 'price')

		let EstimateResponseObj = {
			UserName: userInfoDecrypt.UserName,
			UserPhone: userInfoDecrypt.PhoneNumber,
			UserEmail: userInfoDecrypt.EmailAddress,
			StartAddress: BookingOption?.contractTerms1?.serviceArea?.departure?.address1,
			EndAddress: '',
			SelectedProductStr: SelectedProduct,
			Route: '',
			ConciergeString: '(New)웹견적서',
			Requestcomment: '',
			UsageSchedule: getDateCompateToString(BookingOption?.contractTerms1?.schedule),
			NeedsString: getValueByTarget(PurposeOfContractOptions, BookingOption?.purposeOfContract, 'title'),
			// NeedsString: '',
			MmOptionVehicles: getValueByTarget(NumberOfVehiclesOptions, BookingOption.vehicleOption.numberOfVehicles, 'title'),  // ***
			MmOptionCarAmount: getValueByTarget(CarAmountOptions, BookingOption.vehicleOption.carAmount, 'title'),  // ***
			MmOptionInsurance: getValueByTarget(InsuranceOptions, BookingOption.vehicleOption.insurance, 'title'),  // ***
			MmOptionPassenger: getValueByTarget(PassengerPersonOptions, BookingOption.vehicleOption.passengerPerson, 'title'),  // ***
			MmOptionService: getValueByTarget(RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime), BookingOption.vehicleOption.roleOfVehicles, 'title'), // ***
			DdOptionMonthlyMosiler: '',
			DdOptionRecruitPurpose: '',
			ContractMonth: (selectedOptionInfoData.subscription === 'annual') ? '' : getValueByTarget(ContractPeriodOptions, BookingOption?.contractTerms1?.periodOfContract, 'title'), // ***	  
			MmEstimateAmount: transFormWon2(totalPrice)
		}

	
		if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
	
		}
		else {
		  setSheetEstimateResponse(EstimateResponseObj)
		  SendSlackEstimate(EstimateResponseObj)
		}
	}
	
	const handleSubmit = async (selectedOptionInfoData) => {
		console.log(selectedOptionInfoData.subscription)
		console.log(selectedOptionInfoData.usageTime)
		if (selectedOptionInfoData.subscription === 'none' && 
			selectedOptionInfoData.usageTime === 'none') {
				await showModalDetail('상품선택 필요', '상품을 선택해주세요.')
				return;
		}
		HandleSelectedProduct(selectedOptionInfoData.subscription, selectedOptionInfoData.usageTime)
		googleProcess(selectedOptionInfoData)
		
	}

	return (
		<>
			{<Header headerTitle={'비교견적'} onClickCloseBtn={() => setOpenComparisionEstimate(false)} />}
			<div className='comparison-estimate-wrap'>
				<div className='comparison-estimate'>
					<div className='estimate-item'>
						<div className='item-content'>
							<Select value={selectedOption1} selectedOption={selectedOption1} options={COMPARISON_ESTIMATE_OPTION_LIST} onOptionChange={(value) => setSelectedOption1(value)} />
							{selectedOptionInfo1 && (
								<>
									{selectedOptionInfo1.info.map((i) => (
										<div className={classNames('info-item-set', i.label === '총액' && 'sum')}>
											<span className='info-title'>{i.label}</span>
											<span className='info-value' style={{color: blockColor(i.label, i.value)}}>{i.value === ' ' ? '&nbsp;' : i.value}</span>
										</div>
									))}
								</>
							)}
						</div>
						<Button styleType='secondary' onClick={()=>handleSubmit(selectedOptionInfo1)}>견적확인</Button>
					</div>
					<div className='estimate-item'>
						<div className='item-content'>
							<Select value={selectedOption2} selectedOption={selectedOption2} options={COMPARISON_ESTIMATE_OPTION_LIST} onOptionChange={(value) => setSelectedOption2(value)} />
							{selectedOptionInfo2 && (
								<>
									{selectedOptionInfo2.info.map((i) => (
										<div className={classNames('info-item-set', i.label === '총액' && 'sum')}>
											<span className='info-title'>{i.label}</span>
											<span className='info-value' style={{color: blockColor(i.label, i.value)}}>{i.value === ' ' ? '&nbsp;' : i.value}</span>
										</div>
									))}


								</>
							)}
						</div>
						<Button styleType='secondary' onClick={()=>handleSubmit(selectedOptionInfo2)}>견적확인</Button>
					</div>
					<div className='estimate-item pc'>
						<div className='item-content'>
							<Select value={selectedOption3} selectedOption={selectedOption3} options={COMPARISON_ESTIMATE_OPTION_LIST} onOptionChange={(value) => setSelectedOption3(value)} />
							{selectedOptionInfo3 && (
								<>
									{selectedOptionInfo3.info.map((i) => (
										<div className={classNames('info-item-set', i.label === '총액' && 'sum')}>
											<span className='info-title'>{i.label}</span>
											<span className='info-value' style={{color: blockColor(i.label, i.value)}}>{i.value === ' ' ? '&nbsp;' : i.value}</span>
										</div>
									))}
								</>
							)}
						</div>
						<Button styleType='secondary' onClick={()=>handleSubmit(selectedOptionInfo3)}>견적확인</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ComparisonEstimate;
