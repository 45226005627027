import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from './Icon';
import { BookingEstimateSteps } from '../../utils/steps';
import useCheckPC from '../../hooks/useCheckPC';
import { useNavigate } from 'react-router-dom';

const Steps = ({ stepList, currentStep }) => {
  const { isPC } = useCheckPC();
  const navigate = useNavigate();
  const convertCamelCaseToHyphenated = (str) => {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
  }
  const findKeyByIndex = (obj, index) => {
    for (const [key, value] of Object.entries(obj)) {
      if (value.index === index) {
        return key;
      }
    }
    return null; // 찾지 못한 경우 null 반환
  }

  return (
    <ul id='step-list' className={classNames('step-list')}>
      {stepList.map((step, i) => {
        const stepInfo = Object.values(BookingEstimateSteps).find(info => info.title === step);
        if (!stepInfo) {
          return null;
        }
        const isActive = stepInfo.index <= currentStep.index;

        return (
          <li
            key={i}
            className={classNames('step', isActive && 'active')}
          >
            {isPC && (
              <>
                <span style={{ cursor: isActive ? 'pointer' : '' }} className={classNames('txt-regular', isActive ? 'grey-1' : 'disabled')} onClick={() =>
                  isActive && navigate(`/booking/${convertCamelCaseToHyphenated(findKeyByIndex(BookingEstimateSteps, stepInfo.index))}`)}>
                  {stepInfo.title}
                </span>
                {i !== stepList.length - 1 && (
                  <Icon icon={'arrow_right_grey'} />
                )}
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

Steps.propTypes = {
  stepList: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.shape({
    index: PropTypes.number.isRequired,
  }).isRequired,
};

export default Steps;
