import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import BookingItem from '../../molecules/BookingItem';
import Button from '../../atoms/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';

import { UsageTimeOptionsMonthly, UsageTimeOptionsAnnual, BookingProducts } from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import { useDispatch, useSelector } from 'react-redux';
import { updateUsageTime } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { getValueByTarget } from '../../../utils/parse'
import MobileDescriptionPop from '../../organisms/MobileDescriptionPop';
import useCheckPC from '../../../hooks/useCheckPC';
import TotalAmount2 from '../../atoms/TotalAmount2';
import { updateVehicleOption } from '../../../reducers/bookingEstimation';


import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';
import {
  setSheetContractResponseTest,
  setSheetEstimateResponseTest,
  setSheetContractResponse,
  setSheetEstimateResponse,
  SetMmEstimateHistory,
  GetMmEstimateLatestHistory
} from '../../../lib/EstimateApi';
import { getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { updateSelect } from '../../../reducers/monthlyContractMonthArray';
import { getValue } from '@testing-library/user-event/dist/utils';
import BookingItemSizeUp from '../../molecules/BookingItemSizeUp';
/*
dispatch(openLoading())
dispatch(closeLoading())
    // await하지 않고, 모시러 서버에 저장
    await SetMmEstimateHistory({
      UserName: 'rise',
      PhoneNumber: '01087223834',
      EmailAddress: 'rise@mosiler.com',
      EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
      EstimatePage: PAGE.PAGE_01,
      JsonData: JSON.stringify({
        ...selectedOptions,
        subscription: data?.subscription ?? selectedOptions.subscription
      })
    })

*/


/**
 * 이용시간 선택 페이지
 * name : usageTime
 * */

const UsageTime = () => {
  const { isPC } = useCheckPC();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { BookingOption } = useSelector((state) => state);

  const { selectedOptions, setSelectedOptions } = useBookingOptionContext();
  const [showDescription, setShowDescription] = useState(false)


  const [UsageTimeOptions, SetUsageTimeOptions] = useState(
    (BookingOption.subscription !== null) ?
      ((BookingOption.subscription === 'annual') ? UsageTimeOptionsAnnual : UsageTimeOptionsMonthly) :
      {}
  )

  const { handleSubmit, setValue } = useForm();

  const onSubmit = handleSubmit(async data => {

    dispatch(openLoading())
    const updatedOptions = {
      ...selectedOptions,
      usageTime: data?.usageTime ?? selectedOptions.usageTime,
    };
    setSelectedOptions(updatedOptions);
    dispatch(updateUsageTime(data?.usageTime ?? selectedOptions.usageTime));

    // await하지 않고, 모시러 서버에 저장
    SetMmEstimateHistory({
      ...getEstimateCookieDataAndDecrypt(),
      EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
      EstimatePage: PAGE.PAGE_02,
      JsonData: JSON.stringify(updatedOptions)
    })

    // navigate(URLS.BOOKING_ESTIMATION_VEHICLE_OPTION);
    navigate(URLS.BOOKING_ESTIMATION_CONTRACT_TERMS1);
    dispatch(closeLoading())
  });


  return (
    <>
      <div className={classNames('booking-wrap')}>
        <PageTitle
          title={'이용 시간'}
          subtitle={'이용 시간을 선택하세요'}
          setShowDescription={setShowDescription}
        />
        <form className={classNames('booking-form')} onSubmit={() => onSubmit()}>
          <div className={classNames('booking-select-list')}>
            {!!UsageTimeOptions && (
              <RadioGroup
                legend={'usageTime'}
                selected={selectedOptions.usageTime}
              >
                <ul className={classNames('booking-item-list')}>
                  {UsageTimeOptions.map((item) => (
                    <li key={item.title}>
                      <Radio
                        key={item.title}
                        onChange={e => {
                          setValue('usageTime', item.value);
                          const updatedOptions = {
                            ...selectedOptions,
                            usageTime: item.value,
                          };
                          setSelectedOptions(updatedOptions);
                          dispatch(updateUsageTime(item.value));

                          //월간모시러 금액  2950000 * 0.03 = 88500 / 2950000 *  0.07 = 206500
                          if (selectedOptions.subscription === 'monthly') {
                            let MmPlusUsage = getValueByTarget(BookingProducts, selectedOptions.subscription, 'price') + getValueByTarget(UsageTimeOptionsMonthly, item.value, 'price')
                            dispatch(updateSelect([
                              {
                                title: '1개월',
                                value: 'month1',
                                category: 'periodOfContract',
                                numberValue: 1,
                                discount: 0,
                                description: '기본 비용 / 할인 없음',
                                descriptionTitle: '할인 없음'
                              },
                              {
                                title: '4개월',
                                value: 'month4',
                                category: 'periodOfContract',
                                numberValue: 4,
                                discount: Math.floor(MmPlusUsage * 0.03),
                                description: '4개월 할인',
                                descriptionTitle: '할인율 3%'
                              },
                              {
                                title: '12개월',
                                value: 'month12',
                                category: 'periodOfContract',
                                numberValue: 12,
                                discount: Math.floor(MmPlusUsage * 0.07),
                                description: '12개월 할인',
                                descriptionTitle: '할인율 7%'
                              },
                            ]))

                          }

                        }}
                      >
                        <BookingItemSizeUp
                          selected={selectedOptions.usageTime === item.value}
                          title={item?.title}
                          subtitle={item?.subtitle}
                          priceTitle={''}
                          price={item?.price}
                        />
                      </Radio>
                    </li>
                  ))}
                </ul>
              </RadioGroup>
            )}
          </div>
          <BookingFormSummary
            SubAmountObj={isPC ? TotalAmount2 : null}
            description={
              getValueByTarget(
                UsageTimeOptions,
                selectedOptions.usageTime,
                'description'
              )
            }>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => navigate(URLS.BOOKING_ESTIMATION_SUBSCRIPTION)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary>
        </form>
      </div>
      {showDescription && !isPC && (
        <MobileDescriptionPop
          targetDictList={UsageTimeOptions}
          setShowDescription={setShowDescription}
          selectedOptions={selectedOptions}
        />
      )}
    </>
  )
}
export default UsageTime