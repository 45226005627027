import React from 'react';
import classNames from 'classnames';

const ListWithBullet = ({description, gap}) => {
  return (
    <ul className={classNames('list-style', gap >= 0 && `gap-${gap}`)}>
      {description.map((item, index) => (
        <li key={index}>
            <span className={classNames('grey-2')} dangerouslySetInnerHTML={{ __html: item }}>
            </span>
        </li>
      ))}
    </ul>
  )
}

export default ListWithBullet