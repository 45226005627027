import React from 'react';
import { createGlobalStyle } from 'styled-components';
import share from './share.svg'
import share2 from './share2.svg'
import useDetectBrowser from '../../hooks/useDetectBrowser';

const GlobalStyle = createGlobalStyle`
.scroll-to-top {position: fixed;bottom: 12.8375rem;right: 2.8125rem;animation: fadeIn 700ms ease-in-out 1s both;cursor: pointer; z-index: 100;}
.scroll-to-top > button {border:none;}
.scroll-to-top > button:focus {border: none; outline:none;}
.btn-share-qna {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	background: #fff url("${share}");
	background-repeat: no-repeat;background-size: 60px;
	background-position: 100% 50%;
	/*transition: all 0.23s ease-in-out, box-shadow 1.5s linear;*/
	box-shadow: 0px 10px 15px 0 rgb(0 0 0 / 25%);
}
`;


const ShareButton = ({type}) => {
    
    const { Os } = useDetectBrowser()

    const onShareButton = () => {
        if (type === 'monthly') {
            window.navigator.share({
                url:'http://mosiler.com/newestimate/introduce?inflow=share_monthly',
                // text:'월간모시러 소개서 링크로 이동합니다.',
                title:'월간모시러 소개서'
            })
        }
        else {
            window.navigator.share({
                url:'http://mosiler.com/driverdispatch/introduce?inflow=share_chauffeur',
                // text:'모시러 채용/파견 소개서 링크로 이동합니다.',
                title:'모시러 채용/파견 소개서'
            })
        }
        
    }

    if (Os === 'pc') {
        return <></>
    }

    return (
        <>
            <GlobalStyle />
            <div class="scroll-to-top">
                <button id="channel-add-button" class="btn-share-qna" onClick={onShareButton}></button>
            </div>
        </>

    )
}

export default ShareButton