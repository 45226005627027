import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import BookingItem from '../../molecules/BookingItem';
import Button from '../../atoms/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';

import {
  NumberOfVehiclesOptions,
  RoleOfVehiclesOptions,
  VehicleOptionDescription,
  CarAmountOptions,
  InsuranceOptions,
  PassengerPersonOptions,
  PurposeOfContractOptions,
  BookingProducts,
  UsageTimeOptionsAnnual,
  UsageTimeOptionsMonthly,
  ContractPeriodOptions,

} from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import { useDispatch, useSelector } from 'react-redux';
import { updateVehicleOption, updateOptionHex } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import useCheckPC from '../../../hooks/useCheckPC';
import MobileDescriptionPop from '../../organisms/MobileDescriptionPop';
import TotalAmount3 from '../../atoms/TotalAmount3';
import { SetMmEstimateHistory, getEstimateHistoryInfo, setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { base64EncodeUnicode, getDateCompateToString, getEstimateCookieData, getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, PAGE } from '../../../utils/constant';
import { getValueByTarget } from '../../../utils/parse';
import TotalAmount4 from '../../atoms/TotalAmount4';
import { closeLoading, openLoading } from '../../../reducers/loadingRedux';
import { SendSlackEstimate } from '../../../lib/SlackApi';
import { SendEztoc2 } from '../../../lib/EztocApi';
import { transFormWon2 } from '../../../utils/numbers';

/**
 * 옵션 선택 페이지
 * name : vehicleOption
 * */

const VehicleOption = () => {
  const { isPC } = useCheckPC();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOptions, setSelectedOptions } = useBookingOptionContext();
  const [showDescription, setShowDescription] = useState(false)
  const { BookingOption } = useSelector((state) => state);
  const { handleSubmit, setValue } = useForm();
  const [VehicleOptionDescription2, SetVehicleOptionDescription] = useState([])

  const onSubmit = handleSubmit(async data => {

    dispatch(openLoading())
    let optionHex =
      getValueByTarget(NumberOfVehiclesOptions, data?.numberOfVehicles, 'optionValue') |
      getValueByTarget(RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime), data?.roleOfVehicles, 'optionValue') |
      getValueByTarget(CarAmountOptions, data?.carAmount, 'optionValue') |
      getValueByTarget(InsuranceOptions, data?.insurance, 'optionValue') |
      getValueByTarget(PassengerPersonOptions, data?.passengerPerson, 'optionValue')

    setSelectedOptions((prevState) => ({
      ...prevState,
      optionHex,
      vehicleOption: {
        ...prevState.vehicleOption,
        numberOfVehicles: data?.numberOfVehicles,
        roleOfVehicles: data?.roleOfVehicles,
        carAmount: data?.carAmount,
        insurance: data?.insurance,
        passengerPerson: data?.passengerPerson,
      },
    }));



    if ((document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) || (document.location.hostname.indexOf('localhost') >= 0)) {

    }
    else {
      // 1. 기존 데이터 취득하여 어디까지 왔는지 확인
      let reqData = getEstimateCookieData()
      let plainData = decodeURIComponent(atob(reqData))
      let infoResult = await getEstimateHistoryInfo(base64EncodeUnicode(plainData), 1)
      if (infoResult.Item.ResultCode === 200) {
        let resultJson = infoResult.ItemArray[0]
        // page가 4 이상이라면 미발송
        if (Number(resultJson.EstimatePage) >= PAGE.PAGE_04) {
          // No Action
        }
        // 3 이하라면 발송
        else {
          let info = getEstimateCookieDataAndDecrypt()
          await SendEztoc2({
            eztocType: 'estimate',
            UserName: info?.UserName,
            UserPhone: info?.PhoneNumber
          })
        }
      }

    }




    // await하지 않고, 모시러 서버에 저장
    SetMmEstimateHistory({
      ...getEstimateCookieDataAndDecrypt(),
      EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
      EstimatePage: PAGE.PAGE_04,
      JsonData: JSON.stringify({
        ...selectedOptions,
        optionHex,
        vehicleOption: {
          ...selectedOptions.vehicleOption,
          numberOfVehicles: data?.numberOfVehicles,
          roleOfVehicles: data?.roleOfVehicles,
          carAmount: data?.carAmount,
          insurance: data?.insurance,
          passengerPerson: data?.passengerPerson,
        },
      })
    })

    dispatch(updateVehicleOption({
      numberOfVehicles: data?.numberOfVehicles ?? selectedOptions?.vehicleOption?.numberOfVehicles,
      roleOfVehicles: data?.roleOfVehicles ?? selectedOptions?.vehicleOption?.roleOfVehicles,
      carAmount: data?.carAmount ?? selectedOptions?.vehicleOption?.carAmount,
      insurance: data?.insurance ?? selectedOptions?.vehicleOption?.insurance,
      passengerPerson: data?.passengerPerson ?? selectedOptions?.vehicleOption?.passengerPerson,
    }));

    dispatch(updateOptionHex({
      optionHex
    }));




    let userInfoDecrypt = getEstimateCookieDataAndDecrypt();
    let SelectedProduct = ''
    SelectedProduct += getValueByTarget(BookingProducts, BookingOption.subscription, 'title') + ' ';
    SelectedProduct += (BookingOption.subscription === 'annual') ?
      getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'title') + ' ' + getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'subtitle') + ' ' + getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'hourPrice') + '원' :
      getValueByTarget(UsageTimeOptionsMonthly, BookingOption.usageTime, 'title') + ' ' + getValueByTarget(UsageTimeOptionsMonthly, BookingOption.usageTime, 'subtitle') + ' ' + getValueByTarget(UsageTimeOptionsMonthly, BookingOption.usageTime, 'hourPrice') + '원';

    /*let SelectedRoute = getValueByTarget(RouteOptions, data?.route, 'title')
    if (data?.route === 'route_etc') {
      SelectedRoute = `기타 (${data?.routeEtcString})`
    }*/


    let EstimateResponseObj = {
      UserName: userInfoDecrypt.UserName,
      UserPhone: userInfoDecrypt.PhoneNumber,
      UserEmail: userInfoDecrypt.EmailAddress,
      StartAddress: BookingOption?.contractTerms1?.serviceArea?.departure?.address1,
      EndAddress: '',
      SelectedProductStr: SelectedProduct,
      Route: '',
      ConciergeString: '(New)웹견적서',
      Requestcomment: '',
      UsageSchedule: getDateCompateToString(BookingOption?.contractTerms1?.schedule),
      NeedsString: getValueByTarget(PurposeOfContractOptions, data?.purposeOfContract, 'title'),
      MmOptionVehicles: getValueByTarget(NumberOfVehiclesOptions, BookingOption.vehicleOption.numberOfVehicles, 'title'),
      MmOptionCarAmount: getValueByTarget(CarAmountOptions, BookingOption.vehicleOption.carAmount, 'title'),
      MmOptionInsurance: getValueByTarget(InsuranceOptions, BookingOption.vehicleOption.insurance, 'title'),
      MmOptionPassenger: getValueByTarget(PassengerPersonOptions, BookingOption.vehicleOption.passengerPerson, 'title'),
      MmOptionService: getValueByTarget(RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime), BookingOption.vehicleOption.roleOfVehicles, 'title'),
      DdOptionMonthlyMosiler: '',
      DdOptionRecruitPurpose: '',
      ContractMonth: (BookingOption.subscription === 'annual') ? '' : getValueByTarget(ContractPeriodOptions, BookingOption?.contractTerms1?.periodOfContract, 'title'),
      MmEstimateAmount: transFormWon2( BookingOption?.totalAmount)
    }

    /* 모시러 DB에 들어가야할 정보
    이름, 전화번호, 이메일, 출발지, 종료지, 
    상품 타입 (월간/연간) BookingOption.subscription
    getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'estimateDay')
    getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'estimateHour')
    getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'estimateMinHour')
    getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'hourPrice')   
    
    */

    if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {

    }
    else {
      setSheetEstimateResponse(EstimateResponseObj)
      SendSlackEstimate(EstimateResponseObj)
    }



    // navigate(URLS.BOOKING_ESTIMATION_CONTRACT_TERMS1)
    navigate(URLS.BOOKING_ESTIMATION_CONFIRM_ESTIMATE)
    dispatch(closeLoading())
  });

  useEffect(() => {
    async function initData() {
      setValue('numberOfVehicles', selectedOptions?.vehicleOption?.numberOfVehicles)
      setValue('roleOfVehicles', selectedOptions?.vehicleOption?.roleOfVehicles)
      setValue('carAmount', selectedOptions?.vehicleOption?.carAmount)
      setValue('insurance', selectedOptions?.vehicleOption?.insurance)
      setValue('passengerPerson', selectedOptions?.vehicleOption?.passengerPerson)




      /*
       numberOfVehicles: BookingOption?.vehicleOption?.numberOfVehicles ?? 'twoVehicles',
      roleOfVehicles: BookingOption?.vehicleOption?.roleOfVehicles ?? 'driver',
      carAmount: BookingOption?.vehicleOption?.carAmount ?? 'twomilliondown',
      insurance: BookingOption?.vehicleOption?.insurance ?? 'insubasic',
      passengerPerson: BookingOption?.vehicleOption?.passengerPerson ?? 'persontwo',
      */

    }
    initData();
  }, [])


  useEffect(() => {
    SetVehicleOptionDescription([
      `월간 모시러 서비스 이용 대상 차량은 <i class="blue">${getValueByTarget(NumberOfVehiclesOptions, selectedOptions?.vehicleOption?.numberOfVehicles, 'title')}</i> 입니다.`,
      `차량 모델은 <i class="blue">${getValueByTarget(CarAmountOptions, selectedOptions?.vehicleOption?.carAmount, 'title')}</i> 입니다.`,
      `사고 발생 시 <i class="blue">${getValueByTarget(InsuranceOptions, selectedOptions?.vehicleOption?.insurance, 'title')}</i>으로 처리됩니다.`,
      `드라이버를 <i class="blue">${getValueByTarget(RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime), selectedOptions?.vehicleOption?.roleOfVehicles, 'title')}</i> 서비스로 이용 가능합니다.`,
    ])

  }, [
    selectedOptions?.vehicleOption?.numberOfVehicles,
    selectedOptions?.vehicleOption?.carAmount,
    selectedOptions?.vehicleOption?.insurance,
    selectedOptions?.vehicleOption?.roleOfVehicles
  ])




  return (
    <>
      <div className={classNames('booking-wrap')}>
        <PageTitle
          title={'옵션 선택'}
          subtitle={'옵션을 선택하세요'}
          setShowDescription={setShowDescription}
        />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          <div className={classNames('booking-form-list')}>

            {!!NumberOfVehiclesOptions && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>차량 등록</span>
                <RadioGroup
                  classes={''}
                  legend={'numberOfVehicles'}
                  selected={selectedOptions.vehicleOption.numberOfVehicles}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {NumberOfVehiclesOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          onChange={e => {
                            setValue('numberOfVehicles', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              vehicleOption: {
                                ...prevState.vehicleOption,
                                numberOfVehicles: item.value,
                              },
                            }));

                            dispatch(updateVehicleOption({
                              ...selectedOptions?.vehicleOption,
                              numberOfVehicles: item.value,
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.vehicleOption.numberOfVehicles === item.value}
                            subtitle={item?.title}
                            price={item?.price}
                            priceVisibleFlag={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}


            {!!CarAmountOptions && (   //*
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>차량 모델</span>
                <RadioGroup classes={''} legend={'carAmount'} selected={selectedOptions.vehicleOption.carAmount}>   {/*  */}
                  <ul className={classNames('booking-item-list row')}>

                    {/*  */}
                    {CarAmountOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          onChange={e => {
                            setValue('carAmount', item.value);   // *

                            // *
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              vehicleOption: {
                                ...prevState.vehicleOption,
                                carAmount: item.value,
                              },
                            }));
                            dispatch(updateVehicleOption({
                              ...selectedOptions?.vehicleOption,
                              carAmount: item.value,
                            }));
                          }}
                        >
                          {/*    // * */}
                          <BookingItem
                            selected={selectedOptions.vehicleOption.carAmount === item.value}
                            subtitle={item?.title}
                            price={item?.price}
                            priceVisibleFlag={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}

            {!!InsuranceOptions && (   //*
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>보험</span>
                <RadioGroup classes={''} legend={'insurance'} selected={selectedOptions.vehicleOption.insurance}>   {/*  */}
                  <ul className={classNames('booking-item-list row')}>

                    {/*  */}
                    {InsuranceOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          onChange={e => {
                            setValue('insurance', item.value);   // *

                            // *
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              vehicleOption: {
                                ...prevState.vehicleOption,
                                insurance: item.value,
                              },
                            }));
                            dispatch(updateVehicleOption({
                              ...selectedOptions?.vehicleOption,
                              insurance: item.value,
                            }));
                          }}
                        >
                          {/*    // * */}
                          <BookingItem
                            selected={selectedOptions.vehicleOption.insurance === item.value}
                            subtitle={item?.title}
                            discount={item?.discount}
                            priceVisibleFlag={true}

                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}

            {/* {!!PassengerPersonOptions && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>탑승 고객</span>
                <RadioGroup classes={''} legend={'passengerPerson'} selected={selectedOptions.vehicleOption.passengerPerson}>
                  <ul className={classNames('booking-item-list row')}>


                    {PassengerPersonOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          onChange={e => {
                            setValue('passengerPerson', item.value);


                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              vehicleOption: {
                                ...prevState.vehicleOption,
                                passengerPerson: item.value,
                              },
                            }));
                            dispatch(updateVehicleOption({
                              ...selectedOptions?.vehicleOption,
                              passengerPerson: item.value,
                            }));
                          }}
                        >

                          <BookingItem
                            selected={selectedOptions.vehicleOption.passengerPerson === item.value}
                            subtitle={item?.title}
                            price={item?.price}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )} */}

            {!!RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime) && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>서비스</span>
                <RadioGroup classes={''} legend={'roleOfVehicles'} selected={selectedOptions.vehicleOption.roleOfVehicles}>
                  <ul className={classNames('booking-item-list row')}>
                    {RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime).map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          onChange={e => {
                            setValue('roleOfVehicles', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              vehicleOption: {
                                ...prevState.vehicleOption,
                                roleOfVehicles: item.value,
                              },
                            }));
                            dispatch(updateVehicleOption({
                              ...selectedOptions?.vehicleOption,
                              roleOfVehicles: item.value,
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.vehicleOption.roleOfVehicles === item.value}
                            subtitle={item?.title}
                            price={item?.price}
                            priceVisibleFlag={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}


          </div>
          <BookingFormSummary
            SubAmountObj={isPC ? TotalAmount4 : null}
            description={VehicleOptionDescription2}>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => {

                    const updatedOptions = {
                      ...selectedOptions,
                      vehicleOption: {
                        numberOfVehicles: 'twoVehicles',
                        roleOfVehicles: 'driver',
                        carAmount: 'twomilliondown',
                        insurance: 'insubasic',
                        passengerPerson: 'persontwo',
                      },
                    };
                    setSelectedOptions(updatedOptions);

                    dispatch(updateVehicleOption({
                      numberOfVehicles: 'twoVehicles',
                      roleOfVehicles: 'driver',
                      carAmount: 'twomilliondown',
                      insurance: 'insubasic',
                      passengerPerson: 'persontwo',
                    }));



                    navigate(URLS.BOOKING_ESTIMATION_CONTRACT_TERMS1)

                  }
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary>
        </form>
      </div>
      {showDescription && !isPC && (
        <MobileDescriptionPop
          targetList={VehicleOptionDescription}
          setShowDescription={setShowDescription}
        />
      )}
    </>

  )
}
export default VehicleOption