import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import {
	DriverDispatchProducts,
	WeekendUsageOptions,
	MonthlyMosilerOption,
	RecruitPurposeOption,
	RouteOptions,
	ConciergeAdviceOptions,
	EtcRequestOptions,
	UsageTimeOptionsDriverDispatch,
	UsageTimeOptionsDriverDispatchPagyun,
} from '../../../utils/EstimateFormData';
import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListBox from '../../atoms/ListBox';
import TotalAmount from '../../atoms/TotalAmount';
import useCheckPC from '../../../hooks/useCheckPC';
import ListWithBullet from '../../atoms/ListWithBullet';
import { getCalcSalaryDetailEstimate } from '../../../lib/DriverDispatchApi';
import moment from 'moment';
import { SetMmEstimateHistory, setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { closeLoading, closeLoadingWithEstimate, openLoading, openLoadingWithEstimate } from '../../../reducers/loadingRedux';
import TotalAmountDriverDispatch from '../../atoms/TotalAmountDriverDispatch';
import { getDdEstimateCookieDataAndDecrypt, isDev } from '../../../lib/Util';
import ListBoxWithIcon from '../../atoms/ListBoxWithIcon';
import ListBox3 from '../../atoms/ListBox3';
import NewListBox from '../../atoms/NewListBox';
import ListBoxWithIcon2 from '../../atoms/ListBoxWithIcon2';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';

/**
 * 견적 확인 페이지
 * name : confirmEstimate
 * */

const EstimateDocs = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isPC } = useCheckPC();
	const { DriverDispatchOption } = useSelector((state) => state);

	/** 상품명 **/
	// DriverDispatchOption?.subscription
	const [subscription, setSubscription] = useState([]);

	/** 기본정보 **/
	const [basicInfomation, setbasicInfomation] = useState([]);

	const [basicOption, setbasicOption] = useState([
		{
			title: `연 4회 맞춤형 기사 교육(분기당 1회)`,
		},
		{
			title: `기사 자동 관리 프로그램 제공`,
		},
	]);

	/** 선택옵션 **/
	const [optionData, setOptionData] = useState([]);

	/** 상세견적 (임금) **/
	const [estimateStep1, setEstimateStep1] = useState([]);

	/** 상세견적 (제세공과금) **/
	const [estimateStep2, setEstimateStep2] = useState([]);

	/** 상세견적 (관리비용) **/
	const [estimateStep3, setEstimateStep3] = useState([]);

	/** 상세견적 (이익금) **/
	const [estimateStep4, setEstimateStep4] = useState([]);

	/** 견적 총 금액 **/
	const [estimatedTotalAmount, setEstimatedTotalAmount] = useState([{ price: 0 }]);

	const timeData = `${DriverDispatchOption?.basicInfo1?.workStartTime}~${DriverDispatchOption?.basicInfo1?.workEndTime}`;

	/** 견적 총 금액 **/
	const [ConfirmEstimateDescription, setConfirmEstimateDescription] = useState(
		DriverDispatchOption.subscription === 'recruit'
			? [
					'본 견적은 채용 수수료에 대한 견적이며, 채용인원의 급여는 직접 지급해 주시기 바랍니다.',
					'실제 계약에 반영되는 내용에 따라 일부 조정될 수 있습니다.',
					'채용 수수료는 채용 시 최초 1회에 한하여 발생되는 금액입니다.',
			  ]
			: [
					'본 견적은 월 단위 금액으로써 산출되었으며, 실제 계약에 반영되는 내용에 따라 조정될 수 있습니다.',
					'휴일근로 및 특근수당은 근무시간에 따라 별도 청구될 수 있습니다.',
					'퇴직/연차급여충당금은 연 단위의 계약 만료시 발생여부에 따라 별도 청구됩니다.',
					'법정비용은 2023년 1월 1일 기준 노동부 고시된 법정요율을 적용하였습니다. (중도 변동 시, 변경될 수 있음)',
			  ]
	);

	// 견적서 구글에 전송
	useEffect(() => {
		const initSendSheet = async () => {};

		initSendSheet();
	}, []);

	useEffect(() => {
		const YearSalary = Number(DriverDispatchOption?.basicInfo2?.selectedSalary) * 10000;

		/** 상품명 **/
		const updatedSubscription = [
			{
				title: getValueByTarget(DriverDispatchProducts, DriverDispatchOption.subscription, 'title'),
				// ,
				// price: getValueByTarget(
				//   DriverDispatchProducts,
				//   DriverDispatchOption.subscription,
				//   'price'
				// )
			},
		];
		setSubscription(updatedSubscription);

		/** 기본정보 **/
		const updateBasicInfomation = [
			{
				title: '상품',
				value:
					DriverDispatchOption.subscription === 'recruit'
						? getValueByTarget(UsageTimeOptionsDriverDispatch, DriverDispatchOption?.usageTime, 'subtitle2')
						: getValueByTarget(UsageTimeOptionsDriverDispatchPagyun, DriverDispatchOption?.usageTime, 'subtitle2'),
			},
			{
				title: `차고지 주소`,
				value: `${DriverDispatchOption?.basicInfo2?.garageAddress}`,
			},
			{
				title: `회사 주소`,
				value: `${DriverDispatchOption?.basicInfo2?.companyAddress}`,
			},
			{
				title: `필요 시점`,
				value: `${moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy년 MM월 DD일')}`,
			},
		];
		setbasicInfomation(updateBasicInfomation);

		/** 옵션 **/
		const updateOptionInfo = [
			{
				title: `면접자 1일 운행 체험 서비스`,
				value: `${getValueByTarget(MonthlyMosilerOption, DriverDispatchOption?.options?.monthlyMosilerDemo, 'title')}`,
			},
			{
				title: '채용 목적',
				value: getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'title'),
			},
		];
		setOptionData(updateOptionInfo);

		/** 견적 상세 정보 **/
		{
			let obj = getCalcSalaryDetailEstimate(YearSalary);
			const totalToHapgye = (str) => {
				return str === 'total' ? '합계' : str;
			};

			const updateEstimateStep1 = [];
			for (let i in Object.keys(obj.step1)) {
				let str = Object.keys(obj.step1)[i];
				updateEstimateStep1.push({ title: totalToHapgye(str), price: obj.step1[str] });
			}

			const updateEstimateStep2 = [];
			for (let i in Object.keys(obj.step2)) {
				let str = Object.keys(obj.step2)[i];
				updateEstimateStep2.push({ title: totalToHapgye(str), price: obj.step2[str] });
			}

			const updateEstimateStep3 = [];
			for (let i in Object.keys(obj.step3)) {
				let str = Object.keys(obj.step3)[i];
				updateEstimateStep3.push({ title: totalToHapgye(str), price: obj.step3[str] });
			}

			const updateEstimateStep4 = [];
			for (let i in Object.keys(obj.step4)) {
				let str = Object.keys(obj.step4)[i];
				updateEstimateStep4.push({ title: totalToHapgye(str), price: obj.step4[str] });
			}

			setEstimateStep1(updateEstimateStep1);
			setEstimateStep2(updateEstimateStep2);
			setEstimateStep3(updateEstimateStep3);
			setEstimateStep4(updateEstimateStep4);

			setEstimatedTotalAmount([
				{
					title: '총 금액 (임금 + 제세공과금 + 관리비용 + 이익금)',
					price: obj.totalEstimateAmount,
				},
			]);
		}
	}, [DriverDispatchOption]);

	return (
		<>
			<div className={classNames('booking-wrap')}>
				<PageTitle title={'견적 확인'} subtitle={'견적을 확인해 주세요'} />
				<div className={classNames('confirm-booking-wrap')}>
					<div className={classNames('confirm-booking-content')}>
						{/** title **/}
						<ListBoxWithIcon2
							title={`${DriverDispatchOption?.subscription === 'recruit' ? '모시러 채용 상품 견적서' : '모시러 파견 상품 견적서'}`}
							downloadFlag={true}
							url={URLS.DRIVER_DISPATCH_ESTIMATE_DOWNLOAD}
						/>

						{!isPC && (
							<div className={classNames('new-list-box')}>
								<div className={classNames('estimate')}>
									<div className={classNames('estimate-sub-date')}>
										<div className={classNames('flex')}>
											<div className={classNames('title')}>PREPARED DATE</div>
											<div className={classNames('date')}>{moment().format('yyyy년 MM월 DD일')}</div>
										</div>
										<div className={classNames('flex')}>
											<div className={classNames('title')}>EXPIRY DATE</div>
											<div className={classNames('date')}>{moment().add(1, 'months').add(-1, 'days').format('yyyy년 MM월 DD일')}</div>
										</div>
									</div>
								</div>
							</div>
						)}

						{/* Options */}
						<div className={classNames('new-list-box')}>
							<div>
								<div className={classNames('option title')}>OPTIONS</div>
								<div className={classNames('option wrap')}>
									{basicInfomation.map((item, idx) => (
										<div key={idx} className={classNames('option list estimateview')}>
											<div>{item.title}</div>
											<div>{item.value}</div>
										</div>
									))}
									<br />

									{optionData.map((item, idx) => (
										<div key={idx} className={classNames('option list estimateview')}>
											<div>{item.title}</div>
											<div>{item.value}</div>
										</div>
									))}
								</div>
							</div>
						</div>

						{/** 상품명 **/}
						<NewListBox salary={DriverDispatchOption?.usageTime} subscript={DriverDispatchOption?.subscription} title={'상품명'} listItem={subscription} />
					</div>

					<div className={classNames('booking-form-summary')}>
						{isPC && (
							<div className={classNames('new-list-box')}>
								<div className={classNames('estimate')} style={{ justifyContent: 'center' }}>
									<div className={classNames('estimate-sub-date')}>
										<div className={classNames('flex')}>
											<div className={classNames('title')}>PREPARED DATE</div>
											<div className={classNames('date')}>{moment().format('yyyy년 MM월 DD일')}</div>
										</div>
										<div className={classNames('flex')}>
											<div className={classNames('title')}>EXPIRY DATE</div>
											<div className={classNames('date')}>{moment().add(1, 'months').add(-1, 'days').format('yyyy년 MM월 DD일')}</div>
										</div>
									</div>
								</div>
							</div>
						)}

						{isPC && ConfirmEstimateDescription && <ListWithBullet description={ConfirmEstimateDescription} />}
						{/* {hasAmonut && <TotalAmount />}
            {SubAmountObj && <SubAmountObj />} */}
						<div>
							<div className={classNames('button-set')}>
								<Button onClick={() => navigate(URLS.DRIVER_DISPATCH_OPTION)}>이전</Button>
								<Button
									styleType={'secondary'}
									onClick={() => {
										// await하지 않고, 모시러 서버에 저장
										SetMmEstimateHistory({
											...getDdEstimateCookieDataAndDecrypt(),
											EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
											EstimatePage: PAGE.PAGE_06,
											JsonData: JSON.stringify(DriverDispatchOption),
										});
										navigate(URLS.DRIVER_DISPATCH_RECRUIT_REQUEST);
									}}
								>
									다음
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{!isPC && (
				<div className={classNames('box-light-grey-1 footer-contents')}>
					<ListWithBullet description={ConfirmEstimateDescription} />
				</div>
			)}
		</>
	);
};

export default EstimateDocs;
