import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';

import { useRadioContext } from './RadioContext';
import classNames from 'classnames';
import Icon from '../Icon';

const Radio = forwardRef(({ label, children, classes, ...props }, ref) => {
  const { selected, setSelected } = useRadioContext();

  const id = useMemo(() => props.id ?? `radio-${uniqueId()}`, [props.id]);
  const isSelected = useMemo(() => {
    return selected === (props.value ?? id);

  }, [selected, id, props.value]);

  return (
    <label htmlFor={id} className={classNames('cursor-pointer', selected && 'selected')}>
    <span className={classes}>
      <input
        id={id}
        ref={ref}
        type={'radio'}
        onChange={e => {
          props.onChange?.(e);
        }}
        onClick={e => {
          setSelected(props.value ?? id);
          props.onClick?.(e);
        }}
        checked={isSelected}
        {...props}
      />

        {children ?? (label && (
          <>
            <span className={classNames('radio-set')}>
              {isSelected && <Icon icon={'radio_selected'} /> }
              {!isSelected && <Icon icon={'radio'} /> }
              <span className={classNames(isSelected && 'selected')}>{label}</span>
            </span>
          </>
        ))}
    </span>
    </label>
  );
});

export default Radio;

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};
