import ReactDOM from 'react-dom/client';
import React from 'react';
import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { persistedReducer, rootReducer } from './reducers/index';
import { composeWithDevTools } from 'redux-devtools-extension';

import './index.css';
import App from './App';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import trunk from 'redux-thunk'

const logger = store => next => action => {
  // // 현재 스토어 상태값 기록
  // console.log('현재 상태', store.getState());
  // // 액션 기록
  // console.log('액션', action);

  // // 액션 처리 후의 스토어 상태 기록
  // console.log('다음 상태', store.getState());
  // console.log('\n'); // 기록 구분을 위한 비어있는 줄 프린트
  // // 액션을 다음 미들웨어, 혹은 리듀서로 넘김
  const result = next(action);

  return result; // 여기서 반환하는 값은 store.dispatch(ACTION_TYPE) 했을때의 결과로 설정됩니다
}


// const store = createStore(persistedReducer);
const store = createStore(persistedReducer, composeWithDevTools(
  applyMiddleware(logger),
  applyMiddleware(trunk)
));
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
