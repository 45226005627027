import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BookingBox = ({ children }) => {
  return (
    <div id='bookingboxId' className={classNames('booking-box')}>
      {children}
    </div>
  )
}

BookingBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BookingBox;