import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { layoutActionCreator } from '../reducers/layout';

export default function useLayout() {
  const dispatch = useDispatch();

  const openModal = useCallback(
    ({ title, closeBtn, confirmBtn }) => {
      dispatch(
        layoutActionCreator.openModal({
          title,
          closeBtn,
          confirmBtn,
        }),
      );
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch(layoutActionCreator.closeModal());
  }, [dispatch]);

  return {
    isModalOpen: useSelector(state => state.layout.isModalOpen),
    openModal,
    closeModal,
  };
}
