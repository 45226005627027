import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  ConciergeAdviceOptions,
  EtcRequestOptions,
  RouteOptions
} from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import BookingItem from '../../molecules/BookingItem';

import Radio from '../../atoms/Radio';
import Input from '../../atoms/Input';

import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDriverDispatchOptionContext } from '../../organisms/DriverDispatchOptionContext';
import { useForm } from 'react-hook-form';
import { updateContractTerms2 } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import { formErrorMessage } from '../../../utils/alertMessage';
import { getEstimateCookieDataAndDecrypt, getDateCompateToString, getDdEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { SetMmEstimateHistory, setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import moment from 'moment';
import BookingItemRadio from '../../molecules/BookingItemRadio';
import { updateDriverDispatchBasicInfo3 } from '../../../reducers/driverDispatchEstimation';
import BookingFormSummary2 from '../../atoms/BookingFormSummary2';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';
import Textarea from '../../atoms/Textarea';


/**
 * 이용 정보 페이지
 * name : contractTerms2
 * */
const BasicInfo3 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
  const [description, setDescription] = useState([]);
  const { DriverDispatchOption } = useSelector((state) => state);

  useEffect(() => {
    const updatedDescription = [
      `컨시어지 상담 가능 시간대는  
        <i class='blue'>
        ${getValueByTarget(
        ConciergeAdviceOptions, selectedOptions?.basicInfo3?.conciergeAdvice,
        'title') ?? '미입력'}</i> 
         입니다.`,
      `별도 요청 사항은
      <i class='blue'>
        ${getValueByTarget(
        EtcRequestOptions, selectedOptions?.basicInfo3?.etcRequest,
        'title') ?? '미입력'}</i> 
         입니다.`,
      // `알게된 경로는 
      // <i class='blue'>
      //   ${getValueByTarget(
      //   RouteOptions, selectedOptions?.basicInfo3?.route,
      //   'title') ?? '미입력'}</i> 
      //    입니다.`,
    ]

    setDescription(updatedDescription)
  }, [
    selectedOptions.basicInfo3.conciergeAdvice,
    selectedOptions.basicInfo3.conciergeAdviceEtcString,
    selectedOptions.basicInfo3.etcRequest,
    selectedOptions.basicInfo3.etcRequestEtcString,
    selectedOptions.basicInfo3.route,
    selectedOptions.basicInfo3.routeEtcString,
  ])

  const { handleSubmit, setValue, register, setError, getValues, formState: { errors } } = useForm({
    defaultValues: {
      conciergeAdvice: selectedOptions?.basicInfo3?.conciergeAdvice,
      conciergeAdviceEtcString: selectedOptions?.basicInfo3?.conciergeAdviceEtcString,
      etcRequest: selectedOptions?.basicInfo3?.etcRequest,
      etcRequestEtcString: selectedOptions?.basicInfo3?.etcRequestEtcString,
      route: selectedOptions?.basicInfo3?.route,
      routeEtcString: selectedOptions?.basicInfo3?.routeEtcString,
    }
  });

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;

    if (!data?.conciergeAdvice) {
      validCheck = false;
      setError('conciergeAdvice', {
        type: 'focus',
        name: 'conciergeAdvice',
        message: formErrorMessage.vehicleNameError
      }, { shouldFocus: true });
    }

    if (data?.conciergeAdvice === 'etc') {
      if (!data?.conciergeAdviceEtcString) {
        validCheck = false;
        setError('conciergeAdviceEtcString', {
          type: 'focus',
          name: 'conciergeAdviceEtcString',
          message: formErrorMessage.vehicleNameError
        }, { shouldFocus: true });
      }
    }


    if (!data?.etcRequest) {
      validCheck = false;
      setError('etcRequest', {
        type: 'focus',
        name: 'etcRequest',
        message: formErrorMessage.vehicleNameError
      }, { shouldFocus: true });
    }

    if (data?.etcRequest === 'yes') {
      if (!data?.etcRequestEtcString) {
        validCheck = false;
        setError('etcRequestEtcString', {
          type: 'focus',
          name: 'etcRequestEtcString',
          message: '별도 요청 사항을 입력해주세요.'
        }, { shouldFocus: true });
      }
    }

    if (!data?.route) {
      validCheck = false;
      setError('etcRequest', {
        type: 'focus',
        name: 'etcRequest',
        message: formErrorMessage.vehicleNameError
      }, { shouldFocus: true });
    }

    if (data?.route === 'route_etc') {
      if (!data?.routeEtcString) {
        validCheck = false;
        setError('routeEtcString', {
          type: 'focus',
          name: 'routeEtcString',
          message: formErrorMessage.vehicleNameError
        }, { shouldFocus: true });
      }
    }




    if (validCheck) {
      dispatch(openLoading())

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        basicInfo3: {
          ...prevOptions.basicInfo3,
          conciergeAdvice: data?.conciergeAdvice ?? selectedOptions.conciergeAdvice,
          conciergeAdviceEtcString: data?.conciergeAdviceEtcString ?? selectedOptions.conciergeAdviceEtcString,
          etcRequest: data?.etcRequest ?? selectedOptions.etcRequest,
          etcRequestEtcString: data?.etcRequestEtcString ?? selectedOptions.etcRequestEtcString,
          route: data?.route ?? selectedOptions.route,
          routeEtcString: data?.routeEtcString ?? selectedOptions.routeEtcString,
        }
      }));

      // await하지 않고, 모시러 서버에 저장
      SetMmEstimateHistory({
        ...getDdEstimateCookieDataAndDecrypt(),
        EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
        EstimatePage: PAGE.PAGE_04,
        JsonData: JSON.stringify({
          ...selectedOptions,
          basicInfo3: {
            ...selectedOptions.basicInfo3,
            conciergeAdvice: data?.conciergeAdvice ?? selectedOptions.conciergeAdvice,
            conciergeAdviceEtcString: data?.conciergeAdviceEtcString ?? selectedOptions.conciergeAdviceEtcString,
            etcRequest: data?.etcRequest ?? selectedOptions.etcRequest,
            etcRequestEtcString: data?.etcRequestEtcString ?? selectedOptions.etcRequestEtcString,
            route: data?.route ?? selectedOptions.route,
            routeEtcString: data?.routeEtcString ?? selectedOptions.routeEtcString,
          }
        })
      })



      dispatch(updateDriverDispatchBasicInfo3({
        conciergeAdvice: data?.conciergeAdvice ?? selectedOptions.conciergeAdvice,
        conciergeAdviceEtcString: data?.conciergeAdviceEtcString ?? selectedOptions.conciergeAdviceEtcString,
        etcRequest: data?.etcRequest ?? selectedOptions.etcRequest,
        etcRequestEtcString: data?.etcRequestEtcString ?? selectedOptions.etcRequestEtcString,
        route: data?.route ?? selectedOptions.route,
        routeEtcString: data?.routeEtcString ?? selectedOptions.routeEtcString,
      }))



      dispatch(closeLoading())
      navigate(URLS.DRIVER_DISPATCH_OPTION)
    }
  });


  return (
    <>

      <div className={classNames('booking-wrap')}>
        <PageTitle title={'기본 정보'} subtitle={'기본 정보를 알려주세요'} />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          <div className={classNames('booking-form-list')}>


            {!!ConciergeAdviceOptions && (
              <div className={'booking-form-item'} style={{ gap: '20px' }}>
                <span className={classNames('title-mid')}>컨시어지 상담 가능 시간대</span>
                <RadioGroup
                  classes={''}
                  legend={'conciergeAdvice'}
                  selected={selectedOptions.basicInfo3.conciergeAdvice}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {ConciergeAdviceOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('conciergeAdvice', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              basicInfo3: {
                                ...prevState.basicInfo3,
                                conciergeAdvice: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.basicInfo3.conciergeAdvice === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>

                      </li>
                    ))}
                  </ul>
                </RadioGroup>
                {selectedOptions.basicInfo3.conciergeAdvice === 'etc' &&
                  <Input
                    type={'text'}
                    required
                    setValue={setValue}
                    error={errors.conciergeAdviceEtcString}
                    {...register('conciergeAdviceEtcString')}
                    onChange={e => {
                      setValue('conciergeAdviceEtcString', e.target.value)
                      setSelectedOptions((prevState) => ({
                        ...prevState,
                        basicInfo3: {
                          ...prevState.basicInfo3,
                          conciergeAdviceEtcString: e.target.value
                        },
                      }));
                    }}
                    placeholder={'기타 내용을 입력하세요'}
                  />}
              </div>
            )}

            {!!EtcRequestOptions && (
              <div className={'booking-form-item'} style={{ gap: '20px' }}>
                <span className={classNames('title-mid')}>별도 요청 사항</span>
                <RadioGroup
                  classes={''}
                  legend={'etcRequest'}
                  selected={selectedOptions.basicInfo3.etcRequest}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {EtcRequestOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('etcRequest', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              basicInfo3: {
                                ...prevState.basicInfo3,
                                etcRequest: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.basicInfo3.etcRequest === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>

                      </li>
                    ))}
                  </ul>
                </RadioGroup>
                {selectedOptions?.basicInfo3?.etcRequest === 'yes' &&
                  <Textarea
                    required
                    error={errors.etcRequestEtcString}
                    {...register('etcRequestEtcString')}
                    onChange={(e) => {
                      setValue('etcRequestEtcString', e.target.value)
                      setSelectedOptions((prevState) => ({
                        ...prevState,
                        basicInfo3: {
                          ...prevState.basicInfo3,
                          etcRequestEtcString: e.target.value
                        },
                      }));
                    }}
                    placeholder={'별도 요청 사항을 입력하세요'}
    
                  />

                  
                }
              </div>
            )}

            {/* {!!RouteOptions && (
              <div className={'booking-form-item'} style={{ gap: '20px' }}>
                <span className={classNames('title-mid')}>알게된 경로</span>
                <RadioGroup
                  classes={''}
                  legend={'route'}
                  selected={selectedOptions.basicInfo3.route}
                >
                  <ul className={classNames('booking-item-list row')} style={{ flexDirection: 'column' }}>
                    {RouteOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('route', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              basicInfo3: {
                                ...prevState.basicInfo3,
                                route: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItemRadio
                            selected={selectedOptions.basicInfo3.route === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>

                      </li>
                    ))}
                  </ul>
                </RadioGroup>
                {selectedOptions.basicInfo3.route === 'route_etc' &&
                  <Input
                    type={'text'}
                    required
                    setValue={setValue}
                    error={errors.routeEtcString}
                    {...register('routeEtcString')}
                    onChange={e => {
                      setValue('routeEtcString', e.target.value)
                      setSelectedOptions((prevState) => ({
                        ...prevState,
                        basicInfo3: {
                          ...prevState.basicInfo3,
                          routeEtcString: e.target.value
                        },
                      }));
                    }}
                    placeholder={'기타 내용을 입력하세요'}
                  />
                }
              </div>
            )} */}

          </div>
          <BookingFormSummary2
            description={description}>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => navigate(URLS.DRIVER_DISPATCH_BASIC_INFO_2)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary2>
        </form>
      </div>
    </>
  )
}

export default BasicInfo3;