import axios from 'axios';
import qs from 'qs'

// const WEB_API_URL = process.env.REACT_APP_WEB_API_URL
const WEB_API_URL = 'https://api.mosiler.com'

const parseGoogleSheet = (str) => {
    // 정규식을 사용하여 원하는 부분 추출
    const pattern = /\{.*\}/;
    const match = str.match(pattern);

    // 추출된 문자열
    const extractedText = match ? match[0] : null;
    const jsonData = JSON.parse(extractedText)
    return jsonData.table;
}

const isChkInflowPhoneChk = (data) => {
    try {
        let resultData = parseGoogleSheet(data)
        return (resultData.rows.length >= 1)
    }
    catch(e) {
        return false;
    }
    return false;
}


export const GetMmInflowPhoneCheck = async (phone) => {
    if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
        return true;
    }
    let Url = `${WEB_API_URL}/GetMmInflowPhoneCheck?PhoneNumber=${phone}`
    const result = await axios({
        url: Url,
        method: 'GET',
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json'
        },
    })

    return isChkInflowPhoneChk(result.data.Item.ResultMsg)
}

