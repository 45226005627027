import React, { useEffect, useState } from 'react';
import ko from 'date-fns/locale/ko';
import classNames from 'classnames';
import Button from './Button';
import Icon from './Icon';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const ScheduleDatePicker2 = (
  {
    selectedDate,
    onChange,
  }) => {

  const [internalDate, setInternalDate] = useState(new Date());

  useEffect(() => {
    setInternalDate(new Date(selectedDate))
  }, [selectedDate])

  const handleDateChange = (date) => {
    setInternalDate(date);
    onChange(date);
  };

  const renderCustomHeader = (
    {
      date,
      decreaseMonth,
      increaseMonth
    }) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return (
      <div className={classNames('datepicker__header')}>
        <Button styleType={'icon'} onClick={decreaseMonth} classes={'btn-month'}>
          <Icon icon={'arrow_left'} width={'24px'} height={'24px'} />
        </Button>
        <span className={classNames('year-month grey-1')}>{`${year}년 ${month}월`}</span>
        <Button styleType={'icon'} onClick={increaseMonth} classes={'btn-month'}>
          <Icon icon={'arrow_right'} width={'24px'} height={'24px'} />
        </Button>
      </div>
    );
  };

  return (
    <div className={classNames('datepicker-wrap')}>
      <DatePicker
        selected={internalDate}
        onChange={handleDateChange}
        dateFormat='yyyy년 MM월 dd일'
        showPopperArrow={false}
        autoFocus={false}
        disabledKeyboardNavigation
        onFocus={e => e.target.blur()}
        locale={ko}
        renderCustomHeader={renderCustomHeader}
        minDate={moment().add(1, 'days').toDate()}
      />
      <Icon icon={'calendar'} alt={'calendar'} width={'14.4px'} height={'16px'} classes={'schedule'} />
    </div>
  );
};

export default ScheduleDatePicker2;
