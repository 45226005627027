import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formErrorMessage } from '../../../../utils/alertMessage';
import classNames from 'classnames';
import Input from '../../../atoms/Input';
import Button from '../../../atoms/Button';
import { getNumber2Ciphers, getQueryStringObject, isChkResultSuccess } from '../../../../lib/Util';
import AccountApi from '../../../../lib/AccountApi';
import sha1 from 'sha1'
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../../utils/urls';

/**
 * 회원가입
 * path: reservation/login/register
 * **/

const KakaoLoginPhoneCheck = () => {
  const {
    getValues,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const [qsdata, setQsData] = useState('')
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([])
  const [addressLabel, setButtonLabel] = useState('찾기')
  const [accountInfoData, setaccountInfoData] = useState({})
  const [searchButtonAvaliable, setSearchButtonAvaliable] = useState(false)
  const [tmpAuthCode, setTmpAuthCode] = useState('')
  const [stepTwoFlag, setStepTwoFlag] = useState(false)

  const [PhoneButtonDisabled, SetPhoneButtonDisabled] = useState(false)
  const [AuthNumberButtonDisabled, SetAuthNumberButtonDisabled] = useState(false)
  const [NowTime, SetNowTime] = useState('')


  const Unmount = () => {
    clearTimeout(window.timer)
  }

  useEffect(() => {
    function initData() {
      try {
        let qs = getQueryStringObject()
        if (qs.kakaodata.length) {
          setQsData(qs.kakaodata)
        }
      }
      catch (e) {
        setQsData('')
      }
    }
    initData()
    // Unmount
    return () => Unmount()
  }, [])


  const handleSearchPhoneNumberToId = async () => {
    let phone = getValues('phonenumber')
    let validCheck = true;

    if (!phone?.length) {
      validCheck = false;
      setError('phonenumber', {
        type: 'focus',
        name: 'phonenumber',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }
    if (validCheck) {

      try {
        let result = await AccountApi.KakaoAccountSelect(phone)
        if (isChkResultSuccess(result)) {
          setaccountInfoData(result.Item)
          clearErrors('phonenumber')
        }
        else {
          setaccountInfoData({})
          setError('phonenumber', {
            type: 'focus',
            name: 'phonenumber',
            message: result.Message
          }, { shouldFocus: true });
        }
        setSearchButtonAvaliable(true)

      }
      catch (e) {
        console.log(e)
      }

    }

  }

  // const handleSearchPhoneNumberToId = async () => {
  //   let phone = getValues('phonenumber')
  //   let validCheck = true;

  //   if (!phone?.length) {
  //     validCheck = false;
  //     setError('phonenumber', {
  //       type: 'focus',
  //       name: 'phonenumber',
  //       message: formErrorMessage.phoneError
  //     }, { shouldFocus: true });
  //   }
  //   if (validCheck) {

  //     try {
  //       let result = await AccountApi.KakaoAccountSelect(phone)
  //       if (isChkResultSuccess(result)) {
  //         setaccountInfoData(result.Item)
  //         clearErrors('phonenumber')
  //       }
  //       else {
  //         setaccountInfoData({})
  //         setError('phonenumber', {
  //           type: 'focus',
  //           name: 'phonenumber',
  //           message: result.Message
  //         }, { shouldFocus: true });
  //       }
  //       setSearchButtonAvaliable(true)

  //     }
  //     catch (e) {
  //       console.log(e)
  //     }

  //   }

  // }

  const isChkCustomerInfoData = () => {
    return accountInfoData?.CustomerNo !== null && accountInfoData?.CustomerName !== null && accountInfoData?.UserId !== null && accountInfoData?.AccountType !== null
  }

  // 

  const userInfoConvertAndLogin = async () => {
    let phone = getValues('phonenumber')
    let kakaoid = qsdata

    if (window.confirm('계정타입이 kakao로 변경됩니다.\n계속 하시겠습니까?')) {
      let result = await AccountApi.ConvertAccountTypeToKakao(kakaoid, phone)
      if (isChkResultSuccess(result)) {
        alert('계정 변환 처리가 완료되었습니다.\n다시 한번 카카오 로그인을 진행해주세요.')
        navigate(URLS.EMAIL_LOGIN, { replace: true })
      }
      else {
        alert('계정변환 처리에 실패 했습니다. 모시러 컨시어지로 문의해주세요.')
      }
    }

  }

  const handlePhoneAuth = async () => {
    let phone = getValues('phonenumber')
    let validCheck = true;

    if (!phone?.length) {
      validCheck = false;
      setError('phonenumber', {
        type: 'focus',
        name: 'phonenumber',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }
    if (validCheck) {

      try {
        let result = await AccountApi.SetVerifySms(phone)
        if (isChkResultSuccess(result)) {
          alert('입력하신 전화번호로 인증번호를 발송했습니다.')
          clearErrors('phonenumber')
          setStepTwoFlag(true)
          setTmpAuthCode(result.Message)
          SetAuthNumberButtonDisabled(false)
          limitTimer()
        }
        else {
          alert(result.Message)
        }

      }
      catch (e) {
        console.log(e)
      }

    }
  }

  // 인증 버튼 눌렀을때
  const handleAuthCodeCheck = async () => {
    let authNumber = getValues('authNumber')
    let phone = getValues('phonenumber')
    let validCheck = true;

    if (!authNumber?.length) {
      validCheck = false;
      setError('authNumber', {
        type: 'focus',
        name: 'authNumber',
        message: '인증번호를 입력해주세요.'
      }, { shouldFocus: true });
    }
    if (validCheck) {

      if (authNumber === tmpAuthCode) {
        try {
          clearErrors('authNumber')
          let result = await AccountApi.KakaoAccountSelect(phone)
          if (isChkResultSuccess(result)) {
            setaccountInfoData(result.Item)
            // clearErrors('phonenumber')
            SetAuthNumberButtonDisabled(true)
            setSearchButtonAvaliable(true)
            clearInterval(window.timer)
          }
          else {
            setaccountInfoData({})
            alert(result.Message)
            clearInterval(window.timer)
            setStepTwoFlag(false)

            navigate(`${URLS.EMAIL_LOGIN}`, { replace: 'true' })
            navigate(`${URLS.REGISTER}?kakaoid=${qsdata}`)
            // "회원가입을 해주세요"로 나올 경우는 
            // 
          }


        }
        catch (e) {
          console.log(e)
        }



      }
      else {

        setError('authNumber', {
          type: 'focus',
          name: 'authNumber',
          message: '인증번호가 일치하지 않습니다.'
        }, { shouldFocus: true });
      }

    }




  }

  const handleMemberJoinKakao = async () => {
    if (qsdata?.length >= 1) {
      navigate(`${URLS.REGISTER}?kakaoid=${qsdata}`)
    }
    else {
      alert()
      navigate(`${URLS.EMAIL_LOGIN}`, {
        replace: true
      })
    }

  }

  const limitTimer = () => {
    let time = 180;   // 3분
    let min = '';
    let sec = '';
    window.timer = setInterval(() => {
      min = parseInt(time / 60);
      sec = time % 60;
      // document.querySelector('.text-darkblue').innerHTML =
      SetNowTime(`${getNumber2Ciphers(min)}:${getNumber2Ciphers(sec)}`)
      time--;

      if (time < 0) {
        // this.setState({
        //   responseAuthNum: '',
        //   authCheckFlag: 1
        // })
        alert('인증 시간이 지났습니다. 다시 인증해 주세요.')
        clearErrors('authNumber')
        setStepTwoFlag(false)
        SetNowTime('')
        setValue('authNumber', '')
        clearInterval(window.timer)
        // document.querySelector('.is-successed').style.display="none"
        // document.querySelector('.is-successed').innerHTML = '';
        // document.querySelector('.is-errored').style.display=""
        // document.querySelector('.is-errored').innerHTML = '인증시간이 초과되었습니다.';
        // document.querySelector('.text-darkblue').innerHTML = '';
      }
    }, 1000);
  }



  return (
    <div className={classNames('login-form')} style={{ gap: '20px' }}>
      <div className={classNames('input-set')}>
        <div>
          <ul className={classNames('item-list pending-details')}>
            <li>전화번호 인증을 진행 합니다.</li>
            <li>모시러에 회원가입 되어있다면 카카오 계정 전환처리를 도와드립니다.</li>
            <li>첫 이용이실 경우는 회원가입 버튼을 눌러주세요.</li>
          </ul>
        </div>

      </div>

      <div className={classNames('input-set')}>
        <span className={classNames('txt-large')}>
          전화번호
        </span>
        <Input
          type={'text'}
          required
          setValue={setValue}
          error={errors.phonenumber}
          onChange={(e) => {
            setValue('phonenumber', e.target.value)
          }}
          placeholder={'전화번호'}
          {...register('phonenumber')}
        />
      </div>
      <Button
        type={'button'}
        styleType={'secondary'}
        classes={'bold txt-large'}
        onClick={handlePhoneAuth}
      >전화번호인증</Button>

      {stepTwoFlag && (<>
        <div className={classNames('input-set')}>
          <span className={classNames('txt-large')}>
            인증번호 입력
          </span>
          <Input
            type={'text'}
            required
            {...register('authNumber')}
            setValue={setValue}
            error={errors.authNumber}
            onChange={(e) => {
              setValue('authNumber', e.target.value)
            }}
            placeholder={'인증번호 입력'}
            disabled={AuthNumberButtonDisabled}

          />
          <span className={classNames('txt-regular')}>
            {AuthNumberButtonDisabled ? '인증이 완료되었습니다.' : `제한시간 ${NowTime}`}
          </span>
        </div>

        <Button
          type={'button'}
          styleType={AuthNumberButtonDisabled ? 'primary' : 'secondary'}
          classes={'bold txt-large'}
          onClick={handleAuthCodeCheck}
          disabled={AuthNumberButtonDisabled}
          style={AuthNumberButtonDisabled ? { backgroundColor: '#cbcbcb' } : {}}
        >{AuthNumberButtonDisabled ? '인증완료' : '인증'}</Button>

      </>)}

      {/* 리스트표시 */}
      {(searchButtonAvaliable && isChkCustomerInfoData()) && (
        <div className={classNames('input-set')}>
          <span className={classNames('txt-large')}>
            검색 결과
          </span>
          <div>{accountInfoData?.CustomerName} / {accountInfoData?.UserId} / {accountInfoData?.AccountType} </div>
        </div>
      )}

      {(searchButtonAvaliable && isChkCustomerInfoData()) && (
        <Button
          type={'button'}
          styleType={'secondary'}
          classes={'bold txt-large'}
          onClick={userInfoConvertAndLogin}
        >카카오 계정변경</Button>
      )}
      <div>
        <hr style={{ border: '1px solid #e0e0e0' }} />
      </div>
      <Button
        type={'button'}
        styleType={'secondary'}
        classes={'bold txt-large'}
        onClick={handleMemberJoinKakao}
      >회원가입</Button>


      {/* <Button
        type={'submit'}
        styleType={'secondary'}
        classes={'bold txt-large'}
      >{addressLabel}</Button> */}
    </div>

  )
}

export default KakaoLoginPhoneCheck