import axios from 'axios';

import { Token } from '../constantData';

const TIME_OUT = 20000;
const USER_API_URL = (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) ? 'https://apitest.client.mosiler.com' : process.env.REACT_APP_USER_API_URL

const CookieData = document.cookie
    .split(';')
    .reduce((res, c) => {
        const [key, val] = c.trim().split('=').map(decodeURIComponent)
        try {
            return Object.assign(res, { [key]: JSON.parse(val) })
        } catch (e) {
            return Object.assign(res, { [key]: val })
        }
    }, {});


const ReserveApi = {};
/******************************************************************************************/
/*                                      API 호출 부                                       */
/******************************************************************************************/
ReserveApi.GetAddressReserveList = (CustomerNo) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/GetAddressReserveList?CustomerNo=${CustomerNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
                'Authorize': CookieData.li_02,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

ReserveApi.SetDrivingReservationMultiple = (arrData) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/SetDrivingReservationMultiple`,
            method: 'POST',
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
                'Authorize': CookieData.li_02,
            },
            data: { Row: arrData }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


ReserveApi.SetDrivingReservationMultipleAsync = (arrData) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/SetDrivingReservationMultipleAsync`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
                'Authorize': CookieData.li_02,
            },
            data: { Row: arrData }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

ReserveApi.SetDrivingReservationMultipleAsyncTest = (arrData) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/SetDrivingReservationMultipleAsyncTest`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
                'Authorize': CookieData.li_02,
            },
            data: { Row: arrData }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}



ReserveApi.MmCustomerInfo = (CustomerNo) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/MmCustomerInfo?CustomerNo=${CustomerNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
                'Authorize': CookieData.li_02,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


ReserveApi.CanCancelDriving = (drivingNo) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v2/CanCancelDriving?drivingNo=${drivingNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
                'Authorize': CookieData.li_02,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

ReserveApi.CancelDrivingReservation = (customerno, drivingno, cancelHostType, cancelHostNo) => {
    
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/CancelDrivingReservation?customerno=${customerno}&drivingno=${drivingno}&cancelhosttype=${cancelHostType}&cancelhostno=${cancelHostNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
                'Authorize': CookieData.li_02,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });

}

export default ReserveApi;
