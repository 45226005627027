import React from 'react';
import classNames from 'classnames';
import Button from '../atoms/Button';

const TableRowCustom = (
  {
    row,
    large = false,
    colspan,
    classes,
    seeDetail = false,
    onClickSeeDetail,
    onClickCancel,
  }) => {
  return (
    <>
      {typeof row == 'string' ? (
        <tr>
          <td
            dangerouslySetInnerHTML={{ __html: row }}
            colSpan={colspan}
            className={classNames(large && 'large', classes ?? classes)}>
          </td>
        </tr>
      ) : (
        <tr>
          {row?.map((item, index) => {
            if (item.indexOf(`cancel_`) >= 0) {
              return (
                <td
                  key={index}
                  // dangerouslySetInnerHTML={{ __html: item }}
                  className={classNames(large && 'large', classes ?? classes)}>
                  <Button
                    className={'bg-red'}
                    onClick={() => onClickCancel(item)}>
                    취소하기
                  </Button>
                </td>
              )
            }
            if (item.indexOf(`detail_`) >= 0) {
              return (
                <td
                  key={index}
                  // dangerouslySetInnerHTML={{ __html: item }}
                  className={classNames(large && 'large', classes ?? classes)}>
                  <Button
                    className={'bg-green'}
                    onClick={() => onClickSeeDetail(item)}>
                    보기
                  </Button>
                </td>
              )
            }
            return (
              <td
                key={index}
                dangerouslySetInnerHTML={{ __html: item }}
                className={classNames(large && 'large', classes ?? classes)}>
              </td>
            )
          })}
          {seeDetail && (
            <td className={classNames('align-right')}>
              <Button
                type={'text'}
                className='txt-small grey-3 table-summary-button'
                onClick={onClickSeeDetail}
              >
                더보기
              </Button>
            </td>
          )}
        </tr>
      )}
    </>
  );
};

export default TableRowCustom;
