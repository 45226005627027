import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useBookingOptionContext} from '../../organisms/BookingOptionContext';
import {PaymentMethodOptions} from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import BookingItem from '../../molecules/BookingItem';
import {getValueByTarget} from '../../../utils/parse';
import Button from '../../atoms/Button';
import {URLS} from '../../../utils/urls';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import Checkbox from '../../atoms/CheckBox';
import Input from '../../atoms/Input';
import {formErrorMessage} from '../../../utils/alertMessage';
import {updatePaymentMethod} from '../../../reducers/bookingEstimation';
import {handlePhoneChange} from '../../../utils/numbers';


/**
 * 계약서 확인 > 결제수단 선택 페이지
 * name : paymentMethod
 * */

const PaymentMethod = () =>{
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ description, setDescription ] = useState([]);
  const { selectedOptions, setSelectedOptions } = useBookingOptionContext();

  useEffect(()=>{
    const updatedDescription = [
      `결제 수단은 <i class='blue'>
        ${getValueByTarget(
        PaymentMethodOptions,
        selectedOptions.paymentMethod.method,
        'title'
      )}</i> 입니다.`,
      `<i class='blue'>
            ${selectedOptions?.paymentMethod?.taxBill ? '세금 계산서 발행' : '세금 계산서 미발행'}</i> 
         으로 진행됩니다.`,
    ]
    setDescription(updatedDescription)
  }, [
    selectedOptions.paymentMethod.method,
    selectedOptions.paymentMethod.taxBill
  ])

  const {
    handleSubmit, setValue, register, setError, formState: { errors }} = useForm({
    defaultValues: {
      paymentMethod: selectedOptions?.paymentMethod.method,
      taxBill: selectedOptions?.paymentMethod.method,
      cashReceiptPhoneNumber: selectedOptions?.paymentMethod.cashReceiptPhoneNumber
    }
  });

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;
    if (data?.method === 'cash' && data?.cashReceiptPhoneNumber !== '') {
      setError('cashReceiptPhoneNumber', {
        type: 'focus',
        name: 'cashReceiptPhoneNumber',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }

    if (validCheck) {
      setSelectedOptions((prevState) => ({
        ...prevState,
        paymentMethod: {
          ...prevState.paymentMethod,
          method: data?.method,
          taxBill: data?.taxBill,
          cashReceiptPhoneNumber: data?.cashReceiptPhoneNumber
        },
      }));
      dispatch(updatePaymentMethod({
        method: data?.method ?? selectedOptions?.paymentMethod?.method,
        taxBill: data?.taxBill ?? selectedOptions?.paymentMethod?.taxBill,
        cashReceiptPhoneNumber: data?.cashReceiptPhoneNumber ?? selectedOptions?.paymentMethod?.cashReceiptPhoneNumber,
      }))
      navigate(URLS.BOOKING_ESTIMATION_CONFIRM_CONTRACT)
    }
  })

  return (
    <>
      <div className={classNames('booking-wrap')}>
        <PageTitle title={'계약서 확인(2)'} subtitle={'결제 수단을 선택해 주세요'}/>
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          <div className={classNames('booking-form-list')}>
              {!!PaymentMethodOptions && (
                <div className={'booking-form-item'}>
                  <span className={classNames('title-mid')}>결제 수단</span>
                  <RadioGroup
                    classes={''}
                    legend={'method'}
                    selected={selectedOptions.paymentMethod.method}
                  >
                    <ul className={classNames('booking-item-list row')}>
                      {PaymentMethodOptions.map((item) => (
                        <li key={item.title}>
                          <Radio
                            key={item.title}
                            value={item.value}
                            onChange={e=>{
                              setValue('method', item.value);
                              setSelectedOptions((prevState) => ({
                                ...prevState,
                                paymentMethod: {
                                  ...prevState.paymentMethod,
                                  method: item.value,
                                },
                              }));
                            }}
                          >
                            <BookingItem
                              selected={selectedOptions.paymentMethod.method === item.value}
                              subtitle={item?.title}
                              isAlignCenter={true}
                            />
                          </Radio>
                        </li>
                      ))}
                    </ul>
                  </RadioGroup>
                </div>
              )}
              <div className={classNames('booking-form-list')}>
                <div className={'booking-form-item'}>
                  <span className={classNames('title-mid')}>세금계산서</span>
                  <Checkbox
                    label={'세금 계산서 발행을 원합니다.'}
                    checked={selectedOptions.paymentMethod.taxBill}
                    onClick={e => {
                      setSelectedOptions((prevState) => ({
                        ...prevState,
                        paymentMethod: {
                          ...prevState.paymentMethod,
                          taxBill: e.target.checked,
                        },
                      }));
                      setValue('taxBill', e.target.checked)
                    }}
                  />
                </div>
            </div>
            {selectedOptions.paymentMethod.method === 'cash' && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>현금영수증</span>
                <Input
                  type={'tel'}
                  required
                  setValue={setValue}
                  maxLength={11}
                  error={errors.cashReceiptPhoneNumber}
                  placeholder={'휴대폰 번호'}
                  {...register('cashReceiptPhoneNumber')}
                  onChange={e=>{
                    handlePhoneChange(e, setValue, 'cashReceiptPhoneNumber')
                    setSelectedOptions((prevState) => ({
                      ...prevState,
                      paymentMethod: {
                        ...prevState.paymentMethod,
                        cashReceiptPhoneNumber: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
            )}
          </div>
          <BookingFormSummary
            hasAmonut={false}
            description={description}>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  ()=>navigate(URLS.BOOKING_ESTIMATION_USER_INFORMATION)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary>
        </form>
      </div>
    </>
  )
}

export default PaymentMethod;