import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { transFormWon, transFormWon2 } from '../../utils/numbers';
import useCheckPC from '../../hooks/useCheckPC';
import { getCalcSalaryDetailEstimate2, getCalcSalaryDetailEstimateRecruit } from '../../lib/DriverDispatchApi';

const NewListBox = (
  {
    title,
    listItem,
    salary,
    subscript
  }) => {
  const [DriverDispatch, SetDriverDispatch] = useState({
    총연봉액: 0,
    totalEstimateAmount: 0,
    step1: {
      기본급: 0,
      중식보조금: 0,
      시간외근로수당: 0,
      // 퇴직급여충당금: 0,
      // 연차급여충당금: 0
      total: 0
    },
    step2: {
      customDriverEdu: 1750000,                 // 연 4회 맞춤형 기사 교육
      driverMgmtProgram: 1100000,               // 수행기사 자동 관리 프로그램 제공
      total: 0
    },
    step3: {
      국민연금료: 0,
      건강보험료: 0,
      노인장기요양보험료: 0,
      산재보험료: 0,
      고용보험료: 0,
      장애자고용분담금: 0,
      사업소세: 0,
      total: 0
    },
    step4: {
      복리후생비: 0,
      교육훈련비: 0,
      채용선발비: 0,
      일반관리비: 0,
      기타직접비: 0,
      이익준비금: 0,
      total: 0
    }
  })
  const [DriverRecruit, SetDriverRecruit] = useState({
    totalSalary: 0,
    totalEstimateAmount: 0,
    step1: {                                // service
      customDriverEdu: 1750000,                 // 연 4회 맞춤형 기사 교육
      driverMgmtProgram: 1100000,               // 수행기사 자동 관리 프로그램 제공
      total: 0
    },
    step2: {                                // 채용수수료
      educationTrainingCost: 0,           // 교육훈련비
      recruitmentSelectionCost: 0,        // 채용선발비
      generalManagementCost: 0,           // 일반관리비
      otherDirectCost: 0,                 // 기타직접비
      profitReserveFund: 0,               // 이익준비금
      total: 0
    },
  })

  useEffect(() => {
    SetDriverDispatch(getCalcSalaryDetailEstimate2(Number(salary) * 10000))
    SetDriverRecruit(getCalcSalaryDetailEstimateRecruit(Number(salary) * 10000))

    // for (let i in Object.keys(obj.step1)) {
    //   let str = Object.keys(obj.step1)[i]
    //   updateEstimateStep1.push({ title: totalToHapgye(str), price: obj.step1[str] })
    // }

  }, [])

  const { isPC } = useCheckPC();
  // 채용
  if (subscript === 'recruit') {
    return (
      <div className={classNames('new-list-box')}>
        <div className={classNames('unit')}>
          (단위 : KRW)
        </div>

        <div className={classNames('sub title')}>
          <div>DESCRIPTION</div>
          <div>UNIT PRICE</div>
          <div>TOTAL PRICE</div>
        </div>

        <div className={classNames('sub')}>
          <div>채용 월 급여액</div>
          <div></div>
          <div>{transFormWon2(DriverRecruit.totalSalary)}</div>
          {/* <div>추후협의</div> */}
        </div>
        <div className={classNames('sub')}>
          <div>Service</div>
          <div></div>
          <div>0</div>
        </div>
        <div>
          <div className={classNames('sub-list')}>
            <div>연 4회 맞춤형 기사 교육</div>
            <div className='dead-line'>1,750,000</div>
            <div>&nbsp;</div>
          </div>
          <div className={classNames('sub-list')}>
            <div>수행기사 자동 관리 프로그램 제공</div>
            <div className='dead-line'>1,100,000</div>
            <div>&nbsp;</div>
          </div>
        </div>

        <div className={classNames('sub')}>
          <div>채용 수수료</div>
          <div></div>
          <div>{transFormWon2(DriverRecruit?.step2?.total)}</div>
        </div>
        <div>
          {Object.keys(DriverRecruit?.step2).map((data, idx) => {
            return (
              <>
                {data !== 'total' &&
                  <div className={classNames('sub-list')} key={idx}>
                    <div>{data}</div>
                    <div >{transFormWon2(DriverRecruit?.step2[data])}</div>
                    <div>&nbsp;</div>
                  </div>
                }
              </>
            )
          })}
        </div>
        <div className={classNames('sub result')}>
          <div>TOTAL</div>
          <div></div>
          <div>{transFormWon2(DriverRecruit?.step2?.total)}</div>
        </div>

      </div>
    )
  }

  // 파견
  return (
    <div className={classNames('new-list-box')}>
      <div className={classNames('unit')}>
        (단위 : KRW)
      </div>

      <div className={classNames('sub title')}>
        <div>DESCRIPTION</div>
        <div>UNIT PRICE</div>
        <div>TOTAL PRICE</div>
      </div>

      {/* 직간접인건비 */}
      <div className={classNames('sub')}>
        <div>기준 연봉액</div>
        <div></div>
        <div>{transFormWon2(DriverDispatch?.totalSalary)}</div>
      </div>

      {/* 직간접인건비 */}
      <div className={classNames('sub')}>
        <div>직간접인건비</div>
        <div></div>
        <div>{transFormWon2(DriverDispatch?.step1?.total)}</div>
      </div>
      <div>

        {Object.keys(DriverDispatch?.step1).map((data, idx) => {
          return (
            <>
              {data !== 'total' &&
                <div className={classNames('sub-list')} key={idx}>
                  <div>{data}</div>
                  <div >{transFormWon2(DriverDispatch?.step1[data])}</div>
                  <div>&nbsp;</div>
                </div>
              }
            </>
          )
        })}
        <div className={classNames('sub-list')}>
          <div>퇴직급여충당금</div>
          <div>발생 시 청구</div>
          <div>&nbsp;</div>
        </div>
        <div className={classNames('sub-list')}>
          <div>연차급여충당금</div>
          <div>발생 시 청구</div>
          <div>&nbsp;</div>
        </div>
      </div>

      {/* 서비스 */}
      <div className={classNames('sub')}>
        <div>Service</div>
        <div></div>
        <div>0</div>
      </div>
      <div>
        <div className={classNames('sub-list')}>
          <div>연 4회 맞춤형 기사 교육</div>
          <div className='dead-line'>1,750,000</div>
          <div>&nbsp;</div>
        </div>
        <div className={classNames('sub-list')}>
          <div>수행기사 자동 관리 프로그램 제공</div>
          <div className='dead-line'>1,100,000</div>
          <div>&nbsp;</div>
        </div>
      </div>

      {/* 법정비용 */}
      <div className={classNames('sub')}>
        <div>법정비용</div>
        <div></div>
        <div>{transFormWon2(DriverDispatch?.step3?.total)}</div>
      </div>
      <div>
        {Object.keys(DriverDispatch?.step3).map((data, idx) => {
          return (
            <>
              {data !== 'total' &&
                <div className={classNames('sub-list')} key={idx}>
                  <div>{data}</div>
                  <div >{transFormWon2(DriverDispatch?.step3[data])}</div>
                  <div>&nbsp;</div>
                </div>
              }
            </>
          )
        })}
      </div>

      {/* 관리비용 */}
      <div className={classNames('sub')}>
        <div>관리비용</div>
        <div></div>
        <div>{transFormWon2(DriverDispatch?.step4?.total)}</div>
      </div>
      <div>
        {Object.keys(DriverDispatch?.step4).map((data, idx) => {
          return (
            <>
              {data !== 'total' &&
                <div className={classNames('sub-list')} key={idx}>
                  <div>{data}</div>
                  <div >{transFormWon2(DriverDispatch?.step4[data])}</div>
                  <div>&nbsp;</div>
                </div>
              }
            </>
          )
        })}
      </div>

      <div className={classNames('sub result')}>
        <div>TOTAL</div>
        <div></div>
        <div>{transFormWon2(DriverDispatch?.step1?.total + DriverDispatch?.step3?.total + DriverDispatch?.step4?.total)}</div>
      </div>


      {/* <div className={classNames('sub')}></div> */}

    </div>
  )
}

export default NewListBox