import React from 'react';
import classNames from 'classnames';

const PercentageGraph = ({ child, parent, purechild }) => {

  const percentage = child / parent * 100

  return (
    <>
      <div className={classNames('mb-8')}>
        {(purechild !== null) ? (
          <>
            <i className={classNames('blue')}>{Math.floor(purechild / 60)}시간 {purechild % 60}분</i> 이용 ({Math.floor(percentage)}%)
          </>
        ) : (
          <>
            <i className={classNames('blue')}>{child}시간</i> 이용 ({Math.floor(percentage)}%)
          </>
        )}

      </div>
      <div className={classNames('percentage-graph')}>
        <div
          className={classNames('rate')}
          style={{
            width: `${percentage >= 100 ? 100 : percentage}%`,
          }}
        />
      </div>
    </>
  )
}

export default PercentageGraph