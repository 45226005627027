const initialMyPageData = {
    AddressList: [
        {
            Id: 0,
            CustomerNo: 0,
            Alias: '',
            Address: '',
            Lat: '',
            Lng: ''
        }
    ],
    BusinessData: 
    {
        Id: 0,
        CustomerNo: 0,
        BusinessCode: '',
        RepresentativeName: '',
        Address1: '',
        Address2: '',
        ImageUrl: ''
    },
    CarInfoList: [
        {
            Id: 0,
            CustomerNo: 0,
            CarNumber: '',
            CarModel: ''
        }
    ],
    MemberList: [
        {
            CustomerNo: 0,
            CustomerName: '',
            PhoneNumber: '',
            UserId: '',
            AccountType: '',
            ParentCustomerNo: 0,
            UseType: 0,
            Roles: 0,
            MemberType: ''
        }
    ],
}

const MYPAGE_RESET = 'MYPAGE/RESET';
const MYPAGE_ALL = 'MYPAGE/ALL';
const MYPAGE_ADDRESSLIST = 'MYPAGE/ADDRESSLIST';
const MYPAGE_BUSINESSDATA = 'MYPAGE/BUSINESSDATA';
const MYPAGE_CARINFOLIST = 'MYPAGE/CARINFOLIST';
const MYPAGE_MEMBERLIST = 'MYPAGE/MEMBERLIST';


export const mypageReset = () => ({
    type: MYPAGE_RESET
})
export const mypageAll = (data) => ({
    type: MYPAGE_ALL,
    payload: data
})
export const mypageAddressList = (data) => ({
    type: MYPAGE_ADDRESSLIST,
    payload: data
})
export const mypageBusinessData = (data) => ({
    type: MYPAGE_BUSINESSDATA,
    payload: data
})
export const mypageCarinfoList = (data) => ({
    type: MYPAGE_CARINFOLIST,
    payload: data
})
export const mypageMemberList = (data) => ({
    type: MYPAGE_MEMBERLIST,
    payload: data
})

export const MyPageRedux = (state = initialMyPageData, action) => {
    let retData = {}
    switch(action.type) {
        case MYPAGE_RESET:
            retData = {
                ...state,
            }
            break;
        case MYPAGE_ALL:
            retData = {
                ...state,
                ...action.payload
            }
            break;
        case MYPAGE_ADDRESSLIST:
            retData = {
                ...state,
                AddressList: action.payload
            }
            break;
        case MYPAGE_BUSINESSDATA:
            retData = {
                ...state,
                BusinessData: action.payload
            }
            break;
        case MYPAGE_CARINFOLIST:
            retData = {
                ...state,
                CarInfoList: action.payload
            }
            break;
        case MYPAGE_MEMBERLIST:
            retData = {
                ...state,
                MemberList: action.payload
            }
            break;
        default:
            retData = {
                ...state,
            }
            break;
    }
    return retData;
    
};