import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DriverDispatchManageBox = ({ children }) => {
  return (
    <div className={classNames('reservation-box')}>
      {children}
    </div>
  )
}

DriverDispatchManageBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DriverDispatchManageBox;