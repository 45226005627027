import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  BookingProducts,
  ContractPeriodOptions,
  PassengerOptions,
  PurposeOfContractOptions,
  UserTypeOptions,
  UsageTimeOptionsAnnual,
  UsageTimeOptionsMonthly,
  RouteOptions,
  NumberOfVehiclesOptions,
  CarAmountOptions,
  InsuranceOptions,
  PassengerPersonOptions,
  RoleOfVehiclesOptions


} from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import BookingItem from '../../molecules/BookingItem';
import Radio from '../../atoms/Radio';
import Input from '../../atoms/Input';

import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import { useForm } from 'react-hook-form';
import { updateContractTerms2 } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import { formErrorMessage } from '../../../utils/alertMessage';
import { getEstimateCookieDataAndDecrypt, getDateCompateToString } from '../../../lib/Util';
import { SetMmEstimateHistory, setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import TotalAmount4 from '../../atoms/TotalAmount4';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, PAGE } from '../../../utils/constant';
import useCheckPC from '../../../hooks/useCheckPC';




/**
 * 이용 정보 페이지
 * name : contractTerms2
 * */
const ContractTerms2 = () => {
  const { isPC } = useCheckPC();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOptions, setSelectedOptions } = useBookingOptionContext();
  const [description, setDescription] = useState([]);
  const { BookingOption } = useSelector((state) => state);



  useEffect(() => {
    const updatedDescription = [
      `월간모시러의 이용목적은
        <i class='blue'>
        ${getValueByTarget(
        PurposeOfContractOptions,
        selectedOptions.contractTerms2.purposeOfContract,
        'title'
      )}</i> 입니다.`,
      `주 탑승자는
        <i class='blue'>
        ${getValueByTarget(
        PassengerOptions, selectedOptions?.contractTerms2?.passenger,
        'title') ?? '미입력'}</i> 
         입니다.`,
      `알게된 경로는 
        <i class='blue'>
        ${getValueByTarget(
        RouteOptions, selectedOptions?.contractTerms2?.route,
        'title') ?? '미입력'}</i> 
         입니다.`,
      `운행 차량의 모델명은 
        <i class='blue'>${selectedOptions?.contractTerms2?.vehicleName1 ?? '미입력'}</i> 
      입니다.`,
      `운행 차량의 모델명은 
        <i class='blue'>${selectedOptions?.contractTerms2?.vehicleName2 ?? '미입력'}</i> 
      입니다.`
    ]


    setDescription(updatedDescription)
  }, [
    selectedOptions.contractTerms2.purposeOfContract,
    selectedOptions.contractTerms2.passenger,
    selectedOptions.contractTerms2.route,
    selectedOptions.contractTerms2.vehicleName1,
    selectedOptions.contractTerms2.vehicleName2,
    selectedOptions.contractTerms2.vehicleName3,
  ])

  const { handleSubmit, setValue, register, setError, formState: { errors } } = useForm({
    defaultValues: {
      purposeOfContract: selectedOptions?.contractTerms2?.purposeOfContract,
      passenger: selectedOptions?.contractTerms2?.passenger,
      passengerEtcString: selectedOptions?.contractTerms2?.passengerEtcString,
      route: selectedOptions?.contractTerms2?.route,
      routeEtcString: selectedOptions?.contractTerms2?.routeEtcString,
      vehicleName1: selectedOptions?.contractTerms2?.vehicleName1,
      vehicleName2: selectedOptions?.contractTerms2?.vehicleName2,
      vehicleName3: selectedOptions?.contractTerms2?.vehicleName3,
    }
  });

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;

    if (!data?.vehicleName1) {
      validCheck = false;
      setError('vehicleName1', {
        type: 'focus',
        name: 'vehicleName1',
        message: formErrorMessage.vehicleNameError
      }, { shouldFocus: true });
    }

    if (!data?.vehicleName2) {
      validCheck = false;
      setError('vehicleName2', {
        type: 'focus',
        name: 'vehicleName2',
        message: formErrorMessage.vehicleNameError
      }, { shouldFocus: true });
    }

    // 
    if (VehiclesOptionCount(selectedOptions?.vehicleOption?.numberOfVehicles) === 3) {
      if (!data?.vehicleName3) {
        validCheck = false;
        setError('vehicleName3', {
          type: 'focus',
          name: 'vehicleName3',
          message: formErrorMessage.vehicleNameError
        }, { shouldFocus: true });
      }
    }

    if (data?.passenger === 'etc') {
      if (!data?.passengerEtcString) {
        validCheck = false;
        setError('passengerEtcString', {
          type: 'focus',
          name: 'passengerEtcString',
          message: formErrorMessage.passengerEtcStringError
        }, { shouldFocus: true });
      }
    }

    if (data?.route === 'route_etc') {
      if (!data?.routeEtcString) {
        validCheck = false;
        setError('routeEtcString', {
          type: 'focus',
          name: 'routeEtcString',
          message: formErrorMessage.routeEtcStringError
        }, { shouldFocus: true });
      }
    }

    if (validCheck) {
      dispatch(openLoading())
      setSelectedOptions((prevState) => ({
        ...prevState,
        contractTerms2: {
          ...prevState.contractTerms2,
          purposeOfContract: data?.purposeOfContract,
          passenger: data?.passenger,
          passengerEtcString: data?.passengerEtcString,
          route: data?.route,
          routeEtcString: data?.routeEtcString,
          vehicleName1: data?.vehicleName1,
          vehicleName2: data?.vehicleName2,
          vehicleName3: data?.vehicleName3,
        },
      }));
      dispatch(updateContractTerms2({
        purposeOfContract: data?.purposeOfContract ?? null,
        passenger: data?.passenger ?? null,
        passengerEtcString: data?.passengerEtcString ?? null,
        route: data?.route ?? null,
        routeEtcString: data?.routeEtcString ?? null,
        vehicleName1: data?.vehicleName1 ?? null,
        vehicleName2: data?.vehicleName2 ?? null,
        vehicleName3: data?.vehicleName3 ?? null,
      }))


      // await하지 않고, 모시러 서버에 저장
      SetMmEstimateHistory({
        ...getEstimateCookieDataAndDecrypt(),
        EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
        EstimatePage: PAGE.PAGE_05,
        JsonData: JSON.stringify({
          ...selectedOptions,
          contractTerms2: {
            ...selectedOptions.contractTerms2,
            purposeOfContract: data?.purposeOfContract,
            passenger: data?.passenger,
            passengerEtcString: data?.passengerEtcString,
            route: data?.route,
            routeEtcString: data?.routeEtcString,
            vehicleName1: data?.vehicleName1,
            vehicleName2: data?.vehicleName2,
            vehicleName3: data?.vehicleName3,
          },
        })
      })


      /*
      let form = qs.stringify({
        '1. 성함': userinfo.UserName,
        '2. 연락처': userinfo.UserPhone,
        '3. 이메일 주소': userinfo.UserEmail,
        '4. 출발지와 종료지 주소를 알려주세요': obj.formData.StartArea + "/" + obj.formData.EndArea,
        '5. 월간 모시러의 어떤 상품 사용을 원하시나요? ': (obj.productCategory === 0 ? '[월간]' : '[연간]') + (obj.selectedHour + '시간 상품 ') + ('1일 최소 ' + obj.selectedOneDayHour + '이상') + ('단가:' + obj.selectedHourPrice),
        '6. 서비스 이용을 하신다면 시점은 언제인가요? ': obj.UseServiceString,
        '7. 월간 모시러를 알게 된 경로는 어떻게 되시나요? ': Number(obj.formData.Route) === ETC_ID ? obj.formData.RouteEtcStr : obj.RouteString,
        '8. 특별히 요청할 내용이 있으면 기재해 주시기 바랍니다.': obj.formData.EtcRequest,
        '9. 컨시어지 상담을 원하시면 알려주세요.': '(웹)' + obj.ConciergeString,
        '타임스탬프': moment().format('yyyy-MM-DD HH:mm'),
        '이용목적': obj.NeedsString,
    });
     */

      let userInfoDecrypt = getEstimateCookieDataAndDecrypt();
      let SelectedProduct = ''
      SelectedProduct += getValueByTarget(BookingProducts, BookingOption.subscription, 'title') + ' ';
      SelectedProduct += (BookingOption.subscription === 'annual') ?
        getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'title') + ' ' + getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'subtitle') + ' ' + getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'hourPrice') + '원' :
        getValueByTarget(UsageTimeOptionsMonthly, BookingOption.usageTime, 'title') + ' ' + getValueByTarget(UsageTimeOptionsMonthly, BookingOption.usageTime, 'subtitle') + ' ' + getValueByTarget(UsageTimeOptionsMonthly, BookingOption.usageTime, 'hourPrice') + '원';

      let SelectedRoute = getValueByTarget(RouteOptions, data?.route, 'title')
      if (data?.route === 'route_etc') {
        SelectedRoute = `기타 (${data?.routeEtcString})`
      }

      let EstimateResponseObj = {
        UserName: userInfoDecrypt.UserName,
        UserPhone: userInfoDecrypt.PhoneNumber,
        UserEmail: userInfoDecrypt.EmailAddress,
        StartAddress: BookingOption?.contractTerms1?.serviceArea?.departure?.address1,
        EndAddress: BookingOption?.contractTerms1?.serviceArea?.destination?.address1,
        SelectedProductStr: SelectedProduct,
        Route: SelectedRoute,
        ConciergeString: '(New)웹견적서',
        Requestcomment: '',
        UsageSchedule: getDateCompateToString(BookingOption?.contractTerms1?.schedule),
        NeedsString: getValueByTarget(PurposeOfContractOptions, data?.purposeOfContract, 'title'),
        MmOptionVehicles: getValueByTarget(NumberOfVehiclesOptions, BookingOption.vehicleOption.numberOfVehicles, 'title'),
        MmOptionCarAmount: getValueByTarget(CarAmountOptions, BookingOption.vehicleOption.carAmount, 'title'),
        MmOptionInsurance: getValueByTarget(InsuranceOptions, BookingOption.vehicleOption.insurance, 'title'),
        MmOptionPassenger: getValueByTarget(PassengerPersonOptions, BookingOption.vehicleOption.passengerPerson, 'title'),
        MmOptionService: getValueByTarget(RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime), BookingOption.vehicleOption.roleOfVehicles, 'title'),
        DdOptionMonthlyMosiler: '',
        DdOptionRecruitPurpose: '',
      }

      /* 모시러 DB에 들어가야할 정보
      이름, 전화번호, 이메일, 출발지, 종료지, 
      상품 타입 (월간/연간) BookingOption.subscription
      getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'estimateDay')
      getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'estimateHour')
      getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'estimateMinHour')
      getValueByTarget(UsageTimeOptionsAnnual, BookingOption.usageTime, 'hourPrice')   
      
      */

      setSheetEstimateResponse(EstimateResponseObj)


      dispatch(closeLoading())
      navigate(URLS.BOOKING_ESTIMATION_CONFIRM_ESTIMATE)
    }
  });

  return (
    <>
      <div className={classNames('booking-wrap')}>
        <PageTitle title={'이용 정보'} subtitle={'이용 정보를 알려주세요'} />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          <div className={classNames('booking-form-list')}>
            {!!PurposeOfContractOptions && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>이용목적</span>
                <RadioGroup
                  classes={''}
                  legend={'purposeOfContract'}
                  selected={selectedOptions.contractTerms2.purposeOfContract}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {PurposeOfContractOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('purposeOfContract', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              contractTerms2: {
                                ...prevState.contractTerms2,
                                purposeOfContract: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.contractTerms2.purposeOfContract === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}

            {!!PassengerOptions && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>탑승자</span>
                <RadioGroup
                  classes={''}
                  legend={'passenger'}
                  selected={selectedOptions.contractTerms2.passenger}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {PassengerOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('passenger', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              contractTerms2: {
                                ...prevState.contractTerms2,
                                passenger: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.contractTerms2.passenger === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>

                {selectedOptions.contractTerms2.passenger === 'etc' && <div className={'booking-form-item'}>
                  <Input
                    type={'text'}
                    required
                    error={errors.passengerEtcString}
                    setValue={setValue}
                    onChange={e => {
                      setValue('passengerEtcString', e.target.value)
                      setSelectedOptions((prevState) => ({
                        ...prevState,
                        contractTerms2: {
                          ...prevState.contractTerms2,
                          passengerEtcString: e.target.value,
                        },
                      }));
                    }}
                    placeholder={'손님 ...'}
                    {...register('passengerEtcString')}
                  />
                </div>}


              </div>
            )}

            {!!RouteOptions && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>알게된 경로</span>
                <RadioGroup
                  classes={''}
                  legend={'route'}
                  selected={selectedOptions.contractTerms2.route}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {RouteOptions.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('route', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              contractTerms2: {
                                ...prevState.contractTerms2,
                                route: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.contractTerms2.route === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>

                {selectedOptions.contractTerms2.route === 'route_etc' && <div className={'booking-form-item'}>
                  <Input
                    type={'text'}
                    required
                    error={errors.routeEtcString}
                    setValue={setValue}
                    onChange={e => {
                      setValue('routeEtcString', e.target.value)
                      setSelectedOptions((prevState) => ({
                        ...prevState,
                        contractTerms2: {
                          ...prevState.contractTerms2,
                          routeEtcString: e.target.value,
                        },
                      }));
                    }}
                    placeholder={'검색, 유투브, 블로그...'}
                    {...register('routeEtcString')}
                  />
                </div>}



              </div>
            )}


            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>차량 번호(1)</span>
              <Input
                type={'text'}
                required
                error={errors.vehicleName1}
                setValue={setValue}
                onChange={e => {
                  setValue('vehicleName1', e.target.value)
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    contractTerms2: {
                      ...prevState.contractTerms2,
                      vehicleName1: e.target.value,
                    },
                  }));
                }}
                placeholder={'차량 번호 1'}
                {...register('vehicleName1')}
              />
            </div>

            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>차량 번호(2)</span>
              <Input
                type={'text'}
                required
                error={errors.vehicleName2}
                setValue={setValue}
                placeholder={'차량 번호 2'}
                onChange={e => {
                  setValue('vehicleName2', e.target.value)
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    contractTerms2: {
                      ...prevState.contractTerms2,
                      vehicleName2: e.target.value,
                    },
                  }));
                }}
                {...register('vehicleName2')}
              />
            </div>

            {VehiclesOptionCount(selectedOptions?.vehicleOption?.numberOfVehicles) === 3 && <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>차량 번호(3)</span>
              <Input
                type={'text'}
                required
                error={errors.vehicleName3}
                setValue={setValue}
                placeholder={'차량 번호 3'}
                onChange={e => {
                  setValue('vehicleName3', e.target.value)
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    contractTerms2: {
                      ...prevState.contractTerms2,
                      vehicleName3: e.target.value,
                    },
                  }));
                }}
                {...register('vehicleName3')}
              />
            </div>}

          </div>
          <BookingFormSummary
            description={description}
            SubAmountObj={isPC ? TotalAmount4 : null}>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => navigate(URLS.BOOKING_ESTIMATION_CONTRACT_TERMS1)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary>
        </form>
      </div>
    </>
  )
}

export default ContractTerms2;