const initialReportData = {
    ContractDateList: [
        {
            StartDate: '2022-09-26',
            EndDate: '2024-09-25',
            ContractRangeType: 0
        }
    ],
    Summary: {
        ContractTypeDay: 0,
        ContractTypeHour: 0,
        OvercarriedMinute: 0,
        UseDays: 0,
        ReserveDays: 0,
        RemainDays: 0,
        ExceedDays: 0,
        UseTimes: 0,
        ReserveTimes: 0,
        RemainTimes: 0,
        ExceedTimes: 0,
        MmHourPrice: 0,
        expence: [
            {
                StartDate: '2022-10-06T11:00:00',
                ItemCode: '0',
                DrivingNo: 0,
                UseAmount: 0,
                BillingYN: ''
            }
        ],
        PersonalHistoryList: [],
        ExceedAmountAll: 0,
        ExpensesAmountAll: 0,
        ExtraFeeAmountAll: 0
    },
    DrivingList: [
        {
            DrivingNo: 0,
            DriverNo: 0,
            DriverName: '',
            DrivingStatus: '',
            RevStartDt: '2022-10-06T11:00:00',
            RevEndDt: '2022-10-06T16:00:00',
            StartDate: '2022-10-06T11:00:00',
            EndDate: '2022-10-06T16:00:00',
            ReserveHourMin: '00:00',
            UseHourMin: '00:00',
            DiffHourMin: '00:00',
            ReserveMin: 0,
            UseMin: 0,
            DiffMin: 0,
            StartAreaFee: 0,
            AreaFee: 0,
            NightFee: 0,
            ExtraTimeFee: 0,
            SelectDriverFee: 0,
            ReserveCancelFee: 0,
            ParkingFee: 0,
            TollgateFee: 0,
            EtcFee: 0,
            UseTime: 0,
            ResultUseTime: 0,
            ExceedAmount: 0,
            UseTimeDeductionAfterAmount: 0,
            RealUseTime: 0,
            RealTimeDeductionAfterAmount: 0,
            ReserveTime: 0,
            RemainTime: 0,
            MinimumTimeApplyFlag: 0,
            DrivingStatusCode: '',
            CancelType: -1,
            CancelTime: 0,
            CancelAmount: 0,
            BillingYN: 'N',
            ExceedTime: 0,
            RevStartAddress: '',
            RevEndAddress: '',
            RevWaypointAddress: '',
            StartAddress: '',
            EndAddress: '',
            WaypointAddress: '',
            MmHourPriceHistory: 0,
            OvercarriedMinute: 0
        }
    ],
}


const REPORT_RESET = 'REPORT/RESET';
const REPORT_ALL = 'REPORT/ALL';
const REPORT_CONTRACTDATELIST = 'REPORT/CONTRACTDATELIST';
const REPORT_SUMMARY = 'REPORT/SUMMARY';
const REPORT_DRIVINGLIST = 'REPORT/DRIVINGLIST';



export const reportReset = () => ({
    type: REPORT_RESET
})
export const reportAll = (data) => ({
    type: REPORT_ALL,
    payload: data
})
export const reportContractDateList = (data) => ({
    type: REPORT_CONTRACTDATELIST,
    payload: data
})
export const reportSummary = (data) => ({
    type: REPORT_SUMMARY,
    payload: data
})
export const reportDrivingList = (data) => ({
    type: REPORT_DRIVINGLIST,
    payload: data
})


export const ReportRedux = (state = initialReportData, action) => {
    let retData = {}
    switch (action.type) {
        case REPORT_RESET:
            retData = {
                ...state,
            }
            break;
        case REPORT_ALL:
            retData = {
                ...state,
                ...action.payload
            }
            break;
        case REPORT_CONTRACTDATELIST:
            retData = {
                ...state,
                ContractDateList: action.payload
            }
            break;
        case REPORT_SUMMARY:
            retData = {
                ...state,
                Summary: action.payload
            }
            break;
        case REPORT_DRIVINGLIST:
            retData = {
                ...state,
                DrivingList: action.payload
            }
            break;
        default:
            retData = {
                ...state,
            }
            break;
    }
    return retData;

};