const initialReservationFormData = {
  reservationDate: null,
  user: {
    name: null,
    phone: null
  },
  usageTime: {
    startTime: {
      dayTime: null,
      time: null,
      hours: null,
    },
    endTime: {
      dayTime: null,
      time: null,
      nextDayFlag: false
    }
  },
  departure: {
    zip: null,
    address1: null,
    address2: null
  },
  endArea: {
    zip: null,
    address1: null,
    address2: null
  },
  destination: {
    zip: null,
    address1: null,
    address2: null,
  },
  vehicle: null,
  contractOnedayHour: 1,

}

const RESERVATION_CREATE = 'RESERVATION/CREATE';
const RESERVATION_CONTRACT_ONEDAY_HOUR = 'RESERVATION/CONTRACT_ONEDAY_HOUR';
const RESERVATION_ENDTIME = 'RESERVATION/ENDTIME';

export const createReservation = (reservation) => ({
  type: RESERVATION_CREATE,
  payload: { reservation }
})

export const setContractOnedayHour = (hour) => ({
  type: RESERVATION_CONTRACT_ONEDAY_HOUR,
  payload: hour
})
export const setEndTime = (timeStr, nextDayFlag) => ({
  type: RESERVATION_ENDTIME,
  payload: {
    dayTime: null,
    time: timeStr,
    nextDayFlag: nextDayFlag
  }
})

export const Reservation = (state = initialReservationFormData, action) => {
  if (action.type === RESERVATION_CREATE) {
    return {
      ...state,
      ...action.payload,
    }
  }
  else if (action.type === RESERVATION_CONTRACT_ONEDAY_HOUR) {
    return {
      ...state,
      contractOnedayHour: action.payload,
    }
  }
  else if (action.type === RESERVATION_ENDTIME) {
    return {
      ...state,
      usageTime: {
        ...state.usageTime,
        endTime: action.payload
      }
    }
  }
  return {
    ...state,
  };
};