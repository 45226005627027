
export const subscriptionData = [
  {
    orderNumber: 1,
    productTitle: '200시간',
    productPricePerItem: 25000,
    active: 'completed',
    beforePaidAmount: {
      amount: 5000000,
      paidDate: '02/26',
    },
    afterPaidAmount: {
      amount: 1200000,
      paidDate: '02/26',
      dueDate: '',
      paid: true,
    },
    period: {
      start: '2023.04.29',
      end: '2023.05.28',
    }
  },
  {
    orderNumber: 2,
    productTitle: '200시간',
    productPricePerItem: 25000,
    active: 'completed',
    beforePaidAmount: {
      amount: 5000000,
      paidDate: '02/26',
    },
    afterPaidAmount: {
      amount: 1200000,
      paidDate: '',
      dueDate: '05/26',
      paid: false,
    },
    period: {
      start: '2023.06.29',
      end: '2023.07.28',
    }
  },
  {
    orderNumber: 3,
    productTitle: '200시간',
    productPricePerItem: 25000,
    active: 'using',
    beforePaidAmount: {
      amount: 5000000,
      paidDate: '02/26'
    },
    afterPaidAmount: {
      amount: 253000,
      paidDate: '',
      dueDate: '05/26',
      paid: false,
    },
    period: {
      start: '2023.07.29',
      end: '2023.08.28',
    }
  },
  {
    orderNumber: 4,
    productTitle: '200시간',
    productPricePerItem: 25000,
    active: 'pending',
    beforePaidAmount: {
      amount: 5000000,
      paidDate: '',
      dueDate: '05/26'
    },
    afterPaidAmount: {
      amount: '',
      paidDate: '',
      dueDate: '',
      paid: false,
    },
    period: {
      start: '2023.08.29',
      end: '2023.09.28',
    }
  }
]

export const reservationHistoryData = {
  name: '(주)버틀러',
  subscription: 'montly',
  subscriptionOrder: 3,
  subscriptionHour: 200,
  period: {
    start: '2023.04.29',
    end: '2023.05.28'
  },
  useAgeHour: {
    used: 154,
    total: 180
  },
  paidAmount: 2956800,
  remainedPayment: 1249200,
  additionalPayment: 1090200,
  surcharge: 1090200,
  gateway: 124000,
  history: [
    {
      status: 'completed',
      usedDate: '02/11(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/12(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/13(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/16(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/18(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/17(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/25(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/12(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/18(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/19(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/28(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/15(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/24(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/16(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/16(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/19(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/21(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/24(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/23(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/25(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/30(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/18(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/09(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/01(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/06(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/08(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/07(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/09(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/24(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/18(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/17(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/16(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/10(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/29(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/15(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/19(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/27(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/18(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/17(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/11(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/18(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/18(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/11(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/11(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/20(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/18(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/20(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/10(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '04/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '09/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '07/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '09/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '07/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '11/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '12/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '06/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '08/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '07/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '08/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '08/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '09/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '08/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '02/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '08/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/28(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/04(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/27(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/08(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/15(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/19(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/24(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/15(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/12(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/19(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/15(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/24(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/28(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/16(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/15(토) 11:30 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/15(수) 10:30 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:55',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'completed',
      usedDate: '02/14(토) 11:32 ~ 00:24',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:38 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '결제대행',
          title: '통행료',
          amount: 4000,
          additionalFee: 4800
        }
      ],
    },
    {
      status: 'canceled',
      usedDate: '02/14(수) 10:39 ~ 17:55',
      items: [
        {
          type: '기본이용',
          usedTime: '10시간 55분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    },
    {
      status: 'pending',
      usedDate: '05/26(수) 10:30 ~ 17:12',
      items: [
        {
          type: '추가이용',
          usedTime: '8시간 0분',
          additionalFee: 0
        },
        {
          type: '할증',
          title: '심야할증',
          additionalFee: 30000
        }
      ],
    }
  ],
  pattern: '준비중'
}



export const customerData = {
  customer: {
    name: '(주)버틀러',
    businessRegistrationNumber: '152-08-15187',
    representative: '대표 이길동',
    address: {
      address1: '서울 강남구 역삼로 160',
      address2: '강남취창업센터 15층'
    }
  },
  card: [
    {
      type: 'regular',
      cardName: '현대카드',
      cardNum: '3**8'
    },
    {
      type: 'warranty',
      cardName: '신한카드',
      cardNum: '4**5'
    }
  ],
  vehicle: [
    {
      number: '216가2654',
      model: 'KIA 카니발 하이브리드'
    },
    {
      number: '216가2654',
      model: 'KIA 카니발 하이브리드'
    }
  ],
  userGroup: [
    {
      type: 'owner',
      name: '김모시러',
      phone: '010-9999-9999',
    },
    {
      type: 'manager',
      name: '박모시러',
      phone: '010-1234-1234',
    },
    {
      type: 'member',
      name: '홍길동',
      phone: '010-0000-0000',
    }
  ],
  addressGroup: [
    {
      type: '자택1',
      address1: '서울 강남구 테헤란로 123',
      address2: '지하 1층'
    },
    {
      type: '자택2',
      address1: '서울 용산구 이촌로 1352',
      address2: '1층'
    },
    {
      type: '회사',
      address1: '서울 서초구 마이한로21길',
      address2: '253'
    },
    {
      type: '별칭1',
      address1: '서울 서초구 마이한로21길 253',
      address2: ' FAS빌딩 지하2층'
    }
  ]
}

