const initialLoadingState = {
    isLoading: false,
    isEstimate: false,
    isReserve: false,
};

const LOADING_OPEN = 'LOADING/OPEN';
const LOADING_CLOSE = 'LOADING/CLOSE';

const LOADING_OPEN_ESTIMATE = 'LOADING/OPEN/ESTIMATE';
const LOADING_CLOSE_ESTIMATE = 'LOADING/CLOSE/ESTIMATE';

const LOADING_OPEN_RESERVE = 'LOADING/OPEN/RESERVE';
const LOADING_CLOSE_RESERVE = 'LOADING/CLOSE/RESERVE';

export const openLoading = status => ({ type: LOADING_OPEN, payload: true });
export const closeLoading = status => ({ type: LOADING_CLOSE, payload: false });

export const openLoadingWithEstimate = status => ({ type: LOADING_OPEN_ESTIMATE, payload: { isLoading: true, isEstimate: true } });
export const closeLoadingWithEstimate = status => ({ type: LOADING_CLOSE_ESTIMATE, payload: { isLoading: false, isEstimate: false } });

export const openLoadingWithReserve = status => ({ type: LOADING_OPEN_RESERVE, payload: { isLoading: false, isEstimate: false, isReserve: true } });
export const closeLoadingWithReserve = status => ({ type: LOADING_CLOSE_RESERVE, payload: { isLoading: false, isEstimate: false, isReserve: false } });

export const LoadingRedux = (state = initialLoadingState, action) => {
    switch (action.type) {
        case LOADING_OPEN:
        case LOADING_CLOSE:
            return {
                ...state,
                isLoading: action.payload,
            };
        case LOADING_OPEN_ESTIMATE:
        case LOADING_CLOSE_ESTIMATE:
        case LOADING_OPEN_RESERVE:
        case LOADING_CLOSE_RESERVE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

