import React from 'react';
import Icon from '../atoms/Icon'
import classNames from 'classnames';
const ListWithCheckIcon = ({listItem}) => {
  return (
    <ul className={classNames('list-with-check')}>
      {listItem.map((item, index) => (
        <li key={index}>
          <span className={classNames('check-icon')}>
           <Icon icon={'check'}/>
          </span>
          <span className={classNames('txt-large text')} dangerouslySetInnerHTML={{ __html: item }}></span>
        </li>
      ))}
    </ul>
  )
}

export default ListWithCheckIcon