import React from 'react';
import WhiteBox from '../atoms/WhiteBox';
import classNames from 'classnames';
import { transFormWon } from '../../utils/numbers';
import moment from 'moment';

const PaymentHistory = ({ title, reservationHistory, mmCustomerInfo }) => {

  // ex 계약종료일 : 10/20
  let today = moment()
  let paymentDate = moment(mmCustomerInfo?.ContractEndDate).add(-3, 'days')
  // let today = moment('2023-10-15')
  // let paymentDate = moment('2023-10-20 00:00').add(-3, 'days')

  let diffdata = paymentDate.diff(today, 'days')
  // console.log('paymenthistory', paymentDate.diff(today, 'days'))
  // console.log('mmCustomerInfo?.ContractEndDate', mmCustomerInfo?.ContractEndDate)

  let subTitleVisible = null
  if (diffdata >= 1 && diffdata <= 10) {
    subTitleVisible = `다음결제일 : D-${diffdata}`
  }
  else if (diffdata === 0) {
    subTitleVisible = `다음결제일 : D-Day`
  }
  else if (diffdata <= -1) {
    subTitleVisible = `결제일이 지났습니다. 입금완료시, 확인 후 갱신처리됩니다.`
  }
  else {
    subTitleVisible = null
  }

  subTitleVisible = null


  return (
    <>
      <WhiteBox
        title={title}
        subTitle={subTitleVisible}>
        <div className={classNames('completed-payment-item payment-wrap')}>
          <span className={classNames('txt-regular')}>기본 이용료</span>
          <span className={'amount-set'}>
            <span className={classNames('txt-regular')}>
              {transFormWon((reservationHistory.ContractTypeDay * reservationHistory.ContractTypeHour) * reservationHistory.MmHourPrice)}
            </span>
            {/* 
            To-do
            <span className={classNames('bg-grey')}>결제완료</span> 
            */}
          </span>
        </div>
        <div className={classNames('pending-payment-item')}>
          <div className={classNames('payment-wrap')}>
            <span className={classNames('txt-regular blue')}>후불 청구액</span>
            <span className={'amount-set'}>
              <span className={classNames('txt-regular blue')}>
                {transFormWon(
                  reservationHistory.ExceedAmountAll +
                  reservationHistory.ExpensesAmountAll +
                  reservationHistory.ExtraFeeAmountAll + 
                  (reservationHistory.OtherAmountAll ?? 0) + 
                  (reservationHistory.SelectDriverFeetAll ?? 0)
                )}
              </span>
              {/* 
              To-do
              <span className={classNames('bg-red')}>미결제</span> 
              */}
            </span>
          </div>
          <ul className={classNames('pending-details')}>
            <li>
              <span className={classNames('txt-small grey-3')}>추가 이용료</span>
              <span className={classNames('txt-small grey-3')}>{transFormWon(reservationHistory.ExceedAmountAll)}</span>
            </li>
            <li>
              <span className={classNames('txt-small grey-3')}>지명비</span>
              <span className={classNames('txt-small grey-3')}>{transFormWon(reservationHistory.SelectDriverFeetAll ?? 0)}</span>
            </li>
            <li>
              <span className={classNames('txt-small grey-3')}>할증</span>
              <span className={classNames('txt-small grey-3')}>{transFormWon(reservationHistory.ExtraFeeAmountAll)}</span>
            </li>
            <li>
              <span className={classNames('txt-small grey-3')}>기타금액</span>
              <span className={classNames('txt-small grey-3')}>{transFormWon(reservationHistory.OtherAmountAll ?? 0)}</span>
            </li>
            <li>
              <span className={classNames('txt-small grey-3')}>결제대행</span>
              <span className={classNames('txt-small grey-3')}>{transFormWon(reservationHistory.ExpensesAmountAll)}</span>
            </li>
          </ul>
          <span className={classNames('txt-small light-grey-3')}>결제대행 금액은 대행 수수료 20%가 포함된 금액입니다.</span>
        </div>
      </WhiteBox>
    </>
  )
}

/*
 "ContractTypeDay": 8,
        "ContractTypeHour": 8,
        "OvercarriedMinute": 0,
        "UseDays": 3,
        "ReserveDays": 8,
        "RemainDays": 0,
        "ExceedDays": 3,
        "UseTimes": 2010,
        "ReserveTimes": 1980,
        "RemainTimes": 0,
        "ExceedTimes": 150,
        "MmHourPrice": 13100,
        "ExceedAmountAll": 32749,
        "ExpensesAmountAll": 36000,
        "ExtraFeeAmountAll": 60000
*/



export default PaymentHistory