import React from 'react';
import PageTitle from '../atoms/PageTitle';
import classNames from 'classnames';

const ReservationStepContent = (
  {
    title,
    subtitle,
    children,
  }) => {
  return (
    <div className={classNames('reservation-content')}>
      <PageTitle
        title={title}
        subtitle={subtitle}
      />
      <div className={classNames('reservation-item-list')}>
        {children}
      </div>
    </div>
  )
}

export default ReservationStepContent