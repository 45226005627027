import React from 'react';
import classNames from 'classnames';

const Table = ({titleList, children}) => {
  return (
    <div className={classNames('table-wrap')}>
      <table>
        <thead>
        <tr>
          {titleList.map((item, index) => (
            <th key={index} value={item.target}>{item.title}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {children}
        </tbody>
      </table>
    </div>
  )
}

export default Table