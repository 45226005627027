import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Input from '../../../atoms/Input';
import { useForm } from 'react-hook-form';
import Checkbox from '../../../atoms/CheckBox';
import Button from '../../../atoms/Button';
import { Link } from 'react-router-dom';
import { URLS } from '../../../../utils/urls';
import Icon from '../../../atoms/Icon';
import { formErrorMessage } from '../../../../utils/alertMessage';
import AccountApi from '../../../../lib/AccountApi'
import { isChkResultSuccess, sleep, EncryptAES, isChkReservationCookieData } from '../../../../lib/Util';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bookerPageLoginUser, bookerPageLoginUserPool } from '../../../../reducers/Users';
import { useCookies } from 'react-cookie'
import CookieApi from '../../../../lib/CookieApi';
import { openLoading, closeLoading } from '../../../../reducers/loadingRedux';
import { KAKAO_CLIENT_ID } from '../../../../utils/constant';
import qs from 'qs'

/**
 * 로그인
 * path: reservation/login/email-login
 * **/


const DriverLogin = () => {
	const {
		getValues,
		handleSubmit,
		setValue,
		register,
		setError,
		formState: { errors }
	} = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [cookies, setCookie, removeCookie] = useCookies();

	useEffect(() => {
		function loginCheck() {
			if (isChkReservationCookieData()) {
				navigate(URLS.RESERVATION_CREATE)
			}
		}
		loginCheck()
	}, [])


	const onSubmit = handleSubmit(async data => {
		let validCheck = true;

		let str = ''

		if (!data?.email) {
			validCheck = false;
			setError('email', {
				type: 'focus',
				name: 'email',
				message: formErrorMessage.emailError
			}, { shouldFocus: true });
		}

		if (!data?.password) {
			validCheck = false;
			setError('password', {
				type: 'focus',
				name: 'password',
				message: formErrorMessage.passwordError
			}, { shouldFocus: true });
		}

		if (validCheck) {
			dispatch(openLoading())
			try {
				// 로그인
				let result = await AccountApi.requestLoginDriver(getValues('email').trim(), getValues('password'))

				if (isChkResultSuccess(result)) {

					dispatch(bookerPageLoginUser(result.Item))
					dispatch(bookerPageLoginUserPool(result.ItemList))

					const expires = new Date()
					expires.setDate(expires.getDate() + 90)

					// 자동로그인 true일때
					if (data?.autoLogin) {
						setCookie('li_01', result.infoData, { path: '/', expires })
						setCookie('li_02', result.Item.LoginEncrypt, { path: '/', expires })
						setCookie('alchk', 1, { path: '/', expires })
						setCookie('lgi', EncryptAES(JSON.stringify(result.Item)), { path: '/', expires })
						setCookie('lgupi', EncryptAES(JSON.stringify(result.ItemList)), { path: '/', expires })
					}
					else {
						// 세션이 남아있을때만 유효
						setCookie('li_01', result.infoData, { path: '/' })
						setCookie('li_02', result.Item.LoginEncrypt, { path: '/' })
						setCookie('alchk', 0, { path: '/' })
						setCookie('lgi', EncryptAES(JSON.stringify(result.Item)), { path: '/' })
						setCookie('lgupi', EncryptAES(JSON.stringify(result.ItemList)), { path: '/' })
					}

					await sleep(500)
					navigate(URLS.RESERVATION_CREATE)

				}
				else {
					alert(result.Message)
				}
				
			}
			catch (e) {
				console.log(e)
				alert(e.response.data.Message)

			}
			dispatch(closeLoading())
			
		}
	});

	
	return (
		<form onSubmit={onSubmit} className={classNames('login-form')}>
			<div className={classNames('input-set')}>
				<span className={classNames('txt-large')}>
					이메일 ID
				</span>
				<Input
					type={'text'}
					required
					setValue={setValue}
					error={errors.email}
					onChange={(e) => {
						setValue('email', e.target.value)
					}}
					placeholder={'이메일'}
					{...register('email')}
				/>
			</div>
			<div className={classNames('input-set')}>
				<span className={classNames('txt-large')}>
					비밀번호
				</span>
				<Input
					type={'password'}
					required
					setValue={setValue}
					error={errors.password}
					onChange={(e) => {
						setValue('password', e.target.value)
					}}
					placeholder={'비밀번호'}
					{...register('password')}
				/>

			</div>
			<Checkbox
				label={'자동로그인'}
				checked={getValues('autoLogin')}
				onClick={e => {
					setValue('autoLogin', e.target.checked)
				}}
			/>
			<Button
				type={'submit'}
				styleType={'secondary'}
				classes={'bold txt-large'}
			>로그인</Button>
			
			

		</form>
	)
};

export default DriverLogin