import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  WeekendUsageOptions,
  SalaryOptions,
  GenderOption,
  DriverSmokingStatusOption,
  DriverParkingSupportOption,
  CommutingVehicleProvidedOption,
  ForeignLanguageProficiencyOption,
  DriverWaitingRoomAvaliableOption,
  EducationalBackgroundOption,
  DriverDispatchProducts,
  RecruitPurposeOption,
  MonthlyMosilerOption,
  UsageTimeOptionsDriverDispatch,
} from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import BookingItem from '../../molecules/BookingItem';

import Radio from '../../atoms/Radio';
import Input from '../../atoms/Input';

import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDriverDispatchOptionContext } from '../../organisms/DriverDispatchOptionContext';
import { useForm } from 'react-hook-form';
import { updateContractTerms2 } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import { formErrorMessage } from '../../../utils/alertMessage';
import { getEstimateCookieDataAndDecrypt, getDateCompateToString, getDdEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { SetMmEstimateHistory, setSheetContractResponse, setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import moment from 'moment';
import ScheduleDatePicker from '../../atoms/ScheduleDatePicker';
import ListWithBullet from '../../atoms/ListWithBullet';
import AddressInput2 from '../../molecules/AddressInput2';
import { updateDriverDispatchBasicInfo1, updateDriverDispatchBasicInfo2, updateDriverDispatchRecruitRequest } from '../../../reducers/driverDispatchEstimation';
import BookingFormSummary2 from '../../atoms/BookingFormSummary2';
import { Range, getTrackBackground } from 'react-range';
import ScheduleDatePicker2 from '../../atoms/ScheduleDatePicker2';
import Textarea from '../../atoms/Textarea';
import Checkbox from '../../atoms/CheckBox';
import useCheckPC from '../../../hooks/useCheckPC';
import CheckBox2 from '../../atoms/CheckBox2';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';


/**
 * 이용 정보 페이지
 * name : contractTerms2
 * */
const RecuritRequest = () => {
  const { isPC } = useCheckPC();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
  const { DriverDispatchOption } = useSelector((state) => state);
  const [description, setDescription] = useState([])
  const [pageTitleInfo, setPageTitleInfo] = useState('')

  useEffect(() => {
    if (selectedOptions?.subscription === 'recruit') {
      setPageTitleInfo('계약 정보')
    }
    else {
      setPageTitleInfo('파견 의뢰 정보')
    }

  }, [])

  const { handleSubmit, setValue, register, setError, getValues, formState: { errors }, clearErrors } = useForm({
    defaultValues: {
      usageDate: selectedOptions.basicInfo1.usageDate,
      gender: selectedOptions.recruitRequest.gender,
      age20: selectedOptions.recruitRequest.age20,
      age30: selectedOptions.recruitRequest.age30,
      age40: selectedOptions.recruitRequest.age40,
      age50: selectedOptions.recruitRequest.age50,
      age60: selectedOptions.recruitRequest.age60,
      ageboth: selectedOptions.recruitRequest.ageboth,
      driverSmokingStatus: selectedOptions.recruitRequest.driverSmokingStatus,
      driverParkingSupport: selectedOptions.recruitRequest.driverParkingSupport,
      commutingVehicleProvided: selectedOptions.recruitRequest.commutingVehicleProvided,
      postHireJobResponsibilities: selectedOptions.recruitRequest.postHireJobResponsibilities,
      benefits: selectedOptions.recruitRequest.benefits,
      vehicleModelName: selectedOptions.recruitRequest.vehicleModelName,
      foreignLanguageProficiency: selectedOptions.recruitRequest.foreignLanguageProficiency,
      foreignLanguageProficiencyStr: selectedOptions.recruitRequest.foreignLanguageProficiencyStr,
      driverWaitingRoomAvailable: selectedOptions.recruitRequest.driverWaitingRoomAvailable,
      educationalBackground: selectedOptions.recruitRequest.educationalBackground,
      specialNotes: selectedOptions.recruitRequest.specialNotes,
    }
  });


  const isChkAge = () => {
    return selectedOptions.recruitRequest.age20 ||
      selectedOptions.recruitRequest.age30 ||
      selectedOptions.recruitRequest.age40 ||
      selectedOptions.recruitRequest.age50 ||
      selectedOptions.recruitRequest.age60 ||
      selectedOptions.recruitRequest.ageboth
  }

  const onSubmit = handleSubmit(async data => {
    let validCheck = true;

    // 연령 (하나 이상 선택해야함)
    if (!isChkAge()) {
      validCheck = false;
      setError('checkboxMsg', {
        type: 'focus',
        name: 'checkboxMsg',
        message: '연령을 1개 이상 선택해주세요.'
      }, { shouldFocus: true });
    }


    if (!data?.postHireJobResponsibilities) {
      validCheck = false;
      setError('postHireJobResponsibilities', {
        type: 'focus',
        name: 'postHireJobResponsibilities',
        message: '입사 후 담당업무를 입력해주세요.'
      }, { shouldFocus: true });
    }

    if (!data?.benefits) {
      validCheck = false;
      setError('benefits', {
        type: 'focus',
        name: 'benefits',
        message: '복리후생을 입력해주세요.'
      }, { shouldFocus: true });
    }

    if (!data?.vehicleModelName) {
      validCheck = false;
      setError('vehicleModelName', {
        type: 'focus',
        name: 'vehicleModelName',
        message: '차량모델명을 입력해주세요.'
      }, { shouldFocus: true });
    }

    if (selectedOptions.recruitRequest.foreignLanguageProficiency === 'possible') {
      if (!data?.foreignLanguageProficiencyStr) {
        validCheck = false;
        setError('foreignLanguageProficiencyStr', {
          type: 'focus',
          name: 'foreignLanguageProficiencyStr',
          message: '가능 언어를 입력해주세요.'
        }, { shouldFocus: true });
      }
    }



    // if (!data?.selectedSalary) {
    //   validCheck = false;
    //   setError('selectedSalary', {
    //     type: 'focus',
    //     name: 'selectedSalary',
    //     message: formErrorMessage.vehicleNameError
    //   }, { shouldFocus: true });
    // }
    // 

    if (validCheck) {
      dispatch(openLoading())

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        basicInfo1: {
          ...prevOptions.basicInfo1,
          usageDate: data?.usageDate ?? selectedOptions.basicInfo1.usageDate,
        },
        recruitRequest: {
          ...prevOptions.recruitRequest,
          gender: data?.gender ?? selectedOptions.recruitRequest.gender,
          age20: data?.age20 ?? selectedOptions.recruitRequest.age20,
          age30: data?.age30 ?? selectedOptions.recruitRequest.age30,
          age40: data?.age40 ?? selectedOptions.recruitRequest.age40,
          age50: data?.age50 ?? selectedOptions.recruitRequest.age50,
          age60: data?.age60 ?? selectedOptions.recruitRequest.age60,
          ageboth: data?.ageboth ?? selectedOptions.recruitRequest.ageboth,
          driverSmokingStatus: data?.driverSmokingStatus ?? selectedOptions.recruitRequest.driverSmokingStatus,
          driverParkingSupport: data?.driverParkingSupport ?? selectedOptions.recruitRequest.driverParkingSupport,
          commutingVehicleProvided: data?.commutingVehicleProvided ?? selectedOptions.recruitRequest.commutingVehicleProvided,
          postHireJobResponsibilities: data?.postHireJobResponsibilities ?? selectedOptions.recruitRequest.postHireJobResponsibilities,
          benefits: data?.benefits ?? selectedOptions.recruitRequest.benefits,
          vehicleModelName: data?.vehicleModelName ?? selectedOptions.recruitRequest.vehicleModelName,
          foreignLanguageProficiency: data?.foreignLanguageProficiency ?? selectedOptions.recruitRequest.foreignLanguageProficiency,
          foreignLanguageProficiencyStr: data?.foreignLanguageProficiencyStr ?? selectedOptions.recruitRequest.foreignLanguageProficiencyStr,
          driverWaitingRoomAvailable: data?.driverWaitingRoomAvailable ?? selectedOptions.recruitRequest.driverWaitingRoomAvailable,
          actualWorkLocation: data?.actualWorkLocation ?? selectedOptions.recruitRequest.actualWorkLocation,
          educationalBackground: data?.educationalBackground ?? selectedOptions.recruitRequest.educationalBackground,
          specialNotes: data?.specialNotes ?? selectedOptions.recruitRequest.specialNotes,
        }
      }));

      dispatch(updateDriverDispatchRecruitRequest({

        age20: data?.age20 ?? selectedOptions.recruitRequest.age20,
        age30: data?.age30 ?? selectedOptions.recruitRequest.age30,
        age40: data?.age40 ?? selectedOptions.recruitRequest.age40,
        age50: data?.age50 ?? selectedOptions.recruitRequest.age50,
        age60: data?.age60 ?? selectedOptions.recruitRequest.age60,
        ageboth: data?.ageboth ?? selectedOptions.recruitRequest.ageboth,

        usageDate: data?.usageDate ?? selectedOptions.basicInfo1.usageDate,
        gender: data?.gender ?? selectedOptions.recruitRequest.gender,
        driverSmokingStatus: data?.driverSmokingStatus ?? selectedOptions.recruitRequest.driverSmokingStatus,
        driverParkingSupport: data?.driverParkingSupport ?? selectedOptions.recruitRequest.driverParkingSupport,
        commutingVehicleProvided: data?.commutingVehicleProvided ?? selectedOptions.recruitRequest.commutingVehicleProvided,
        postHireJobResponsibilities: data?.postHireJobResponsibilities ?? selectedOptions.recruitRequest.postHireJobResponsibilities,
        benefits: data?.benefits ?? selectedOptions.recruitRequest.benefits,
        vehicleModelName: data?.vehicleModelName ?? selectedOptions.recruitRequest.vehicleModelName,
        foreignLanguageProficiency: data?.foreignLanguageProficiency ?? selectedOptions.recruitRequest.foreignLanguageProficiency,
        foreignLanguageProficiencyStr: data?.foreignLanguageProficiencyStr ?? selectedOptions.recruitRequest.foreignLanguageProficiencyStr,
        driverWaitingRoomAvailable: data?.driverWaitingRoomAvailable ?? selectedOptions.recruitRequest.driverWaitingRoomAvailable,
        actualWorkLocation: data?.actualWorkLocation ?? selectedOptions.recruitRequest.actualWorkLocation,
        educationalBackground: data?.educationalBackground ?? selectedOptions.recruitRequest.educationalBackground,
        specialNotes: data?.specialNotes ?? selectedOptions.recruitRequest.specialNotes,
      }))

      dispatch(updateDriverDispatchBasicInfo1({
        ...DriverDispatchOption?.basicInfo1,
        usageDate: data?.usageDate ?? selectedOptions.basicInfo1.usageDate,
      }))


      // await하지 않고, 모시러 서버에 저장
      SetMmEstimateHistory({
        ...getDdEstimateCookieDataAndDecrypt(),
        EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
        EstimatePage: PAGE.PAGE_07,
        JsonData: JSON.stringify({
          ...selectedOptions,
          basicInfo1: {
            ...selectedOptions.basicInfo1,
            usageDate: data?.usageDate ?? selectedOptions.basicInfo1.usageDate,
          },
          recruitRequest: {
            ...selectedOptions.recruitRequest,
            gender: data?.gender ?? selectedOptions.recruitRequest.gender,
            age20: data?.age20 ?? selectedOptions.recruitRequest.age20,
            age30: data?.age30 ?? selectedOptions.recruitRequest.age30,
            age40: data?.age40 ?? selectedOptions.recruitRequest.age40,
            age50: data?.age50 ?? selectedOptions.recruitRequest.age50,
            age60: data?.age60 ?? selectedOptions.recruitRequest.age60,
            ageboth: data?.ageboth ?? selectedOptions.recruitRequest.ageboth,
            driverSmokingStatus: data?.driverSmokingStatus ?? selectedOptions.recruitRequest.driverSmokingStatus,
            driverParkingSupport: data?.driverParkingSupport ?? selectedOptions.recruitRequest.driverParkingSupport,
            commutingVehicleProvided: data?.commutingVehicleProvided ?? selectedOptions.recruitRequest.commutingVehicleProvided,
            postHireJobResponsibilities: data?.postHireJobResponsibilities ?? selectedOptions.recruitRequest.postHireJobResponsibilities,
            benefits: data?.benefits ?? selectedOptions.recruitRequest.benefits,
            vehicleModelName: data?.vehicleModelName ?? selectedOptions.recruitRequest.vehicleModelName,
            foreignLanguageProficiency: data?.foreignLanguageProficiency ?? selectedOptions.recruitRequest.foreignLanguageProficiency,
            foreignLanguageProficiencyStr: data?.foreignLanguageProficiencyStr ?? selectedOptions.recruitRequest.foreignLanguageProficiencyStr,
            driverWaitingRoomAvailable: data?.driverWaitingRoomAvailable ?? selectedOptions.recruitRequest.driverWaitingRoomAvailable,
            actualWorkLocation: data?.actualWorkLocation ?? selectedOptions.recruitRequest.actualWorkLocation,
            educationalBackground: data?.educationalBackground ?? selectedOptions.recruitRequest.educationalBackground,
            specialNotes: data?.specialNotes ?? selectedOptions.recruitRequest.specialNotes,
          }
        })
      })

      dispatch(closeLoading())
      navigate(URLS.DRIVER_DISPATCH_USER_INFORMATION)
    }
  });


  const handleAddressChange = (subField, value, target) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      basicInfo2: {
        ...prevState.basicInfo2,
        [subField]: value,
      },
    }));
  };


  /*
  출근일
  성별  - 남, 여, 무관
  연령  - 20대 30대 40대 50대 60대 연령무관 (체크박스)
  기사 흡연여부 - 비흡연, 전자담배허용, 상관없음
  기사 주차 지원 - 가능, 불가능
  출퇴근 차량 제공 - 가능, 불가능
  입사 후 담당업무 - textarea
  복리후생 - textarea
  차량모델명
  외국어 가능여부
    - "유"로 했을떄
       - input box 나와서 무슨언어 필요한지 적어라
  기사 대기실 유무
  실 근무지 (앞에서 받고있음, 출근지)
  학력
    - 무관
    - 초대졸
    - 대졸
  특이사항
   - textarea
  7
  recruitRequest: {
  v  workStartDate: null,                    출근일 - Schedule                 
  v  gender: null,                           성별 - Option                    GenderOption                   
  v  age: null,                              연령 - Checkbox                   
  v  driverSmokingStatus: null,              기사 흡연 여부 - Option           DriverSmokingStatusOption           
  v  driverParkingSupport: null,             기사 주차 지원 - Option           DriverParkingSupportOption
  v  commutingVehicleProvided: null,         출퇴근 차량 제공 - Option         CommutingVehicleProvidedOption
  v  postHireJobResponsibilities: null,      입사 후 담당업무 - Textarea       
  v  benefits: null,                         복리후생 - Textarea               
  v  vehicleModelName: null,                 차량모델명 - Text                 
  v  foreignLanguageProficiency: null,       외국어 가능여부 - Option Text     ForeignLanguageProficiencyOption
  v  driverWaitingRoomAvailable: null,       기사 대기실 유무 - Option         DriverWaitingRoomAvaliableOption
    actualWorkLocation: null,               실 근무지 - Hidden                
  v  educationalBackground: null,            학력 - Option                     EducationalBackgroundOption
  v  specialNotes: null,                     특이사항 - Textarea               
  }
  


  수행파견_채용의뢰_출근일
  수행파견_채용의뢰_성별
  수행파견_채용의뢰_연령

  

  출근일_수행파견_채용의뢰                       - Schedule                 
  성별_수행파견_채용의뢰                       - Option                    GenderOption                   
  연령_수행파견_채용의뢰                       - Checkbox                   
  기사흡연여부_수행파견_채용의뢰                       - Option           DriverSmokingStatusOption           
  기사주차지원_수행파견_채용의뢰                       - Option           DriverParkingSupportOption
  출퇴근차량제공_수행파견_채용의뢰                       - Option         CommutingVehicleProvidedOption
  입사후담당업무_수행파견_채용의뢰                       - Textarea       
  복리후생_수행파견_채용의뢰                       - Textarea               
  차량모델명_수행파견_채용의뢰                       - Text                 
  외국어가능여부_수행파견_채용의뢰                       - Option Text     ForeignLanguageProficiencyOption
  기사대기실유무_수행파견_채용의뢰                       - Option         DriverWaitingRoomAvaliableOption
  실근무지_수행파견_채용의뢰                       - Hidden                
  학력_수행파견_채용의뢰                       - Option                     EducationalBackgroundOption
  특이사항_수행파견_채용의뢰                       - Textarea               

  */

  const updateCheckbox = (target, value) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      recruitRequest: {
        ...prevState.recruitRequest,
        [target]: value,
      },
    }));
    setValue(target, value)

    if (errors?.checkboxMsg?.message?.length >= 1) {
      if (value) {
        clearErrors('checkboxMsg')
      }
    }
  }




  return (
    <>

      <div className={classNames('booking-wrap')}>
        <PageTitle
          title={pageTitleInfo}
          subtitle={'채용 정보를 알려주세요'} />
        <form className={classNames('booking-form')} onSubmit={onSubmit}>
          <div className={classNames('booking-form-list')}>

            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>출근일</span>
              <ScheduleDatePicker2
                selectedDate={selectedOptions?.basicInfo1?.usageDate}
                onChange={(newDate) => {
                  setValue('usageDate', newDate)
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    basicInfo1: {
                      ...prevState.basicInfo1,
                      usageDate: newDate,
                    },
                  }));
                }}
              />
            </div>



            {/* 성별 */}
            {!!GenderOption && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>성별</span>
                <RadioGroup
                  classes={''}
                  legend={'purposeOfContract'}
                  selected={selectedOptions.recruitRequest.gender}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {GenderOption.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('gender', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              recruitRequest: {
                                ...prevState.recruitRequest,
                                gender: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.recruitRequest.gender === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}


            {/* 연령 */}
            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>연령</span>

              {isPC ? (
                <div className={classNames('rr-checkbox')}>
                  <CheckBox2
                    label={'20대'}
                    checked={selectedOptions.recruitRequest.age20}
                    onClick={e => {
                      updateCheckbox('age20', e.target.checked)

                    }}
                  />
                  <CheckBox2
                    label={'30대'}
                    checked={selectedOptions.recruitRequest.age30}
                    onClick={e => {
                      updateCheckbox('age30', e.target.checked)
                    }}
                  />
                  <CheckBox2
                    label={'40대'}
                    checked={selectedOptions.recruitRequest.age40}
                    onClick={e => {
                      updateCheckbox('age40', e.target.checked)
                    }}
                  />

                  <CheckBox2
                    label={'50대'}
                    checked={selectedOptions.recruitRequest.age50}
                    onClick={e => {
                      updateCheckbox('age50', e.target.checked)
                    }}
                  />
                  <CheckBox2
                    label={'60대'}
                    checked={selectedOptions.recruitRequest.age60}
                    onClick={e => {
                      updateCheckbox('age60', e.target.checked)
                    }}
                  />
                  <CheckBox2
                    label={'연령무관'}
                    checked={selectedOptions.recruitRequest.ageboth}
                    onClick={e => {
                      updateCheckbox('ageboth', e.target.checked)
                    }}
                  />
                </div>


              ) : (
                <>
                  <div className={classNames('rr-checkbox')}>
                    <CheckBox2
                      label={'20대'}
                      checked={selectedOptions.recruitRequest.age20}
                      onClick={e => {
                        updateCheckbox('age20', e.target.checked)
                      }}
                    />
                    <CheckBox2
                      label={'30대'}
                      checked={selectedOptions.recruitRequest.age30}
                      onClick={e => {
                        updateCheckbox('age30', e.target.checked)
                      }}
                    />
                    <CheckBox2
                      label={'40대'}
                      checked={selectedOptions.recruitRequest.age40}
                      onClick={e => {
                        updateCheckbox('age40', e.target.checked)
                      }}
                    />
                  </div>

                  <div className={classNames('rr-checkbox')}>
                    <CheckBox2
                      label={'50대'}
                      checked={selectedOptions.recruitRequest.age50}
                      onClick={e => {
                        updateCheckbox('age50', e.target.checked)
                      }}
                    />
                    <CheckBox2
                      label={'60대'}
                      checked={selectedOptions.recruitRequest.age60}
                      onClick={e => {
                        updateCheckbox('age60', e.target.checked)
                      }}
                    />
                    <CheckBox2
                      label={'연령무관'}
                      checked={selectedOptions.recruitRequest.ageboth}
                      onClick={e => {
                        updateCheckbox('ageboth', e.target.checked)
                      }}
                    />
                  </div>

                </>
              )}
              {errors?.checkboxMsg?.message && <span className={classNames('error')}>{errors?.checkboxMsg?.message}</span>}




            </div>

            {/* 기사 흡연 여부 */}
            {!!DriverSmokingStatusOption && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>기사 흡연 여부</span>
                <RadioGroup
                  classes={''}
                  legend={'purposeOfContract'}
                  selected={selectedOptions.recruitRequest.driverSmokingStatus}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {DriverSmokingStatusOption.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('driverSmokingStatus', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              recruitRequest: {
                                ...prevState.recruitRequest,
                                driverSmokingStatus: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.recruitRequest.driverSmokingStatus === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}

            {/* 출퇴근 차량 제공 */}
            {!!CommutingVehicleProvidedOption && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>출퇴근 차량 제공</span>
                <RadioGroup
                  classes={''}
                  legend={'purposeOfContract'}
                  selected={selectedOptions.recruitRequest.commutingVehicleProvided}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {CommutingVehicleProvidedOption.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('commutingVehicleProvided', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              recruitRequest: {
                                ...prevState.recruitRequest,
                                commutingVehicleProvided: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.recruitRequest.commutingVehicleProvided === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}

            {(selectedOptions.recruitRequest.commutingVehicleProvided === 'impossible') && <>
              {/* 기사 주차 지원 */}
              {!!DriverParkingSupportOption && (
                <div className={'booking-form-item'}>
                  <span className={classNames('title-mid')}>기사 주차 지원</span>
                  <RadioGroup
                    classes={''}
                    legend={'purposeOfContract'}
                    selected={selectedOptions.recruitRequest.driverParkingSupport}
                  >
                    <ul className={classNames('booking-item-list row')}>
                      {DriverParkingSupportOption.map((item) => (
                        <li key={item.title}>
                          <Radio
                            key={item.title}
                            value={item.value}
                            onChange={e => {
                              setValue('driverParkingSupport', item.value);
                              setSelectedOptions((prevState) => ({
                                ...prevState,
                                recruitRequest: {
                                  ...prevState.recruitRequest,
                                  driverParkingSupport: item.value,
                                },
                              }));
                            }}
                          >
                            <BookingItem
                              selected={selectedOptions.recruitRequest.driverParkingSupport === item.value}
                              subtitle={item?.title}
                              isAlignCenter={true}
                            />
                          </Radio>
                        </li>
                      ))}
                    </ul>
                  </RadioGroup>
                </div>
              )}
            </>}





            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>입사 후 담당업무</span>
              <Textarea
                required
                error={errors.postHireJobResponsibilities}
                {...register('postHireJobResponsibilities')}
                onChange={(e) => {
                  setValue('postHireJobResponsibilities', e.target.value)
                }}
                placeholder={'입사 후 담당업무 내용을 입력해주세요.'}

              />
            </div>

            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>복리후생</span>
              <Textarea
                required
                error={errors.benefits}
                {...register('benefits')}
                onChange={(e) => {
                  setValue('benefits', e.target.value)
                }}
                placeholder={'복리후생 내용을 입력해주세요'}

              />
            </div>

            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>차량모델명</span>
              <Input
                type={'text'}
                required
                error={errors.vehicleModelName}
                {...register('vehicleModelName')}
                onChange={(e) => {
                  setValue('vehicleModelName', e.target.value)
                }}
                placeholder={'ex. 제네시스 G90'}

              />
            </div>


            {/* 외국어 가능여부 */}
            {!!ForeignLanguageProficiencyOption && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>외국어 가능여부</span>
                <RadioGroup
                  classes={''}
                  legend={'purposeOfContract'}
                  selected={selectedOptions.recruitRequest.foreignLanguageProficiency}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {ForeignLanguageProficiencyOption.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('foreignLanguageProficiency', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              recruitRequest: {
                                ...prevState.recruitRequest,
                                foreignLanguageProficiency: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.recruitRequest.foreignLanguageProficiency === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>

                {selectedOptions.recruitRequest.foreignLanguageProficiency === 'possible' &&
                  <Input
                    type={'text'}
                    required
                    error={errors.foreignLanguageProficiencyStr}
                    {...register('foreignLanguageProficiencyStr')}
                    onChange={(e) => {
                      setValue('foreignLanguageProficiencyStr', e.target.value)
                    }}
                    placeholder={'ex. 영어, 일본어'}

                  />}

              </div>
            )}

            {/* 기사 대기실 유무 */}
            {!!DriverWaitingRoomAvaliableOption && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>기사 대기실 유무</span>
                <RadioGroup
                  classes={''}
                  legend={'purposeOfContract'}
                  selected={selectedOptions.recruitRequest.driverWaitingRoomAvailable}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {DriverWaitingRoomAvaliableOption.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('driverWaitingRoomAvailable', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              recruitRequest: {
                                ...prevState.recruitRequest,
                                driverWaitingRoomAvailable: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.recruitRequest.driverWaitingRoomAvailable === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}

            {/* 기사 대기실 유무 */}
            {!!EducationalBackgroundOption && (
              <div className={'booking-form-item'}>
                <span className={classNames('title-mid')}>학력</span>
                <RadioGroup
                  classes={''}
                  legend={'purposeOfContract'}
                  selected={selectedOptions.recruitRequest.educationalBackground}
                >
                  <ul className={classNames('booking-item-list row')}>
                    {EducationalBackgroundOption.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          value={item.value}
                          onChange={e => {
                            setValue('educationalBackground', item.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              recruitRequest: {
                                ...prevState.recruitRequest,
                                educationalBackground: item.value,
                              },
                            }));
                          }}
                        >
                          <BookingItem
                            selected={selectedOptions.recruitRequest.educationalBackground === item.value}
                            subtitle={item?.title}
                            isAlignCenter={true}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </div>
            )}

            <div className={'booking-form-item'}>
              <span className={classNames('title-mid')}>특이사항</span>
              <Textarea
                required
                error={errors.specialNotes}
                {...register('specialNotes')}
                onChange={(e) => {
                  setValue('specialNotes', e.target.value)
                }}
                placeholder={'특이사항을 입력해주세요.'}

              />
            </div>






          </div>
          <BookingFormSummary2
            description={description}>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => navigate(URLS.DRIVER_DISPATCH_ESTIMATE)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary2>
        </form>
      </div>
    </>
  )
}

export default RecuritRequest;