import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import BookingItem from '../../molecules/BookingItem';
import Button from '../../atoms/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';

import { UsageTimeOptionsDriverDispatch, UsageTimeOptionsDriverDispatchPagyun, BookingProducts } from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import { useBookingOptionContext } from '../../organisms/BookingOptionContext';
import { useDispatch, useSelector } from 'react-redux';
import { updateUsageTime } from '../../../reducers/bookingEstimation';
import PageTitle from '../../atoms/PageTitle';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { getValueByTarget } from '../../../utils/parse'
import MobileDescriptionPop from '../../organisms/MobileDescriptionPop';
import useCheckPC from '../../../hooks/useCheckPC';
import TotalAmount2 from '../../atoms/TotalAmount2';
import { updateVehicleOption } from '../../../reducers/bookingEstimation';


import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';
import {
  setSheetContractResponseTest,
  setSheetEstimateResponseTest,
  setSheetContractResponse,
  setSheetEstimateResponse,
  SetMmEstimateHistory,
  GetMmEstimateLatestHistory
} from '../../../lib/EstimateApi';
import { getDdEstimateCookieDataAndDecrypt, getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { updateSelect } from '../../../reducers/monthlyContractMonthArray';
import { getValue } from '@testing-library/user-event/dist/utils';
import BookingItemSizeUp from '../../molecules/BookingItemSizeUp';
import { useDriverDispatchOptionContext } from '../../organisms/DriverDispatchOptionContext';
import { updateDriverDispatchSelectedSalary, updateDriverDispatchUsageTime } from '../../../reducers/driverDispatchEstimation';

const UsageTimeDriverDispatch = () => {
  const { isPC } = useCheckPC();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { DriverDispatchOption } = useSelector((state) => state);

  const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
  const [showDescription, setShowDescription] = useState(false)

  const { handleSubmit, setValue } = useForm();

  const onSubmit = handleSubmit(async data => {

    dispatch(openLoading())
    const updatedOptions = {
      ...selectedOptions,
      usageTime: data?.usageTime ?? selectedOptions.usageTime,
      basicInfo2: {
        ...selectedOptions.basicInfo2,
        selectedSalary: data?.usageTime ?? selectedOptions.usageTime,
      }
    };
    setSelectedOptions(updatedOptions);


    // await하지 않고, 모시러 서버에 저장
    SetMmEstimateHistory({
      ...getDdEstimateCookieDataAndDecrypt(),
      EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
      EstimatePage: PAGE.PAGE_02,
      JsonData: JSON.stringify({
        ...selectedOptions,
        usageTime: data?.usageTime ?? selectedOptions.usageTime,
        basicInfo2: {
          ...selectedOptions.basicInfo2,
          selectedSalary: data?.usageTime ?? selectedOptions.usageTime,
        }
      })
    })


    dispatch(updateDriverDispatchUsageTime(data?.usageTime ?? selectedOptions.usageTime));
    dispatch(updateDriverDispatchSelectedSalary(data?.usageTime ?? selectedOptions.usageTime));


    // // await하지 않고, 모시러 서버에 저장
    // SetMmEstimateHistory({
    //   ...getEstimateCookieDataAndDecrypt(),
    //   EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
    //   EstimatePage: PAGE.PAGE_02,
    //   JsonData: JSON.stringify(updatedOptions)
    // })

    // navigate(URLS.BOOKING_ESTIMATION_VEHICLE_OPTION);
    navigate(URLS.DRIVER_DISPATCH_BASIC_INFO_2);
    dispatch(closeLoading())
  });


  return (
    <>
      {selectedOptions.subscription === 'recruit' ? <>
        <div className={classNames('booking-wrap')}>
          <PageTitle
            title={'상품 선택'}
            subtitle={'이용 시간을 선택하세요'}
            setShowDescription={setShowDescription}
          />
          <form className={classNames('booking-form')} onSubmit={() => onSubmit()}>
            <div className={classNames('booking-select-list')}>
              {!!UsageTimeOptionsDriverDispatch && (
                <RadioGroup
                  legend={'usageTime'}
                  selected={selectedOptions.usageTime}
                >
                  <ul className={classNames('booking-item-list')}>
                    {UsageTimeOptionsDriverDispatch.map((item) => (
                      <li key={item.title}>
                        <Radio
                          key={item.title}
                          onChange={e => {
                            setValue('usageTime', item.value);
                            const updatedOptions = {
                              ...selectedOptions,
                              usageTime: item.value,
                            };
                            setSelectedOptions(updatedOptions);
                            dispatch(updateDriverDispatchUsageTime(item.value));

                          }}
                        >
                          <BookingItemSizeUp
                            selected={selectedOptions.usageTime === item.value}
                            title={item?.title}
                            subtitle={item?.subtitle}
                            priceTitle={''}
                            price={item?.price}
                          />
                        </Radio>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              )}
            </div>
            <BookingFormSummary
              SubAmountObj={isPC ? TotalAmount2 : null}
              description={
                getValueByTarget(
                  UsageTimeOptionsDriverDispatch,
                  selectedOptions.usageTime,
                  'description'
                )
              }>
              <div className={classNames('button-set')}>
                <Button
                  onClick={
                    () => navigate(URLS.DRIVER_DISPATCH_SUBSCRIPTION)
                  }
                >이전</Button>
                <Button
                  styleType={'secondary'}
                  type={'submit'}
                >다음</Button>
              </div>
            </BookingFormSummary>
          </form>
        </div>
        {showDescription && !isPC && (
          <MobileDescriptionPop
            targetDictList={UsageTimeOptionsDriverDispatch}
            setShowDescription={setShowDescription}
            selectedOptions={selectedOptions}
          />
        )}
      </> : <>
      <div className={classNames('booking-wrap')}>
        <PageTitle
          title={'상품 선택'}
          subtitle={'이용 시간을 선택하세요'}
          setShowDescription={setShowDescription}
        />
        <form className={classNames('booking-form')} onSubmit={() => onSubmit()}>
          <div className={classNames('booking-select-list')}>
            {!!UsageTimeOptionsDriverDispatchPagyun && (
              <RadioGroup
                legend={'usageTime'}
                selected={selectedOptions.usageTime}
              >
                <ul className={classNames('booking-item-list')}>
                  {UsageTimeOptionsDriverDispatchPagyun.map((item) => (
                    <li key={item.title}>
                      <Radio
                        key={item.title}
                        onChange={e => {
                          setValue('usageTime', item.value);
                          const updatedOptions = {
                            ...selectedOptions,
                            usageTime: item.value,
                          };
                          setSelectedOptions(updatedOptions);
                          dispatch(updateDriverDispatchUsageTime(item.value));

                        }}
                      >
                        <BookingItemSizeUp
                          selected={selectedOptions.usageTime === item.value}
                          title={item?.title}
                          subtitle={item?.subtitle}
                          priceTitle={''}
                          price={item?.price}
                        />
                      </Radio>
                    </li>
                  ))}
                </ul>
              </RadioGroup>
            )}
          </div>
          <BookingFormSummary
            SubAmountObj={isPC ? TotalAmount2 : null}
            description={
              getValueByTarget(
                UsageTimeOptionsDriverDispatchPagyun,
                selectedOptions.usageTime,
                'description'
              )
            }>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => navigate(URLS.DRIVER_DISPATCH_SUBSCRIPTION)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                type={'submit'}
              >다음</Button>
            </div>
          </BookingFormSummary>
        </form>
      </div>
      {showDescription && !isPC && (
        <MobileDescriptionPop
          targetDictList={UsageTimeOptionsDriverDispatchPagyun}
          setShowDescription={setShowDescription}
          selectedOptions={selectedOptions}
        />
      )}
      
      
      </>}
      

    </>
  )
}
export default UsageTimeDriverDispatch